/* eslint-disable prefer-const */
import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Button,
  Label,
  Text,
} from '@resideo/blueprint-react';
import { InputField } from '../../common/InputField';
import { STATES } from '../../../config/states';
import { CloseBtn, ModalHeader, ModalBody } from './AddAddressModal';
import { Field } from 'formik';
import {
  ErrorInputField,
  useSwitchPlaceholder,
} from '../../ErrorInputField';
import { PROVINCES } from '../../../config/provinces';
import { useTranslation } from 'react-i18next';
import SeparatorLine from '../../common/SeparatorLine';

const EditAddressModal = ({
  setFieldValue,
  address,
  updateAddress,
  close,
  setErrors,
  errors,
  country,
  shippingOrBilling,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    setFieldValue(`${shippingOrBilling}.addressLine1`, address.addressLine1);
    setFieldValue(`${shippingOrBilling}.addressLine2`, address.addressLine2);
    setFieldValue(`${shippingOrBilling}.city`, address.city);
    setFieldValue(`${shippingOrBilling}.companyName`, address.companyName);
    setFieldValue(`${shippingOrBilling}.firstName`, address.firstName);
    setFieldValue(`${shippingOrBilling}.lastName`, address.lastName);
    setFieldValue(`${shippingOrBilling}.countryCode`, address.countryCode);
    setFieldValue(`${shippingOrBilling}.id`, address.id);
    setFieldValue(
      `${shippingOrBilling}.stateProvinceRegionCode`,
      address.stateProvinceRegionCode
    );
    setFieldValue(`${shippingOrBilling}.zipPostalCode`, address.zipPostalCode);
  }, [setFieldValue, address, shippingOrBilling]);

  return (
    <>
      <ModalHeader>
        <Heading className='add'>
          {t('cart.shipping.modal.editAddress')}
        </Heading>
        <CloseBtn close={close} setErrors={setErrors} />
      </ModalHeader>
      <ModalBody className='content'>
        <Box marginBottom='medium'>
          <ErrorInputField
            isError={errors.companyName}
            placeholder={useSwitchPlaceholder('Company Name', errors)}
            label='Company Name*'
            name={`${shippingOrBilling}.companyName`}
          />
        </Box>
        <Box display='flex' alignItems='flex-end'>
          <Box marginBottom='medium' flex={1}>
            <ErrorInputField
              isError={errors.firstName}
              placeholder={useSwitchPlaceholder('First Name', errors)}
              label='First Name*'
              name={`${shippingOrBilling}.firstName`}
              marginRight='small'
            />
          </Box>
          <Box marginBottom='medium' flex={1}>
            <InputField
              placeholder='Last Name'
              label='Last Name*'
              name={`${shippingOrBilling}.lastName`}
              marginLeft='small'
            />
          </Box>
        </Box>
        <Box display='flex' alignItems='flex-end'>
          <Box marginBottom='medium' flex={1}>
            <ErrorInputField
              isError={errors.addressLine1}
              placeholder={useSwitchPlaceholder('Address', errors)}
              label='Address Line 1*'
              name={`${shippingOrBilling}.addressLine1`}
              marginRight='small'
            />
          </Box>
          <Box marginBottom='medium' flex={1}>
            <InputField
              placeholder='Address'
              label='Address Line 2 (Optional)'
              name={`${shippingOrBilling}.addressLine2`}
              marginLeft='small'
            />
          </Box>
        </Box>
        <Box marginBottom='medium' flex={1}>
          <Label>
            <Text
              marginLeft='medium'
              paddingBottom={'xSmall'}
              color='black'
              fontSize='small'>
              Country*
            </Text>
          </Label>
          <Field
            style={{
              padding: '0.75rem 0.25rem',
              borderRadius: '15px',
              width: '100%',
              fontSize: '1rem',
            }}
            as='select'
            name={`${shippingOrBilling}.countryCode`}>
            <option value='US'>United States</option>
            {/* <option value='CA'>Canada</option> */}
          </Field>
        </Box>
        <Box
          display='flex'
          flexDirection={['column', 'row', 'row', 'row']}
          alignItems='flex-end'>
          <Box
            width='100% !important'
            marginBottom='medium'
            flex={['100%', '40%', '40%', '40%']}
            paddingRight={['0', 'small', 'small', 'small']}>
            <ErrorInputField
              isError={errors.city}
              placeholder={useSwitchPlaceholder('City', errors)}
              label='City*'
              name={`${shippingOrBilling}.city`}
            />
          </Box>
          <Box
            marginBottom='medium'
            width='100% !important'
            flex={['100%', '60%', '60%', '60%']}>
            <Flex display='flex' alignItems='flex-end'>
              <Box flex={['60%', '50%', '50%', '50%']}>
                <Label>
                  <Text
                    marginLeft='medium'
                    paddingBottom={'xSmall'}
                    color='black'
                    fontSize='small'>
                    State*
                  </Text>
                </Label>
                <Field
                  style={{
                    padding: '0.75rem 0.25rem',
                    borderRadius: '15px',
                    width: '100%',
                    fontSize: '1rem',
                  }}
                  as='select'
                  name={`${shippingOrBilling}.stateProvinceRegionCode`}>
                  {country === 'Canada'
                    ? PROVINCES.map((obj, i) => (
                        <option key={i} value={obj.value}>
                          {obj.value}
                        </option>
                      ))
                    : STATES.map((obj, i) => (
                        <option
                          disabled={obj.value === 'Select a State'}
                          key={i}
                          value={obj.value}>
                          {obj.value}
                        </option>
                      ))}
                </Field>
              </Box>
              <Box flex={['40%', '50%', '50%', '50%']} paddingLeft='small'>
                <ErrorInputField
                  isError={errors.zipPostalCode}
                  placeholder={useSwitchPlaceholder('Zip code', errors)}
                  label='Zip code*'
                  name={`${shippingOrBilling}.zipPostalCode`}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
        <SeparatorLine />
        <Box display='flex' justifyContent='flex-end'>
          <Button
            data-test-shipping-add-new-address
            onClick={close}
            variant='secondary'
            marginRight='medium'
            paddingX='2.5rem'
            type='submit'>
            {t('common.cancel')}
          </Button>
          <Button
            data-test-shipping-add-new-address
            onClick={() => updateAddress(address)}
            variant='primary'
            paddingX='2.85rem'
            type='submit'>
            {t('cart.shipping.modal.save')}
          </Button>
        </Box>
      </ModalBody>
    </>
  );
};

export default EditAddressModal;
