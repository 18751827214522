export const types = {
    UPDATE_DR_PAYMENT: 'UPDATE_DR_PAYMENT',
    UPDATE_DR_PAYMENT_SUCCESS: 'UPDATE_DR_PAYMENT_SUCCESS',
    UPDATE_DR_PAYMENT_FAIL: 'UPDATE_DR_PAYMENT_FAIL',
    CREATE_DR_NEW_BILLING: 'CREATE_DR_NEW_BILLING',
    CREATE_DR_NEW_BILLING_SUCCESS: 'CREATE_DR_NEW_BILLING_SUCCESS',
    CREATE_DR_NEW_BILLING_FAIL: 'CREATE_DR_NEW_BILLING_FAIL',
    CREATE_DR_BILLING_RESPONSE: 'CREATE_DR_BILLING_RESPONSE',
    CREATE_DR_BILLING_RESPONSE_SUCCESS: 'CREATE_DR_BILLING_RESPONSE_SUCCESS',
    CREATE_DR_BILLING_RESPONSE_FAIL: 'CREATE_DR_BILLING_RESPONSE_FAIL',
};
