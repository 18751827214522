import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchingProductList: false,
    productList: [],
    fetchingProductListError: null,
}

const productListData = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch(type) {
        case types.FETCH_PRODUCT_LIST:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingProductList = true;
            })
        case types.FETCH_PRODUCT_LIST_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingProductList = false;
                draftState.productList   = payload
            })
        case types.FETCH_PRODUCT_LIST_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingProductList = false;
                draftState.fetchingProductListError   = payload
            })
        default:
            return state;
    }
}

export { productListData }