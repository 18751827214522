import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { getProductListUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import { getProductListSuccess, getProductListFail } from './actions';

function* getProductList({ payload }: any): any {
    const { id, pageNumber } = payload;
    try {
        const url = getProductListUrl + `${id ? id : 'RB2B'}/products?currentPage=${pageNumber}`;
        const response = yield call(api, url, httpRequest.GET, null, 2, 0, false, true);
        yield put(getProductListSuccess(response));
    } catch (error) {
        yield put(getProductListFail(error));
    }
}

function* getProductListWatcher() {
    yield takeLatest(types.FETCH_PRODUCT_LIST, getProductList);
}

export default function* productListSaga() {
    yield all([getProductListWatcher()]);
}
