import React, { ComponentType, FC, lazy, useCallback, useContext, useEffect } from 'react';
import { Switch, useRouteMatch, useLocation, Redirect } from 'react-router-dom';
//import Route, { AnonymousRoute } from '../../components/common/Route';
import { Route } from 'react-router-dom';
import { CartProvider } from './CartProvider';
import styled from 'styled-components';
import SecondaryNavigation from '../../components/common/SecondaryNavigation';
import { Flex } from '@resideo/blueprint-react';
import Cart from './Cart';
import PDP from './PDP';
import ProductListPage from './PLP/ProductListPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { handleAuthenticationCallback } from 'redux/authentication/action';
import { getJWtDetails } from 'utils/utils';
import { withPersistGate } from 'components/HOC/withPersistGate';

type LazyModulePromise<T = Record<string, unknown>> = Promise<{
    default: ComponentType<T>;
}>;

//const SelectPlan = lazy((): LazyModulePromise => import('./SelectPlan'));
//const Cart = lazy((): LazyModulePromise => import('./Cart'));
//const PDP = lazy((): LazyModulePromise => import('./PDP'));

const MainContainer = styled(Flex)`
    border-bottom: 1px solid lightgray;
    height: 100%;
`;

const ContentWrapper = styled(Flex)<{
    withSubNav?: boolean;
}>`
    height: 100%;
    padding-top: ${({ withSubNav }) => (withSubNav ? '4rem' : '0')};

    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        padding-top: 0;
    }
`;

const MyBusinessLayout: FC<any> = ({ children, menuItems, pathName }) => {
    //const environment = useRelayEnvironment();
    //const { cartActions } = useContext(CartContext);
    const path: string = pathName ?? '';
    const withSubNav = !(
        path.includes('cart') ||
        path.includes('pdp') ||
        path.includes('select-plan') ||
        path.includes('invoice')
    );
    useEffect(() => {
        //programProductData();
    }, []);

    return (
        <MainContainer flexDirection='column' width={1}>
            <SecondaryNavigation menuItems={menuItems} />
            <ContentWrapper withSubNav={withSubNav}>{children}</ContentWrapper>
        </MainContainer>
    );
};

const ServicesAndSubscriptionRoutes: FC = () => {
    const { isLoggedIn, loginInProgress } = useSelector((state: RootState) => state.authentication);
    const { isSignedIn } = getJWtDetails();
    const { path } = useRouteMatch();
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isSignedIn) {
            dispatch(handleAuthenticationCallback());
        }
    }, [isSignedIn]);

    return (
        <CartProvider>
            <Switch>
                <Route exact path={path} component={ProductListPage} />
                <Route path={`${path}cart`} component={Cart} />
                <Route path={`${path}pdp`} component={PDP} />
            </Switch>
        </CartProvider>
    );
};

const Services = withPersistGate(ServicesAndSubscriptionRoutes);

export default Services;
