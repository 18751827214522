export const parseReviewOrder = (rawData: any) => {
    const model = {
        email: '',
        phone: '',
        shippingAddress: {
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        shippingMethod: '',
        billingAddress: {
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        card: {
            type: '',
            image: '',
            lastFourNums: '',
            cvc: '',
            exp: '',
            firstNum: '',
            name: '',
            number: '',
        },
        paymentMethodType: '',
        paymentMode: '',
        terms: false,
    };

    if (!rawData) return model;
    
    if (rawData.drpaymentInfo) {
        const lastFourNums = rawData?.drpaymentInfo?.drcclastFourDigits;
        const expiryYear = rawData?.drpaymentInfo?.drccexpiryYear;
        model.card.lastFourNums = lastFourNums;
        model.card.exp = rawData?.drpaymentInfo?.drccexpiryMonth + '/' + expiryYear;
        model.card.type = rawData?.drpaymentInfo?.drccbrand?.toUpperCase();
        model.card.name = rawData?.drpaymentInfo?.draccountHolderName;
        model.paymentMode = rawData?.drpaymentInfo?.drSourceType;
        if (rawData.drpaymentInfo.billingAddress) {
            model.billingAddress.contactName =
                rawData?.drpaymentInfo?.billingAddress?.firstName +
                ' ' +
                rawData?.drpaymentInfo?.billingAddress?.lastName;
            model.billingAddress.addressLine1 = rawData?.drpaymentInfo?.billingAddress?.line1;
            model.billingAddress.addressLine2 = rawData?.drpaymentInfo?.billingAddress?.line2;
            model.billingAddress.city = rawData?.drpaymentInfo?.billingAddress?.town;
            model.billingAddress.stateProvinceRegionCode =
                rawData?.drpaymentInfo?.billingAddress?.region?.isocode;
            model.billingAddress.zipPostalCode = rawData?.drpaymentInfo?.billingAddress?.postalCode;
        }
    }
    if (rawData.deliveryAddress) {
        model.shippingAddress.contactName =
            rawData?.deliveryAddress?.firstName + ' ' + rawData?.deliveryAddress?.lastName;
        model.shippingAddress.addressLine1 = rawData?.deliveryAddress?.line1;
        model.shippingAddress.addressLine2 = rawData?.deliveryAddress?.line2;
        model.shippingAddress.city = rawData?.deliveryAddress?.town;
        model.shippingAddress.stateProvinceRegionCode = rawData?.deliveryAddress?.region?.isocode;
        model.shippingAddress.zipPostalCode = rawData?.deliveryAddress?.postalCode;
    }
    if (rawData.deliveryMode) {
        model.shippingMethod = rawData?.deliveryMode.name;
    }
    if (rawData.email) {
        model.email = rawData?.email;
        model.phone = rawData?.phoneNumber;
    }
    if (rawData.paymentType) {
        model.paymentMethodType = rawData?.paymentType?.displayName;
    }

    return model;
};
