import { call, all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { getCartUrl, addProductInCartUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    getCardIDSuccess,
    getCardIDFail,
    addProductInCartSuccess,
    addProductInCartFail,
    updateCartQuantitySuccess,
    updateCartQuantityFail,
    removeCartItemSuccess,
    removeCartItemFail,
    getCardDetailsSuccess,
    getCardDetailsFail,
    createCardIDSuccess,
    createCardIDFail,
} from './actions';
import { storeCartID, retrieveCartID, storeCartItems, retrieveCartItems, removeCartItems } from '.';
import { setCartItems } from 'redux/checkout/action';

function* parseCartIDResponse(response: any) {
    const { code } = response;
    yield call(storeCartID, code);
}

function* getCartDetails(): any {
    let cartID: string = '';
    try {
        cartID = yield call(retrieveCartID);
        if (!cartID) {
            cartID = yield createCartIdDetails();
        }
        return { cartID };
    } catch (error) {
        console.log('getCartDetails', error);
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* parseCartItemResponse(response: any) {
    yield call(storeCartItems, JSON.stringify(response));
}

function* getCartItems(): any {
    let cartItems: Array<any> = [];
    try {
        cartItems = yield call(retrieveCartItems);
        return { cartItems };
    } catch (error) {
        console.log('cartItems', error);
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* createCartIdDetails(): any {
    //const { cartID } = yield* getCartDetails();
    const cartData = yield* getCartIDDetails();
    const NewCartId =
        cartData && cartData.carts && cartData.carts.length > 0 ? cartData?.carts[0]?.code : '';
    if (!NewCartId) {
        try {
            const url = getCartUrl + `current/carts`;
            const response = yield call(api, url, httpRequest.POST, {}, 0, 0);
            if (response) {
                //yield parseCartIDResponse(response);
                const { code } = response;
                yield call(storeCartID, code);
                return code;
            } else {
                throw new Error('Something Wrong');
            }
            yield put(createCardIDSuccess(response));
        } catch (error) {
            yield put(createCardIDFail(error));
        }
    } else {
        //const { cartID } = yield* getCartDetails();
        const cartID = yield call(retrieveCartID);
        if (!cartID) {
            yield call(storeCartID, NewCartId);
            return NewCartId;
        }
    }
}

function* getCartIDDetails(): any {
    try {
        const url = getCartUrl + `current/carts`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getCardIDSuccess(response));
        return response;
    } catch (error) {
        yield put(getCardIDFail(error));
        return error;
    }
}

function* addProductInCart({ payload, resolve, reject }: any): any {
    const { body } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = addProductInCartUrl + `current/carts/${cartID}/entries/`;
        const response = yield call(api, url, httpRequest.POST, body, 0, 0);
        yield put(addProductInCartSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(addProductInCartFail(error));
        reject(error);
    }
}

function* updateCartItemQty({ payload }: any): any {
    const { body } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = addProductInCartUrl + `current/carts/${cartID}/entries/`;
        const response = yield call(api, url, httpRequest.PUT, body, 0, 0);
        yield put(updateCartQuantitySuccess(response));
    } catch (error) {
        yield put(updateCartQuantityFail(error));
    }
}

function* removeCartItem({ payload, resolve, reject }: any): any {
    const { entryNumber } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = getCartUrl + `current/carts/${cartID}/entries/${entryNumber}`;
        const response = yield call(api, url, httpRequest.DELETE, {}, 0, 0);
        yield put(removeCartItemSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(removeCartItemFail(error));
        reject(error);
    }
}

function* getCartId(): any {
    let cartID: string = '';
    try {
        cartID = yield call(retrieveCartID);
        return { cartID };
    } catch (error) {
        console.log('getCartDetails', error);
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* getCartListDetails({ payload }: any): any {
    const { cartID } = yield* getCartId();
    try {
        if (cartID) {
            const url = getCartUrl + `current/carts/${cartID}/?fields=FULL`;
            const response = yield call(api, url, httpRequest.GET, null, 2);
            if (response) {
                yield parseCartItemResponse(response);
            } else {
                throw new Error('Something Wrong');
            }
            yield put(setCartItems(response));
            yield put(getCardDetailsSuccess(response));
        }
    } catch (error) {
        yield call(removeCartItems);  
        yield put(getCardDetailsFail(error));
    }
}

function* getCardIdWatcher() {
    yield takeLatest(types.FETCH_CART_ID, getCartIDDetails);
}

function* createCardIdWatcher() {
    yield takeLatest(types.CREATE_CART_ID, createCartIdDetails);
}

function* addCartWatcher() {
    yield takeLatest(types.ADD_PRODUCT_IN_CART, addProductInCart);
}

function* updateCartWatcher() {
    yield takeLatest(types.UPDATE_CART_QUANTITY, updateCartItemQty);
}

function* removeCartItemWatcher() {
    yield takeLatest(types.REMOVE_CART_ITEM, removeCartItem);
}

function* getCartDetailsWatcher() {
    yield takeLatest(types.FETCH_CART_DETAILS, getCartListDetails);
}

export default function* cartSaga() {
    yield all([
        getCardIdWatcher(),
        createCardIdWatcher(),
        addCartWatcher(),
        updateCartWatcher(),
        removeCartItemWatcher(),
        getCartDetailsWatcher(),
    ]);
}
