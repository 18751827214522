import { store } from '../redux/store';
import moment from 'moment';
import jwt_decode from "jwt-decode";

//TODO: Fix timestamp
export const getJWtDetails = (jwt?: any) => {
    const token = localStorage.getItem('jwt');
    const body = {  tokenExists: false, isSignedIn: false, profile: {} }
    //const { token } = authentication;
    const storedToken = jwt || token;
    const now = moment();
    if (storedToken) {
        const result: any = jwt_decode(storedToken);
        const { exp } = result
        body.tokenExists = true;
        body.isSignedIn = moment(exp * 1000).isAfter(now);
        body.profile = result;
    } 
    return body
};