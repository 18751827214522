import AsyncLocalStorage from '@createnextapp/async-local-storage'

export const setExp = (expirationDate: any) => new Promise((resolve, reject) => AsyncLocalStorage.setItem('expires_in', expirationDate).then(() => resolve(expirationDate)).catch((error) => reject(error)))

export const storeJwt = (jwt: any) => new Promise((resolve, reject) => AsyncLocalStorage.setItem('jwt', jwt).then(() => resolve(jwt)).catch((error) => reject(error)))
export const storeRefreshToken = (refreshToken: any) => new Promise((resolve, reject) => AsyncLocalStorage.setItem('jwt-refresh-token', refreshToken).then(() => resolve(refreshToken)).catch((error) => reject(error)))

export const retrieveExpiresIn = () => new Promise<void>((resolve, reject) => AsyncLocalStorage.getItem('expires_in').then((expires_in) => resolve(expires_in)).catch((error) => reject(error)))
export const retrieveJwt = () => new Promise<void>((resolve, reject) => AsyncLocalStorage.getItem('jwt').then((jwt) => resolve(jwt)).catch((error) => reject(error)))
export const retrieveRefreshToken = () => new Promise<void>((resolve, reject) => AsyncLocalStorage.getItem('jwt-refresh-token').then((refreshToken) => resolve(refreshToken)).catch((error) => reject(error)))

export const removeJwt = () => new Promise<void>((resolve, reject) => AsyncLocalStorage.removeItem('jwt').then(() => resolve()).catch((error) => reject(error)))
export const removeRefreshToken = () => new Promise<void>((resolve, reject) => AsyncLocalStorage.removeItem('jwt-refresh-toke').then(() => resolve()).catch((error) => reject(error)))