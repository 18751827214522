import React, { FC } from 'react';
import { Box, Flex, Heading, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { convertMoneyAmount } from '../../../routes/Products/Cart';
import { useTranslation } from 'react-i18next';
import ServiceIconLarge from '../../../assets/images/ServiceIconLarge';

const Container = styled(Flex)`
    position: relative;
    margin: 1rem 0 2rem 0;
    padding: 0.85rem 0 2rem 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightenGray};
`;

const ImageContainer = styled(Flex)`
    border: 2px solid ${({ theme }) => theme.colors.lightenGray};
`;

const ShippingOrderItem: FC<any> = ({ data }) => {
    const { t } = useTranslation();
    return (
        <Container alignItems='center'>
            <Box flex='75%' display='flex' alignItems='center'>
                <Box display={['block', 'block', 'flex', 'flex']} alignItems='center'>
                    <ImageContainer
                        padding='medium'
                        height={['70px', '115px', '115px', '115px']}
                        width={['70px', '115px', '115px', '115px']}
                        marginRight='medium'
                        justifyContent='center'
                        alignItems='center'>
                        {/* TODO : Need to enable when dynamic images available */}
                        {/* {data && data?.product?.images ? (
                        <Image
                          src={data?.product?.images[0].url}
                          alt='box image'
                          maxWidth='115px'
                          height='115px'
                        />
                      ) : (
                        <ServiceIconLarge />
                      )} */}
                        <ServiceIconLarge />
                    </ImageContainer>
                </Box>
                <Flex flex={1} alignItems='center' justifyContent='space-between'>
                    <Box>
                        {data?.product?.itemType?.toLowerCase() === 'product' ? (
                            <Heading
                                fontSize={['small', 'small', 'medium', 'medium']}
                                fontWeight='bold'
                                color='cartTextHeading'
                                letterSpacing='normal'
                                className='cursor-pointer'>
                                {data?.product?.name}
                            </Heading>
                        ) : (
                            <Heading
                                fontSize={['small', 'small', 'medium', 'medium']}
                                fontWeight='bold'
                                color='cartTextHeading'
                                letterSpacing='normal'
                                className='cursor-pointer'>
                                <Heading
                                    fontSize={['small', 'small', 'medium', 'medium']}
                                    fontWeight='bold'
                                    color={'textLight3'}
                                    letterSpacing='normal'
                                    as='span'>
                                    {data?.product?.name}
                                </Heading>{' '}
                                <br />
                                <>
                                    {/* {`${t('cart.shipping.then')}${
                                        data.recurringPricePerDevice || ''
                                    } `}
                                    {t('cart.shipping.perDevice')}
                                    {data.month
                                        ? t('cart.shipping.month')
                                        : t('cart.shipping.year')}
                                    <br />
                                    {t('cart.shipping.plusTaxes')} */}
                                </>
                            </Heading>
                        )}
                        <Text
                            color='gray'
                            fontSize='small'
                            fontWeight='500'
                            paddingTop='small'
                            paddingBottom='small'>
                            {data?.product?.code}
                        </Text>
                    </Box>
                    <Heading color='cartTextHeading'>x{data?.quantity || 0}</Heading>
                </Flex>
            </Box>
            <Box flex='25%'>
                <Heading
                    color='cartTextHeading'
                    letterSpacing='normal'
                    fontSize='medium'
                    fontWeight='bold'
                    textAlign='right'>
                         {convertMoneyAmount(
                                {
                                    amount: data?.totalPrice?.value ?? 0,
                                    currencyCode: data?.totalPrice?.currencyIso ?? 'USD',
                                },
                                'en-us',
                            )}
                </Heading>
            </Box>
        </Container>
    );
};

export default ShippingOrderItem;
