import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Cart from './Cart';
import Shipping from './Shipping';
import Payment from './Payment';
import ReviewOrder from './ReviewOrder';
import Order from './Order';
import PageLoading from '../../../components/common/PageLoading';
import PrintFriendlyView from './PrintFriendlyView';
import Page from '../../../components/common/Page';
import { RootState } from '../../../redux/root-reducer';
import { useSelector } from 'react-redux';

interface MoneyProps {
    amount: number | string;
    currencyCode: string;
}

export const convertMoneyAmount = (value: MoneyProps, locale) => {
    if (value === null || value === undefined || locale === undefined || !value?.currencyCode){
        return '';
    }else {
        return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: value?.currencyCode || 'USD',
    }).format(+value?.amount || 0);
  }
};

const CartRoutes: FC = () => {
    const { path } = useRouteMatch();
    const isLoading = useSelector((state: RootState) => state.checkOutDetails.isLoading);

    return (
        <Page as='main' maxWidth='1375px' data-test-cart-wrapper>
            {isLoading && <PageLoading />}
            <Switch>
                <Route exact path={path} component={() => <Cart />} />
                <Route path={`${path}/shipping`} component={() => <Shipping />} />
                <Route
                    path={`${path}/payment`}
                    component={() => <Payment viewer={null} />}
                />
                <Route
                    path={`${path}/review-order`}
                    component={() => <ReviewOrder data-test-review-order-page />}
                />
                <Route path={`${path}/order/:id`} component={() => <Order />} />
                <Route
                    path={`${path}/print-friendly-view/:id`}
                    component={() => <PrintFriendlyView />}
                />
            </Switch>
        </Page>
    );
};

export default CartRoutes;
