import styled, { keyframes } from 'styled-components';
import { MenuList, MenuLink } from '@reach/menu-button';
interface MainContainerProps {
    transform?: any;
}

const animateCartDropdownDesktop = keyframes`
  0% {
    top: 20px;
  }

  100% {
    top: 0px;
  }
`;

export const MainContainer = styled(MenuList) <MainContainerProps>`
    display: block;
    position: absolute;
    background: white;
    left: 0;
    padding: 1.25rem 1rem;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    right: auto;
    -webkit-transform: ${(props) => (props.transform ? `translate(-${props.transform}px, 0)` : 0)};
    -o-transform: ${(props) => (props.transform ? `translate(-${props.transform}px, 0)` : 0)};
    //transform: translate(-20%, 0);
    transform: ${(props) => (props.transform ? `translate(-${props.transform}px, 0)` : 0)};
    z-index: var(--zidx-profile-menu);

    &.rdeCardOpen {
        top: 20px;
        display: block;
        overflow: visible;
        height: auto;
        animation: ${animateCartDropdownDesktop} 0.3s ease;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
        @media (max-width: 992px) {
            display: none;
        }
    }

    // margin-right: auto;
    // margin-left: auto;
    // max-width: 1200px;
    // @media (min-width: 768px) {
    //     width: 750px;
    // }
    // @media (min-width: 992px) {
    //     width: 970px;
    // }
    // @media (min-width: 1200px) {
    //     width: 1170px;
    // }
    // @media (min-width: 1400px) {
    //     width: 1375px;
    // }
    @media (max-width: 992px) {
        display: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 218px;
    background: #ededed;
    margin-top: 40px;
    transition: all 0.3s ease;
    position: relative;
    flex-grow: 0;
    margin: 0 8px;
    padding: 8px;
    width: 100%;
    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    }

    @media (min-width: 992px) {
        height: auto;
        min-height: 274px;
        &:hover {
            transform: translateY(-8px);
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 120px;
    }
`;

export const Container = styled.div`
    width: 140px;
    white-space: normal;
    height: auto;
`;

export const ImageBox = styled.img`
    width: 84px;
    height: 84px;
    margin: 0 0 16px 0;
`;
export const Caption = styled.div`
    width: 100%;
    text-align: center;
`;
export const Heading = styled.h2`
    font-family: 'roboto', sans-serif;
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 14px;
    margin: 0 0 8px 0;
`;

export const Paragraph = styled.p`
    font-size: 14px;
    margin: 0;
`;

export const ViewAllProductsButton = styled.button`
    display: inline-flex;
    position: relative;
    width: 250px;
    height: 48px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    color: #166a96;
    font-size: 14px;
    font-family: roboto;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 12px 46px;
    border: 2px solid #166a96;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    transition: color 250ms ease-in-out;
    background-color: white;
    text-decoration: none !important;
    text-align: center;
    border-radius: 0;
    transition: all 0.3s ease;

    ::before {
        content: '';
        width: 250px;
        height: 48px;
        position: absolute;
        background-color: #166a96;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 250ms ease-in-out;
    }
    :hover {
        color: white;
    }
    :hover::before,
    :focus::before {
        transform: scaleX(1);
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 200px;
    }
`;

export const ProductsButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 16px;
    @media (min-width: 992px) and (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        width: 160px;
        height: auto;
        white-space: normal;
    }
`;

export const ViewProductsButton = styled.button`
    background: transparent;
    border: 1px solid transparent;
    height: 48px;
    color: #166a96;
    position: relative;
    font-weight: bold;
    font-size: 14px;
    font-family: roboto;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
`;

export const ViewProductsArrow = styled.img`
    height: 10px;
    width: 20px;
    left: 0px;
    &.move-arw {
        position: relative;
        -webkit-transition-duration: 1.5s;
        -webkit-transition-timing-function: linear;
        &:hover,
        :focus {
            left: 20px;
        }
    }
`;

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 16px;
    padding: 0px 8px;
    &:after {
        position: absolute;
        top: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background: #dadada;
        content: '';
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
`;

export const MenuListWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-right: 12px;

    > div > a {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #166a96;
        line-height: 16px;
        margin-bottom: 10px;
        text-decoration: none;
        background-color: transparent;
    }

    > div > a:hover {
        color: #004266;
        text-decoration: none;
        cursor: pointer;
    }

    > div > hr {
        border: 1px solid rgba(255, 255, 255, 0.15);
    }
`;

export const SectionTitleAndIconContainer = styled.div`
    display: flex;
    align-items: center;
    height: 35px;
    font-size: 16px;
    font-weight: 700;
    color: #060202;
    text-transform: uppercase;
    border-bottom: 1px solid #dadada;
    padding-bottom: 16px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 8px;
    margin-bottom: 16px;
`;

export const SectionIcon = styled.img`
    width: 35px;
    margin-right: 12px;
    margin-bottom: 0;
    display: block;
    max-width: 100%;
    height: 45.76px;
    vertical-align: middle;
    border: 0;
`;

export const SectionTitle = styled.span`
    height: 24px;
    font-size: 16px;
    font-weight: 700;
    color: #060202;
    text-transform: uppercase;

    white-space: normal;
    float: left;
    height: auto;
`;

export const CustomMenuLink = styled(MenuLink)`
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #166a96;
    line-height: 16px;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: transparent;
    padding: 0px;
    letter-spacing: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: left;

    white-space: normal;
    float: left;
    width: 100%;
    height: auto;
    word-wrap: break-word;
    &:hover {
        color: #004266;
        text-decoration: none;
        cursor: pointer;
    }
`;

export const MenuListContainer = styled.div`
    width: 140px;
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 120px;
    }
`;

export const HeaderText = styled.p`
    display: flex;
    margin: 16px 0 8px 32px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.4;
`;

export const SideDealContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    width: 140px;
    float: left;
    margin: 0 16px 16px 16px;
    background: transparent;
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 120px;
        margin: 0 8px 8px 8px;
    }
`;

export const SideDealImageBox = styled.img`
    border-radius: 4px;
    opacity: 1.015;
    width: 140px;
    height: auto;
    min-width: inherit;
    min-height: inherit;
    margin-bottom: 8px;
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        width: 120px;
    }
`;

export const TitleBox = styled.p`
    display: block;
    min-height: auto;
    //max-height: 38px;
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    overflow: hidden;
    line-height: 20px;
    text-align: center;
    white-space: normal;
    float: left;
    width: 100%;
    height: auto;
    font-family: 'roboto', sans-serif;
    &:hover {
        color: #166a96;
    }
`;

export const HomeContainer = styled.div`
    margin-bottom: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: auto;
`;
export const ImageWrapper = styled.img`
    position: relative;
    width: 100%;
    max-height: 150px;
`;
export const SideDealCaption = styled.div`
    flex-grow: 1;
    text-align: center;
    background: #2e2e2e;
    padding: 16px;
`;
export const SideDealHeading = styled.h2`
    position: relative;
    font-family: 'roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin: 0 0 4px 0;
    padding: 0 20px 0 0;
    color: #fff;
`;

export const Description = styled.p`
    font-size: 14px;
    color: #fff;
    line-height: 21px;
    margin: 0;
    white-space: normal;
`;
