import React, { useState } from 'react';
import styled,{keyframes} from 'styled-components';
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button';
import { Image, Flex, Heading, Box } from '@resideo/blueprint-react';
import { downArrowIcon, upArrowIcon } from '../../assets/images';
import SeparatorLine from 'components/common/SeparatorLine';

const Button = styled(MenuButton)`
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border: none;
    padding-left: 6px;
    padding-right: 22px;
    &:hover {
        color: #fff;
        opacity: 0.7;
    }
    &.btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
`;
const animateLanguageDropdown = keyframes`
  0% {
    top: 65px;
  }

  100% {
    top: 50px;
  }
`;
const CustomMenuList = styled(MenuList)`
    position: fixed;
    right: 10px;
    top: 60px;
    background-color:white;
    margin-top: 30px;
    padding: 1rem;
    box-sizing: border-box;
    animation: ${animateLanguageDropdown} 0.3s ease;
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        right: 50px;
        top: 50px;
        width: auto;
    }
`;

const CustomMenuLink = styled(MenuLink)`
    height: 20px;
    color: #286DAA;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 19px;
    text-decoration: none;
    padding-bottom: 11px;
    &:hover {
          color: #153755;
          text-decoration: underline;
          cursor: pointer;
      }
`;

const CustomHeading = styled(Heading)`
  text-transform: uppercase;
  float:left;
  height: 16px;
  width: 175px;
  color: #060202;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 16.5px;
`;
const StyledImage = styled(Image)`
    width: 22px;
    height: 18px;
    margin-right: 16px;
    opacity: 1.004;
`;

const languageList = {
  header: 'Country Selector',
  leftItems: [
    {
      button: 'Österreich | Deutsch',
      buttonLink: 'https://www.resideo.com/de/de/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/Flag_Austria_38x27.jpg?rv=5d12ac6f68d6461395604e9cb8727fe0'
    },
    {
      button: 'Deutschland | Deutsch',
      buttonLink: 'https://www.resideo.com/de/de/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/germany-flag.png?rv=550b57eb6c094353bf78892a273aad73'
    },
    {
      button: 'Great Britain | English',
      buttonLink: 'https://www.resideo.com/gb/en/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/gb-flag.png?rv=ee87070e5b874cc38a1ca3c8b9b71524'
    },
    {
      button: 'Polska | Polski',
      buttonLink: 'https://www.resideo.com/pl/pl/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/Polish_flag_38x27.jpg?rv=5ca89ac12516499f9ae8934a9f43a025'
    },
  ],
  rightItems: [
    {
      button: 'Schweiz | Deutsch',
      buttonLink: 'https://www.resideo.com/de/de/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/Flag_Switzerland_38x27.jpg?rv=c2fbc0030c724da586a194619b5e689f'
    },
    {
      button: 'EMEA | English',
      buttonLink: 'https://www.resideo.com/emea/en/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/flag_emea.png?rv=14827583aae8457e99787ad282f46dae'
    },
    {
      button: 'Nederland | Nederlands',
      buttonLink: 'https://www.resideo.com/nl/nl/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/Dutch_flag_38x27.jpg?rv=94bf12f0b25741e7883232e24bda1d15'
    },
    {
      button: 'United States | English',
      buttonLink: 'https://www.resideo.com/us/en/',
      imageLink: 'https://www.resideo.com/us/en/-/media/Resideo/Flags/us-flag.png?rv=b66476b9d9434c9fb777979957045464'
    },
  ]
}

const Language = () => {
  const [language, setLanguage] = useState(false);
  const handleLanguage = () => {
    setLanguage(!language);
  }
  return (
    <>
      <Menu>
        <Button onClick={handleLanguage}>
          United States | English
          <Image
            src={language ? upArrowIcon : downArrowIcon}
            alt='down-arrow'
            paddingLeft={['small', 'small', 'medium', 'medium']}
          />
        </Button>

        <CustomMenuList portal={false}>
          <CustomHeading>{languageList.header}</CustomHeading>
          <SeparatorLine />
          <Flex justifyContent={'space-between'} paddingX={'10px'}>
          <Box flex='50%' marginRight={'1rem'}>
              {languageList.leftItems.map((item, index) =>
                <Flex padding={'0.5rem'} key={index}>
                  <StyledImage src={item.imageLink}
                    alt="flags"
                  />
                  <CustomMenuLink as="a" target='_blank' key={index}
                    href={item.buttonLink}>{item.button}
                  </CustomMenuLink>
                </Flex>
              )}
            </Box>
            <Box flex='50%'>
              {languageList.rightItems.map((item, index) =>
                <Flex padding={'0.5rem'} key={index}>
                  <StyledImage src={item.imageLink}
                    alt="flags"
                  />
                  <CustomMenuLink as="a" target='_blank' key={index}
                    href={item.buttonLink}>
                    {item.button}
                  </CustomMenuLink>
                </Flex>
              )}
            </Box>
            </Flex>
         </CustomMenuList>
      </Menu>
    </>
  )
}
export default Language;