/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor } from '../../redux/store';

export const withPersistGate = (WrappedComponent: any) => (wrappedcomponentProps: any) =>
    (
        <PersistGate persistor={persistor} loading={null}>
            <WrappedComponent {...wrappedcomponentProps} />
        </PersistGate>
    );
