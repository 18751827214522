import { types } from './types';

export const getUserProfile = () => ({
    type: types.GET_USER_PROFILE,
});

export const getUserProfileSuccess = (profile: any) => ({
    type: types.GET_USER_PROFILE_SUCCESS,
    payload: profile,
});

export const getUserProfileFailed = (error: any) => ({
    type: types.GET_USER_PROFILE_FAILED,
    payload: error,
});