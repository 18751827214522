import React from 'react';
import { Caption, Container, Heading, ImageBox, Paragraph, Wrapper } from './MenuItemStyle';

const CardView = ({ CardList }: any) => {
    const RouteExternally = (link) => {
        window.location = link;
    };
    return CardList.map((data, index) => (
        <Wrapper key={`card-view-${index}`}>
            <Container onClick={() => RouteExternally(data.linkPath)}>
                <ImageBox src={data.imageLink} />
                <Caption>
                    <Heading>{data.title}</Heading>
                    <Paragraph>{data.description}</Paragraph>
                </Caption>
            </Container>
        </Wrapper>
    ));
};

export default CardView;
