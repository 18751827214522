export const types = {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',
    LOGIN_CANCELLED: 'LOGIN_CANCELLED',
    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'LOGOUT_FAIL',
    GET_JWT: 'GET_JWT',
    SET_JWT: 'SET_JWT',
    GET_JWT_SUCCESS: 'GET_JWT_SUCCESS',
    GET_JWT_FAIL: 'GET_JWT_FAIL',
    DELETE_JWT: 'DELETE_JWT',
    DELETE_JWT_SUCCESS: 'DELETE_JWT_SUCCESS',
    DELETE_JWT_FAILED: 'DELETE_JWT_FAILED',
    DELETE_REFRESH_JWT: 'DELETE_REFRESH_JWT',
    DELETE_REFRESH_JWT_SUCCESS: 'DELETE_REFRESH_JWT_SUCCESS',
    DELETE_REFRESH_JWT_FAILED: 'DELETE_REFRESH_JWT_FAILED',
    APP_LAUNCH_FLOW: 'APP_LAUNCH_FLOW',
    APP_LAUNCH_FLOW_COMPLETE: 'APP_LAUNCH_FLOW_COMPLETE',

    HANDLE_AUTHENTICATION_CALLBACK: 'HANDLE_AUTHENTICATION_CALLBACK',
    LOGIN_PROCESS_COMPLETE: 'LOGIN_PROCESS_COMPLETE',
}