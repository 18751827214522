import React, { FC } from 'react';
import { Flex, Text, Card, Box } from '@resideo/blueprint-react';
import { CloseBtn, ModalHeader } from './AddAddressModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UserAddressCard = styled(Card)`
  width: 300px;
  height: 250px;
  border: 1px solid #ebebeb;
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const UserAddressCardAdd = styled(Card)`
  width: 300px;
  height: 250px;
  border: 1px dashed #eee;
  padding: 1rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AddressOptions = styled.button`
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

interface ManageAddressModalProps {
  addresses?: any;
  close?: any;
  setErrors?: any;
  defaultAddress?: any;
  setDefaultAddress?: any;
  remove?: any;
  setOpenEditAddressModal?: any;
  openEditAddressModal?: any;
  setClickedAddressIndex?: any;
  setOpenAddAddressModal?: any;
  openAddAddressModal?: any;
  viewerAddress?: any;
}

const ManageAddressModal: FC<ManageAddressModalProps> = ({
  addresses,
  close,
  setErrors,
  setDefaultAddress,
  remove,
  setOpenEditAddressModal,
  openEditAddressModal,
  setClickedAddressIndex,
  setOpenAddAddressModal,
  openAddAddressModal,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalHeader>
        <Text height='40px' className='add'>
          {t('cart.shipping.modal.yourAddress')}
        </Text>
        <CloseBtn close={close} setErrors={setErrors} />
      </ModalHeader>
      <Flex flexWrap='wrap'>
        <UserAddressCardAdd
          onClick={() => {
            setOpenAddAddressModal(!openAddAddressModal);
            close();
          }}>
          <Text>
            <i className='fas fa-plus'></i>
          </Text>
          <Text>{t('cart.shipping.modal.add')}</Text>
        </UserAddressCardAdd>
        {addresses.map((a, i) => (
          <UserAddressCard key={i}>
            <Box>
              {a.isDefault && (
                <Text
                  fontSize='small'
                  style={{
                    borderBottom: '1px solid #ebebeb',
                    paddingBottom: '0.25rem',
                  }}>
                  {t('cart.shipping.modal.default')}
                </Text>
              )}
              <strong style={{ marginTop: '0.5rem' }}>{a.contactName}</strong>
              <Text>{a.companyName}</Text>
              <Text>
                {a.addressLine1}, {a.addressLine2}
              </Text>
              <Text>
                {a.city}, {a.stateProvinceRegionCode} {a.zipPostalCode}
              </Text>
              <Text>{a.countryCode}</Text>
            </Box>
            <Flex>
              <>
                <AddressOptions
                  onClick={() => {
                    setClickedAddressIndex(i);
                    setOpenEditAddressModal(!openEditAddressModal);
                    close();
                  }}>
                  {t('cart.shipping.modal.edit')}
                </AddressOptions>
                <AddressOptions onClick={() => remove(i)}>
                  {t('cart.shipping.modal.remove')}
                </AddressOptions>
              </>

              {!a.isDefault && (
                <AddressOptions onClick={() => setDefaultAddress(a)}>
                  {t('cart.shipping.modal.setAsPrimary')}
                </AddressOptions>
              )}
            </Flex>
          </UserAddressCard>
        ))}
      </Flex>
    </>
  );
};

export default ManageAddressModal;
