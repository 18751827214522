import React, { FC, useEffect, useState, useRef, useContext } from 'react';
import { Box, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import ProductDescription from '../../../components/productdetails/ProductDescription';
import HashRouter from '../../../components/productdetails/HashRouter';
import ProgramOverview from '../../../components/productdetails/ProgramOverview';
import GetStarted from '../../../components/productdetails/GetStarted';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetails } from '../../../redux/productdetails/actions';
import { RootState } from '../../../redux/root-reducer';
import PageLoading from '../../../components/common/PageLoading';
import PageError from '../../../components/common/PageError';
import Specifications from 'components/productdetails/Specifications';
import Resources from 'components/productdetails/Resources';


export const StyledBox = styled(Box)`
  cursor: pointer;
`;

export const StyledTextUpper = styled(Text)`
  text-transform: uppercase;
`;

const ProductDetails: FC = () => {
  const [tab, setTab] = useState('Overview');
  const overviewRef = useRef(null);
  const getStartedRef = useRef(null);
  const resourcesRef = useRef(null);
  const specificationsRef = useRef(null);
  const [service, setService] = useState('');
  const { path } = useRouteMatch();
  const history = useHistory() as any;
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.productDetails.fetchingProductDetails);
  const error = useSelector((state: RootState) => state.productDetails.fetchingProductDetailError);
  const productDetailsData: any = useSelector((state: RootState) => state.productDetails.productDetail);
  const productID: any = localStorage.getItem('productID') ?? '';


  useEffect(() => {
    setService('PRO');
  }, [path]);

  useEffect(() => {
    if (history.location && productID) {
      dispatch(getProductDetails(productID));
    }
  }, [history.location]);

  return isLoading && productDetailsData ? (
    <PageLoading />
  ) : error ? (
    <PageError />
  ) : (
    <Box data-test-pdp>
      {productDetailsData && (
        <ProductDescription
          auth={true}
          service={service}
          setService={setService}
          productDetails={productDetailsData}
        />
      )}
      <HashRouter
        setTab={setTab}
        tab={tab}
        overviewRef={overviewRef}
        specificationsRef={specificationsRef}
        relatedProductRef={getStartedRef}
        resourcesRef={resourcesRef}
        getStartedRef={getStartedRef}
        data={productDetailsData}
      />
      {productDetailsData && productDetailsData?.webOverview && (
        <ProgramOverview overviewRef={overviewRef} data={productDetailsData?.webOverview} />
      )}
      {productDetailsData && productDetailsData?.specs && productDetailsData?.specs.length > 0 &&(
      <Specifications specificationsRef={specificationsRef} data={productDetailsData?.specs} />
      )}
      {productDetailsData && productDetailsData?.resources && productDetailsData?.resources.length > 0 &&(
      <Resources resourcesRef={resourcesRef} data={productDetailsData?.resources} />
      )}
      <GetStarted getStartedRef={getStartedRef} />
    </Box>
  );
};

export default ProductDetails;
