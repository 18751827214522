import { call, all, put, takeLatest, take, cancel } from 'redux-saga/effects';
import { api } from '../../config/api';
import { getUserProfileUrl } from '../../config/api/url';
import { removeJwt, removeRefreshToken } from '../authentication/index';
import { appLaunchFlowComplete, deleteJwtSuccess } from '../authentication/action';
import { getJWT } from '../authentication/saga'
import { httpRequest } from '../types';

import {
    getUserProfileSuccess,
    getUserProfileFailed,
} from './action';

import { types } from './types';


export function* getUserProfile(action?: any): any {
    try {
        const response = yield call(api, getUserProfileUrl, httpRequest.GET, null, 0, 12);
        if (!response) {
            yield put(getUserProfileFailed('Error with getting user profile'));
            // RESET TOKEN
            yield resetToken();
            // APP LAUNCH ISSUE
            yield appLaunchFlowComplete(false);
        } else {
            yield put(getUserProfileSuccess(response));
            yield appLaunchFlowComplete(true);
        }
    } catch (error) {
        console.log(error);
        yield put(getUserProfileFailed(error));
        yield resetToken();
    }
}

function* resetToken() {
    const { token, refreshToken } = yield* getJWT();
    if (!token) {
        throw 'JWT NOT AVAILABLE'
    }
    // if (token) yield call(removeJwt);
    // if (refreshToken) yield call(removeRefreshToken);
    // yield put(deleteJwtSuccess());
}


function* getUserProfileWatcher() {
    yield takeLatest(types.GET_USER_PROFILE, getUserProfile);
}

export default function* userSaga() {
    yield all([
        getUserProfileWatcher(),
    ]);
}
