import React from 'react';
import { Box, BoxProps } from '@resideo/blueprint-react';

interface PageProps extends BoxProps {
  maxWidth?: string; // defaults to 48rem, the "medium" blueprint-react breakpoint
}

const Page = ({ children, maxWidth = '100%', ...rest }: PageProps) => {
  return (
    <Box
      marginX='auto'
      maxWidth={maxWidth}
      paddingX='medium'
      paddingY='2.5rem'
      {...rest}>
      {children}
    </Box>
  );
};

export default Page;
