import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const REACT_APP_AUTH0_AUDIENCE = 'https://resideo.auth0.com/api/v2/';
const REACT_APP_AUTH0_CLIENT_ID = 'TDvgR8AZXLx3UvH734fHagm8nNXdm8EY';

const Auth0ProviderWithHistory = ({ children }) => {
    const domain: string = REACT_APP_AUTH0_AUDIENCE;
    const clientId: string = REACT_APP_AUTH0_CLIENT_ID;

    const history = useHistory();

    const onRedirectCallback = (appState) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
