import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Flex } from '@resideo/blueprint-react';
import primaryNavigation from '../../config/navigation';
import Header from './Header';
import HeaderNavLink from './HeaderNavLink';
import MobileMenu, { MobileMenuToggle } from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import Notifications from './Notifications';
import Cart from './Cart';
import SearchButton from './SearchButton';

interface PrimaryNavOuter {
    mobileMenuOpen: boolean;
}

const PrimaryNavOuter = styled.nav<PrimaryNavOuter>`
    display: flex;
    align-items: center;
    width: 100%;
    height: ${({ mobileMenuOpen }) => (mobileMenuOpen ? 'inherit' : '3.75rem')};
    background-color: ${({ theme }) => theme.colors.header.primaryBackground};
    overflow: hidden;
    @media (min-width: ${({ theme }) => theme.breakpoints.xLarge}) {
        height: 3.75rem;
    }
`;

const PrimaryNavLinksContainer = styled.div`
    display: none;
    margin-right: auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
`;

const StyledProfileMenu = styled(ProfileMenu)`
    display: none;

    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        display: block;
    }
`;

const AuthenticatedHeader: FC = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        console.log(`path name is ${pathname}`);
    }, []);

    const [perksStatus, setPerksStatus] = useState<string>('');
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <Header
            mobileMenuOpen={mobileMenuOpen}
            primaryNav={
                <>
                    <PrimaryNavLinksContainer data-test-primary-web-nav>
                        {primaryNavigation.map((data) => (
                            <HeaderNavLink
                                key={data.name}
                                linkPath={
                                    data.name === 'perks'
                                        ? perksStatus === 'APPROVED'
                                            ? data.linkPath
                                            : 'https://www.resideo.com/us/en/pro/resideo-pro-perks/'
                                        : data.linkPath
                                }
                                name={data.name}
                                linkTextKey={data.linkTextKey}
                                isExternalLink={data.isExternalLink}
                                isActive={data.linkPath === pathname}
                                isDirect={data.isDirect}
                            />
                        ))}
                    </PrimaryNavLinksContainer>
                    <Flex>
                        <SearchButton />
                        <Cart />
                        <Notifications />
                        <StyledProfileMenu />
                        <MobileMenuToggle onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
                    </Flex>
                    <MobileMenu
                        show={mobileMenuOpen}
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    />
                </>
            }
        />
    );
};

export default AuthenticatedHeader;
