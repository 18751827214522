import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/authentication/action';
import { Link } from 'react-router-dom';
import {
    CustomMenuLink,
    MenuListContainer,
    MenuListWrapper,
    SectionIcon,
    SectionTitle,
    SectionTitleAndIconContainer,
} from './MenuItemStyle';
import { signIn, signOut } from 'config/Auth';

const MenuListItem: FC<any> = ({ listItems }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleSignOut = () => {
        dispatch(logout());
    };

    return (
        <MenuListWrapper>
            <MenuListContainer>
                {listItems.header && (
                    <SectionTitleAndIconContainer data-test-item>
                        <SectionIcon src={listItems.icon}></SectionIcon>
                        <SectionTitle>{listItems.title}</SectionTitle>
                    </SectionTitleAndIconContainer>
                )}
                <div>
                    {listItems.subItems.map((data, index) => {
                        if (!data.linkPath || !data.linkTextKey) return null;
                        return !data.isExternalLink ? (
                            <CustomMenuLink
                                style={{
                                    fontFamily: 'roboto',
                                    color: data.isUnderLined ? '#000' : '#166a96',
                                    fontWeight: data.isUnderLined ? 700 : 500,
                                    textDecoration: data.isUnderLined ? 'underline' : 'none',
                                }}
                                as={Link as any}
                                key={data.name}
                                to={data.linkPath}>
                                {t(data.linkTextKey)}
                            </CustomMenuLink>
                        ) : (
                            <CustomMenuLink
                                key={data.name}
                                style={{
                                    color: data.isUnderLined ? '#000' : '#166a96',
                                    fontWeight: data.isUnderLined ? 700 : 500,
                                    textDecoration: data.isUnderLined ? 'underline' : 'none',
                                }}
                                href={data.linkPath}
                                onClick={handleSignOut}>
                                {t(data.linkTextKey)}
                            </CustomMenuLink>
                        );
                    })}
                </div>
            </MenuListContainer>
        </MenuListWrapper>
    );
};

export default MenuListItem;
