import styled from 'styled-components';

interface NavProps {
    imageUrl?: any;
    title?: any;
}

export const Container = styled.div`
    position: relative;
    &:before {
        position: absolute;
        top: -168px;
        width: 100%;
        height: calc(100% + 168px);
        background: #032a3c;
        content: '';
    }
`;

export const Wrapper = styled.div`
    padding-top: 32px !important;
    z-index: -1;
    @media (min-width: 992px) {
        padding-top: 64px !important;
    }
`;

export const List = styled.ul`
    width: 100%;
    text-align: center;
    margin: 0 0 -11px 0;
    padding: 0;
    @media (max-width: 991px) {
        display: none !important;
        max-width: none;
        margin-left: -32px;
        text-align: left;
    }
`;

export const ListItem = styled.li`
    display: inline-block;
    list-style: none;
    margin: 0 25px;
    @media (max-width: 991px) {
        margin: 0;
        padding: 30px 0 0 32px;
    }
`;

export const Title = styled.a`
    position: relative;
    display: block;
    color: #767676;
    font-family: 'URW_Din_CondBold';
    font-size: 60px;
    letter-spacing: 1px;
    line-height: 60px;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 1;
    &:before {
        transition: all 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 60px;
        height: 60px;
        opacity: 0;
        z-index: -1;
        top: -20px;
        left: -25px;
    }
    &:hover {
        color: #fff;
        &::before {
            opacity: 1;
        }
    }
    &.active {
        color: #fff;
        &:hover {
            color: #dadada;
        }
    }
    &.item0background {
        &:before {
            background: url('https://www.resideo.com/areas/resideo/img/cat-nav-air.svg') center
                center no-repeat;
        }
        &.active {
            &:before {
                background: url('https://www.resideo.com/areas/resideo/img/cat-nav-air.svg') center
                    center no-repeat;
                opacity: 1;
            }
        }
    }
    &.item1background {
        &:before {
            background: url('https://www.resideo.com/areas/resideo/img/cat-nav-security.svg') center
                center no-repeat;
        }
        &.active {
            &:before {
                background: url('https://www.resideo.com/areas/resideo/img/cat-nav-security.svg')
                    center center no-repeat;
                opacity: 1;
            }
        }
    }
    &.item2background {
        &:before {
            background: url('https://www.resideo.com/areas/resideo/img/cat-nav-water.svg') center
                center no-repeat;
        }
        &.active {
            &:before {
                background: url('https://www.resideo.com/areas/resideo/img/cat-nav-water.svg')
                    center center no-repeat;
                opacity: 1;
            }
        }
    }
    &.item3background {
        &:before {
            background: url('https://www.resideo.com/areas/resideo/img/cat-nav-energy.svg') center
                center no-repeat;
        }
        &.active {
            &:before {
                background: url('https://www.resideo.com/areas/resideo/img/cat-nav-energy.svg')
                    center center no-repeat;
                opacity: 1;
            }
        }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 55px;
        line-height: 55px;
    }
`;

export const DescriptionWrapper = styled.div<NavProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 145px;
    text-align: center;
    padding: 32px;
    color: ${(props) => (props.title === 'Security' ? '#fff' : '')};
    // top: 4px;
    @media (max-width: 991px) {
        padding: 32px 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        padding: 32px 15%;
    }
    &:after {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        content: '';
        background: ${(props) =>
            props.imageUrl ? `url(${props.imageUrl})center center no-repeat` : ''};
        background-size: cover;
    }
`;

export const HeaderText = styled.h1`
    font-weight: 400;
    font-size: 60px;
    font-family: 'URW_Din_CondBold';
    line-height: 60px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 16px 0;
    z-index: 1;
    @media (max-width: 991px) {
        font-size: 35px;
        line-height: 35px;
    }
`;

export const DescriptionText = styled.p`
    margin: 0;
    padding: 0 25%;
    text-align: center;
    line-height: 1.5;
    z-index: 1;
    @media (max-width: 991px) {
        padding: 0 15%;
    }
`;

export const ArrowButton = styled.button`
    display: block;
    top: calc(100% - 50px);
    width: 18px;
    height: 30px;
    border: none !important;
    position: absolute;
    z-index: 2;
    font-size: 0;
    &.slick-prev {
        right: 104px;
        left: inherit;
        background: url('https://www.resideo.com/areas/resideo/img/arrow-catalog-left-grey.svg')
            center center no-repeat;
        background-size: 18px;
    }
    &.slick-next {
        right: 32px;
        background: url('https://www.resideo.com/areas/resideo/img/arrow-catalog-right-grey.svg')
            center center no-repeat;
        background-size: 18px;
    }
    @media (max-width: 480px) {
        display: none;
    }
`;
