import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { handleAuthenticationCallback, loginProcessComplete } from 'redux/authentication/action';
import { RootState } from 'redux/root-reducer';

const Authentication = () => {
    const { isLoggedIn, loginInProgress } = useSelector((state: RootState) => state.authentication);
    const dispatch = useDispatch();
    const { action } = useHistory();

    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(handleAuthenticationCallback());
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (window.location.pathname === '/auth/get-started' && !isLoggedIn) {
            const URL =
                process.env.REACT_APP_SIGNUP_URL ||
                'https://develop--resideo-pro.netlify.app/get-started';
            window.location.replace(URL as string);
        }
    }, [isLoggedIn]);

    if (isLoggedIn && loginInProgress) return <Redirect to='/subscription' />;
    return <div>''</div>;
};

export default Authentication;
