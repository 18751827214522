export interface ProfileMenuItem {
  name?: string;
  linkTextKey?: string;
  linkPath?: string;
  isExternalLink?: boolean;
}
const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';

const profileMenuItems = (viewerHasPermissions: boolean, isSignedIn: boolean = true): ProfileMenuItem[] => {
  const retval: ProfileMenuItem[] = [
    {
     ...(isSignedIn ?{ name: 'profile',
      linkTextKey: 'header.profile',
      linkPath: 
        `${process.env.REACT_APP_PRO_PORTAL_BASE_URL}/profile`,
      isExternalLink: true}: undefined),
    },
    {
      ...(viewerHasPermissions
        ? {
            name: 'company',
            linkTextKey: 'header.company',
            linkPath: isDevelopEnvironment
              ? 'https://develop--resideo-pro.netlify.app/mybusiness/company'
              : `${window.location.origin}/mybusiness/company`,
            isExternalLink: true,
          }
        : undefined),
    },
    {
      name: 'signout',
      linkTextKey: 'header.signout',
      linkPath: '#',
      isExternalLink: true,
    },
  ];
  return retval;
};

export { profileMenuItems as default };
