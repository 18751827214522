import React, { useState } from 'react';

interface ILeftNavContext {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const MobileLeftNavContext = React.createContext<ILeftNavContext>({
  isOpen: false,
  setIsOpen: () => ({}),
});

export const MobileLeftNavProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <MobileLeftNavContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </MobileLeftNavContext.Provider>
  );
};

export const useLeftNav = () => React.useContext(MobileLeftNavContext);
