import React, { createContext, useReducer } from 'react';
import { cartItemsReducer } from 'redux/cartProvider/reducer';
import { types } from 'redux/cartProvider/types';
import { getCCType, getFirstNumFromBrand } from 'components/payment/PaymentMethodFormBody';
import { getSmallerCCimg } from 'components/cart/reviewOrder/PaymentMethod';

interface CartContextProps {
  state: any;
  cartActions: any;
  dispatch: ({ type }: { type: string }) => void;
}

export const CartContext = createContext({} as CartContextProps);

export const initialState = {
  creditCard: {},
  billingAddress: {},
  shippingAddress: {},
  shippingMethod: {},
  cartItems: [],
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartItemsReducer, initialState);
  const cartActions = {
    setCartItem: item => dispatch({ type: types.SET_CART_ITEMS, payload: item }),
    setCreditCard: card => dispatch({ type: types.SET_CREDIT_CARD, payload: card }),
    setShippingAddress: address => dispatch({ type: types.SET_SHIPPING_ADDRESS, payload: address }),
    setBillingAddress: address => dispatch({ type: types.SET_BILLING_ADDRESS, payload: address }),
    setShippingMethod: type => dispatch({ type: types.SET_SHIPPING_METHOD, payload: type }),
  };

  return (
    <CartContext.Provider
      value={{
        state,
        cartActions,
        dispatch,
      }}>
      {children}
    </CartContext.Provider>
  );
};
