import { call, all, put, takeLatest} from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { getLanguageUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    getLanguageSuccess,
    getLanguageFail
} from './actions';

function* getLanguages(): any {
    try {
        const response = yield call(api, getLanguageUrl, httpRequest.GET, null, 2);
        yield put(getLanguageSuccess(response.languages))
    } catch (error) {
        yield put(getLanguageFail(error))
    }
}

function* getLanguagesWatcher() {
    yield takeLatest(types.FETCH_LANGUAGE, getLanguages);
}

export default function* languageSaga() {
    yield all([
        getLanguagesWatcher(),
    ])
}
