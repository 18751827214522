import produce from 'immer';
import { types } from './types';

const initialState = {
    user: null,
    gettingUserProfile: false,
    gettingUserProfileErrorMessage: null,
}

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.GET_USER_PROFILE:
            return produce(state, (draftState) => {
                draftState.gettingUserProfile = true;
            });
        case types.GET_USER_PROFILE_SUCCESS:
            return produce(state, (draftState) => {
                draftState.user = payload;
                draftState.gettingUserProfile = false;
            });
        case types.GET_USER_PROFILE_FAILED:
            return produce(state, (draftState) => {
                draftState.gettingUserProfileErrorMessage = payload;
                draftState.gettingUserProfile = false;
            });
        default:
            return state;
    }
};

export { reducer };