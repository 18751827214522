/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import * as is from '@redux-saga/is';
import { CANCELLED, PENDING, REJECTED, RESOLVED } from './constants';
import { isRaceEffect } from './checkers';
import Manager from './manager';
import { store } from '../store';
import { enqueueFailedAction } from '../refreshToken/action';
import { getLanguage } from '../language/actions';
import { getOrderInformation } from '../orderSummary/actions'


// `VERBOSE` can be made a setting configured from the outside.
const VERBOSE = false;

function time() {
    return Date.now();
}

const manager: any = new Manager();

function rootSagaStarted(desc: { saga: { name: any }; args: any; effectId: any }) {
    if (VERBOSE) {
        console.log('Root saga started:', desc.saga.name || 'anonymous', desc.args);
    }
    manager.setRootEffect(desc.effectId, { ...desc, status: PENDING, start: time() });
}

function effectTriggered(desc: { effectId?: any; parentEffectId?: string | number }) {
    if (VERBOSE) {
        console.log('Saga monitor: effectTriggered:', desc);
    }
    manager.set(desc.effectId, { ...desc, status: PENDING, start: time() });
}

function effectResolved(effectId: any, result: any) {
    if (VERBOSE) {
        console.log('Saga monitor: effectResolved:', effectId, result);
    }

    resolveEffect(effectId, result);
}

function effectRejected(effectId: string | number, error: { status: number }) {
    if (VERBOSE) {
        console.log('Saga monitor: effectRejected:', effectId, error);
    }

    const effect = manager.get(effectId);
    const parentEffect = manager.get(effect.parentEffectId);

    if (error && error.status && error.status === 401) {
        const { effect: rejectedEffect } = parentEffect;
        if (rejectedEffect && rejectedEffect.payload) {
            const action = rejectedEffect.payload.args[0];
            // Don't refresh token for failed token refresh or failed app launch flow
            if (action && action.type !== 'REFRESH_TOKEN') {
                // store.dispatch(enqueueFailedAction(action));
                // store.dispatch({type: 'LOGOUT'});
                // store.dispatch(getLanguage());
            }
        }
    }
    rejectEffect(effectId, error);
}

function effectCancelled(effectId: any) {
    if (VERBOSE) {
        console.log('Saga monitor: effectCancelled:', effectId);
    }
    cancelEffect(effectId);
}

function computeEffectDur(effect: { start: number }) {
    const now = time();
    Object.assign(effect, {
        end: now,
        duration: now - effect.start,
    });
}

function resolveEffect(effectId: string | number, result: { toPromise: () => Promise<any>; isCancelled: () => any }) {
    const effect = manager.get(effectId);

    if (is.task(result)) {
        result.toPromise().then(
            (taskResult) => {
                if (result.isCancelled()) {
                    cancelEffect(effectId);
                } else {
                    resolveEffect(effectId, taskResult);
                }
            },
            (taskError) => rejectEffect(effectId, taskError),
        );
    } else {
        computeEffectDur(effect);
        effect.status = RESOLVED;
        effect.result = result;
        if (isRaceEffect(effect.effect)) {
            setRaceWinner(effectId, result);
        }
    }
}

function rejectEffect(effectId: string | number, error: any) {
    const effect = manager.get(effectId);
    computeEffectDur(effect);
    effect.status = REJECTED;
    effect.error = error;
    if (isRaceEffect(effect.effect)) {
        setRaceWinner(effectId, error);
    }
}

function cancelEffect(effectId: string | number) {
    const effect = manager.get(effectId);
    computeEffectDur(effect);
    effect.status = CANCELLED;
}

function setRaceWinner(raceEffectId: string | number, result: {}) {
    const winnerLabel = Object.keys(result)[0];
    // eslint-disable-next-line
    for (const childId of manager.getChildIds(raceEffectId)) {
        const childEffect = manager.get(childId);
        if (childEffect.label === winnerLabel) {
            childEffect.winner = true;
        }
    }
}

// Export the `sagaMonitor` to pass to the middleware.
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    rootSagaStarted,
    effectTriggered,
    effectResolved,
    effectRejected,
    effectCancelled,
    actionDispatched: () => {},
};
