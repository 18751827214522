import React, { FC, useContext } from 'react';
import ReviewSectionCard from '../../../components/cart/ReviewSectionCard';
import { Button, Box, Text, Flex, Checkbox, Label } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import OrderSummaryDesktop from '../../../components/cart/OrderSummaryDesktop';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import { PageTitle } from './Shipping';
import { Form, Formik } from 'formik';
import GoBackButton from '../../../components/common/GoBackButton';
import OrderSummaryMobile from '../../../components/cart/OrderSummaryMobile';
import { backArrow, redAlertIcon } from '../../../assets/images';
import ReviewOrderData from '../../../data/reviewOrderData.json';

interface Link {
    externalLink?: boolean;
}

const Link = styled.a<Link>`
    color: ${({ theme }) => theme.colors.text};
`;

interface ReviewCardDataProps {
    type?: string;
    heading?: string;
    email?: string;
    phone?: string;
    shippingContactName?: string;
    shippingCompanyName?: string;
    shippingAddressLine1?: string;
    shippingAddressLine2?: string;
    shippingCity?: string;
    shippingStateProvinceRegionCode?: string;
    shippingZipPostalCode?: string;
    shippingCountryCode?: any;
    shippingMethod?: any;
    billingContactName?: string;
    billingCompanyName?: string;
    billingAddressLine1?: string;
    billingAddressLine2?: string;
    billingCity?: string;
    billingStateProvinceRegionCode?: string;
    billingZipPostalCode?: string;
    billingCountryCode?: string;
    ccType?: string;
    image?: string;
    lastFourNums?: string;
    paymentMode?: string;
    exp?: string;
    hideMe?: any;
    companyName?: string;
    stateName?: string;
    taxAuthority?: string;
    startDate?: string;
    endDate?: string;
    certFileName?: string;
}
const StyledText = styled(Text)`
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    padding: 0 ${({ theme }) => theme.space.xSmall};
`;
const Content = styled(Flex)`
    justify-content: space-between;
    width: 100%;
    flex-direction: column-reverse;
    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        flex-direction: row;
    }
    .left {
        order: 2;
        width: 100%;
        @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
            order: 1;
            margin-right: ${({ theme }) => theme.space.medium};
        }
    }
    .right {
        order: 1;
        width: 100%;
        @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
            order: 2;
            margin-left: ${({ theme }) => theme.space.medium};
        }
    }
`;

const ErrorMessage = styled(Flex)`
    min-height: 70px;
    background-color: #c6361c;
    color: white;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 0 2rem;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
        padding: 2rem;
        margin-bottom: 20px;
    }
`;

const ButtonBackToPayment = styled.button`
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: bold;
    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const BoxErrorMessage = styled(Box)``;

const IconError = styled.img`
    filter: brightness(0) invert(1);
    margin-right: 5px;
    height: 14px;
    width: 14px;
`;

const IconBack = styled.img`
    position: absolute;
    margin-left: -40px;
    filter: brightness(0) invert(1);
`;

const ReviewOrder: FC<any> = ({ createOrderMutation, viewerData, digitalRiverBillingId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const state = ReviewOrderData;

    const hasProduct = state?.order?.lineItems?.filter((f) => f.type === 'product').length > 0;

    const createOrder = async (values) => {
        const variables = {
            digitalRiverBillingId,
            checkoutId: state.order.id,
            input: {
                digitalRiverPaymentSourceId: state?.paymentSourceId,
                billTo: {
                    firstName: state?.order?.billTo?.firstName,
                    lastName: state?.order?.billTo?.lastName,
                    email: state?.order?.billTo?.email,
                    address: {
                        addressLine1: values?.billingAddress?.addressLine1,
                        addressLine2: values?.billingAddress?.addressLine2,
                        city: values?.billingAddress?.city,
                        stateProvinceRegionCode: values?.billingAddress?.stateProvinceRegionCode,
                        zipPostalCode: values?.billingAddress?.zipPostalCode,
                        countryCode: 'US',
                        contactName: values?.billingAddress?.contactName,
                    },
                },
            },
        };
        if (hasProduct) {
            // if (state.order.shipTo === undefined) {
            //   console.log(
            //     'error',
            //     'An error has occurred.  Your order contains a physical product, but no shipping address was found on the order.'
            //   );
            //   toast(
            //     <Alert variant='error'>
            //       <Text>
            //         An error has occurred. Your order contains a physical product, but
            //         no shipping address was found on the order.
            //       </Text>
            //     </Alert>,
            //     { duration: 10000 }
            //   );
            //   return null as any;
            // }

            variables.input = {
                ...variables.input,
                ...{
                    shipTo: {
                        firstName: state.order.shipTo.firstName,
                        lastName: state.order.shipTo.lastName,
                        email: state.order.shipTo.email,
                        organization: values.shippingAddress.companyName,
                        address: {
                            addressLine1: values.shippingAddress.addressLine1,
                            addressLine2: values.shippingAddress.addressLine2,
                            city: values.shippingAddress.city,
                            stateProvinceRegionCode: values.shippingAddress.stateProvinceRegionCode,
                            zipPostalCode: values.shippingAddress.zipPostalCode,
                            countryCode: values.shippingAddress.countryCode,
                            contactName: values.shippingAddress.contactName,
                        },
                    },
                    shippingChoice: {
                        description: 'Standard Ground 1-5 Business Days',
                        //   state.order.shippingChoice?.serviceLevel === 'STANDARD_GROUND'
                        //     ? 'Standard Ground 1-5 Business Days'
                        //     : state.order.shippingChoice?.serviceLevel === 'EXPRESS_2_DAY'
                        //     ? 'Domestic Second Day Air'
                        //     : state.order.shippingChoice?.serviceLevel ===
                        //       'EXPRESS_NEXT_DAY'
                        //     ? 'Domestic Next Day Air'
                        //     : 'Standard Ground 1-5 Business Days',
                        // serviceLevel: state.order.shippingChoice?.serviceLevel,
                    },
                },
            };
        }
        history.push(`/cart/order/RGlnaXRhbFJpdmVyT3JkZXI6MjE2MzM1NDcwMzM2`);
    };

    const taxData = JSON.parse(`${localStorage.getItem('taxExemptionApplication')}`);
    const reviewCardData = (values): ReviewCardDataProps[] => [
        {
            type: 'info',
            heading: 'cart.orderComplete.contactInformation',
            email: viewerData?.contactEmail,
            phone: viewerData?.primaryPhoneNumber ?? viewerData?.phoneNumbers?.[0]?.number,
        },
        {
            type: 'shippingAddress',
            heading: 'cart.shipping.shippingAddress',
            shippingContactName: values.shippingAddress?.contactName,
            shippingCompanyName: values.shippingAddress?.companyName,
            shippingAddressLine1: values.shippingAddress?.addressLine1,
            shippingAddressLine2: values.shippingAddress?.addressLine2,
            shippingCity: values.shippingAddress?.city,
            shippingStateProvinceRegionCode: values.shippingAddress?.stateProvinceRegionCode,
            shippingZipPostalCode: values.shippingAddress?.zipPostalCode,
            shippingCountryCode: values.shippingAddress?.countryCode,
            hideMe: !hasProduct, // values?.shippingAddress.contactName ? false : true,
        },
        {
            type: 'shippingMethod',
            heading: 'cart.shipping.shippingMethod',
            shippingMethod: values.shippingMethod,
            hideMe: !hasProduct, // values?.shippingMethod ? false : true,
        },
        {
            type: 'billingAddress',
            heading: 'cart.billing.billingAddress',
            billingContactName: values.billingAddress?.contactName,
            billingCompanyName: values.billingAddress?.companyName,
            billingAddressLine1: values.billingAddress?.addressLine1,
            billingAddressLine2: values.billingAddress?.addressLine2,
            billingCity: values.billingAddress?.city,
            billingStateProvinceRegionCode: values.billingAddress?.stateProvinceRegionCode,
            billingZipPostalCode: values.billingAddress?.zipPostalCode,
            billingCountryCode: values.billingAddress?.countryCode,
        },
        {
            type: 'paymentMethod',
            heading: 'cart.payment.paymentMethod',
            paymentMode: values?.paymentMethodType,
            ccType: values?.card?.type,
            image: values?.card?.image,
            lastFourNums: values.card?.lastFourNums,
            exp: values?.card?.exp,
        },
        {
            type: 'taxExemption',
            heading: 'cart.completeOrder.taxExemptionInformation',
            companyName: taxData?.companyName,
            stateName: '',
            taxAuthority: taxData?.taxAuthority,
            startDate: taxData?.startDate,
            endDate: taxData?.endDate,
            certFileName: 'Test.jpg',
        },
    ];
    const data = () => ({
        email: '',
        phone: '',
        shippingAddress: {
            contactName: [state.order.shipTo?.firstName, state.order.shipTo?.lastName].join(' '), // values.shippingAddress?.contactName,
            addressLine1: state.order?.shipTo?.address?.addressLine1,
            addressLine2: state.order?.shipTo?.address?.addressLine2,
            city: state.order?.shipTo?.address?.city,
            stateProvinceRegionCode: state.order?.shipTo?.address?.stateProvinceRegionCode,
            countryCode: state.order?.shipTo?.address?.countryCode,
            zipPostalCode: state.order?.shipTo?.address?.zipPostalCode,
        },
        shippingMethod: 'standard',
        // state.order.shippingChoice?.serviceLevel === 'EXPRESS_2_DAY'
        //   ? 'expressSecondDay'
        //   : state.order.shippingChoice?.serviceLevel === 'EXPRESS_NEXT_DAY'
        //   ? 'expressNextDay'
        //   : 'standard',
        billingAddress: {
            contactName: [state.order.billTo?.firstName, state.order.billTo?.lastName].join(' '),
            addressLine1: state.order?.billTo?.address?.addressLine1,
            addressLine2: state.order?.billTo?.address?.addressLine2,
            city: state.order?.billTo?.address?.city,
            stateProvinceRegionCode: state.order?.billTo?.address?.stateProvinceRegionCode,
            countryCode: state.order?.billTo?.address?.countryCode,
            zipPostalCode: state.order?.billTo?.address?.zipPostalCode,
        },
        card: {
            type: state?.cc?.type,
            image: state?.cc?.image,
            lastFourNums: state?.cc?.lastFourNums,
            cvc: '123', //state?.cc?.cvc,
            exp: state?.cc?.exp,
            firstNum: '',
            name: '',
            number: '',
        },
        paymentMethodType: state?.order?.paymentSource?.type,
        terms: false,
    });

    return (
        <Box data-test-review-order-page>
            <CheckoutSteps step4 />
            <Box marginBottom='medium' marginX='large'>
                {/* <PageTitle
          data-test-page-title
          paddingTop={['medium', 'medium', 'large', 'large']}>
          {t('cart.reviewOrder.reviewOrderDetails')}
        </PageTitle> */}
                {state.showPaymentError && (
                    <ErrorMessage>
                        <BoxErrorMessage>
                            <IconError src={redAlertIcon} alt='error' />
                            {state.paymentErrorMsg}
                        </BoxErrorMessage>
                        <ButtonBackToPayment onClick={() => history.push('/cart/payment')}>
                            <IconBack src={backArrow} alt='back' />{' '}
                            {t('cart.payment.backToPayment')}
                        </ButtonBackToPayment>
                    </ErrorMessage>
                )}
                <Formik initialValues={data()} onSubmit={createOrder}>
                    {({ values, setFieldValue }) => {
                        return (
                            <Form>
                                <Flex marginBottom='medium'>
                                    <OrderSummaryMobile values={values} />
                                </Flex>
                                <Content>
                                    <Box className='left'>
                                        {reviewCardData(values)
                                            .filter((f) => !f.hideMe)
                                            .map((card, i) => (
                                                <ReviewSectionCard
                                                    key={i}
                                                    card={card}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                />
                                            ))}
                                    </Box>
                                    <Box className='right' color='textLight3'>
                                        <OrderSummaryDesktop
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                        <Flex
                                            data-test-agree-terms
                                            textAlign='left'
                                            display='inline-block'
                                            fontSize='small'
                                            paddingY='medium'>
                                            {t('cart.payment.agreeAndSubmitText.1')}
                                            <StyledText>
                                                <Link
                                                    target='blank'
                                                    href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRTermsAndConditionsPage/eCommerceProvider.DR+globalTech+Inc'>
                                                    {t('cart.payment.agreeAndSubmitText.2')}
                                                </Link>
                                            </StyledText>
                                            <StyledText>
                                                <Link
                                                    target='blank'
                                                    href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRPrivacyPolicyPage/eCommerceProvider.DR+globalTech+In'>
                                                    {t('cart.payment.agreeAndSubmitText.4')}
                                                </Link>
                                            </StyledText>
                                            {t('cart.payment.agreeAndSubmitText.6')}
                                        </Flex>
                                        <Flex
                                            data-test-agree-terms
                                            textAlign='left'
                                            display='inline-block'
                                            fontSize='small'>
                                            {t('cart.payment.agreeAndSubmitText.7')}
                                        </Flex>
                                        <Flex
                                            data-test-agree-terms
                                            textAlign='left'
                                            display='inline-block'
                                            fontSize='small'
                                            paddingTop='medium'>
                                            {t('cart.payment.agreeAndSubmitText.8')}
                                        </Flex>
                                        <Flex
                                            alignItems='center'
                                            justifyContent='flex-start'
                                            display='flex'>
                                            <Label
                                                paddingBottom='medium'
                                                style={{ margin: '1.063rem 0 1.625rem 0' }}>
                                                <Checkbox
                                                    disabled={state.isFetching}
                                                    name='terms'
                                                    type='checkbox'
                                                    marginRight='0'
                                                    onChange={(e) =>
                                                        setFieldValue('terms', e.target.checked)
                                                    }
                                                    checked={values.terms}
                                                />
                                                <Text
                                                    fontSize='small'
                                                    fontWeight='normal'
                                                    color={'textHeading'}
                                                    fontStyle='italic'>
                                                    {t('cart.payment.agreeAndSubmitText.9')}
                                                </Text>
                                            </Label>
                                        </Flex>
                                        <Flex
                                            justifyContent='flex-end'
                                            alignItems='center'
                                            flexDirection={[
                                                'column-reverse',
                                                'column-reverse',
                                                'column-reverse',
                                                'row',
                                            ]}>
                                            <GoBackButton
                                                url={`${process.env.PUBLIC_URL ?? ''}/cart/payment`}
                                                text='Back to Payment'
                                            />
                                            <Button
                                                disabled={!values.terms || state.isFetching}
                                                marginLeft={['0', '0', '0', 'small']}
                                                data-test-go-to-order-completed
                                                type='submit'
                                                width={['100%', 'auto', 'auto', 'auto']}
                                                variant='primary'
                                                marginBottom={['large', 'large', 'small', '0']}>
                                                {t('cart.reviewOrder.agreeAndCompleteOrder')}
                                            </Button>
                                        </Flex>
                                    </Box>
                                </Content>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
        </Box>
    );
};
export default ReviewOrder;
