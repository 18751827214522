import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Text, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SeparatorLine from 'components/common/SeparatorLine';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import Alert from 'components/common/Alert';
import { useToast } from 'components/common/Toast';
import PaymentMethodFormBody, {
  createExistingCreditCardItem,
  ExistingCreditCardItem,
  ExistingPaymentSource,
  getMonthAndYear,
  initialPaymentBodyValues,
  PaymentMethodBodyValues,
  PaymentMethodOptions,
  validatePaymentMethod,
} from 'components/payment/PaymentMethodFormBody';
import { createDRSubscriptionPayment, refreshSubscriptionDetails } from 'redux/subscriptions/actions';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const ChangePaymentBody = styled.div`
    /* padding: 44px 69px 50px 69px; */
    width: 697px;
    div {
        span {
            font-weight: bold;
        }
    }
    .warning-label {
        font-weight: 400;
    }
    @media (max-width: 768px) {
      width: auto;
    }
`;

const CancelBtn = styled(Button)`
    border: 0;
    box-shadow: none;
    color: #296a98;
    :focus,
    :active {
        outline: none;
        box-shadow: none;
    }
`;

export const processPaymentSources = (
  paymentSources: ExistingPaymentSource[],
): ExistingCreditCardItem[] => {
  if (Array.isArray(paymentSources)) {
    const cardChoices = paymentSources
      // only include credit card payment sources in the drop down
      .filter((source) => source.type === 'CREDIT_CARD')
      // flatten the GraphQL payment source shape into the desired ExistingCreditCardItem
      // shape and calculate any necessary values.
      .map((existingCard) => createExistingCreditCardItem(existingCard));
    return cardChoices;
  } else {
    return [];
  }
};

interface PaymentMethodFormProps {
  isFromChangePaymentMethod: boolean;
  paymentSources: ExistingPaymentSource[];
  currentSourceId: string | null;
  enrollment?: any | null;
  billingId?: string | null;
  handleEnrollmentUpdate?: any | null;
  viewer?: any | null;
  onCancel?: () => void;
  onSubmit?: (sourceId: string) => void;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}

const PaymentMethodForm: FC<PaymentMethodFormProps> = ({
  isFromChangePaymentMethod,
  paymentSources,
  currentSourceId,
  enrollment,
  billingId,
  handleEnrollmentUpdate,
  viewer,
  onCancel,
  onSubmit,
  setLoading,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const hookDispatch = useDispatchPromise();
  const dispatch = useDispatch();
  const path = useRouteMatch() as any;
  const enrollmentId = path.params.id;
  const [drJsLibrary, setDrJsLibrary] = useState() as any;
  const [agreementChecked, setAgreementChecked] = useState(false) as any;
  const [newCardIsChecked, setNewCardIsChecked] = useState(false) as any;
  const [savedCards /*, setSavedCards*/] = useState<ExistingCreditCardItem[]>(
    processPaymentSources(paymentSources),
  );
  //* on mount, instantiate the DigitalRiver.js object if it is not defined.
  useEffect(() => {
    if (drJsLibrary === undefined) {
      // @ts-expect-error because we need to ignore this
      const paymentLib = new DigitalRiver(
        `${process.env.REACT_APP_DIGITAL_RIVER_API_PUBLIC_KEY}`
      );
      setDrJsLibrary(paymentLib);
    }
  }, []);

  const initialValues = {
    paymentMethod: '' as PaymentMethodOptions,
    savedSourceIndex: -1,
    payPalSourceId: '',
  };

  const currentSource = paymentSources?.find((x) => x.paymentSourceId === currentSourceId);

  const currentCardIndex = savedCards.findIndex((x) => x.paymentSourceId === currentSourceId);

  switch (currentSource?.type) {
    case 'PAYPAL':
      initialValues.paymentMethod = 'payPal';
      initialValues.payPalSourceId = currentSourceId ?? '';
      break;
    case 'CREDIT_CARD':
      initialValues.paymentMethod = 'existingCC';
      initialValues.savedSourceIndex = currentCardIndex;
      break;
    default:
      break;
  }

  interface HandleChangePaymentResult {
    updatedEnrollment: any;
    updatedSources?: ExistingPaymentSource[];
  }

  const createDRpayment = async (drResponse) => {    
    const dataToSend = {
      // billingAddress: {
      //   cellphone: drResponse?.owner?.phone ?? '',
      //   companyName: drResponse?.owner?.organization ?? '',
      //   country: {
      //     isocode: drResponse?.owner?.address?.country,
      //     name: drResponse?.owner?.address?.country,
      //   },
      //   defaultAddress: false,
      //   email: drResponse?.owner?.email,
      //   firstName: drResponse?.owner?.firstName,
      //   lastName: drResponse?.owner?.lastName,
      //   line1: drResponse?.owner?.address?.line1,
      //   line2: drResponse?.owner?.address?.line2 ?? '',
      //   phone: drResponse?.owner?.address?.phone ?? '',
      //   postalCode: drResponse?.owner?.address?.postalCode,
      //   region: {
      //     countryIso: drResponse?.owner?.address?.country,
      //     isocode:
      //       drResponse?.owner?.address?.country +
      //       '-' +
      //       drResponse?.owner?.address?.state,
      //   },
      //   shippingAddress: false,
      //   town: drResponse?.owner?.address?.city,
      //   visibleInAddressBook: false,
      // },
      //drAmount: drResponse?.amount,
      drClientSecret: drResponse?.clientSecret,
      drSourceId: drResponse?.id,
      drSourceType: drResponse?.type,
      drccbrand: drResponse?.creditCard?.brand,
      drccexpiryMonth: drResponse?.creditCard?.expirationMonth,
      drccexpiryYear: drResponse?.creditCard?.expirationYear,
      drcclastFourDigits: drResponse?.creditCard?.lastFourDigits,
      savePaymentInfo: true,
    };
    hookDispatch(createDRSubscriptionPayment({ dRPaymentDetailsForm: dataToSend, subscriptionId: enrollmentId }))
      .then((response) => {
        dispatch(refreshSubscriptionDetails({ subscriptionId: enrollmentId }));
        sessionStorage.setItem('temp-name-on-card', '');
        sessionStorage.setItem('temp-make-primary', '');
        sessionStorage.setItem('temp-country-code', '');
        sessionStorage.setItem('temp-city', '');
        sessionStorage.setItem('temp-security-code', '');
        sessionStorage.setItem('temp-address-line-1', '');
        sessionStorage.setItem('temp-address-line-2', '');
        sessionStorage.setItem('temp-month-year', '');
        sessionStorage.setItem('temp-state-province-region-code', '');
        sessionStorage.setItem('temp-card-number', '');
        sessionStorage.setItem('temp-zip-postal-code', '');
        setTimeout(() => {
          setLoading?.(false);
        }, 300);
      })
      .catch((error) => {
        setLoading?.(false);
        console.log('Error', error);
        if (error?.data?.errors[0]?.type === 'FlexibleSearchError') {
          toast(
              <Alert variant='error'>
                  <Text>{t('error.retryLaterMessage')}</Text>
              </Alert>,
              { duration: 1000 },
          );
      }
      });
  };

  const updateExistingDRpayment = async (dataItem) => {
    const [month, year] = dataItem?.monthYear?.split('/');

    const dataToSend = {
      drClientSecret: dataItem?.clientSecret,
      drSourceId: dataItem?.paymentSourceId,
      drSourceType: 'CREDIT_CARD',
      drccbrand: dataItem?.brand,
      drccexpiryMonth: month,
      drccexpiryYear: year,
      drcclastFourDigits: dataItem?.lastFour,
    };

    hookDispatch(createDRSubscriptionPayment({ dRPaymentDetailsForm: dataToSend, subscriptionId: enrollmentId }))
      .then((response) => {
        dispatch(refreshSubscriptionDetails({ subscriptionId: enrollmentId }));
        setTimeout(() => {
          setLoading?.(false);
        }, 300);
      })
      .catch((error) => {
        setLoading?.(false);
        console.log('Error', error);
        if (error?.data?.errors[0]?.type === 'FlexibleSearchError') {
          toast(
              <Alert variant='error'>
                  <Text>{t('error.retryLaterMessage')}</Text>
              </Alert>,
              { duration: 1000 },
          );
      }
      });
  };

  const handleChangePayment = async (
    values: PaymentMethodBodyValues
  ): Promise<HandleChangePaymentResult> => {
    //if (!enrollment.id) return { updatedEnrollment: null };
    const partnerAccount = viewer?.partnerUsers?.edges[0]?.node?.partnerAccount;
    let paymentSourceCreateUpdateSuccess = false;
    let newSourceId = '';
    let updatedEnrollment;
    let updatedSources: ExistingPaymentSource[] | undefined;

    setLoading?.(true);
    if (
      values.paymentMethod === 'existingCC' &&
      values.savedSourceIndex >= 0 &&
      savedCards[values.savedSourceIndex]?.paymentSourceId &&
      savedCards[values.savedSourceIndex]?.paymentSourceId !==
      currentSourceId &&
      enrollment
    ) {
      await updateExistingDRpayment(savedCards[values.savedSourceIndex]);
      newSourceId = savedCards[values.savedSourceIndex].paymentSourceId;
      paymentSourceCreateUpdateSuccess = true;
      // send the update mutation.
    } else if (values.paymentMethod === 'newCC') {
      const sourceData = {
        type: 'creditCard',
        sessionId: '',
        futureUse: true,
        usage: 'subscription',
        mandate: {
          terms: t('cart.payment.terms'),
        },
        owner: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          organization: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: 'US',
            postalCode: '',
          },
        },
        creditCard: {
          number: '',
          expirationMonth: 0,
          expirationYear: 0,
          cvv: '',
        },
        amount: 0,
        currency: 'USD',
      };

      sourceData.sessionId = enrollment.paymentSource.paymentSessionId;

      //let sourceResult = null;

      const [firstName, lastName, ...rest] = values.contactName.split(' ');
      const billToFirstName = firstName;
      const billToLastName = lastName + (rest ? ' ' + rest.join(' ') : '');
      sourceData.owner = {
        firstName: billToFirstName,
        lastName: billToLastName,
        email: `${partnerAccount?.contactEmail ?? viewer?.contactEmail ?? ''}`,
        phone: `${partnerAccount?.primaryPhoneNumber ??
          viewer?.primaryPhoneNumber ??
          ''}`,
        organization: values.companyName,
        address: {
          line1: values.addressLine1,
          line2: values.addressLine2 ?? ' ',
          city: values.city,
          state: values.stateProvinceRegionCode,
          country: values.countryCode,
          postalCode: values.zipPostalCode,
        },
      };
      if (values.contactName.trim() !== '') {
        const nameParts = values.contactName.split(' ');
        if (nameParts.length === 1) {
          sourceData.owner.organization = nameParts[0];
        } else {
          sourceData.owner.firstName = nameParts[0];
          sourceData.owner.lastName = nameParts[1];
        }
      }

      sourceData.creditCard = {
        number: values.cardNumber,
        ...getMonthAndYear(values.monthYear),
        cvv: values.security,
      };
      sourceData.amount = 0.0;

      await drJsLibrary
        .createSource(sourceData)
        .then(async result => {
          if (result.error) {
            setLoading?.(false);
            console.log('error', result.error);
            toast(
              <Alert variant='error'>
                <Text>Error saving credit card:</Text>
                <Text>{result.error.type}:</Text>
                {result.error.errors.map(err => {
                  return (
                    <>
                      <Text>code: {err.code}</Text>
                      <Text>mesg: {err.message}</Text>
                    </>
                  );
                })}
              </Alert>,
              { duration: 10000 }
            );
            return null;
          } else {
            newSourceId = result.source.id;
            createDRpayment(result.source);
            // await digitalRiverBillingUpdateMutation[0]({
            //   variables: {
            //     digitalRiverBillingId: billingId ?? '',
            //     input: {
            //       paymentSourceId: newSourceId,
            //       isPrimaryPaymentSource: values.makePrimary,
            //     },
            //   },
            //   onError: err => {
            //     console.log('error', err);
            //     toast(
            //       <Alert variant='error'>
            //         <Text>{err}</Text>
            //       </Alert>,
            //       { duration: 10000 }
            //     );
            //     return null;
            //   },
            //   onCompleted: (
            //     res: PaymentMethodFormDigitalRiverBillingUpdateMutationResponse
            //   ) => {
            //     console.log(res.digitalRiverBillingUpdate?.digitalRiverBilling);
            //     const sources =
            //       res.digitalRiverBillingUpdate?.digitalRiverBilling
            //         ?.paymentSources ?? [];

            //     updatedSources =
            //       sources?.map(src => {
            //         const src1 = { ...src };
            //         const {
            //           paymentSourceId,
            //           paymentSessionId,
            //           clientSecret,
            //           type,
            //           payPalBillingToken,
            //         } = src1;

            //         const src2 = {
            //           paymentSourceId,
            //           paymentSessionId,
            //           clientSecret,
            //           type: (type ?? null) as any,
            //           payPalBillingToken,
            //           ...(src?.creditCard
            //             ? ({ creditCard: { ...src.creditCard } } as any)
            //             : null),
            //           ...(src?.billTo
            //             ? {
            //                 billTo: {
            //                   ...src.billTo,
            //                   ...(src.billTo.address
            //                     ? { address: { ...src.billTo.address } }
            //                     : null),
            //                 },
            //               }
            //             : null),
            //         };

            //         return src2;
            //       }) ?? [];
            //     paymentSourceCreateUpdateSuccess = true;
            //   },
            // });
          }
        })
        .catch((err: any) => {
          setLoading?.(false);
          console.log('error', err);
          toast(
            <Alert variant='error'>
              <Text>{err.message}</Text>
            </Alert>,
            { duration: 10000 }
          );
        });

    }else{
      setLoading?.(false);
    }
    if (paymentSourceCreateUpdateSuccess) {
      // await paymentMethodUpdateMutation[0]({
      //   variables: {
      //     paymentSourceId: newSourceId,
      //     programEnrollmentId: enrollment.id ?? null,
      //   },
      //   onError: err => {
      //     console.log('error', err);
      //     return null;
      //   },
      //   onCompleted: (
      //     res: PaymentMethodFormPaymentMethodUpdateMutationResponse
      //   ) => {
      //     updatedEnrollment =
      //       res.updatePartnerProgramEnrollmentPaymentSource
      //         ?.partnerProgramEnrollment;
      //   },
      // }
      // );

      //setLoading?.(false);
      return {
        updatedEnrollment,
        updatedSources,
      };
    }
    //setLoading?.(false);
    return { updatedEnrollment: null };
  };

  const submit = async (values, action) => {
    onSubmit?.('');
  };

  const validate = (values: PaymentMethodBodyValues) => {
    const paymentErrors = validatePaymentMethod(values, t);
    if (paymentErrors) {
      return paymentErrors;
    }
    return {};
  };

  const formValues = {
    ...initialPaymentBodyValues(initialValues),
  };

  const setAgreementCheckedFn = (agreementSelectionMade: boolean) => {
    setAgreementChecked(agreementSelectionMade);
  };

  const setNewCardSelectedFn = (newCardSelectionMade: boolean) => {
    setNewCardIsChecked(newCardSelectionMade);
  };

  const buttonEnablementHandler = (formik, agreementChecked) => {
    if (isFromChangePaymentMethod) {
      if (!newCardIsChecked) {
        // If Using existing card, don't disable the button;
        return false;
      } else if (newCardIsChecked) {
        if (!formik.dirty || !formik.isValid || !agreementChecked) {
          return true;
        }
      }
    }
  };

  return (
    <Formik initialValues={formValues} onSubmit={submit} validate={validate}>
      {(formik) => {
        return (
          <Form>
            <ChangePaymentBody>
              <PaymentMethodFormBody
                savedCreditCardSources={savedCards}
                drJsLibrary={drJsLibrary}
                formik={formik}
                config={{
                  withSavedCards: true,
                  withPayPal: false,
                  hideSavedCardsWhenNotActive: false,
                  withMakePrimary: true,
                  withRenewalTerms: true,
                }}
                setIsNewCardSelected={setNewCardSelectedFn}
                agreementSelected={setAgreementCheckedFn}
              />
              <SeparatorLine />
              <Flex justifyContent='flex-end'>
                <CancelBtn onClick={onCancel}>{t('common.cancel')}</CancelBtn>
                <Button
                  variant='primary'
                  type='button'
                  disabled={
                    buttonEnablementHandler(formik, agreementChecked)
                    // !formik.dirty || !formik.isValid || !agreementChecked
                  }
                  onClick={async () => {
                    let isError = false;
                    const newTouched: any = {};
                    Object.keys(formik.values).map((key) => {
                      newTouched[key] = true;
                      isError = !isError
                        ? formik.errors[key]
                          ? true
                          : false
                        : true;
                    });
                    formik.setTouched(newTouched);
                    if (isError) {
                      return console.log('form invalid', formik);
                    }
                    const { updatedEnrollment, updatedSources } =
                      await handleChangePayment(formik.values);

                    if (typeof handleEnrollmentUpdate === 'function')
                      handleEnrollmentUpdate(
                        updatedEnrollment,
                        updatedSources,
                      );
                    onSubmit?.(
                      updatedEnrollment?.paymentSource?.paymentSourceId ?? '',
                    );
                  }}
                  marginLeft='medium'>
                  {t('common.save')}
                </Button>
              </Flex>
            </ChangePaymentBody>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentMethodForm;
