import { types } from './types';

type UpdateDRPaymentProps = {
    selectedPaymentMethodId: string;
};

type CreteDRNewBillingProps = {
    billingAddress: any;
};

type CreteDRBillingResponseProps = {
    dRPaymentDetailsForm: any;
};

export const updateDRPayment = (payload?: UpdateDRPaymentProps) => ({
    type: types.UPDATE_DR_PAYMENT,
    payload,
});

export const updateDRPaymentSuccess = (payload: any) => ({
    type: types.UPDATE_DR_PAYMENT_SUCCESS,
    payload,
});

export const updateDRPaymentFail = (payload: any) => ({
    type: types.UPDATE_DR_PAYMENT_FAIL,
    payload,
});

export const creteDRNewBilling = (payload?: CreteDRNewBillingProps) => ({
    type: types.CREATE_DR_NEW_BILLING,
    payload,
});

export const creteDRNewBillingSuccess = (payload: any) => ({
    type: types.CREATE_DR_NEW_BILLING_SUCCESS,
    payload,
});

export const creteDRNewBillingFail = (payload: any) => ({
    type: types.CREATE_DR_NEW_BILLING_FAIL,
    payload,
});

export const creteDRBillingResponse = (payload?: CreteDRBillingResponseProps) => ({
    type: types.CREATE_DR_BILLING_RESPONSE,
    payload,
});

export const creteDRBillingResponseSuccess = (payload: any) => ({
    type: types.CREATE_DR_BILLING_RESPONSE_SUCCESS,
    payload,
});

export const creteDRBillingResponseFail = (payload: any) => ({
    type: types.CREATE_DR_BILLING_RESPONSE_FAIL,
    payload,
});
