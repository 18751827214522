import React, { useCallback, useEffect, useState } from 'react';
import { Box, Heading, Text, Image } from '@resideo/blueprint-react';
import { resideoBlackLogo } from 'assets/images';
import { TableHeader } from 'components/common/Styles';
import styled from 'styled-components';
import SeparatorLine from 'components/common/SeparatorLine';
import { convertMoneyAmount } from 'routes/ServicesAndSubscriptions/Cart';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router';
import PageLoading from 'components/common/PageLoading';
import { COUNTRIES } from 'config/countries';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { getOrdersByOrderNumber } from 'redux/subscriptions/actions';
import _ from 'lodash';

const Container = styled.div`
    max-width: 1024px;
    margin: 0 auto;
    padding: 50px 50px 0px 50px;
`;

const TitleInvoice = styled.h1`
    margin-bottom: 0;
    font-family: 'URW_Din_CondBold';
    font-size: 37px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    letter-spacing: 1px;
`;

const InvoiceNo = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    letter-spacing: 0;
`;

const BillTo = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    letter-spacing: 0;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.textHeading};
    padding-top: 1.5rem;
`;

const BillToAddress = styled(Text)`
    font-size: ${({ theme }) => theme.fontSizes.medium};
    letter-spacing: 0;
    color: ${({ theme }) => theme.colors.textHeading};
`;

const TextDue = styled(Text)`
    margin-top: 50px;
    color: ${({ theme }) => theme.colors.textHeading};
    font-size: ${({ theme }) => theme.fontSizes.small};
    letter-spacing: 0;
`;

const TableItems = styled.table`
    margin-top: 10px;
    th {
        font-size: ${({ theme }) => theme.fontSizes.small} !important;
        &:last-child {
            text-align: right;
        }
    }
    td {
        font-size: ${({ theme }) => theme.fontSizes.medium};
        letter-spacing: 0;
        color: ${({ theme }) => theme.colors.textHeading};
        &:nth-last-child(2) {
            color: ${({ theme }) => theme.colors.blackText};
        }
        &:last-child {
            text-align: right;
            color: ${({ theme }) => theme.colors.text};
            font-weight: ${({ theme }) => theme.fontWeights.bold};
        }
    }
`;

const SubtotalBox = styled.div`
    padding: 15px 25px;
`;

const FooterNote = styled.p`
    font-family: 'Roboto';
    margin-top: 100px;
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.textHeading};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-style: italic;
    letter-spacing: 0;
    line-height: 21.77px;
    text-align: center;
`;

const ServiceLocationTd = styled.td`
    color: ${({ theme }) => theme.colors.blackText};
    font-family: 'Roboto';
    font-size: ${({ theme }) => theme.fontSizes.medium};
`;

interface addressDataState {
    firstName?: string | null;
    lastName?: string | null;
    companyName?: string | null;
    line1?: string | null;
    line2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zipCode?: string | null;
}
interface costData {
    subTotal?: string | null;
    salesTax?: string | null;
    total?: number | string | null;
    currency?: string | null
}

interface entriesDataState {
    date?: string | null;
    description?: string | null;
    servicePeriod?: string | null;
    serviceLocations?: string | null;
    amount?: string | null;
}

const Invoice = () => {
    useEffect(() => {
        document.title = `Invoice #208702430336 | Resideo Pro`;
    }, []);
    const hookDispatch = useDispatchPromise();
    const params = useParams() as any;
    const invoiceId = params?.id;
    const [isLoading, setLoading] = useState(true);
    const [entries, setEntries] = useState<any[]>([]);
    const [cost, setCost] = useState<any>({
        subTotal: '',
        salesTax: '',
        total: '',
    });
    const [addressData, setAddressData] = useState<addressDataState>({
        firstName: '',
        lastName: '',
        companyName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
    });
    const [billingDate, setBillingDate] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const { t } = useTranslation();
    const countryCodeToFullText = (code) => {
        let countryText = '';
        COUNTRIES.map((obj) => {
            if (obj.value === code) {
                countryText = t(obj.text);
            }
        });
        return countryText;
    };

    const dateSort = (data: Array<any>) => {        
        return _.orderBy(data, item => item.ratingStartDate);
    };

    const retrieveInvoiceData = () => {
        hookDispatch(getOrdersByOrderNumber({ invoiceId: invoiceId }))
            .then((response: any) => {
                const addressDataObj: addressDataState = {};
                addressDataObj.firstName = response.drpaymentInfo.billingAddress.firstName;
                addressDataObj.lastName = response.drpaymentInfo.billingAddress.lastName;
                addressDataObj.companyName = response.orgCustomer.name;
                addressDataObj.line1 = response.drpaymentInfo.billingAddress.line1;
                addressDataObj.line2 = response.drpaymentInfo.billingAddress.line2;
                addressDataObj.city = response.drpaymentInfo.billingAddress.town;
                addressDataObj.state = response.drpaymentInfo.billingAddress.region.isocodeShort;
                addressDataObj.country = response.drpaymentInfo.billingAddress.country.name;
                addressDataObj.zipCode = response.drpaymentInfo.billingAddress.postalCode;
                setAddressData(addressDataObj);
                const costDataObj: costData = {};
                costDataObj.currency = response.deliveryOrderGroups[0].entries[0].basePrice.currencyIso
                costDataObj.subTotal = response.subTotal.value;
                costDataObj.salesTax = response.totalTax.value;
                costDataObj.total = response.totalPriceWithTax.value;
                setCost(costDataObj);
                setEntries(response.entries);
                setBillingDate(response?.created);
                setLoading(false);
            })
            .catch((error) => {
                console.log('Error', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        retrieveInvoiceData();
    }, [invoiceId]);

    const dateFormattingFn = (date) => {
        let desiredChunk = date.slice(0, 10);
        let yearPiece = desiredChunk.slice(0, 4).slice(-2);
        let dateArray = desiredChunk.split('-');
        dateArray.push(yearPiece);
        dateArray.splice(0, 1);
        const finalFormat = dateArray.join('/');
        return finalFormat;
    };

    return isLoading ? (
        <PageLoading />
    ) : (
        <Container>
            <Image src={resideoBlackLogo} alt='resideo' />
            <TitleInvoice>{t('invoiceService.title')}</TitleInvoice>
            <InvoiceNo>
                <strong>{t('invoiceService.invoiceNo')} :</strong> {invoiceId}
            </InvoiceNo>
            <BillTo>{t('invoiceService.billTo')}:</BillTo>
            <BillToAddress>
                {addressData.firstName} {addressData.lastName}
                <br />
                {addressData.companyName}
                <br />
                {addressData.line1} { } {addressData.line2}
                <br />
                {addressData.city}, {addressData.state} {addressData.zipCode}
                <br />
                {addressData.country}
            </BillToAddress>
            <TextDue>{t('invoiceService.chargedMsg')}</TextDue>
            <TableItems className='table-responsive'>
                <thead>
                    <tr>
                        <TableHeader>{t('invoiceService.date')}</TableHeader>
                        <TableHeader>{t('invoiceService.description')}</TableHeader>
                        <TableHeader>{t('invoiceService.billingPeriod')}</TableHeader>
                        <TableHeader>{t('invoiceService.monitors')}*</TableHeader>
                        <TableHeader>{t('invoiceService.amount')}</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {dateSort(entries).map((invoice, i) => (
                        <tr key={i}>
                            <td>
                                {billingDate ? (
                                    moment(billingDate).format(
                                        'MMM D, YYYY',
                                    )
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </td>
                            {/* <td>{showDesc(invoice?.sku)}</td> */}
                            <td>{invoice.product.name}</td>
                            <td>
                                {invoice.ratingStartDate
                                    ? dateFormattingFn(invoice.ratingStartDate)
                                    : ''}{' '}
                                {'-'}{' '}
                                {invoice.ratingEndDate
                                    ? dateFormattingFn(invoice.ratingEndDate)
                                    : ''}
                            </td>
                            <ServiceLocationTd>
                                {parseFloat(invoice?.chargedQuantity ?? '0') > 0
                                    ? invoice.chargedQuantity
                                    : '0'}
                            </ServiceLocationTd>
                            <td color='#000000'>{invoice.totalPrice.formattedValue}</td>
                        </tr>
                    ))}
                </tbody>
            </TableItems>
            <SeparatorLine />
            <SubtotalBox>
                <Box marginBottom='small'>
                    <Box
                        display='flex'
                        width='100%'
                        justifyContent='flex-end'
                        paddingBottom='small'>
                        <Text fontSize='17px' width='calc(100% - 7.625rem)' textAlign='right'>
                            {t('invoiceService.subTotalInUSD')}
                        </Text>
                        <Text fontSize='17px' width='7.625rem' textAlign='right'>
                            {convertMoneyAmount(
                                {
                                    amount: cost?.subTotal,
                                    currencyCode: cost?.currency,
                                },
                                'en-us',
                            )}
                        </Text>
                    </Box>
                    <Box display='flex' width='100%' justifyContent='flex-end'>
                        <Text fontSize='17px' width='calc(100% - 7.625rem)' textAlign='right'>
                            {t('invoiceService.salesTax')} (X%)
                        </Text>
                        <Text fontSize='17px' width='7.625rem' textAlign='right'>
                            {convertMoneyAmount(
                                {
                                    amount: cost?.totalTax,
                                    currencyCode: cost?.currency,
                                },
                                'en-us',
                            )}
                        </Text>
                    </Box>
                </Box>
                <Box>
                    <Box display='flex' alignItems='center' width='100%' justifyContent='flex-end'>
                        <Heading
                            letterSpacing='normal'
                            fontSize='17px'
                            width='calc(100% - 7.625rem)'
                            textAlign='right'>
                            {t('invoiceService.totalInUSD')}
                        </Heading>
                        <Heading
                            letterSpacing='normal'
                            width='7.625rem'
                            fontSize='17px'
                            textAlign='right'>
                            {convertMoneyAmount(
                                {
                                    amount: cost?.total,
                                    currencyCode: cost?.currency,
                                },
                                'en-us',
                            )}
                        </Heading>
                    </Box>
                </Box>
            </SubtotalBox>
            <FooterNote>
                * {t('invoiceService.footerMsg1')}
                <br />
                <strong>{t('invoiceService.footerMsg2')}</strong> {t('invoiceService.footerMsg3')}
            </FooterNote>
        </Container>
    );
};

export default Invoice;
