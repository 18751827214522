import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchingCartID: false,
    cartIDDetails: [],
    fetchingCartIDError: null,
    createCartIdSuccess: false,
    createCartIdDetails: [],
    createCartIdError: null,
    addProductInCart: false,
    addProductInCartDetails: [],
    addProductInCartError: null,
    updateCartItemLoading: false,
    updateCartItemSuccess: false,
    updateCartItemDetails: [],
    updateCartItemError: null,
    removeCartItemLoading: false,
    removedCartItemSuccess: false,
    removeCartItemDetails: [],
    removeCartItemError: null,
    fetchingCartDetails: false,
    cartDetails: [],
    fetchingCartDetailsError: null,
};

const cartDetails = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_CART_ID:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCartID = true;
            });
        case types.FETCH_CART_ID_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCartID = false;
                draftState.cartIDDetails = payload;
            });
        case types.FETCH_CART_ID_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCartID = false;
                draftState.fetchingCartIDError = payload;
            });

        case types.CREATE_CART_ID:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createCartIdSuccess = true;
            });
        case types.CREATE_CART_ID_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createCartIdSuccess = false;
                draftState.createCartIdDetails = payload;
            });
        case types.CREATE_CART_ID_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createCartIdSuccess = false;
                draftState.createCartIdError = payload;
            });

        case types.ADD_PRODUCT_IN_CART:
            return produce(state, (draftState: typeof initialState) => {
                draftState.addProductInCart = true;
            });
        case types.ADD_PRODUCT_IN_CART_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.addProductInCart = false;
                draftState.addProductInCartDetails = payload;
            });
        case types.ADD_PRODUCT_IN_CART_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.addProductInCart = false;
                draftState.addProductInCartError = payload;
            });

        case types.UPDATE_CART_QUANTITY:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateCartItemLoading = true;
            });
        case types.UPDATE_CART_QUANTITY_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateCartItemLoading = false;
                draftState.updateCartItemSuccess = true;
                draftState.updateCartItemDetails = payload;
            });
        case types.UPDATE_CART_QUANTITY_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateCartItemLoading = false;
                draftState.updateCartItemError = payload;
            });

        case types.REMOVE_CART_ITEM:
            return produce(state, (draftState: typeof initialState) => {
                draftState.removeCartItemLoading = true;
            });
        case types.REMOVE_CART_ITEM_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.removeCartItemLoading = false;
                draftState.removedCartItemSuccess = true;
                draftState.removeCartItemDetails = payload;
            });
        case types.REMOVE_CART_ITEM_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.removeCartItemLoading = false;
                draftState.removeCartItemError = payload;
            });
        case types.FETCH_CART_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCartDetails = true;
            });
        case types.FETCH_CART_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCartDetails = false;
                draftState.cartDetails = payload;
            });
        case types.FETCH_CART_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCartDetails = false;
                draftState.fetchingCartDetailsError = payload;
            });
        case types.UPDATE_CART_FLAG:
            return produce(state, (draftState: typeof initialState) => {
                draftState.removedCartItemSuccess = false;
                draftState.updateCartItemSuccess = false;
            });
        default:
            return state;
    }
};

export { cartDetails };
