import React, { FC } from 'react';
import { Text, Image, Link } from '@resideo/blueprint-react';
import styled from 'styled-components';
import backArrow from '../../assets/images/back-arrow.png';
import { useHistory } from 'react-router-dom';
const StyledText = styled(Text) <{ isManageSub?: boolean }>`
  text-transform: uppercase;
  margin-left: ${({ theme }) => theme.space.small};
  font-size: ${({ theme, isManageSub }) =>
    isManageSub ? theme.fontSizes.small : theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme, isManageSub }) =>
    isManageSub ? theme.fontWeights.bold : theme.fontWeights.medium};
  cursor: pointer;
  font-family: 'Roboto';
`;

const StyledLink = styled(Link)`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    justify-content: left;
  }
`;
interface GoBackButtonProps {
  url: string;
  text: string;
  isManageSub?: boolean;
}

const GoBackButton: FC<GoBackButtonProps> = ({
  url,
  text,
  isManageSub = false,
}) => {
  const history = useHistory();

  const handleGoBack = (urlLink) => {
    history.push(urlLink);
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  return (
    <StyledLink
      data-test-go-back-btn
      //href={url}
      onClick={() => handleGoBack(url)}
      className='back-arrow-animation'>
      <Image src={backArrow} alt='backArrow' />
      <StyledText isManageSub={isManageSub}>{text}</StyledText>
    </StyledLink>
  )
};

export default GoBackButton;
