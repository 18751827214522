/* eslint-disable import/no-anonymous-default-export */
export default {
    title: 'Resideo Pro - Services & Subscriptions',
    leftNav: {
        personalInfo: 'Personal Info'
    },
    common: {
        agree: 'Agree',
        decline: 'Decline',
        yes: 'Yes',
        no: 'No',
        finish: 'Finish',
        cancel: 'Cancel',
        edit: 'Edit',
        save: 'Save',
        reset: 'Reset',
        close: 'Close',
        confirm: 'Confirm',
        activate: 'Activate',
        browse: 'Browse',
        invite: 'Invite',
        view: 'View',
        viewAll: 'View All',
        learnMore: 'Learn more',
        setupBtn: 'Setup',
        send: 'Send',
        sending: 'Sending',
        skip: 'Skip for now',
        preview: 'Preview',
        fileupload: 'Select file to upload',
        dropfile: 'Drag your file here or select your file by clicking here',
        file: 'file',
        files: 'files',
        requiredFields: '*Required Fields',
        for: 'for',
        notify: 'Notify me',
        learn: 'Learn More',
        enroll: 'Enroll',
        create: 'Create',
        restore: 'Restore',
        submit: 'Submit',
        search: 'Search',
        makePrimary: 'Make Primary',
        saving: 'Saving...',
        back: 'Back',
        next: 'Next',
        step: 'Step',
        of: 'of',
        status: 'Status',
        success: 'Success',
        failed: 'Failed',
        companyEmail: 'Company Email',
        companyName: 'Company Name',
        companyPhone: 'Company Phone',
        resideoAccount: 'Resideo Account Number',
        email: 'Email Address',
        emailShort: 'Email',
        password: 'Password',
        phone: 'Phone',
        active: 'Active',
        inactive: 'Inactive',
        pending: 'Pending',
        notActive: 'Not Active',
        canceled: 'Canceled',
        forms: {
            choose: 'Choose...',
            none: 'None',
            hidePassword: 'Hide password',
            showPassword: 'Show password',
        },
        name: {
            label: 'Name',
            first: 'First Name',
            last: 'Last Name',
            middle: 'Middle Name',
            prefix: 'Prefix',
            suffix: 'Suffix',
        },
        numbers: {
            0: '0',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
            9: '9',
            10: '10',
        },
        languages: {
            en: 'English',
            es: 'Spanish',
        },
        proCategories: {
            label: 'Pro Categories',
            AIR: 'Air',
            AUTOMATION: 'Automation',
            ELECTRICIAN: 'Electrician',
            ENERGY: 'Energy',
            HVAC: 'HVAC Contractor',
            OTHER: 'Other',
            PLUMBER: 'Plumber',
            SECURITY: 'Security',
            UTILITY: 'Utility Aggregator',
            WATER: 'Water',
        },
        roles: {
            label: 'Role',
            ADMIN: 'Admin',
            BILLING: 'Billing',
            COMPANY_ADMIN: 'Company Admin',
            MARKETING: 'Marketing',
            OFFICE_ADMIN: 'Office Admin',
            OPERATIONS: 'Operations',
            OWNER: 'Owner',
            SALES: 'Sales',
            TECHNICIAN_INSTALLER: 'Technician Installer',
            TECHNICIAN: 'Technician',
            MEMBER: 'Member',
        },
        programs: {
            label: 'Program Assignment',
        },
        tag: {
            viewer: 'You',
            primary: 'Primary',
        },
        toast: {
            error: 'Something went wrong!',
            errorPerks: 'Failed submission for Perks member!',
            success: 'Changes saved',
            inviteSent: 'Invite Sent!',
            autoRenewOff: 'Your Auto-Renew is now Off.',
            subscriptionCanceled: 'Your subscription has been canceled.',
            paymentMethodChanged: 'Card ending 8545 successfully added.',
        },
        countries: {
            label: 'Country',
            US: 'United States',
            MX: 'Mexico',
            CA: 'Canada',
        },
        verification: {
            verified: 'Email verified',
            unverified: 'Email unverified',
            resend: 'Resend verification',
            resendSuccess: 'Verification email sent',
            resendError: 'Error sending email verification',
        },
        address: {
            label: 'Address',
            address1: 'Address Line 1 *',
            address2: 'Address Line 2',
            city: 'City *',
            state: 'State *',
            province: 'Province *',
            stateProvince: 'State/Prov *',
            zip: 'Postal Code *',
            country: 'Country *',
            addressRequired: 'Please enter a street address',
            cityRequired: 'Please enter a city',
            stateRequired: 'State is required',
            zipRequired: 'Please enter a zip/postal code',
            zipInvalid: 'Invalid Postal Code',
            provinceRequired: 'Province is required',
            countryRequired: 'Country is required',
            locationName: 'Location Name',
            selectACountry: 'Please select a country',
            selectAState: 'Please select a state',
            selectAProvince: 'Please select a province',
        },
    },

    header: {
        primary: {
            home: 'Home',
            mybusiness: 'My business',
            perks: 'Perks',
            training: 'Training',
            products: 'Products',
            solutions: 'Solutions',
            resources: 'Resources',
            settings: 'Settings',
        },
        secondary: {
            dashboard: 'Dashboard',
            customerAccounts: 'Customer Accounts',
            servicesSubscriptions: 'Services & Subscriptions',
            companyInfo: 'Company Info',
        },
        myAccount: 'My account',
        profile: 'My Profile',
        adminConsole: "Admin Console",
        company: 'My company',
        signout: 'Sign out',
        signin: 'Sign In',
    },
    footer: {
        copyright:
            '\u00a9 {{year}} Resideo Technologies, Inc. The Honeywell Home trademark is used under license from Honeywell International Inc.',
        socialMediaHeader: 'Connect With Us',
        legal: {
            links: {
                resideo: 'Resideo.com',
                honeywellhome: 'HoneywellHome.com',
                adiglobal: 'ADIGlobal.com',
                privacypolicy: 'PRIVACY POLICY',
                smallPrivacyPolicy: `Privacy Policy`,
                termsOfSale: 'TERMS OF SALE',
                californiaRights: 'YOUR CALIFORNIA PRIVACY RIGHTS',
                returnPolicy: 'WHAT IS YOUR RETURN POLICY?',
                termsconditions: 'Terms & Conditions',
                sitemap: 'Sitemap',
            },
        },
    },
    404: {
        header: 'Oh snap! This page could not be found.',
        description: 'The requested URL does not exist or is temporarily unavailable.',
        link: 'Go to home page',
    },
    500: {
        header: 'Internal server error',
        description: 'The URL {{url}} had the following error {{err}}.',
    },
    503: {
        header: 'Service unavailable',
        description: 'Sorry about that.',
        verbiage: 'Looks like something went wrong on our end. Try again in a minute or two.',
        button: 'OK',
    },
    error: {
        header: 'Unexpected Error',
        description: 'We are not able to process your request. Please try again later.',
        button: 'Retry',
        retryLater: 'We apologize for the inconveniences. Please try again at a later time.',
        payment:
            'Sorry, there was an issue with your payment method. Please try again or use another payment method.',
        invalidCardExpiration:
            'The expiration date entered is invalid.  Please enter a date in the format MM/YY.',
        expiredCard:
            'The selected payment method is expired. Please select a different payment method.',
        canceledPayment: 'Payment Cancelled',
        retryLaterMessage: 'We apologize for the inconvenience. Your order has not been completed yet. Please try again after 20 minutes',
    },
    perks: {
        dashboard: 'Go To My Dashboard',
        pageTitle: 'Rewards',
        next: ' to next star',
        ytd: ' YTD',
        stars: ' stars',
        average: 'YOY',
        need: 'You need',
        more: ' more stars to reach ',
        oneMore: ' more star to reach ',
        reached: 'You have reached',
        dollars: 'Dollars',
        balances: 'Balances',
        balance: 'Balance',
        levels: {
            member: 'member',
            silver: 'silver',
            gold: 'gold',
            platinum: 'Platinum',
        },
        star: 'star',
        toReach: 'to reach',
        status: 'Status',
        totalPoints: 'Total Points',
        numbers: {
            0: '0',
            5: '5',
            7: '7',
            9: '9',
            11: '11',
        },
        titles: {
            points: 'Points',
            statusTitle: 'Current Business Status Level',
            volume: 'Volume',
            growth: 'Growth',
            productMix: 'Product Mix',
            training: 'Training',
            popular: 'Popular ways to redeem points',
            perks: 'Get the most of your PERKS',
            recentPoints: 'Recent Point Activity',
            categories: ' Categories',
            data: 'Data',
            add: 'Add your customer data',
            mdf: 'MDF',
            leadGen: 'Lead Gen',
            starsAchieved: 'Stars Acheived by Category',
            buyProducts: 'Buy Products',
            otherWaysToRedeem: 'Other ways to redeem',
            calculatingAccount:
                'We are currently calculating your account. Please check back within the next 48 hours.',
            information: 'Information',
            quickLinks: 'Quick Links',
            comingSoon: 'Coming Soon!',
            comingSoonText:
                'Right now, we’re showing you a sample of the data you’ll see in this dashboard.',
            benefits: 'resideo pro perks benefits',
            loyaltyNumber: 'PRO PERKS Loyalty Number:',
            accessBenefits1: 'In order to access all of your benefits, you must click',
            accessBenefits2: 'to accept the terms of participation',
            rewardStatus: 'Reward Status',
            rewardsSummary: 'Rewards Summary',
            learnMore: 'Learn More',
            dealsAndPromotions: 'deals and promotions',
            growYourBusiness: 'grow your business',
            mixItUp: 'mix it up',
            startLearning: 'start learning',
            loyaltyNum: 'Pro PERKS Number: ',
            earnMoreStars: 'Earn more stars towards greater rewards!',
            seeYourProgress: 'See your reward progress',
            memberResources: 'Member Resources',
            tierBenefits: 'Tier Benefits',
        },
        links: {
            details: 'Details',
            seePromotions: 'see promotions',
            grow: 'Grow with resideo',
            myTraining: 'My Training',
            expand: 'expand your portfolio',
            redeem: 'Redeem Points',
            redeemProductCertificate: 'Redeem Product Certificate',
            submitCoOp: 'Submit a Co-Op Claim',
            coOp: 'Co-Op',
            marketing: 'Marketing Enablement',
            marketingTools: 'Marketing Tools',
            marketingEnablement: 'Marketing Enablement',
            training: 'Training',
            seeAll: 'see all activity',
            seeLess: 'see less activity',
            add: 'How to add customer data',
            mdf: 'Submit a MDF Claim',
            programOverview: 'Program Overview',
            resources: 'Other PERKS Documents',
            contactUs: 'Contact Pro PERKS',
            addTeamMember: 'Add a Team Member',
            supportGuide: 'Dealer Support Guide',
            logos: 'Logos',
            coOpFunds: 'Co-Op Funds',
            about: 'ABOUT THE PROGRAM',
            help: 'HELP',
            tierBenefits: 'Tier Benefits',
            memberResources: 'Member Resources',
            mixItUp: 'Mix It Up',
            startLearning: 'Start Learning',
            forwardThinking: 'ForwardThinking.Resideo.com',
            myWebTech: 'MyWebTech',
            photoLibrary: 'Photo Library',
            discountCenter: 'Discount Center',
            registerNow: 'Register Now',
            linkName: 'Link Name',
        },
        icons: {
            titles: {
                performance: 'PERFORMANCE',
                rewards: 'REWARDS',
                services: 'SERVICES',
                enablement: 'ENABLEMENT',
                knowledge: 'KNOWLEDGE',
            },
            text: {
                performance: 'Outstanding financial rewards',
                rewards: 'Marketing, luxury, items, + much more',
                services: 'Elevated support services',
                enablement: 'Best pratices & business enablement',
                knowledge: 'World-class online training content',
            },
        },
        logos: {
            title: 'Pro Perks Logos',
            download: 'Download',
            logo: 'Logo',
            twoColor: 'Two Color Logo',
            oneColor: 'One Color Logo',
            blueColor: 'Blue Color Logo',
            honeywellZip: 'Honeywell Logo Suite Zip',
            resideoZip: 'Resideo Logo Suite Zip',
            ai: 'AI',
            eps: 'EPS',
            png: 'PNG',
            jpg: 'JPG',
        },
    },
    accessDenied: {
        heading: 'Access denied',
        content: "You don't have permission to access this page.",
    },
    dealerDashboard: {
        performance: 'PERFORMANCE',
        asOf: 'As of',
        purchasesToDate: 'Purchases to Date',
        growth: 'Growth',
        connectedDeviceRate: 'Connected Device Attatch Rate',
        education: 'EDUCATION',
        connectedHome: 'Connected Home',
        resideoStory: 'Resideo Story',
        participation: 'PARTICIPATION',
        useOfProgramLogo: 'Use of Program Logo',
        presenceOnWebsite: 'Presence on Website',
        connect: 'Dealer Development Symposium & CONNECT Attendance',
        ddg: 'DDG On-Site Training/Business Planning',
    },
    pageError: {
        heading: "This page isn't working.",
        content: 'Try again, and contact us if the problem continues.',
    },
    terms: {
        agree: 'Agree',
        accept: 'Accept End User License Agreement',
        title: 'Resideo Pro PERKS Program Terms of Participation',
        welcome: `Welcome to the Resideo Pro Perks Program! The following Terms of Participation (the “Agreement”) between Ademco Inc. d/b/a Resideo (“Resideo”), with an office at 901 East 6thStreet, Austin, TX 78702 and  [________________ ], (“Participant”,“You”,”Your”) govern your participation in the Resideo Pro PERKS(“Program”) provided through the Resideo Program portal`,
        firstTitle: '1. Participation',
        firstTerm: `IN ORDER TOPARTICIPATE IN THE PROGRAM, YOU MUST ACCEPT THIS AGREEMENT. PLEASE INDICATE YOUR AGREEMENT BY CLICKING “I AGREE.” Resideo may revise and update this Agreement at anytime, in which case Resideo will notify you of the changesto the Agreementvia electronic notice, at which time they become binding. You maynot amend or change this Agreement unless Resideoagrees in writingthough aduly authorized representative.`,
        secondTitle: '2. Suspension or Termination of Program',
        secondTerm: `Resideo may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate your ability to participate in the program, without notice or liability, for any reason whatsoever, including but not limited to (a) your breach of this Agreement or any other agreement between Resideo and you; (b) Resideo’s inability to verify or authenticate any information you provide; or (c) Resideo’s determination, in its discretion, that your actions may cause,result in, or carry a risk of, legal liability for Resideo or any third party. Resideo reserves the right to terminate the entire Program at any timew ithout liability. Any provision of this Agreement that by its terms imposes continuing obligations on you shall survive the termination of this Agreement.`,
        thirdTitle: '3. Applicable Program Terms and Updates',
        thirdTermFirst: `By using Resideo’s site, you confirm that you accept the terms of use at`,
        thirdTermSecond: `and that you agree to comply with them. When Participant accesses the portal at`,
        thirdTermThird: `the then-current Program terms will apply. Resideo, in its sole discretion may provide updated terms or conditions or benefits and may make any other updates to the Program via electronic notice. All other pre-existing Resideo loyalty programs are hereby terminated and no longer in force or effect. Except to the extent you have an existing written and signed agreement for the sale of products, Resideo’s Standard Terms and Conditions of Sale provide terms for products that are currently available for sale, as updated by Resideo from time to time. For the most recent Standard Terms and Conditions of Sale, please see`,
        forthTitle: '4. Electronic Notices',
        forthTerm: `Resideo may provide Participant with information and notices about new Program Terms and Conditions, including via email, through the portal, or through a web site that Resideo identifies. Notice shall be deemed provide to you as of the date it is made available by Resideo. As Participant’s privacy is important to Resideo,please see Resideo’s Privacy Policy:`,
        fifthTitle: '5. Term',
        fifthTerm: `The Term of this Agreement will begin at the time you indicate acceptance of all Terms and Conditions by clicking “I AGREE’ on the Participant Registration page and will conclude upon Program termination. If at any time Participant wishes to stop participating in the program, Participant shall notify Resideo. Resideo shall have no further liability nor obligations under any agreement, all program benefits for Participant will be immediately terminated and all future sales shall be under Resideo’s Standard Terms and Conditions of Sale.`,
        sixthTitle: '6. Miscellaneous',
        sixthTerm: `This Agreement here to constitutes the entire agreement between the parties with respect to the Program and supersedes all oral or written agreements and understandings made with respect to the Program and any other loyalty program entered into by the parties prior to the date hereof. This Agreement may in some instances differ from the terms in Participant’s purchase order or other documents. In that event, any additional terms or terms conflicting with the terms of this Agreement or the Exhibits will not apply  and  are expressly  rejected unless  specifically  set  forth  in  a  separate  written  agreement  between  the  duly  authorized representative of the parties.`,
        seventhTitle: '7. Marketing',
        seventhTermFirst: `In accordance with applicable laws and regulations, you consent to the Resideo group of companies (as listed in our  10-K  form at`,
        seventhTermSecond: `) using your personal information (including your email address, phone number and other personal information we may  hold about you such as your profile) to send you personalized marketing via email, other online messaging platforms, telephone and SMS. This may also include using third parties to show you advertisements elsewhere (for example, on other websites) that we think may be of interest to you. You are able to opt-out from marketing communications at any timeand free of charge, either by using the “unsubscribe” functionality within our marketing    messagesor by contacting us using the contact information listed in our Privacy Statement at`,
        seventhTermThird: `. For details about we use your personal information, please refer to our Privacy Statement.`,
        linkText: {
            pro: 'https://www.resideo.com/us/en/pro/',
            perks: 'https://www.resideo.com/us/en/pro/resideo-pro-perks',
            tAndC: 'https://www.resideo.com/Ts and Cs of Sale',
            corporate: 'https://www.resideo.com/us/en/corporate/legal/privacy/english/',
            financial: 'https://investor.resideo.com/financials/sec-filings/default.aspx',
            legal: 'https://www.resideo.com/us/en/corporate/legal/privacy/english/',
        },
    }, //below is key is added for resideo
    add: 'Add',
    uploadFile: 'Upload File',
    resideo: 'Resideo',
    subscribe: 'Subscribe',
    upgrade: 'Upgrade',
    invoice: 'Invoice',
    address: 'Address',
    apply: 'Apply',
    close: 'Close',
    open: 'Open',
    comparePlans: 'Compare Plans',
    tax: 'Tax',
    notYetCalculated: '(not yet calculated)',
    viewDetails: 'View details',
    welcome: 'Welcome',
    totalToday: 'Total Today',
    subtotalToday: 'Subtotal Today',
    discount: 'Discount',
    subtotalInUSD: 'Subtotal in USD',
    totalInUSD: 'Total in USD',
    salesTax: 'Sales Tax',
    continue: 'Continue',
    printInvoice: 'Print Invoice',
    fullName: 'Full Name',
    date: 'Date',
    description: 'Description',
    quantity: 'Quantity',
    amount: 'Amount',
    back: 'Back',
    monthly: 'Monthly',
    yearly: 'Yearly',
    home: {
        title: 'Review and access your active and inactive subscription plans.',
    },
    menu: {
        dashboard: 'Dashboard',
        servicesAndSubscriptions: 'SERVICES & SUBSCRIPTIONS',
        rewards: 'Rewards',
        companyInfo: 'Company Info',
    },
    cart: {
        deleteCheckoutCriticalError:
            'An unexpected error occurred creating your checkout. Please try again later.',
        createBillingError: 'An unexpected error occurred creating/verifying your billing account.',
        completeProfileError1:
            'To proceed with this purchase, you must complete setting up your Company Info under your Pro Account. Please ',
        completeProfileError2: 'click here',
        completeProfileError3: 'to complete your profile.',
        hasExistingServiceError:
            'You already have an Homeowner App Branding Subscription with your account. Please remove it from your cart to complete your purchase.',
        existingService: 'Existing service',
        activationChargesLabel: 'Charges begin upon device activation.',
        activationServiceLabel: 'Your credit card will be charged when you set up your service.',
        subtotalNoTax: 'Subtotal (tax not included)',
        cart: 'Cart',
        addANewAddress: 'Add a new address',
        yourShoppingCart: 'Your Shopping Cart',
        promoCode: 'Promo Code',
        promoCodeApplied: 'Promo Code Applied Successfully',
        youSaved: 'You Saved',
        backToCart: 'Back To Cart',
        addPromoCode: 'Add Promo Code',
        continueShopping: 'Continue Shopping',
        unknownError: 'There was an unknown error while processing the request. Try again later.',
        serviceActivatedText:
            'Once the order is processed the shipping time is dependent on the shipping method that has been selected. Prices and offers in the cart are subject to change until the order is submitted.',
        applicationFeesText:
            'is the authorized reseller and merchant of the products and services offered within this store.',
        cartFooterInformation:
            'Prices and offers in the cart are subject to change until the order is submitted. For physical products, once the order is processed the shipping time is dependent on the shipping method that has been selected.',
        drGlobalTech: 'DR GlobalTech, Inc.',
        drGlobalTechText:
            'is the authorized reseller and merchant of the products and services offered within this store.',
        monthFree: '1 Month Free, Then',
        per: 'per device/',
        plusTaxes: 'plus taxes',
        return: `Privacy Policy | Terms of Sale | Your California Privacy Rights |
    What is your return Policy?`,
        emptyCart: 'Your cart is empty.',
        houseHoldTaxes: '$0.99 per household/month plus taxes',
        houseHoldTaxesPayment: '$0.99 per household/month',
        alreadyHave:
            'You already have an AirCycle Subscription associated with your account. Please remove it from your cart to complete your purchase.',
        deviceId: 'Device ID',
        shipping: {
            shipping: 'Shipping',
            orderSummary: 'Order Summary',
            shippingAddress: 'Shipping Address',
            shippingMethod: 'Shipping Method',
            checkout: 'Checkout',
            backToShipping: 'Back to Shipping',
            defaultAddress: 'Make this my default address.',
            domesticSecondDayAir: 'Domestic Second Day Air',
            domesticNextDayAir: 'Domestic Next Day Air',
            standardGroundBusinessDays: 'Standard Ground 1-5 Business Days',
            shippingMethodStandersStatusText:
                'Free ground shipping applies on all orders over $50.00. This will be reflected on the order confirmation page if the Standard Ground shipping option is selected.',
            shippingAvailability: 'Shipping is only available to the 50 United States.',
            subscribeText:
                'Yes, I would like to be notified of product updates, upgrades and special offerings! You can unsubscribe at any time.',
            companyNameRequired: 'Please add a company name',
            contactNameRequired: 'Please add a contact name',
            addressLine1Required: 'Please add an address',
            cityRequired: 'Please add a city',
            zipPostalCodeRequired: 'Please add a zip code',
            addRecipients:
                'Add additional recipients to receive confirmation receipt. Use commas to separate multiple email addresses.',
            email: 'Email',
            phone: 'Phone',
            phoneNumber: 'Phone number',
            primary: 'Primary Address',
            manageAddress: 'Set in manage addresses',
            otherAddresses: 'Other Addresses',
            manageAddresses: 'Manage Addresses',
            oneMonth: '1 month free,',
            then: '$',
            perDevice: 'per device/',
            month: 'month ',
            year: 'year ',
            plusTaxes: 'plus taxes',
            charges: 'Charges begin upon device activation.',
            modal: {
                add: 'Add Address',
                addNewAddress: 'ADD A NEW ADDRESS',
                submit: 'Submit',
                editAddress: 'Edit Address',
                save: 'Save',
                yourAddress: 'Your Address',
                default: 'Default',
                edit: 'Edit',
                remove: 'Remove',
                setAsPrimary: 'Set as primary',
            },
        },
        payment: {
            payment: 'Payment',
            cardExpired: 'Card expired',
            payPal: 'PayPal',
            nameOnCard: 'Name on Card *',
            cardNumber: 'Card Number *',
            cardExpiration: 'Expiration Date (MM/YY) *',
            securityCode: 'Security Code (CVV) *',
            expirationDate: 'Expiration Date',
            paymentMethod: 'Payment Method',
            paymentMethods: 'Payment Methods',
            paymentCardError: 'Enter card number',
            paymentValidCardError: 'Invalid Card Number',
            paymentValidCardName: 'Enter card name',
            paymentValidFirstAndLastName: 'You must enter both a first and last name',
            paymentEnterExpiration: 'Enter valid expiration date',
            paymentSecurityCode: 'Enter security code',
            reviewOrderDetails: 'Review Order Details',
            saveCreditCard: 'Save credit card to account.',
            backToPayment: 'Back to Payment',
            continueToPayment: 'Continue To Payment',
            supportedFile: '(Supported file types: JPG, PNG, PDF)',
            expandTaxExemption: 'Open',
            creditDebitCard: 'Select a credit/debit card...',
            useSavedCard: 'Your Credit & Debit Cards',
            useNewCard: 'New Credit or Debit Card',
            billingAddress: 'Billing Address',
            paymentSuccess: 'Payment Done successfully',
            agreeAndSubmitText: {
                1: 'By clicking Agree and Complete Order button, I confirm that I have read and agree to the Digital River, Inc.',
                2: 'Terms of Sale,',
                3: '',
                4: 'Privacy Policy,',
                5: 'of DR GlobalTech, Inc.',
                6: 'and the Auto-Renewal Plan Terms.',
                7:
                    '\n' +
                    '\n' +
                    'By completing your purchase, you authorize Digital River to automatically renew your purchased license or subscription for successive renewal terms each equal in length to the initial term specified above, at the purchase price for your initial term (plus taxes and fees, less any applicable discounts) using the payment information you provided for your initial purchase, until you cancel. We will send you an e-mail reminder at least once prior to each renewal to remind you of your upcoming renewal and may change the renewal price for any upcoming renewal term by providing you with prior notice of the change by e-mail. The Digital River Terms of Sale and Privacy Policy will apply to each renewal transaction. You may cancel your auto-renewal plan at any time by following the instructions provided in your order confirmation email.',
                8: 'I agree that Digital River may store my payment information for future purchases including the processing of any subsequent subscription renewals which may occur following the date of this order.',
                9: 'By checking this box you agree to the terms of the Auto-Renewal Agreement above.',
            },
            autoRenewal: 'Auto-Renewal',
            autoRenewalText:
                'To continue, you must agree to the Terms of the Auto-Renewal Agreement above.',
            iAgreeText:
                'I agree that Digital River may store my payment information for future purchases including the processing of any subsequent subscription renewals which may occur following the date of this order.',
            completePurchaseText:
                'By completing your purchase, you authorize Digital River to automatically renew your purchased license or subscription for successive renewal terms each equal in length to the initial term specified above, at the purchase price for your initial term (plus taxes and fees, less any applicable discounts) using the payment information you provided for your initial purchase, until you cancel. We will send you an e-mail reminder at least once prior to each renewal to remind you of your upcoming renewal and may change the renewal price for any upcoming renewal term by providing you with prior notice of the change by e-mail. The Digital River Terms of Sale and Privacy Policy will apply to each renewal transaction. You may cancel your auto-renewal plan at any time by following the instructions provided in your order confirmation email.',
            terms: 'Yes, please save this account and payment information for future payments',
            failedToUpload: 'Failed to upload file, please try again',
        },
        reviewOrder: {
            reviewOrder: 'Review Order',
            reviewOrderDetails: 'Review Order Details',
            completeOrder: 'Complete Order',
            agreeAndCompleteOrder: 'Agree and Complete Order',
        },
        completeOrder: {
            orderNumber: 'Order Number:',
            date: 'Date:',
            orderStatus: 'Order Status:',
            contactInformation: 'Contact Information',
            billingAddress: 'Billing Address',
            paymentMethod: 'Payment Method',
            taxExemptionInformation: 'Tax Exemption Information',
            completed: 'Complete',
            quantityPurchased: 'Quantity Purchased',
            free: 'FREE',
            perMonth: 'per device/month plus taxes',
            perMonthPrice: '$10',
            activate: 'Complete Service Setup',
        },
        order: {
            orderStatus: 'Order Status:',
            completed: 'Completed',
            orderNumber: 'Order Number:',
            contactInformation: 'Contact Information',
            date: 'Date:',
            shippingAddress: 'Shipping Address',
            billingAddress: 'Billing Address',
            paymentMethod: 'Payment Method',
            taxExemptionInformation: 'Tax Exemption Information',
            free: 'FREE',
            heading: 'DR GlobalTech, Inc.',
        },
        orderComplete: {
            thankyou: 'Thank you!',
            orderPlaced: 'Your order was placed successfully. We’ve sent a confirmation email to',
            orderNumber: 'Order Number:',
            date: 'Date:',
            orderStatus: 'Order Status:',
            printFriendlyView: 'Print Friendly View',
            contactInformation: 'Contact Information',
            goToAirCycleDashboard: 'Go To AirCycle Dashboard',
        },
        billing: {
            billingAddress: 'Billing Address',
            sameAsShippingAddress: 'Same as shipping address',
            useDifferentBillingAddress: 'I want to use a different billing address',
            taxExemptionApplication: 'Tax Exemption Information',
            companyName: 'Company Name*',
            exemptionCertificate: 'Exemption Certificate Tax Authority*',
            exemptionStartDate: 'Exemption Start Date*',
            exemptionStartDateLable: 'Exemption Start Date:',
            exemptionEndDate: 'Exemption Expiration Date*',
            exemptionEndDateLable: 'Exemption Expiration Date:',
            uploadCertificate: 'Upload Your Certificate:*',
            certFileName: 'Certificate File Name:',
        },
    },
    mybusiness: {
        seePlanDetails: 'See Plan Details',
        dashboard: {
            manageSubscription: 'Manage Subscription',
            goToDashboard: 'Go To Dashboard',
        },
        servicesAndSubscriptions: {
            currentPlan: 'Current Plan',
            activateService: 'Activate Service',
            subscribedServices: 'Subscribed Services',
            unsubscribedServices: 'Unsubscribed Services',
            availableServices:'Available Services',
            billTo: 'Bill to',
            servicePeriod: 'Service Period',
            contactProductSupportText:
                'You are billed for a total number of active monitors in your billing period. It takes 2 billing cycle periods to reflect a monitor being removed on your bill.',
            globalTech: 'DR GlobalTech, Inc.',
            contactProductSupportText2:
                ' is the authorized reseller and merchant of the products and services offered within this store.',
            viewDetails: 'View Details',
            viewPlans: 'View Plans',
            cancelService: "Cancel Service",
            manageSubscription: 'Manage Subscription',
            activateSubscription: 'Activate Subscription',
            purchasedOn: 'Purchased on ',
            activatedOn: 'Active since ',
            canceledOn: 'Canceled on ',
            actionRequired: 'Action Required',
        },
        services: {
            basic: 'AirCycle Basic',
            advanced: 'AirCycle Advanced',
            pro: 'AirCycle Pro',
            service: 'Service',
            branding: 'Homeowner App Branding',
        },
        status: {
            active: 'ACTIVE',
            canceled: 'CANCELED',
            expired: 'EXPIRED',
        },
        billingCycle: {
            monthly: 'MONTHLY',
            annual: 'ANNUAL',
            billedMonthly: 'Billed monthly',
            billedAnnually: 'Billed annually',
        },
        description: {
            basicMore: `The AirCycle Program can help transform the way you build and nurture your business and more...`,
            basicLess: `The AirCycle Program can help transform the way you build and nurture your business and customer relationships. Through measurable, meaningful data, you can simplify the indoor air quality (IAQ) conversation while empowering your technicians to become heroes of the home. less`,
            advancedMore: `The AirCycle Program can help transform the way you build and nurture your business and more...`,
            advancedLess: `The AirCycle Program can help transform the way you build and nurture your business and customer relationships. Through measurable, meaningful data, you can simplify the indoor air quality (IAQ) conversation while empowering your technicians to become heroes of the home. less`,
            proMore: `Includes AirCycle Pro monitor and reports needed to start an effective conversation with homeowners about their IAQ. ...more`,
            proLess: `Includes AirCycle Pro monitor and reports needed to start an effective conversation with homeowners about their IAQ. Each report generates data-driven product recommendations specific to the homeowner, allowing you to use insights that will make you a true whole-home hero. less`,
            brandingMore: `Resideo’s Homeowner App Branding, part of Resideo Pro-IQ™ Services, prominently features your company `,
            brandingLess: `Resideo’s Homeowner App Branding, part of Resideo Pro-IQ™ Services, prominently features your company logo and contact information within your customers’ Honeywell Home app — helping them to reach you easily `,
            brandingLess1: `- Featured logo and contact info in the Honeywell Home app `,
            brandingLess2: `- A hassle-free, no-contract subscription service. `,
            more: 'more...',
            less: 'less.',
        },
    },
    selectPlan: {
        selectAPlan: 'Select a Plan',
        changeAircyclePlan: 'CHANGE AIRCYCLE PLAN',
        basic: 'Basic',
        advanced: 'Advanced',
        planFeatures: 'Plan features',
        viewPlanDetails: 'View Plan Details',
        seePlanDetails: 'See Plan Details',
        loginToViewPricing: 'LOGIN TO VIEW PRICING',
        planOpportunitiesText:
            'Get access to a powerful network of IAQ data, custom reports, sales opportunities and training.',
        aircycleProgram: 'Aircycle Program',
        bannerText:
            'The Resideo AirCycle Program is a business development program that helps you transform the way you build and nurture your business and relationships with your customers. Through training and measurable, meaningful data that simplifies the indoor air quality (IAQ) conversation, you can empower your technicians to truly become the heroes of the home.',
        customReports: 'Custom Reports',
        quickPitchAirCycleReport: 'Quick-Pitch AirCycle Report',
        resideoEquipmentAutoMatch: 'Resideo Equipment Auto-Match',
        ownerDashboards: 'Owner Dashboards',
        weeklyCheckUpDashboard: 'Weekly Check-up Dashboard',
        weeklyReport: 'Weekly Report',
        onGoingTrainingAccess: 'On-Going Training Access',
        unlimitedJumpStartWebinars: 'Unlimited JumpStart Webinars',
        accessToAirCycleConsulting: 'Access to AirCycle Consulting',
        CRMIntegrations: 'CRM Integrations',
        linkToServiceTitan: 'Link to ServiceTitan',
        airCycleReportToServiceTitan: 'AirCycle Report to ServiceTitan',
        salesOpportunityDashboard: 'Sales Opportunity Dashboard',
        aircycleRelatedProducts: 'AIRCYCLE RELATED PRODUCTS',
        relatedProducts: 'RELATED PRODUCTS',
        addToCart: 'Add to Cart',
        haveQuestionsHeading: 'HAVE QUESTIONS? WE’VE GOT ANSWERS.',
        quickPitchToolTipText1:
            ' provides contractors with a quickly digestible report which can be utilized to consult with their consumers while on the job.',
        quickPitchToolTipText2:
            'An auto-filtered measurements section with clearly identified statuses allows for quick diagnosis of what measurements are in or out of control.',
        chooseTheSubscription: 'Choose the subscription plan that works for your business.',
        firstMonthFree: 'First month free',
        perDevice: 'Per device per',
        plusTaxes: 'Plus taxes',
    },
    refreshMonitor: {
        currentlyInYourCart: 'CURRENTLY IN YOUR CART',
        continueToCart: 'Continue to Cart',
        theseDevicesAreExpiredOrExpiringSoon: 'These devices are expired or expiring soon.',
        wouldYouLikeToAddAdditionalDevice: 'WOULD YOU LIKE TO ADD ADDITIONAL DEVICES?',
        instructionInformationText:
            'In order to ensure optimal monitor performance it is recommended that you send your device in for a sensor refresh after one year of use.',
        provideDeviceIdText:
            'Please, provide Device ID numbers that are ready for re-calibration service:',
        addAnotherDevice: 'ADD ANOTHER DEVICE',
        submittedDeviceText:
            'Device ID(s) have been submitted. The AirAdvice Team will get in touch with you with further instructions within the next 24 hours.',
    },
    successExclamationMark: 'Success!',
    learnMore: 'Learn More',
    manageSubscriptions: {
        changePayment: 'Change Payment Method',
        invalidDate: 'Invalid date',
        backToServicesAndSubscriptions: 'BACK TO SERVICES & SUBSCRIPTIONS',
        topNav: {
            homeownerAppBrandingTitle: 'MANAGE HOMEOWNER APP BRANDING',
            title: 'MANAGE AIRCYCLE',
            subTitle:
                'Review your subscription plan status, payment information, billing history as well as upgrade opportunities.',
            homeownerAppBrandingSubTitle:
                'Review your subscription plan status, payment information and billling history.',
            change: 'Change Plan',
        },
        privacypolicy: 'Pricacy Policy',
        termsOfSale: 'Terms of Sale',
        visaMasterCard: 'Visa, Mastercard & Discover',
        americanExpress: 'American Express',
        cardToolTipText1: 'The last 3 digits printed on the back of the card.',
        cardToolTipText2: 'The last 4 digits printed on the back of the card.',
        makePrimaryCard: 'Make this my primary card',
        termsCondition:
            'By checking this box, I agree that Digital River may store my payment information for future purchases including the processing of any subsequent subscription renewals which may occur following the date of this updated payment information.I confirm that I have read and agree to the',
        termsCondition2: 'and the Auto-Renewal Plan Terms.',
        planInfo: {
            title: 'Plan Information',
            table: {
                1: 'Name',
                2: 'Status',
                3: 'Activated',
                4: 'Renews on',
                5: 'Auto-Renew',
                6: 'Cancel Service',
                7: 'Submit',
            },
            on: 'On',
            off: 'Off',
            cancel: 'Cancel Service',
            cancelSub: 'CANCEL SERVICE',
            cancelService: 'Cancel Service',
            viewPlans: 'View Plans',
            autoOn: 'TURN ON AUTO-RENEW',
            autoOff: 'CANCEL AUTO-RENEW',
            submit: 'Submit',
            annually: 'Billed Annually',
            monthly: 'Billed Monthly',
            Annually: 'Annually',
            Monthly: 'Monthly',
            modal: {
                renew: {
                    title: 'Your Auto-Renew is now ',
                    your: 'Your ',
                    expires: 'subscription expires on ',
                    noWorries: 'No worries, we will send you a reminder before it ends.',
                    cancel: 'Cancel',
                    save: 'Save',
                    convenientlyRenewed: ' subscription will be automatically renewed each month.',
                    sorry: `Sorry, this didn't work out for you!`,
                    canceledSubscription: ' subscription will be canceled on ',
                    warning: 'Warning: ',
                    warningLabel: `If you don't manually renew the product before the end of your subscription, the product will be removed from your account.`,
                },
                cancelling: {
                    sorry: "We're sorry you are thinking of canceling.",
                    before: "Before you go, please tell us why you're leaving",
                    notRight: 'Not the right product for me',
                    price: 'Price',
                    technical: 'Technical Issues',
                    other: 'Other',
                    continue: 'Continue',
                    cancel: 'Cancel',
                    describeReason: 'Describe the reason',
                    submit: 'Submit',
                },
            },
        },
        payment: {
            title: 'Payment Information',
            table: {
                1: 'Payment Plan',
                2: '# of Households',
                3: 'Estimated Monthly Bill',
                4: 'Payment Method',
            },
            manage: 'Manage Payment Info',
            assigned: 'assigned',
            perDevice: 'per device/month',
            perHouseHold: 'per household/month',
            perYear: 'per year',
            inStock: 'In Stock',
            tax: '(+ tax)',
            $: '$',
        },
        billingHistory: {
            title: 'Billing History',
            table: {
                1: 'Date',
                2: 'Description',
                3: 'Service Period',
                4: 'Total',
                5: 'Invoice #',
            },
            all: 'View All Invoices',
            less: 'View Less Invoices',
            noHistory: 'You currently have no billing history',
        },
        availableUpgrades: {
            availableUpgrades: 'Available Upgrades',
            deviceRefresh: 'Device Refresh',
            learnMore: 'Learn More',
        },
    },
    productDetailPage: {
        overviewTitle: 'OVERVIEW',
        resourcesTitle: 'RESOURCES',
        relatedProductsTitle: 'RELATED PRODUCTS',
        getStarted: 'GET STARTED',
        specificationTitle: 'SPECIFICATION',
    },
    getStartedDetail: {
        howTogetStarted: 'HOW TO GET STARTED',
        getHeading1: '1. CREATE A PRO PORTAL ACCOUNT',
        getHeading2: '2. GET THE APP',
        getHeading3: '3. INSTALL',
        getHeadingTitle1: 'INSTALLERS:',
        getHeadingTitle2: 'COMPANY ADMINS:',
        getHeadingDesc1:
            'Check with your company admin to get an invite to your company’s profile, then create your own account.',
        getHeadingDesc2:
            'If your company does not have a Pro Portal profile yet, have your company admin',
        signUpHere: 'sign up here.',
        signUpLink:
            'https://pro.resideo.com/sign-up?_ga=2.237082240.1635401948.1632925710-763900604.1614190159',
        getHeadingDesc3:
            'Next, make sure to update your company contact information from the main dashboard in the portal.',
        getHeadingDesc4:
            'Simplify the installation for your technicians and homeowners. Download the App.',
        getHeadingDesc5: 'Note: Resideo Pro App currently works with T5 & T6 Smart Thermostats',
        getHeadingDesc6:
            'Use the Resideo Pro App to install T6 Pro Smart Thermostats in homes, which will associate those homes',
        getHeadingDesc7:
            'to your company. Then you’ll be able to select which homeowners see your logo based on the pricing per',
        getHeadingDesc8: 'household (consumer will not be charged).',
    },
    productDescription: {
        AirCycleBasicMonthly: 'AirCycle Basic Service Monthly',
        AirCycleBasicYearly: 'AirCycle Basic Service Yearly',
        AirCycleAdvancedMonthly: 'AirCycle Advanced Service Monthly',
        AirCycleAdvancedYearly: 'AirCycle Advanced Service Yearly',
        AirSupportRefresh: 'AirCycle Support Refresh',
        AirProMonitor: 'AirCycle Pro Monitor',
        addToCartCapital: 'ADD TO CART',
        BASIC: 'The Resideo AirCycle Program can help transform the way you build and nurture your business and customer relationships. Through measurable, meaningful data, you can simplify the indoor air quality (IAQ) conversation while empowering your technicians to become heroes of the home.',
        BASIC2: 'The AirCycle Program can help transform the way you build and nurture your business and customer relationships. Through measurable, meaningful data, you can simplify the indoor air quality (IAQ) conversation while empowering your technicians to become heroes of the home.',
        ADVANCED:
            'Features an added Opportunity Dashboard, The Resideo AirCycle Program can help transform the way you build and nurture your business and customer relationships. Through measurable, meaningful data, you can simplify the indoor air quality (IAQ) conversation while empowering your technicians to become heroes of the home.',
        ADVANCED2:
            'Features an added Opportunity Dashboard, The AirCycle Program can help transform the way you build and nurture your business and customer relationships. Through measurable, meaningful data, you can simplify the indoor air quality (IAQ) conversation while empowering your technicians to become heroes of the home.',
        PRO: `Includes AirCycle Pro Monitor, 1 year of Monitor Cell Service. Access to AirCycle reports, dashboards and training is provided separately in Basic or Advanced AirCycle subscriptions.`,
        REFRESH: `AirCycle Support Refresh is the cellular service that delivers reports from your AirCycle Pro Monitor to your assigned email. It also includes a recommended re-calibration of your AirCycle Pro monitor. Continuation for this support is billed annually.`,
        HAB: `Resideo’s Homeowner App Branding, part of Resideo Pro-IQ™ Services, prominently features your company logo and contact information within your customers’ Honeywell Home app — helping them to reach you easily`,
    },
    programOverview: {
        productTitle: 'PRODUCT OVERVIEW',
        title: 'Program Overview',
        proMsg1: `The AirCycle Program provides powerful data captured by our exclusive AirCycle Pro monitor. You’ll learn how to use this data to add value to customer visits, helping improve their lives while helping you boost your bottom line. Equipped with knowledge and backed by science, your technicians can more confidently serve homeowners.`,
        proMsg2: `The reporting capability enables your technicians to gain easy-to-understand reporting they can share with homeowners. Here is a summary of the features you’ll have access to:`,
        proLi1: `Fully automated reporting`,
        proLi2: `Easy-to-Read results in 30 minutes`,
        proLi3: `Tests for dust/particles, chemical pollutants, carbon dioxide, carbon monoxide, temperature, and relative humidity`,
        basicMsg1: `The AirCycle Program features the AirCycle Pro Monitor, a sophisticated diagnostic device that helps measures six key IAQ indicators (carbon dioxide, particles, relative humidity, odors and smells, temperature, & carbon monoxide).`,
        basicMsg2: `Through easy-to-understand reports generated by this powerful data, your technicians can share IAQ product recommendation sets fed by a national database of indoor air quality trends plus specific individual measurement results.`,
        basicMsg3: `The result is more value added to your customer visits, which can help boost your bottom line. Basic Service highlights:`,
        basicLi1: `Quick- Pitch report`,
        basicLi2: `Weekly Company Report`,
        basicLi3: `Resideo Equipment Auto-Match`,
        basicLi4: `CRM Integrations with Service Titan`,
        basicLi5: `Weekly Check Up Dashboard`,
        basicLi6: `On-Going Training`,
        advMsg1: `The AirCycle Program features the AirCycle Pro Monitor, a sophisticated diagnostic device that helps measures six key IAQ indicators (carbon dioxide, particles, relative humidity, odors and smells, temperature, & carbon monoxide).`,
        advMsg2: `Through an Opportunity Dashboard and easy-to-understand reports generated by this powerful data, your technicians can share IAQ product recommendation sets fed by a national database of indoor air quality trends plus specific individual measurement results.`,
        advMsg3: `The result is more value added to your customer visits, which can help boost your bottom line. Advanced Service highlights`,
        advLi1: `Sales Opportunity Dashboard (Advanced Service Only)`,
        advLi2: `Weekly Company Report`,
        advLi3: `Quick- Pitch report`,
        advLi4: `CRM Integrations with Service Titan`,
        advLi5: `Resideo Equipment Auto-Match`,
        advLi6: `On-Going Training Support`,
        advLi7: `Weekly Check Up Dashboard`,
        refMsg: `Act now to keep uninterrupted AirCycle Support with included cellular service and reports from your AirCycle Pro Monitor. Through this powerful tool you can drive effective conversations about IAQ through data-driven product recommendations that are specific to each homeowner.`,
        refLi1: `You can renew your AirCycle Support at any time before it expires`,
        refLi2: `For device re-calibration, you will be notified by email for routine maintenance a year after device purchase date. The email notification includes instructions where to ship your device.`,
        refLi3: `Includes recommended, but optional, re-calibration of your monitor.`,
        refLi4: `Note: processing time is approximately 2 weeks`,
        refLi5: `If you would like your device re-calibrated,`,
        contactSupport: `Contact Support`,
        refLi6: `Cellular service agreements may apply with the AirCycle Pro Monitor`,
        HABList1: `Featured logo and contact info in the Honeywell Home app`,
        HABList2: `A hassle-free, no-contract subscription service`,
        HABOverview: `Give your marketing a boost. Long-term success starts with impactful marketing and customer loyalty. Homeowner App Branding service from Resideo can help you connect with customers who use the Honeywell Home app.`,
        HABLi1: `Show your logo in the Honeywell Home app`,
        HABLi2: `Easy setup via Resideo Pro Portal`,
        HABLi3: `Promote your company to every customer you sign up`,
        HABLi4: `Change your logo and contact info at any time`,
        HABLi5: `Homeowners can easily tap on your logo for quick access to your contact details`,
        HABLi6: `Requires Resideo Pro App and Resideo Pro Portal account`,
        HABLi7: `Pay per household – select the homes you want to promote your brand to`,
        rollOverToImg: 'Roll over image to zoom',
    },
    invoicePdf: {
        address: 'Address',
        city: 'City, State, 12345',
        us: 'United States',
        fedID: 'Federal Tax ID: 11-1234567',
        invoice: 'INVOICE',
        invoiceNum: 'Invoice #: ',
        num: '1234567890123',
        billTo: 'Bill to: ',
        fullName: 'Full Name',
        companyName: 'Company Name',
        autoCharge: 'You will be automatically charged for any amount due.',
        date: 'Date',
        description: 'Description',
        billingPeriod: 'Billing Period',
        monitors: 'Monitors',
        amount: 'Amount',
        invoiceDate: 'Feb 2, 2021',
        service: 'AirCycle Basic Service',
        billingPeriodDates: '02/02/21 - 03/02/21',
        monitorAmount: '5',
        subtotalInUSD: 'Subtotal in USD',
        subtotalAmount: '$50.00',
        salesTax: 'Sales Tax (X%)',
        salesTaxX: '$X.XX',
        totalPlacer: '$XX.XX',
        totalInUSD: 'Total in USD',
    },
    invoiceService: {
        title: `INVOICE`,
        invoiceNo: `Invoice #`,
        billTo: `Bill to`,
        chargedMsg: `You will be automatically charged for any amount due.`,
        date: `Date`,
        description: `Description`,
        billingPeriod: `Service Period`,
        monitors: `Service Locations`,
        amount: `Amount`,
        subTotalInUSD: `Subtotal in USD`,
        salesTax: `Sales Tax`,
        totalInUSD: `Total in USD`,
        footerMsg1: `You are billed for a total number of service locations activated on your Resideo Pro App.`,
        footerMsg2: `DR GlobalTech, Inc.`,
        footerMsg3: `is the authorized reseller and merchant of the products and services offered within this store.`,
    },
    resources: {
        resources: 'RESOURCES',
        sell: 'SELL',
        promote: 'PROMOTE',
    },
};