import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PageLoading from 'components/common/PageLoading';
import PlanInformation from 'components/managesubscription/PlanInformation';
import PaymentInformation from 'components/managesubscription/PaymentInformation';
import BillingHistory from 'components/managesubscription/BillingHistory';
import TopNav from 'components/managesubscription/TopNav';
import Page from 'components/common/Page';
import { ExistingPaymentSource } from 'components/payment/PaymentMethodFormBody';
import {
    getSubscriptionBillingDetails,
    getCompleteSubscriptionBill,
    getSubscriptionDetails,
    renewalSubscriptionService,
} from 'redux/subscriptions/actions';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import PageError from 'components/common/PageError';
import { getCreditCardDetails } from 'redux/checkout/action';

interface SubscriptionState {
    enrollment: any;
    billingId: string | null;
    billingHistory: any;
    paymentSources: ExistingPaymentSource[];
    isLogoApproved: boolean;
    inactive: boolean;
    viewer: any;
}

interface FinalBillState {
    billingDate?: string;
    description?: string;
    servicePeriod?: string | null;
    total?: string;
    invoiceNumber?: string;
}

const ManageSubscription = () => {
    const [finalBillData /* setFinalBillData */] = useState<Array<FinalBillState>>([]);
    const [numberOfBills, setNumberOfBills] = useState<Number>(0);
    const path = useRouteMatch() as any;
    const enrollmentId = path.params.id;
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();

    const subscriptionDataLoading = useSelector(
        (state: RootState) => state.subscriptionListData.getSubscriptionDetailSuccess,
    );
    const error = useSelector(
        (state: RootState) => state.subscriptionListData.getSubscriptionDetailFail,
    );
    const subscriptionDetails: any = useSelector(
        (state: RootState) => state.subscriptionListData.getSubscriptionDetails,
    );

    const billingDataLoading = useSelector(
        (state: RootState) => state.subscriptionListData.getCompleteSubscriptionBillSuccess,
    );
    const creditcardListData = useSelector(
        (state: RootState) => state.checkOutDetails.creditCardDetailsList,
    );

    const [isLoading, setLoading] = useState(true);
    const [state, setState] = useState<SubscriptionState>({
        enrollment: null,
        billingId: null,
        billingHistory: null,
        paymentSources: [],
        isLogoApproved: false,
        inactive: false,
        viewer: null,
    });

    const enrollmentData = useCallback(() => {
        if (enrollmentId !== '' && enrollmentId !== undefined) {
            hookDispatch(getSubscriptionDetails({ subscriptionId: enrollmentId }))
                .then((response: any) => {
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error', error);
                    setLoading(false);
                });
        }
    }, [enrollmentId]);

    const retrieveSubsBillData = useCallback(() => {
        if (enrollmentId !== '' && enrollmentId !== undefined) {
            hookDispatch(getCompleteSubscriptionBill({ subscriptionId: enrollmentId }))
                .then((response: any) => {
                    setNumberOfBills(response.orders.length);
                    response.orders.map((billItem, i) => {
                        const proxyBillObj: FinalBillState = {};
                        proxyBillObj.billingDate = billItem.placed.slice(0, 10);
                        proxyBillObj.description = billItem.serviceName;
                        if (billItem.servicePeriodStart && billItem.servicePeriodEnd) {
                            let servicePeriodStart = moment(billItem.servicePeriodStart).format(
                                'L',
                            );
                            // Date Formatting Logic:
                            let originalStartDateFormat = servicePeriodStart
                            let startYearPiece = originalStartDateFormat.slice(-4).slice(-2)
                            let startMonthDatePiece = originalStartDateFormat.slice(0, 6)
                            let startFinalForm = startMonthDatePiece + startYearPiece
                            servicePeriodStart = startFinalForm
                            let servicePeriodEnd = moment(billItem.servicePeriodEnd).format('L');
                            let originalEndDateFormat = servicePeriodEnd
                            let endYearPiece = originalEndDateFormat.slice(-4).slice(-2)
                            let endMonthDatePiece = originalEndDateFormat.slice(0, 6)
                            let endFinalForm = endMonthDatePiece + endYearPiece
                            servicePeriodEnd = endFinalForm
                            proxyBillObj.servicePeriod =
                                servicePeriodStart + ' ' + '-' + ' ' + servicePeriodEnd;
                        } else {
                            proxyBillObj.servicePeriod = '-';
                        }
                        proxyBillObj.total = billItem.total.formattedValue;
                        proxyBillObj.invoiceNumber = billItem.code;
                        const precedingBillData = finalBillData;
                        precedingBillData.push(proxyBillObj);
                    });
                    setLoading(false);
                })
                .catch((error) => {
                    console.log('Error', error);
                    setLoading(false);
                });
        }
    }, [enrollmentId]);

    useEffect(() => {
        enrollmentData();
        if (numberOfBills > 0 && numberOfBills === finalBillData.length) {
        } else {
            retrieveSubsBillData();
        }
        //dispatch(getSubscriptionBillingDetails({ subscriptionId: enrollmentId }));
    }, [enrollmentId]);

    const fetchCreditCardDetails = async () => {
        await dispatch(getCreditCardDetails());
    };

    useEffect(() => {
        if (!creditcardListData.length) {
            fetchCreditCardDetails();
        }
    }, [dispatch]);

    useEffect(() => {
        if (subscriptionDetails) {
            setState({
                enrollment: subscriptionDetails?.enrollment,
                billingHistory: finalBillData ?? null,
                billingId: null,
                paymentSources: creditcardListData ?? [],
                isLogoApproved: subscriptionDetails?.logoApproved ?? false,
                inactive: false /*TODO need to change change payment mode*/, //subscriptionDetails?.status !== 'ACTIVE',
                viewer: subscriptionDetails?.viewer ?? {},
            });
        }
    }, [subscriptionDetails, finalBillData, creditcardListData]);

    const handleEnrollmentUpdate = (enrollment, sources) => {
        setState({
            viewer: { ...state.viewer },
            enrollment: {
                ...state.enrollment,
                ...enrollment,
            },
            billingId: state.billingId,
            billingHistory: enrollment?.partnerBillingOrders?.edges
                ? enrollment?.partnerBillingOrders?.edges
                : state.billingHistory,
            paymentSources: sources ?? state.paymentSources,
            isLogoApproved: enrollment?.logoApproved ? enrollment.logoApproved : state.inactive,
            inactive: enrollment?.status ? enrollment.status !== 'ACTIVE' : state.inactive,
        });
    };

    return subscriptionDataLoading && billingDataLoading ? (
        <PageLoading />
    ) : error ? (
        <PageError />
    ) : (
        <>
            {isLoading && <PageLoading />}
            <Page as='main' minWidth='100%' data-test-manage-subscription>
                <TopNav />
                <PlanInformation
                    enrollment={state.enrollment}
                    isLogoApproved={state.isLogoApproved}
                    handleEnrollmentUpdate={handleEnrollmentUpdate}
                    setLoading={setLoading}
                />
                <PaymentInformation
                    enrollment={state.enrollment}
                    paymentSources={state.paymentSources}
                    billingId={state.billingId}
                    handleEnrollmentUpdate={handleEnrollmentUpdate}
                    inactive={state.inactive}
                    setLoading={setLoading}
                    viewer={state.viewer}
                />
                <BillingHistory billingHistory={state.billingHistory} />
                {/* Available Upgrades is out for MVO */}
                {/* <AvailableUpgrades /> */}
            </Page>
        </>
    );
};

export default ManageSubscription;
