export interface PrimaryNavigationItem {
    name: string;
    linkTextKey: string;
    linkPath: string;
    isExternalLink?: boolean;
    isDirect: boolean;
}
const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';

const primaryNavigation: PrimaryNavigationItem[] = [
    {
        name: 'products',
        linkTextKey: 'header.primary.products',
        linkPath: '/',
        isExternalLink: false,
        isDirect: false,
    },
    {
        name: 'solutions',
        linkTextKey: 'header.primary.solutions',
        linkPath: '#',
        isExternalLink: false,
        isDirect: false,
    },
    {
        name: 'resources',
        linkTextKey: 'header.primary.resources',
        linkPath: '#',
        isExternalLink: false,
        isDirect: false,
    },
    {
        name: 'perks',
        linkTextKey: 'header.primary.perks',
        linkPath: isDevelopEnvironment
            ? 'https://develop--resideo-pro.netlify.app/perks'
            : `${window.location.origin}/perks`,
        isExternalLink: true,
        isDirect: false,
    },
    {
        name: 'mybusiness',
        linkTextKey: 'header.primary.mybusiness',
        linkPath: `/subscription`,
        isExternalLink: false,
        isDirect: true,
    },
];

export { primaryNavigation as default };
