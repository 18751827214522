import React from 'react';
import {
    Description,
    HeaderText,
    HomeContainer,
    ImageWrapper,
    SideDealCaption,
    SideDealContainer,
    SideDealHeading,
    SideDealImageBox,
    TitleBox,
} from './MenuItemStyle';

const SideDeal = ({ sideItem }: any) => {
    const RouteExternally = (link) => {
        window.location = link;
    };
    return sideItem.isTodayDeal ? (
        <>
            <HeaderText>{sideItem.title}</HeaderText>
            {sideItem.itemList.map((data, index) => (
                <SideDealContainer
                    onClick={() => RouteExternally(data.linkPath)}
                    key={`sideDeal-${index}`}>
                    <SideDealImageBox
                        onClick={() => RouteExternally(data.linkPath)}
                        src={data.imageLink}
                    />
                    <TitleBox>{data.name}</TitleBox>
                </SideDealContainer>
            ))}
        </>
    ) : (
        <>
            <HomeContainer>
                <ImageWrapper src={sideItem.imageLink} />
                <SideDealCaption>
                    <SideDealHeading>{sideItem.title}</SideDealHeading>
                    <Description>{sideItem.desc}</Description>
                </SideDealCaption>
            </HomeContainer>
        </>
    );
};

export default SideDeal;
