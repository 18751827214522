import React from 'react'
import BG from './BG';
import Layout from './Layout';

const MainContainer = ({ position, leftContainer, rightContainer, bottomContainer }) => {
  return (
    <BG position={position}>
      <Layout left={leftContainer} right={rightContainer} bottom={bottomContainer} />
    </BG>
  )
};


export default MainContainer;