import React, { FC } from 'react';
import { Text, Flex } from '@resideo/blueprint-react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';


interface CrumbProps {
    isCurrent: boolean;
    isProducts: boolean;
    showCursor: boolean;
}

const Crumb = styled(Text) <CrumbProps>`
    font-family: 'Roboto';
    letter-spacing: 1px;
    font-size: 11px;
    margin-right: small;
    font-weight: 400;
    color: ${({ isCurrent }) => (isCurrent ? '#000' : '#FFF')};
    :hover {
      cursor: ${({ showCursor }) => (showCursor ? 'pointer' : '')};
    }
  `;

const BreadCrumbPdp: FC<{ data: any }> = ({ data }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    return (
        <Flex>
            <Crumb
                isCurrent={false}
                isProducts={false}
                showCursor={false}
                onClick={() => {
                    history.push('/');
                }}
                as='span'>
                {'HOME'}&nbsp;
                <Text
                    data-test-pdp-breadcrumb
                    as='span'
                    color={'white'}>
                    {'/'}
                    &nbsp;
                </Text>
            </Crumb>
            <Crumb
                isCurrent={false}
                isProducts={false}
                showCursor={false}
                onClick={() => {
                    history.push('/');
                }}
                as='span'>
                {'PRODUCTS'}&nbsp;
                <Text
                    data-test-pdp-breadcrumb
                    as='span'
                    color={'white'}>
                    {'/'}
                    &nbsp;
                </Text>
            </Crumb>
            {data && data?.categories && data?.categories?.map((item, index) => (
                <Crumb
                    key={index}
                    isCurrent={false}
                    isProducts={false}
                    showCursor={false}
                    onClick={() => {
                        history.push('/');
                    }}
                    as='span'>
                    {item?.name}&nbsp;
                    <Text
                        data-test-pdp-breadcrumb
                        as='span'
                        color={'white'}>
                        {'/'}
                        &nbsp;
                    </Text>
                </Crumb>
            ))}
            
            {data && data?.breadcrumbs ? (
                <Crumb
                    isCurrent={true}
                    isProducts={false}
                    showCursor={true}
                    onClick={() => {
                        history.push('/pdp/home-owner');
                    }}
                    as='span'>
                    {data?.breadcrumbs}&nbsp;
                    <Text
                        data-test-pdp-breadcrumb
                        as='span'
                        color={'white'}>
                        {''}
                        &nbsp;
                    </Text>
                </Crumb>
            ): (
                <Crumb
                    isCurrent={true}
                    isProducts={false}
                    showCursor={true}
                    onClick={() => {
                        history.push('/pdp/home-owner');
                    }}
                    as='span'>
                    {data?.baseProduct}&nbsp;
                    <Text
                        data-test-pdp-breadcrumb
                        as='span'
                        color={'white'}>
                        {''}
                        &nbsp;
                    </Text>
                </Crumb>
            )}
        </Flex>
    );
};

export default BreadCrumbPdp;