import React, { FC, useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Menu, MenuButton, MenuLink, MenuList } from '@reach/menu-button';
import { Avatar } from '@resideo/blueprint-react';
import ProfileAvatar from '../../components/common/ProfileAvatar';
import { Link } from 'react-router-dom';
import { signIn, signOut } from '../../config/Auth';
import { logout } from '../../redux/authentication/action';
import { useDispatch } from 'react-redux';
import MainContainer from './flyout/MainContainer';
import FooterItemList from './flyout/FooterItemList';
import MenuListItem from './flyout/MenuListItem';
import SideDeal from './flyout/SideDeal';
import CardView from './flyout/CardView';

const NavMenuButton = styled(MenuButton)`
    margin-right: 16px;
`;

interface ProfileMenuState {
    firstName?: string;
    lastName?: string;
    photoUrl?: string;
    partnerUser?: any;
    isAdmin?: boolean;
    isOwner?: boolean;
    partnerAccountId?: string | null;
}

interface leftMenuProps {
    listItems: ListItem[];
}
type ListItem = {
    title: string;
    icon: string;
    subItems: SubItem[];
};

type SubItem = {
    name: string;
    linkTextKey: string;
    linkPath: string;
    isExternalLink: boolean;
};

interface FooterProps {
    present: boolean;
    footerButtons: FooterButton[];
}

type FooterButton = {
    name: string;
    linkPath: string;
    isExternalLink: boolean;
    buttonType: string;
    icon: string;
};

interface SideDealProps {
    present: boolean;
    title: string;
    itemList: ItemList[];
}

type ItemList = {
    name: string;
    imageLink: string;
    linkPath: string;
    isExternalLink: boolean;
    isUnderlined: boolean;
};

const LeftContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    padding: 0 20px 0 13px;
    width: calc(100% - 360px);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @media (min-width: 992px) and (max-width: 1199px) {
        width: calc(100% - 300px);
    }
`;

const RightContainer = styled.div`
    width: 360px;
    background: #ededed;
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 300px;
        height: 280px;
    }
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    min-height: initial;
    position: relative;
`;

const Products = {
    leftItemList: {
        isPresent: true,
        isType: 'list',
        itemList: [
            {
                header: true,
                title: 'AIR',
                icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Air-Color.svg?rv=90e347d05cc947f3ae026b1491c4a623',
                linkPath: 'https://www.resideo.com/us/en/pro/products/air/thermostats/',
                isExternalLink: true,
                subItems: [
                    {
                        name: 'Thermostats',
                        linkTextKey: 'Thermostats',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/air/thermostats/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'WiFi thermostats',
                        linkTextKey: 'WiFi thermostats',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/thermostats/wifi-thermostats/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'RedLINK® thermostats',
                        linkTextKey: 'RedLINK® thermostats',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/thermostats/redlink-thermostats/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Line volt thermostats',
                        linkTextKey: 'Line volt thermostats',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/thermostats/line-volt-thermostats/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Humidification',
                        linkTextKey: 'Humidification',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/air-quality-solutions/humidification/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Dehumidification',
                        linkTextKey: 'Dehumidification',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/air-quality-solutions/dehumidification/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Filtration',
                        linkTextKey: 'Filtration',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/air-quality-solutions/filtration/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'UV treatment',
                        linkTextKey: 'UV treatment',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/air-quality-solutions/uv-iaq/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Forced air zoning',
                        linkTextKey: 'Forced air zoning',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/forced-air-zoning/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Ventilation',
                        linkTextKey: 'Ventilation',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/air-quality-solutions/ventilation/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Paid training',
                        linkTextKey: 'Paid training',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/air/training/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'All air products',
                        linkTextKey: 'All air products',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/air/',
                        isExternalLink: true,
                        isUnderLined: true,
                    },
                ],
            },
            {
                header: true,
                title: 'SECURITY',
                icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Security-Color.svg?rv=1f528e78fd3b4653a8354411c0c0b7c1',
                subItems: [
                    {
                        name: 'All-in-one systems',
                        linkTextKey: 'All-in-one systems',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/intrusion-panels-systems/all-in-one-systems/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Hybrid systems',
                        linkTextKey: 'Hybrid systems',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/intrusion-panels-systems/hybrid-systems/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Fire-burglary systems',
                        linkTextKey: 'Fire-burglary systems',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/intrusion-panels-systems/combination-fire-burglary-systems/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Wireless keypads',
                        linkTextKey: 'Wireless keypads',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/keypads/wireless-keypads/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Wired keypads',
                        linkTextKey: 'Wired keypads',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/keypads/wired-keypads/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Wireless sensors',
                        linkTextKey: 'Wireless sensors',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/wireless-sensors/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Communicators',
                        linkTextKey: 'Communicators',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/communicators/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Video solutions',
                        linkTextKey: 'Video solutions',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/video-cameras/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Security accessories',
                        linkTextKey: 'Security accessories',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/security/accessories/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Telehealth solutions',
                        linkTextKey: 'Telehealth solutions',
                        linkPath: 'https://www.cleararchhealth.com/life-care-solutions/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'All security products',
                        linkTextKey: 'All security products',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/security/',
                        isExternalLink: true,
                        isUnderLined: true,
                    },
                ],
            },
            {
                header: true,
                title: 'WATER',
                icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Water-Color.svg?rv=c50ea760e5824e6a893fb75353fe69c2',
                subItems: [
                    {
                        name: 'Potable water solutions',
                        linkTextKey: 'Potable water solutions',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/water/potable-water-solutions/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Hydronic solutions',
                        linkTextKey: 'Hydronic solutions',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/water/hydronic-solutions/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'All water products',
                        linkTextKey: 'All water products',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/water/',
                        isExternalLink: true,
                        isUnderLined: true,
                    },
                ],
            },
            {
                header: true,
                title: 'ENERGY',
                icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Energy-Color.svg?rv=fbd4b40b60c3433db0194150dbcf0f22',
                subItems: [
                    {
                        name: 'Wire & cable',
                        linkTextKey: 'Wire & cable',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/wire-cable/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Gas valves',
                        linkTextKey: 'Gas valves',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/energy/gas-valves/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Water heater controls',
                        linkTextKey: 'Water heater controls',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/energy/water-heater-controls/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Gas ignition controls',
                        linkTextKey: 'Gas ignition controls',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/energy/gas-ignition-controls/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Programmable switches',
                        linkTextKey: 'Programmable switches',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/energy/programmable-switches/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'WiFi thermostats',
                        linkTextKey: 'WiFi thermostats',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/energy/wifi-thermostats/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'LifeWhere',
                        linkTextKey: 'LifeWhere',
                        linkPath: 'https://www.resideo.com/us/en/pro/lifewhere/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'All energy products',
                        linkTextKey: 'All energy products',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/energy/',
                        isExternalLink: true,
                        isUnderLined: true,
                    },
                ],
            },
            {
                header: true,
                title: 'PRODUCT LINE',
                icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Black/Nav-Icon-Product-Line-Logo.svg?rv=5a2cad23ba6d4bc6b3d0c46d152ce667',
                subItems: [
                    {
                        name: 'ProSeries security & life safety',
                        linkTextKey: 'ProSeries security & life safety',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/solutions/security/proseries-security/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Genesis cable',
                        linkTextKey: 'Genesis cable',
                        linkPath: 'https://www.resideo.com/us/en/pro/genesis/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Residential thermal solutions',
                        linkTextKey: 'Residential thermal solutions',
                        linkPath: 'https://www.resideo.com/us/en/pro/products/energy/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'T series thermostats',
                        linkTextKey: 'T series thermostats',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/thermostats/t-series/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'VISTA security',
                        linkTextKey: 'VISTA security',
                        linkPath: 'https://www.resideo.com/us/en/pro/solutions/vista/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Pro-IQ™ solutions',
                        linkTextKey: 'Pro-IQ™ solutions',
                        linkPath: 'https://www.resideo.com/us/en/pro/proiq/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'AirBright IAQ',
                        linkTextKey: 'AirBright IAQ',
                        linkPath:
                            'https://www.resideo.com/us/en/pro/products/air/air-quality-solutions/uv-iaq/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'RedLINK® solutions',
                        linkTextKey: 'RedLINK® solutions',
                        linkPath: 'https://www.resideo.com/us/en/pro/redlink/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                    {
                        name: 'Life Care solutions',
                        linkTextKey: 'Life Care solutions',
                        linkPath: 'https://www.cleararchhealth.com/life-care-solutions/',
                        isExternalLink: true,
                        isUnderLined: false,
                    },
                ],
            },
        ],
    },
    rightItemList: {
        isPresent: true,
        isTodayDeal: true,
        title: 'NEW AND FEATURED PRODUCTS',
        itemList: [
            {
                name: 'PROA7PLUS ProSeries 7 inch All-in-One Panel',
                imageLink:
                    'https://digitalassets.resideo.com/damroot/RDEDesktop/10017/PROA7PLUS_HH-c9-6.jpg?koprop=PrimaryImage&rv=190ac63ac0df464497fadffe9fd3d85a&hash=D38EB82E477AB9A4F3172994C679BA62',
                linkPath:
                    'https://www.resideo.com/us/en/products/security/security-systems/proa7plus-proseries-7-inch-all-in-one-panel-proa7plus/',
                isExternalLink: true,
            },
            {
                name: 'T10 Pro Smart Thermostat with RedLINK® Room Sensor',
                imageLink:
                    'https://digitalassets.resideo.com/damroot/RDEDesktop/10004/THX321WFS2001-c10-6.jpg?koprop=PrimaryImage&rv=190ac63ac0df464497fadffe9fd3d85a&hash=D38EB82E477AB9A4F3172994C679BA62',
                linkPath:
                    'https://www.resideo.com/us/en/products/air/thermostats/wifi-thermostats/t10-pro-smart-thermostat-with-redlinkr-room-sensor-thx321wfs2001w-u/',
                isExternalLink: true,
            },
        ],
    },
    bottomItemList: {
        isPresent: true,
        itemList: [
            {
                name: 'View All Products',
                linkPath: 'https://www.resideo.com/us/en/pro/',
                isExternalLink: true,
                buttonType: 'button',
                icon: '',
            },
            {
                name: 'View Pro Install Products',
                linkPath: 'https://www.resideo.com/us/en/pro/',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
            {
                name: 'View DIY Install Products',
                linkPath: 'https://www.resideo.com/us/en/pro/',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
            {
                name: 'View Offers',
                linkPath: 'https://www.resideo.com/us/en/pro/',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
        ],
    },
};

const howItWorks = {
    leftItemList: {
        isPresent: true,
        isType: 'card',
        itemList: [
            {
                title: 'Air',
                description: 'Keep it clean',
                imageLink:
                    'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Air-Color.svg?rv=90e347d05cc947f3ae026b1491c4a623',
                linkPath: 'https://www.resideo.com/us/en/pro/solutions/air/',
                isExternalLink: true,
            },
            {
                title: 'Security',
                description: 'Share visiblity',
                imageLink:
                    'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Security-Color.svg?rv=1f528e78fd3b4653a8354411c0c0b7c1',
                linkPath: 'https://www.resideo.com/us/en/pro/solutions/security/',
                isExternalLink: true,
            },
            {
                title: 'Water',
                description: 'Avert disaster',
                imageLink:
                    'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Water-Color.svg?rv=c50ea760e5824e6a893fb75353fe69c2',
                linkPath: 'https://www.resideo.com/us/en/pro/water/',
                isExternalLink: true,
            },
            {
                title: 'Energy',
                description: 'Save and support',
                imageLink:
                    'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Energy-Color.svg?rv=fbd4b40b60c3433db0194150dbcf0f22',
                linkPath: 'https://www.resideo.com/us/en/pro/solutions/Energy/',
                isExternalLink: true,
            },
        ],
    },
    rightItemList: {
        isPresent: true,
        isTodayDeal: false,
        title: 'WELCOME TO THE SUPPORTED HOME',
        linkPath: 'https://www.resideo.com/us/en/pro/solutions/',
        imageLink:
            'https://www.resideo.com/us/en/-/media/Resideo/R5/Pro%20Header/supported-home-icon-670x280.jpg?h=280&w=670&rv=f20f354a43f643508140b1b401eda028&hash=EC0E8C3C082FF865D53F7E0E799A6550',
        desc: 'From behind the walls to in front of their eyes, our solutions help you support the critical systems that are part of the safeguards for your customers’ homes.',
    },
    bottomItemList: {
        isPresent: true,
        itemList: [
            {
                name: 'SMART HOME PARTNERS',
                linkPath: 'https://www.resideo.com/us/en/smart-home-partners/',
                isExternalLink: true,
                buttonType: 'link',
                icon: '',
            },
            {
                name: 'LTE UPGRADE',
                linkPath: 'https://www.resideo.com/us/en/lteupgrade/#LTE%20Upgrade',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
            {
                name: 'PROTENTIAL',
                linkPath: 'https://www.resideo.com/us/en/pro/protential-pro-benefits/',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
            {
                name: 'BUILDER SOLUTIONS',
                linkPath: 'https://www.resideo.com/us/en/pro/builder-solutions/',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
            {
                name: 'ENERGY STAR SOLUTIONS',
                linkPath: 'https://www.resideo.com/us/en/energy-star/',
                isExternalLink: true,
                buttonType: 'link',
                icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
            },
        ],
    },
};

const resources = {
    leftItemList: {
        isPresent: true,
        isType: 'list',
        itemList: [
            {
                header: true,
                title: 'Resources',
                icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Black/FAQ.png?h=96&w=96&rv=c335087d23d64d6699114e323c241b6e&hash=67C5F14895C83C9E1CFC253E65B77A59',
                subItems: [
                    {
                        name: 'Resideo Academy',
                        linkTextKey: 'Resideo Academy',
                        linkPath: 'https://www.resideo.com/us/en/pro/resources/resideo-academy/',
                        isExternalLink: true,
                    },
                    {
                        name: 'Security',
                        linkTextKey: 'Security',
                        linkPath: 'https://www.resideo.com/us/en/pro/resources/',
                        isExternalLink: true,
                    },
                    {
                        name: 'HVAC',
                        linkTextKey: 'HVAC',
                        linkPath: 'https://www.resideo.com/us/en/pro/resources/',
                        isExternalLink: true,
                    },
                    {
                        name: 'Plumbing',
                        linkTextKey: 'Plumbing',
                        linkPath: 'https://www.resideo.com/us/en/pro/resources/',
                        isExternalLink: true,
                    },
                    {
                        name: 'Distributors',
                        linkTextKey: 'Distributors',
                        linkPath: 'https://www.resideo.com/us/en/pro/resources/distributors/',
                        isExternalLink: true,
                    },
                    {
                        name: 'Recalls',
                        linkTextKey: 'Recalls',
                        linkPath: 'https://www.resideo.com/us/en/recall/',
                        isExternalLink: true,
                    },
                    {
                        name: 'Utility programs',
                        linkTextKey: 'Utility programs',
                        linkPath: 'https://www.resideo.com/us/en/pro/utility-sell-sheets/',
                        isExternalLink: true,
                    },
                ],
            },
        ],
    },
    rightItemList: {
        isPresent: false,
        title: 'TOP SEARCHED PRODUCTS',
        itemList: [],
    },
    bottomItemList: {
        isPresent: false,
    },
};

const LeftNavContainer = ({ item }) => {
    return item.isPresent ? (
        <LeftContainer>
            {item.isType === 'list' &&
                item.itemList.map((item, index) => <MenuListItem key={index} listItems={item} />)}
            {item.isType === 'card' && <CardView CardList={item.itemList} />}
        </LeftContainer>
    ) : (
        <></>
    );
};

const RightNavContainer = ({ item }): JSX.Element => {
    return item.isPresent ? (
        <RightContainer>
            <SideDeal sideItem={item} />
        </RightContainer>
    ) : (
        <></>
    );
};

const BottomNavContainer = ({ item }): JSX.Element => {
    return item.isPresent ? (
        <FooterContainer>
            <FooterItemList footerItem={item.itemList} />
        </FooterContainer>
    ) : (
        <></>
    );
};

const CustomNavLink = (rest) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSignOut = () => dispatch(logout());

    const [profileMenuState, setProfileMenuState] = useState<ProfileMenuState>({
        firstName: '',
        lastName: '',
        photoUrl: '',
        isAdmin: false,
        isOwner: false,
        partnerAccountId: '',
    });
    const viewerHasPermissions = profileMenuState.isOwner || profileMenuState.isAdmin;
    return (
        <Menu>
            <NavMenuButton data-test-profile-menu-button {...rest}>
                {rest.children}
            </NavMenuButton>
            {rest.children === 'Products' && (
                <MainContainer
                    position={225}
                    leftContainer={<LeftNavContainer item={Products.leftItemList} />}
                    rightContainer={<RightNavContainer item={Products.rightItemList} />}
                    bottomContainer={<BottomNavContainer item={Products.bottomItemList} />}
                />
            )}
            {rest.children === 'Solutions' && (
                <MainContainer
                    position={350}
                    leftContainer={<LeftNavContainer item={howItWorks.leftItemList} />}
                    rightContainer={<RightNavContainer item={howItWorks.rightItemList} />}
                    bottomContainer={<BottomNavContainer item={howItWorks.bottomItemList} />}
                />
            )}
            {rest.children === 'Resources' && (
                <MainContainer
                    position={0}
                    leftContainer={<LeftNavContainer item={resources.leftItemList} />}
                    rightContainer={<RightNavContainer item={resources.rightItemList} />}
                    bottomContainer={<BottomNavContainer item={resources.bottomItemList} />}
                />
            )}
        </Menu>
    );
};

export default CustomNavLink;
