import React from 'react';
import { Flex, Image } from '@resideo/blueprint-react';
import styled, { keyframes } from 'styled-components';
import {
  advancedPDPImage,
  basicServicePDP,
  blackCrossImage,
  homeOwnerZoomedLogo,
} from '../../assets/images';
import proProductPDP from '../../assets/images/productDetails/proProductPDP.jpg';
import supportPDP from '../../assets/images/productDetails/supportPDP.jpg';

const Container = styled(Flex)`
  background-image: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255, 1) 0%,
    rgba(63, 63, 63, 1) 100%
  );
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 100vh;
  margin: auto;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: auto;
    max-height: 90%;
    max-width: 90%;
  }
`;

const spinPlus = keyframes`
  100% { transform: rotate(270deg)}
`;

const Close = styled(Image)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  cursor: pointer;
  :hover {
    animation: ${spinPlus} 500ms ease;
  }
`;

const getImage = (service: string) => {
  return service === 'HAB'
    ? homeOwnerZoomedLogo
    : service === 'BASIC_MONTHLY' || service === 'BASIC_ANNUALLY'
    ? basicServicePDP
    : service === 'ADVANCED_MONTHLY' || service === 'ADVANCED_ANNUALLY'
    ? advancedPDPImage
    : service === 'PRO'
    ? proProductPDP
    : supportPDP;
};

const FullScreenImg = ({ service, setFullScreenImage }) => {
  return (
    <Container>
      <Image src={getImage(service)} />
      <>
        <Close
          onClick={() => {
            setFullScreenImage(false);
            window.scrollTo(0, 0);
          }}
          src={blackCrossImage}
          alt='close'
        />
      </>
    </Container>
  );
};

export default FullScreenImg;
