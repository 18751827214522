import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Text, Label, Radio, Card } from '@resideo/blueprint-react';
import { Field } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateDeliveryMode } from 'redux/checkout/action';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { getCardDetails } from 'redux/cart/actions';

const StyledCard = styled(Card)`
    border: 2px solid ${({ theme }) => theme.colors.lightenGray};
    margin-bottom: ${({ theme }) => theme.space.medium};
`;

const ShippingMethod = ({ setFieldValue, shippingMethod }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hookDispatch = useDispatchPromise();
  const [selectedIndex, setSelectedIndex] = useState(0);

  let cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];
  const shippingMethodItem = cartItems && cartItems?.deliveryMode ? cartItems?.deliveryMode?.code : null;
  const shippingMethodValue = cartItems && cartItems?.deliveryMode ? cartItems?.deliveryMode : null ;
  
  useEffect(() => {    
    if (shippingMethod?.length > 0) {
      setFieldValue('shippingMethod', shippingMethodItem ?? shippingMethod[0]?.code);
      setFieldValue('shippingMethodValue', shippingMethodValue ?? shippingMethod[0]);
    }

    if(shippingMethod?.length > 0){
      changeDeliveryModes(shippingMethod[0]?.code);
    }

  }, [shippingMethod]);

  const changeDeliveryModes = async (deliveryModeId) => {
    hookDispatch(updateDeliveryMode({ deliveryModeId: deliveryModeId }))
      .then((response) => {
        dispatch(getCardDetails());
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };

  return (
    <Box paddingBottom={['medium', 'medium', 'large', 'large']}>
      <Heading
        paddingBottom='xSmall'
        fontSize='1.125rem'
        paddingTop='medium'
        letterSpacing='normal'>
        {t('cart.shipping.shippingMethod')}
      </Heading>
      <Text color='textHeading' paddingBottom='1rem' fontSize='small' fontStyle='italic'>
        {t('cart.shipping.shippingMethodStandersStatusText')}
      </Text>
      <StyledCard
        data-test-shipping-form-sections
        borderRadius='0.9375rem'
        padding='1.5625rem'>
        <Text color='red' paddingLeft='small' fontSize='small'></Text>
        {shippingMethod &&
          shippingMethod?.map(
            (data, i) =>
              shippingMethod.length !== 0 && (
                <Field key={i} name='shippingMethod' type='radio' value={data.code}>
                  {({ field }) => (
                    <Label marginBottom='medium' paddingTop='medium'>
                      <Radio
                        checked={selectedIndex === i}
                        onClick={() => {
                          setSelectedIndex(i);
                          changeDeliveryModes(data.code);
                          setFieldValue('shippingMethodValue', data);
                        }}
                        name='shippingMethod'
                        marginRight={0}
                        {...field}
                      />
                      <Flex
                        fontSize='14px'
                        justifyContent='space-between'
                        flex={1}>
                        {data?.name}
                        <Text fontSize='14px'>
                          {data?.deliveryCost?.formattedValue}
                        </Text>
                      </Flex>
                    </Label>
                  )}
                </Field>
              ),
          )}
      </StyledCard>
    </Box>
  );
};

export default ShippingMethod;
