import React, { Dispatch, SetStateAction } from 'react';
import Accordion from './Accordion';
import MobileBottomMenuItemList from './MobileBottomMenuItem';
import MobileCardView from './MobileCardView';
import MobileRightMenuItem from './MobileRightMenuItem';
import { Container, HeaderTextContainer, ListItemContainer } from './MobileViewStyle';

interface IProps {
    isOpen?: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    data?: any;
    title?: string;
}

const MobileHeaderMenuItem = ({ isOpen, setIsOpen, data, title }: IProps) => {
    return (
        <Container data-mobile-nav-container className={`${isOpen ? 'active' : 'close'}`}>
            <ListItemContainer>
                <HeaderTextContainer
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}>
                    {title}
                </HeaderTextContainer>

                {data && data?.leftItemList && data?.leftItemList?.isPresent ? (
                    <>
                        {data.leftItemList.isType === 'list' &&
                            data.leftItemList.itemList.map(({ title, subItems, icon }) => (
                                <Accordion key={`index-${title}`} title={title} subLinks={subItems} icon={icon} />
                            ))}
                        {data.leftItemList.isType === 'card' &&
                            data.leftItemList.itemList.map((item, index ) => (
                                <MobileCardView key={`index${index}`} data={item} />
                            ))}
                    </>
                ) : (
                    <></>
                )}

                {data && data?.rightItemList && data?.rightItemList?.isPresent ? (
                    <MobileRightMenuItem data={data?.rightItemList} />
                ) : (
                    <></>
                )}

                {data && data?.bottomItemList && data?.bottomItemList?.isPresent ? (
                    <MobileBottomMenuItemList footerItem={data?.bottomItemList} />
                ) : (
                    <></>
                )}
            </ListItemContainer>
        </Container>
    );
};

export default MobileHeaderMenuItem;
