import React from 'react';
import { MainContainer } from './MenuItemStyle';

const BG = ({ position, children }) => {
    return (
        <MainContainer className='rdeCardOpen' data-test-main-container transform={position}>{children}</MainContainer>
    );
};

export default BG;
