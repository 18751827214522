import { types } from './types';

export const refreshToken = (token: any) => ({
    type: types.REFRESH_TOKEN,
    payload: token,
});

export const refreshTokenComplete = () => ({
    type: types.REFRESH_TOKEN_COMPLETE,
});

export const enqueueFailedAction = (action: any) => ({
    type: types.ENQUEUE_FAILED_ACTIONS,
    payload: action,
});

export const dispatchQueuedActions = () => ({
    type: types.DISPATCH_QUEUED_ACTIONS,
});
