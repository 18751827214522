import React from 'react';
import { Box, Heading, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const OverviewWrapper = styled(Box)`
  padding-top: 30px;
  @media (max-width: 768px) {
    padding-top: 20px;
  }
`;

const BoxOverview = styled(Box)`
  padding: 70px 250px;
  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const TextOverviewHAB = styled(Text)`
  color: white;
  font-size: 16px;
  line-height: 24px;
`;

const TextOverview = styled(Text)`
  color: white;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 30px;
`;

const FlexList = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const OverviewListAdv = styled.ul`
  color: white;
  flex: 1;
  margin: 0;
  & li {
    flex-shrink: 1;
    flex-basis: auto;
    flex: 50%;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.18px;
    line-height: 30px;
    padding-right: 10px;
    @media (max-width: 1024px) {
      flex: 100%;
    }
  }
`;
const OverviewList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  color: white;
  padding-left: 20px;
  margin-top: 30px;
  flex: 1;
  & li {
    flex-shrink: 1;
    flex-basis: auto;
    flex: 50%;
    font-size: 16px;
    letter-spacing: 0.18px;
    line-height: 30px;
    @media (max-width: 1450px) {
      padding-right: 5px;
    }
    @media (max-width: 1024px) {
      flex: 100%;
    }
  }
`;

const OverviewListPro = styled.ul`
  display: flex;
  flex-wrap: wrap;
  color: white;
  padding-left: 20px;
  margin-top: 30px;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 0;
  }
  & li {
    flex-shrink: 1;
    flex-basis: auto;
    flex: 50%;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.18px;
    line-height: 30px;
    @media (max-width: 1024px) {
      flex: 100%;
    }
  }
`;

const OverviewHtmlText = styled(Text)`
  color: white;
  font-size: 16px;
  line-height: 24px;
  & ul {
    display: flex;
  flex-wrap: wrap;
  color: white;
  padding-left: 20px;
  margin-top: 30px;
  flex-direction: column;
  flex: 1;
  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 0;
  }
  }
  & li {
    flex-shrink: 1;
    flex-basis: auto;
    flex: 50%;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.18px;
    line-height: 30px;
    @media (max-width: 1024px) {
      flex: 100%;
    }
  }
`;
const ProgramOverview = ({ overviewRef, data }) => {
  const { t } = useTranslation();
  const createHTML = txt => {
    return { __html: txt };
  };

  return (
    <>
      <OverviewWrapper justifyContent='center'>
        <Box
          ref={overviewRef}
          id='Overview'
          justifyContent='center'
          display='flex'
          backgroundColor='productInfoBackgroundColor'>
          <BoxOverview>
            <Heading
              color='white'
              fontSize='2.5rem'
              paddingBottom={['small', 'medium', 'medium', 'large']}
              fontFamily='URW_Din_CondBold'
              letterSpacing='0.125rem'>
              {t('programOverview.productTitle')}
            </Heading>
            <OverviewHtmlText
              color='white'
              fontSize='1rem'
              lineHeight='2.1875rem'
              dangerouslySetInnerHTML={createHTML(data)}
            >

            </OverviewHtmlText>


            {/* {service.toLowerCase().includes('pro') ? (
              <>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.proMsg1')}
                </TextOverview>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.proMsg2')}
                </TextOverview>
                <FlexList>
                  <OverviewListPro>
                    <li>{t('programOverview.proLi1')}</li>
                    <li>{t('programOverview.proLi2')}</li>
                  </OverviewListPro>
                  <OverviewListPro>
                    <li>{t('programOverview.proLi3')}</li>
                  </OverviewListPro>
                </FlexList>
              </>
            ) : service.toLowerCase().includes('basic') ? (
              <>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.basicMsg1')}
                </TextOverview>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.basicMsg2')}
                </TextOverview>
                <TextOverview>{t('programOverview.basicMsg3')}</TextOverview>
                <OverviewList>
                  <li>{t('programOverview.basicLi1')}</li>
                  <li>{t('programOverview.basicLi2')}</li>
                  <li>{t('programOverview.basicLi3')}</li>
                  <li>{t('programOverview.basicLi4')}</li>
                  <li>{t('programOverview.basicLi5')}</li>
                  <li>{t('programOverview.basicLi6')}</li>
                </OverviewList>
              </>
            ) : service.toLowerCase().includes('advance') ? (
              <>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.advMsg1')}
                </TextOverview>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.advMsg2')}
                </TextOverview>
                <TextOverview>{t('programOverview.advMsg3')}</TextOverview>
                <OverviewList>
                  <li>{t('programOverview.advLi1')}</li>
                  <li>{t('programOverview.advLi2')}</li>
                  <li>{t('programOverview.advLi3')}</li>
                  <li>{t('programOverview.advLi4')}</li>
                  <li>{t('programOverview.advLi5')}</li>
                  <li>{t('programOverview.advLi6')}</li>
                  <li>{t('programOverview.advLi7')}</li>
                </OverviewList>
              </>
            ) : service.toLowerCase().includes('hab') ? (
              <>
                <TextOverviewHAB
                  fontFamily='Roboto'
                  fontWeight='400'
                  fontSize='1rem'
                  color='white'
                  lineHeight='2.1875rem'>
                  {t('programOverview.HABOverview')}
                </TextOverviewHAB>
                <Box fontFamily='Roboto' fontWeight='400'>
                  <OverviewList>
                    <li>{t('programOverview.HABLi1')}</li>
                    <li>{t('programOverview.HABLi2')}</li>
                    <li>{t('programOverview.HABLi3')}</li>
                    <li>{t('programOverview.HABLi4')}</li>
                    <li>{t('programOverview.HABLi5')}</li>
                    <li>{t('programOverview.HABLi6')}</li>
                  </OverviewList>
                </Box>
              </>
            ) : (
              <>
                <TextOverview
                  color='white'
                  fontSize='1rem'
                  lineHeight='2.1875rem'>
                  {t('programOverview.refMsg')}
                </TextOverview>
                <ListWrapper>
                  <OverviewListAdv>
                    <li>{t('programOverview.refLi1')}</li>
                    <li>{t('programOverview.refLi3')}</li>
                    <li>
                      {t('programOverview.refLi5')}{' '}
                      <a
                        href='mailto:AirCycleanswers@resideo.com'
                        style={{ color: 'white' }}>
                        {t('programOverview.contactSupport')}
                      </a>
                    </li>
                  </OverviewListAdv>
                  <OverviewListAdv>
                    <li>{t('programOverview.refLi2')}</li>
                    <li>{t('programOverview.refLi4')}</li>
                    <li>{t('programOverview.refLi6')}</li>
                  </OverviewListAdv>
                </ListWrapper>
              </>
            )} */}
          </BoxOverview>
        </Box>
      </OverviewWrapper>
    </>
  );
};

export default ProgramOverview;
