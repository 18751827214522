import React, { FC } from 'react';
import {
  Text,
  Image,
  Box,
  Button,
  Select,
  Flex,
  Label,
} from '@resideo/blueprint-react';
import { CloseRoundGreyImg } from '../../../assets/images';
import styled from 'styled-components';
import { InputField, SelectField } from '../../common/InputField';
import { STATES } from '../../../config/states';
import { PROVINCES } from '../../../config/provinces';
import {
  ErrorInputField,
  useSwitchPlaceholder,
} from '../../ErrorInputField';
import { useTranslation } from 'react-i18next';
import SeparatorLine from '../../common/SeparatorLine';

export const ModalHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary};
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  .add {
    font-family: 'URW_Din_CondBold';
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 2px;
    font-size: 2.8125rem;
    font-weight: 400;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
`;

export const ModalBody = styled(Box)`
  padding: 3rem 5rem 4rem 5rem;
  @media (max-width: 768px) {
    padding: 2rem;
    overflow: auto;
    max-height: 500px;
  }
`;

export const CloseBtn: FC<{
  close?: any;
  setErrors?: any;
}> = ({ close, setErrors }) => {
  return (
    <Image
      onClick={() => {
        close();
        setErrors({});
      }}
      src={CloseRoundGreyImg}
      alt='close'
      className='close'
      width='2rem'
      style={{ cursor: 'pointer' }}
    />
  );
};

const AddAddressModal = ({
  setCompanyName,
  setFirstName,
  setLastName,
  setAddressLine1,
  setAddressLine2,
  setCountry,
  setCity,
  setState,
  setZipPostalCode,
  addNewAddress,
  errors,
  country,
  close,
  setErrors,
}) => {
  const { t } = useTranslation();  
  return (
    <Box
      style={{
        boxShadow: 'drop-shadow(16px 16px 10px black)',
      }}>
      <ModalHeader>
        <Text className='add' fontWeight={'normal'}>
          {t('cart.shipping.modal.addNewAddress')}
        </Text>
        <CloseBtn close={close} setErrors={setErrors} />
      </ModalHeader>
      <ModalBody className='content'>
        <Box data-test-shipping-company-name marginBottom='medium'>
          <ErrorInputField
            isError={errors.companyName}
            placeholder={useSwitchPlaceholder('Company Name', errors)}
            label='Company Name*'
            name='companyName'
            onChange={e => setCompanyName(e.target.value)}
          />
        </Box>
        <Box display='flex' alignItems='flex-end'>
          <Box data-test-shipping-first-name marginBottom='medium' flex={1}>
            <ErrorInputField
              isError={errors.firstName}
              placeholder={useSwitchPlaceholder('First Name', errors)}
              label='First Name*'
              name='firstName'
              marginRight='small'
              onChange={e => setFirstName(e.target.value)}
            />
          </Box>
          <Box data-test-shipping-last-name marginBottom='medium' flex={1}>
            <ErrorInputField
              isError={errors.lastName}
              placeholder={useSwitchPlaceholder('Last Name', errors)}
              label='Last Name*'
              name='lastName'
              marginLeft='small'
              onChange={e => setLastName(e.target.value)}
            />
          </Box>
        </Box>
        <Box display='flex' alignItems='flex-end'>
          <Box data-test-shipping-address-line-1 marginBottom='medium' flex={1}>
            <ErrorInputField
              isError={errors.addressLine1}
              placeholder={useSwitchPlaceholder('Address', errors)}
              label='Address Line 1*'
              name='addressLine1'
              marginRight='small'
              onChange={e => setAddressLine1(e.target.value)}
            />
          </Box>
          <Box data-test-shipping-address-line-2 marginBottom='medium' flex={1}>
            <InputField
              placeholder='Address'
              label='Address Line 2 (Optional)'
              name='addressLine2'
              marginLeft='small'
              onChange={e => setAddressLine2(e.target.value)}
            />
          </Box>
        </Box>
        <Box data-test-shipping-country-code marginBottom='medium' flex={1}>
          <Label>
            <Text
              marginLeft='medium'
              paddingBottom={'xSmall'}
              color='black'
              fontSize='small'>
              Country*
            </Text>
          </Label>
          <SelectField
            label=''
            isError={errors.countryCode}
            name='countryCode'
            onChange={e => setCountry(e.target.value)}>
            <option value=''>{useSwitchPlaceholder('Country', errors)}</option>
            <option value='US'>United States</option>
            {/* <option value='CA'>Canada</option> */}
          </SelectField>
        </Box>
        <Box
          display='flex'
          flexDirection={['column', 'row', 'row', 'row']}
          alignItems='flex-end'>
          <Box
            width='100% !important'
            marginBottom='medium'
            flex={['100%', '40%', '40%', '40%']}
            paddingRight={['0', 'small', 'small', 'small']}>
            <ErrorInputField
              isError={errors.city}
              placeholder={useSwitchPlaceholder('City', errors)}
              label='City*'
              name='city'
              onChange={e => setCity(e.target.value)}
            />
          </Box>

          <Box
            marginBottom='medium'
            width='100% !important'
            flex={['100%', '60%', '60%', '60%']}>
            <Flex display='flex' alignItems='flex-end'>
              <Box
                data-test-shipping-state-province-region-code
                flex={['60%', '50%', '50%', '50%']}>
                <Label>
                  <Text
                    marginLeft='medium'
                    paddingBottom={'xSmall'}
                    color='black'
                    fontSize='small'>
                    State*
                  </Text>
                </Label>
                <Select
                  name='stateProvinceRegionCode'
                  onChange={e => setState(e.target.value)}>
                  {country === 'CA'
                    ? PROVINCES.map((obj, i) => (
                        <option key={i} value={obj.value}>
                          {obj.value}
                        </option>
                      ))
                    : STATES.map((obj, i) => (
                        <option
                          disabled={obj.value === 'Select a State'}
                          key={i}
                          value={obj.value}>
                          {obj.value}
                        </option>
                      ))}
                </Select>
              </Box>
              <Box
                data-test-shipping-zip-postal-code
                flex={['40%', '50%', '50%', '50%']}
                paddingLeft='small'>
                <ErrorInputField
                  isError={errors.zipPostalCode}
                  placeholder={useSwitchPlaceholder('Zip code', errors)}
                  label='Zip code*'
                  name='zipPostalCode'
                  onChange={e => setZipPostalCode(e.target.value)}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
        <SeparatorLine />
        <Box display='flex' justifyContent='flex-end'>
          <Button
            data-test-shipping-add-new-address
            onClick={close}
            variant='secondary'
            marginRight='medium'
            paddingX='2.5rem'
            type='submit'>
            {t('common.cancel')}
          </Button>
          <Button
            data-test-shipping-add-new-address
            onClick={() => addNewAddress()}
            variant='primary'
            paddingX='2.85rem'
            type='submit'>
            {t('add')}
          </Button>
        </Box>
      </ModalBody>
    </Box>
  );
};

export default AddAddressModal;
