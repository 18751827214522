import { Card, Text, Heading, Box, Flex } from '@resideo/blueprint-react';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ShippingAddress from './reviewOrder/ShippingAddress';
import ShippingMethod from './reviewOrder/ShippingMethod';
import BillingAddress from './reviewOrder/BillingAddress';
import PaymentMethod from './reviewOrder/PaymentMethod';
import { useHistory } from 'react-router';
import moment from 'moment';
import { maskPhoneNumber } from '../../utils/maskPhoneNumber';
import paymentData from '../../data/paymentData.json';

const Container = styled(Card) <{ doNotShow?: boolean }>`
    background-color: ${({ theme }) => theme.colors.lightenGray};
    border-radius: 0.9375rem;
    margin: 0 0 1rem 0;
    display: ${({ doNotShow }) => (doNotShow ? 'none' : ' block')};
    @media (max-width: 768px) {
        padding: 1.875rem;
    }
`;

const ReviewSectionCard: FC<any> = ({ card, setFieldValue, values }) => {
    const { t } = useTranslation();
    const state = paymentData;
    let cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];
    const hasProduct =
        cartItems &&
        cartItems?.entries?.length > 0 &&
        cartItems?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length >
        0;

    const history = useHistory();

    const setPaymentMethod = (card) => {
        setFieldValue('card.cvc', card.cvc);
        setFieldValue('card.exp', card.exp);
        setFieldValue('card.firstNum', card.firstNum);
        setFieldValue('card.lastFourNums', card.lastFourNums);
        setFieldValue('card.name', card.name);
        setFieldValue('card.number', card.number);
        setFieldValue('card.type', card.type);
        setFieldValue('card.image', card.image);
    };

    return (
        <Container
            padding={['medium', 'medium', 'large', 'large']}
            doNotShow={
                !hasProduct &&
                (card.heading === 'cart.shipping.shippingAddress' ||
                    card.heading === 'cart.shipping.shippingMethod')
            }>
            <Flex justifyContent='space-between'>
                <Box width='100%'>
                    <Heading
                        paddingBottom='0.625rem'
                        fontSize='medium'
                        color='textHeading'
                        letterSpacing='normal'>
                        {t(card.heading)}
                    </Heading>
                    {card.type === 'info' && (
                        <>
                            <Text color='textHeading'>{values.email || card.email}</Text>
                            <Text color='textHeading'>
                                {maskPhoneNumber(values.phone || card.phone, 'ReviewSectionCard')}
                            </Text>
                        </>
                    )}
                    {card.type === 'taxExemption' && (
                        <Box>
                            <Text color='textHeading'>{card?.companyName}</Text>
                            <Text color='textHeading'>{card?.taxAuthority}</Text>

                            <Text color='textHeading'>
                                {t('cart.billing.exemptionStartDateLable')}{' '}
                                {card?.startDate !== ''
                                    ? moment(card?.startDate).format('MMM YYYY')
                                    : 'N/A'}
                            </Text>
                            <Text color='textHeading'>
                                {t('cart.billing.exemptionEndDateLable')}{' '}
                                {card?.endDate !== ''
                                    ? moment(card?.endDate).format('MMM YYYY')
                                    : 'N/A'}
                            </Text>
                            <Text color='textHeading'>
                                {t('cart.billing.certFileName')}{' '}
                                {localStorage.getItem('temp-file-name')
                                    ? localStorage.getItem('temp-file-name')
                                    : 'N/A'}
                            </Text>
                        </Box>
                    )}
                    {hasProduct && (
                        <>
                            <ShippingAddress
                                showForm={null}
                                type={card.type}
                                values={values}
                                card={card}
                            />
                            <ShippingMethod showForm={null} type={card.type} card={card} />
                        </>
                    )}

                    <BillingAddress showForm={null} card={card} values={values} />

                    <PaymentMethod
                        showForm={null}
                        card={card}
                        state={state}
                        setPaymentMethod={setPaymentMethod}
                        values={values}
                    />
                </Box>
                <Box>
                    <Text
                        onClick={() =>
                            history.push({
                              pathname: '/cart/payment',
                              state: {
                                currentlySelectedCard: JSON.stringify(card),
                              },
                            })
                          }
                        color='primary'
                        fontSize='small'
                        fontWeight='bold'
                        style={{ cursor: 'pointer' }}>
                        {card.type === 'info'
                            ? ''
                            : sessionStorage.getItem('payment-type') === 'newCC'
                                ? 'edit'
                                : ''}
                    </Text>
                </Box>
            </Flex>
        </Container>
    );
};

export default ReviewSectionCard;
