import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Radio,
  Card,
  Box,
  Text,
  Label,
  Flex,
  Input,
  SelectField,
} from '@resideo/blueprint-react';
import { Field } from 'formik';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
//import { CartContext } from 'routes/Products/CartProvider';
import SeparatorLine from '../../common/SeparatorLine';
import { STATES } from '../../../config/states';
import { PROVINCES } from '../../../config/provinces';
import CartItemData from '../../../data/cartItems.json';

export const Container = styled(Card)<{ isError?: boolean }>`
  border: ${({ isError, theme }) =>
    isError ? '2px solid red' : `2px solid ${theme.colors.lightenGray}`};
  border-radius: 0.9375rem;
  padding: 1.5625rem;
`;

const BillingAddress: FC<any> = ({ values, setFieldValue, errors }) => {
  const [
    wantsToUseDifferentAddressForBilling,
    setDifferentBillingAddress,
  ] = useState(false);
  const { t } = useTranslation();

  const [country, setCountry] = useState('');

  // const {
  //   state: { order },
  // } = useContext(CartContext);
  const {order} = CartItemData;

  const setBillingAddress = address => {
    setFieldValue(
      'billingAddress.contactName',
      address?.contactName ? address?.contactName : ''
    );
    setFieldValue(
      'billingAddress.addressLine1',
      address?.addressLine1 ? address?.addressLine1 : ''
    );
    setFieldValue(
      'billingAddress.addressLine2',
      address?.addressLine2 ? address?.addressLine2 : ''
    );
    setFieldValue('billingAddress.city', address?.city ? address?.city : '');
    setFieldValue(
      'billingAddress.stateProvinceRegionCode',
      address?.stateProvinceRegionCode ? address?.stateProvinceRegionCode : ''
    );
    setFieldValue(
      'billingAddress.countryCode',
      address?.countryCode ? address?.countryCode : ''
    );
    setFieldValue(
      'billingAddress.zipPostalCode',
      address?.zipPostalCode ? address?.zipPostalCode : ''
    );
  };

  const useShippingAddressEffect = func => useEffect(func, []);

  const sameAsShippingAddress = () => {
    setFieldValue('radio', 'Same as shipping');
    setBillingAddress(order?.shipTo?.address);
  };

  useShippingAddressEffect(sameAsShippingAddress);

  return (
    <Box data-test-payment-sections>
      <Text
        data-test-section-title
        paddingY='medium'
        fontWeight='bold'
        fontSize='1.125rem'>
        {t('cart.billing.billingAddress')}
      </Text>
      <Container isError={errors.radio}>
        <Flex marginBottom='medium'>
          <Field name='radio'>
            {({ field }) => (
              <Label>
                <Radio
                  name='radio'
                  marginRight={0}
                  {...field}
                  onClick={() => {
                    setDifferentBillingAddress(false);
                    setBillingAddress(order?.shipTo?.address);
                  }}
                  onChange={() => setFieldValue('radio', 'Same as shipping')}
                  checked={values.radio === 'Same as shipping'}
                />
                <Box fontWeight='bold'>
                  {t('cart.billing.sameAsShippingAddress')}
                </Box>
              </Label>
            )}
          </Field>
        </Flex>
        <Flex marginBottom='medium'>
          <Field name='radio'>
            {({ field }) => (
              <Label>
                <Radio
                  name='radio'
                  marginRight={0}
                  {...field}
                  onClick={() => {
                    setDifferentBillingAddress(true);
                    setBillingAddress({});
                  }}
                  onChange={() => setFieldValue('radio', '')}
                />
                <Box fontWeight='bold'>
                  {t('cart.billing.useDifferentBillingAddress')}
                </Box>
              </Label>
            )}
          </Field>
        </Flex>
        {wantsToUseDifferentAddressForBilling && (
          <>
            <SeparatorLine />
            <Box marginBottom='medium'>
              <Label fontSize='small' htmlFor='billingAddress.contactName'>
                Contact Name
              </Label>
              <Input
                type='text'
                name='billingAddress.contactName'
                onChange={e =>
                  setFieldValue('billingAddress.contactName', e.target.value)
                }
              />
            </Box>
            <Flex width='100%' justifyContent='space-between'>
              <Box marginBottom='medium' width='49%'>
                <Label fontSize='small' htmlFor='billingAddress.addressLine1'>
                  Address Line 1
                </Label>
                <Input
                  type='text'
                  name='billingAddress.addressLine1'
                  onChange={e =>
                    setFieldValue('billingAddress.addressLine1', e.target.value)
                  }
                />
              </Box>
              <Box marginBottom='medium' width='49%'>
                <Label fontSize='small' htmlFor='billingAddress.addressLine2'>
                  Address Line 2
                </Label>
                <Input
                  type='text'
                  name='billingAddress.addressLine2'
                  onChange={e =>
                    setFieldValue('billingAddress.addressLine2', e.target.value)
                  }
                />
              </Box>
            </Flex>
            <Flex>
              <Box marginBottom='medium'>
                <Label fontSize='small' htmlFor='billingAddress.city'>
                  City
                </Label>
                <Input
                  type='text'
                  name='billingAddress.city'
                  onChange={e =>
                    setFieldValue('billingAddress.city', e.target.value)
                  }
                />
              </Box>
              <Box
                flex={1}
                marginBottom='medium'
                marginX='small'
                paddingBottom='0'>
                <Label
                  fontSize='small'
                  htmlFor='billingAddress.stateProvinceRegionCode'>
                  State
                </Label>
                <SelectField
                  label=''
                  name='billingAddres.stateProvinceRegionCode'
                  onChange={e =>
                    setFieldValue(
                      'billingAddress.stateProvinceRegionCode',
                      e.target.value
                    )
                  }>
                  {country === 'CA'
                    ? PROVINCES.map((obj, i) => (
                        <option key={i} value={obj.value}>
                          {obj.value}
                        </option>
                      ))
                    : STATES.map((obj, i) => (
                        <option
                          disabled={obj.value === 'Select a State'}
                          key={i}
                          value={obj.value}>
                          {obj.value}
                        </option>
                      ))}
                </SelectField>
              </Box>
              <Box marginBottom='medium'>
                <Label fontSize='small' htmlFor='billingAddress.zipPostalCode'>
                  Zip Code
                </Label>
                <Input
                  type='text'
                  name='billingAddress.zipPostalCode'
                  onChange={e =>
                    setFieldValue(
                      'billingAddress.zipPostalCode',
                      e.target.value
                    )
                  }
                />
              </Box>
            </Flex>
            <Box flex={1}>
              <SelectField
                label=''
                name='countryCode'
                onChange={e => {
                  setFieldValue('billingAddress.countryCode', e.target.value);
                  setCountry(e.target.value);
                }}>
                <option value='US'>United States</option>
                <option value='CA'>Canada</option>
              </SelectField>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default BillingAddress;
