/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Field } from 'formik';
import {
    Card,
    Text,
    Box,
    Label,
    Radio,
    Heading,
    Flex,
    Link,
    Image,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import EditAddressModal from './EditAddressModal';
import CustomPopup from '../../common/customPopup/customPopup.style';
import AddAddressModal from './AddAddressModal';
import ManageAddressModal from './ManageAddressModal';
import { useTranslation } from 'react-i18next';
import { plusBlueIcon } from '../../../assets/images';
import { useDispatch, useSelector } from 'react-redux';
import {
    addDeliveryAddress,
    updateDeliveryAddress,
    selectDeliveryAddress,
} from 'redux/checkout/action';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { getDeliveryModeDetails, getAddressDetails } from 'redux/checkout/action';
import { RootState } from '../../../redux/root-reducer';

const Container = styled(Card)`
    border-radius: 0.9375rem;
    padding: 1.5625rem;
    border: 2px solid ${({ theme }) => theme.colors.lightenGray};
`;

export const StyledText = styled(Link)`
    cursor: pointer;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes.small};
    display: inline;
    margin-left: ${({ theme }) => theme.fontSizes.small};
`;

export const Address = ({ address, action }) => (
    <Flex alignItems='center'>
        <Text fontSize='medium' fontWeight='body' color='textLight3' fontFamily='Roboto'>
            {address.companyName ? `${address.companyName}, ` : ''}
            {`${address.firstName} `}
            {`${address.lastName}, `}
            {`${address.addressLine1}, `}
            {address.addressLine2 ? `${address.addressLine2}, ` : ''}
            {address.city} {address.stateProvinceRegionCode} {address.zipPostalCode}
            {action}
        </Text>
    </Flex>
);

const BillingOrShippingAddressForm: FC<any> = ({
    values,
    setFieldValue,
    setErrors,
    errors,
    shippingAddressList,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const [clickedAddressIndex, setClickedAddressIndex] = useState(0);
    const changeAddress = useSelector(
        (state: RootState) => state.checkOutDetails.selectDeliveryAddressDetails,
    );

    const fetchDeliveryMode = useCallback(async () => {
        dispatch(getDeliveryModeDetails());
    }, [dispatch]);

    useEffect(() => {
        fetchDeliveryMode();
    }, [changeAddress]);

    const allBilling = shippingAddressList
        ? shippingAddressList.map((obj) => ({
            companyName: obj?.companyName,
            firstName: obj?.firstName,
            lastName: obj?.lastName,
            addressLine1: obj?.line1,
            addressLine2: obj.billTo?.address?.line2,
            countryCode: obj?.country?.isocode,
            city: obj?.town,
            stateProvinceRegionCode: obj.region?.isocode?.split('-')[1],
            zipPostalCode: obj?.postalCode,
            addressId: obj.id,
        }))
        : [];

    const [multipleAddresses, setMultipleAddresses] = useState(allBilling) as any;
    const [shippingOrBilling, setShippingOrBilling] = useState('shippingAddress');

    const [companyName, setCompanyName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [countryCode, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [stateProvinceRegionCode, setState] = useState('');
    const [zipPostalCode, setZipPostalCode] = useState('');

    const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
    const closeAddAddressModal = () => setOpenAddAddressModal(false);

    const [openEditAddressModal, setOpenEditAddressModal] = useState(false);
    const closeEditAddressModal = () => setOpenEditAddressModal(false);

    const [openManageAddressModal, setOpenManageAddressModal] = useState(false);
    const closeManageAddressModal = () => setOpenManageAddressModal(false);

    // set other addresses to formik value
    values.multipleAddresses = multipleAddresses;

    const newAddress = {
        companyName,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        countryCode,
        city,
        stateProvinceRegionCode,
        zipPostalCode,
        isDefault: false,
    };

    const resetForm = () => {
        // clear form
        setCompanyName('');
        setFirstName('');
        setLastName('');
        setAddressLine1('');
        setAddressLine2('');
        setCountry('');
        setCity('');
        setState('');
        setZipPostalCode('');

        // reset errors, close modal, end function
        setErrors({});
        closeAddAddressModal();
    };

    const setShippingAddressFields = useCallback(
        (a) => {
            // set address to use
            setFieldValue(`${shippingOrBilling}.addressLine1`, a.addressLine1);
            setFieldValue(`${shippingOrBilling}.addressLine2`, a.addressLine2);
            setFieldValue(`${shippingOrBilling}.city`, a.city);
            setFieldValue(`${shippingOrBilling}.companyName`, a.companyName);
            setFieldValue(`${shippingOrBilling}.firstName`, a.firstName);
            setFieldValue(`${shippingOrBilling}.lastName`, a.lastName);
            setFieldValue(`${shippingOrBilling}.countryCode`, a.countryCode);
            setFieldValue(`${shippingOrBilling}.id`, a.addressId);
            setFieldValue(
                `${shippingOrBilling}.stateProvinceRegionCode`,
                a.stateProvinceRegionCode,
            );
            setFieldValue(`${shippingOrBilling}.zipPostalCode`, a.zipPostalCode);
        },
        [setFieldValue],
    );

    useEffect(() => {
        if (multipleAddresses && multipleAddresses.length > 0) {
            changeDeliveryAddress(values.multipleAddresses[0]); // update shipping address
            setShippingAddressFields(values.multipleAddresses[0]);
        }
    }, [shippingAddressList]);

    const addNewAddress = () => {
        if (
            firstName === '' ||
            lastName === '' ||
            companyName === '' ||
            addressLine1 === '' ||
            city === '' ||
            zipPostalCode === '' ||
            countryCode === ''
        ) {
            setErrors({
                firstName: firstName === '' ? true : false,
                lastName: lastName === '' ? true : false,
                companyName: companyName === '' ? true : false,
                addressLine1: addressLine1 === '' ? true : false,
                city: city === '' ? true : false,
                zipPostalCode: zipPostalCode === '' ? true : false,
                countryCode: countryCode === '' ? true : false,
            });
        } else {
            // prevent duplicate address
            if (
                values.multipleAddresses.length > 0 &&
                values.multipleAddresses.find(
                    (a) =>
                        // if newly added address matches
                        // any of the existing addresses
                        newAddress.companyName === a.companyName &&
                        newAddress.firstName === a.firstName &&
                        newAddress.lastName === a.lastName &&
                        newAddress.addressLine1 === a.addressLine1 &&
                        newAddress.city === a.city &&
                        newAddress.countryCode === a.countryCode &&
                        newAddress.zipPostalCode === a.zipPostalCode &&
                        newAddress.stateProvinceRegionCode === a.stateProvinceRegionCode,
                )
            ) {
                resetForm();
                return;
            }
            addNewDeliveryAddress(newAddress);
            resetForm();
        }
    };

    const addNewDeliveryAddress = async (newAddressData) => {
        const sourceData = {
            companyName: newAddressData?.companyName,
            country: {
                isocode: newAddressData?.countryCode,
            },
            defaultAddress: true,
            firstName: newAddressData?.firstName,
            lastName: newAddressData?.lastName,
            line1: newAddressData?.addressLine1,
            line2: newAddressData?.addressLine2,
            postalCode: newAddressData?.zipPostalCode,
            region: {
                isocode: `${newAddressData?.countryCode}-${newAddressData?.stateProvinceRegionCode}`,
            },
            shippingAddress: true,
            town: newAddressData?.city,
            visibleInAddressBook: true,
        };

        hookDispatch(addDeliveryAddress({ body: sourceData }))
            .then((response) => {
                // add new address
                //setMultipleAddresses((multipleAddresses) => [...multipleAddresses, newAddress]);
                setShippingAddressFields(response);
                dispatch(getAddressDetails());
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    const updateAddress = () => {
        values.multipleAddresses.filter((obj, i) => {
            if (i === clickedAddressIndex) {
                obj.addressLine1 = values[shippingOrBilling].addressLine1;
                obj.addressLine2 = values[shippingOrBilling].addressLine2;
                obj.city = values[shippingOrBilling].city;
                obj.companyName = values[shippingOrBilling].companyName;
                obj.firstName = values[shippingOrBilling].firstName;
                obj.lastName = values[shippingOrBilling].lastName;
                obj.countryCode = values[shippingOrBilling].countryCode;
                obj.stateProvinceRegionCode = values[shippingOrBilling].stateProvinceRegionCode;
                obj.zipPostalCode = values[shippingOrBilling].zipPostalCode;
            }
            return obj;
        });
        updateExistingAddress(multipleAddresses[clickedAddressIndex]);
        setErrors({});
        closeEditAddressModal();
    };

    const updateExistingAddress = async (updateAddressData) => {
        const addressID = updateAddressData.addressId;

        const sourceData = {
            companyName: updateAddressData?.companyName,
            country: {
                isocode: updateAddressData?.countryCode,
            },
            defaultAddress: true,
            firstName: updateAddressData?.firstName,
            lastName: updateAddressData?.lastName,
            line1: updateAddressData?.addressLine1,
            line2: updateAddressData?.addressLine2,
            postalCode: updateAddressData?.zipPostalCode,
            region: {
                isocode: `${updateAddressData?.countryCode}-${updateAddressData?.stateProvinceRegionCode}`,
            },
            shippingAddress: true,
            town: updateAddressData?.city,
            visibleInAddressBook: true,
        };
        dispatch(
            updateDeliveryAddress({ addressId: addressID, body: sourceData }),
        );
    };

    const changeDeliveryAddress = async (changeAddressData) => {
        hookDispatch(
            selectDeliveryAddress({ addressId: changeAddressData.addressId }),
        )
            .then((response) => {
                dispatch(getDeliveryModeDetails());
            })
            .catch((error) => console.log(error));
    };

    const setDefaultAddress = (a) => {
        // set all defaults to false
        values.multipleAddresses.map((address) => {
            if (address.defaultAddress) {
                address.defaultAddress = false;
            }
            return <></>;
        });

        // set correct address to default
        if (!a.defaultAddress) {
            Object.assign(a, { defaultAddress: true });
        }

        setMultipleAddresses([...multipleAddresses]);
    };

    const removeAddress = (i) =>
        setMultipleAddresses(values.multipleAddresses.filter((_, b) => b !== i));

    return (
        <>
            {/* ====== Add Address Modal ⤵ ======⤵ */}
            {openAddAddressModal && (
                <CustomPopup open={openAddAddressModal} closeOnDocumentClick={false}>
                    <AddAddressModal
                        data-test-shipping-add-address-modal
                        setCompanyName={setCompanyName}
                        setFirstName={setFirstName}
                        setLastName={setLastName}
                        setAddressLine1={setAddressLine1}
                        setAddressLine2={setAddressLine2}
                        setCountry={setCountry}
                        setCity={setCity}
                        setState={setState}
                        setZipPostalCode={setZipPostalCode}
                        addNewAddress={addNewAddress}
                        errors={errors}
                        country={countryCode}
                        close={closeAddAddressModal}
                        setErrors={setErrors}
                    />
                </CustomPopup>
            )}

            {/* ⤴ ====== ⤴ Add Address Modal ====== */}
            {/* ====== Manage Address Modal ⤵ ====== ⤵ */}
            {openManageAddressModal && (
                <CustomPopup open={openManageAddressModal} closeOnDocumentClick={false}>
                    <ManageAddressModal
                        addresses={values.multipleAddresses}
                        close={closeManageAddressModal}
                        setErrors={setErrors}
                        setDefaultAddress={setDefaultAddress}
                        remove={removeAddress}
                        setOpenEditAddressModal={setOpenEditAddressModal}
                        openEditAddressModal={openEditAddressModal}
                        setClickedAddressIndex={setClickedAddressIndex}
                        setOpenAddAddressModal={setOpenAddAddressModal}
                        openAddAddressModal={openAddAddressModal}
                        viewerAddress={values?.shippingOrBillings}
                    />
                </CustomPopup>
            )}
            {/* ⤴ ====== ⤴ Manage Address Modal ====== */}
            <Heading
                data-test-shipping-address-form-section-title
                fontSize='1.313rem'
                paddingTop='medium'
                paddingBottom={''}
                letterSpacing='normal'>
                {t('cart.shipping.shippingAddress')}
            </Heading>
            <Text
                data-test-shipping-address-form-sub-title
                color='textHeading'
                paddingBottom='1rem'
                fontSize='small'
                fontStyle='italic'>
                {t('cart.shipping.shippingAvailability')}
            </Text>
            <Container>
                <Box data-test-shipping-form-sections marginBottom='medium'>
                    {values.multipleAddresses.map(
                        (address, i) =>
                            values.multipleAddresses.length !== 0 && (
                                <Flex key={i} alignItems='center' paddingY='medium'>
                                    <Field name='radio' value={address}>
                                        {({ field }) => (
                                            <Label>
                                                <Radio
                                                    checked={clickedAddressIndex === i}
                                                    onClick={() => {
                                                        setClickedAddressIndex(i);
                                                        setShippingAddressFields(address);
                                                        changeDeliveryAddress(address);
                                                    }}
                                                    name='radio'
                                                    marginRight={0}
                                                    {...field}
                                                />
                                                <Flex fontSize='14px'
                                                    justifyContent='space-between'
                                                    flex={1} 
                                                    alignItems='center'>
                                                    <Address
                                                        address={address}
                                                        action={
                                                            <StyledText
                                                                onClick={() => {
                                                                    setClickedAddressIndex(i);
                                                                    setOpenEditAddressModal(
                                                                        !openEditAddressModal,
                                                                    );
                                                                }}>
                                                                {t(
                                                                    'cart.shipping.modal.editAddress',
                                                                )}
                                                            </StyledText>
                                                        }
                                                    />
                                                </Flex>
                                            </Label>
                                        )}
                                    </Field>
                                    {/* ====== Edit Address Modal ⤵ ====== ⤵ */}
                                    {openEditAddressModal && clickedAddressIndex === i && (
                                        <CustomPopup
                                            open={openEditAddressModal}
                                            closeOnDocumentClick={false}>
                                            <EditAddressModal
                                                setFieldValue={setFieldValue}
                                                address={address}
                                                updateAddress={updateAddress}
                                                close={closeEditAddressModal}
                                                setErrors={setErrors}
                                                errors={errors}
                                                country={countryCode}
                                                shippingOrBilling={shippingOrBilling}
                                            />
                                        </CustomPopup>
                                    )}
                                    {/* ⤴ ====== ⤴ Edit Address Modal ====== */}
                                </Flex>
                            ),
                    )}
                    <Flex paddingTop={'medium'}>{/* Add section start */}</Flex>
                    <Box alignItems={'center'} display={'flex'} justifyContent={'flex-start'}>
                        <Image
                            src={plusBlueIcon}
                            alt='add'
                            display={'flex'}
                            marginRight={'xSmall'}
                            onClick={() => setOpenAddAddressModal(!openAddAddressModal)}
                        />
                        <StyledText
                            marginLeft={'medium'}
                            data-test-shipping-form-add-address
                            onClick={() => setOpenAddAddressModal(!openAddAddressModal)}>
                            {t('cart.shipping.modal.addNewAddress')}
                        </StyledText>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default BillingOrShippingAddressForm;
