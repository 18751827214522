import React from 'react';
import { useModal, Box, Button, Text } from '@resideo/blueprint-react';
import ChangePaymentMethodModal from './ChangePaymentMethodModal';
import { TableHeader } from 'components/common/Styles';
import { useTranslation } from 'react-i18next';
import { Title } from 'components/common/Title';
import { convertMoneyAmount } from 'routes/ServicesAndSubscriptions/Cart';
import styled from 'styled-components';

const ChangePaymentMethodButton = styled(Button)`
    text-align: right;
    width: 100%;
    color: ${({ theme }) => theme.colors.primary};
    :hover {
        background: none;
        border: 0;
        color: ${({ theme }) => theme.colors.primary};
    }
    :focus {
        box-shadow: none;
    }
`;

const PaymentInformation = ({
    enrollment,
    paymentSources,
    billingId,
    inactive,
    handleEnrollmentUpdate,
    setLoading,
    viewer,
}) => {
    const { Modal, openModal, closeModal } = useModal();
    const { t } = useTranslation();

    const showLast4Digits = (source) => {        
        if (source.type === 'CREDIT_CARD') {
            switch (source.creditCard.brand) {
                case 'AMERICAN_EXPRESS':
                    return `American Express •••• ${source.creditCard.lastFourDigits}`;
                case 'DISCOVER':
                    return `Discover •••• ${source.creditCard.lastFourDigits}`;
                case 'MASTERCARD':
                    return `Mastercard •••• ${source.creditCard.lastFourDigits}`;
                case 'VISA':
                    return `Visa •••• ${source.creditCard.lastFourDigits}`;
                case 'AMERICANEXPRESS':
                    return `American Express •••• ${source.creditCard.lastFourDigits}`;
            }
        } else {
            return source.type === 'payPalBilling' ? 'PayPal' : '';
        }
    };

    return (
        <Box paddingTop='large'>
            <ChangePaymentMethodModal
                Modal={Modal}
                paymentSources={paymentSources}
                currentSourceId={enrollment?.paymentSource?.paymentSourceId}
                billingId={billingId}
                enrollment={enrollment}
                handleEnrollmentUpdate={handleEnrollmentUpdate}
                closeModal={closeModal}
                setLoading={setLoading}
                viewer={viewer}
            />
            {enrollment?.paymentSource?.type && (
                <>
                    <Title data-test-payment-info-title>
                        {t('manageSubscriptions.payment.title')}
                    </Title>
                    <div className='hscroll'>
                        <table className='table-responsive'>
                            <thead>
                                <tr>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.payment.table.1')}
                                    </TableHeader>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.payment.table.2')}
                                    </TableHeader>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.payment.table.3')}
                                    </TableHeader>
                                    <TableHeader style={{ fontSize: '0.875rem' }}>
                                        {t('manageSubscriptions.payment.table.4')}
                                    </TableHeader>
                                    <TableHeader></TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='tr-border-bottom'>
                                    <td>
                                        <Text data-test-payment-info-payment-plan fontSize='medium'>
                                            {convertMoneyAmount(
                                                {
                                                    amount: enrollment?.estimatedBillPriceCodeValue ?? 0,
                                                    currencyCode: enrollment.currencyCode ?? 'USD',
                                                },
                                                'en-us',
                                            )}{' '}
                                            {enrollment?.estimatedBillPriceCode ?? t('manageSubscriptions.payment.perHouseHold')}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text data-test-payment-info-qty fontSize='medium'>
                                            {enrollment.billableQuantity ?? 0}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text data-test-payment-info-monthly-bill fontSize='medium'>
                                            {convertMoneyAmount(
                                                {
                                                    amount: (enrollment.amount ?? 0),
                                                    currencyCode: enrollment.currencyCode ?? 'USD',
                                                },
                                                'en-us',
                                            )}{' '}
                                            <Text color='#767676' as='span'>
                                                {t('manageSubscriptions.payment.tax')}
                                            </Text>
                                        </Text>
                                    </td>
                                    <td>
                                        <Text data-test-payment-info-method fontSize='medium'>
                                            {enrollment.paymentSource ? (
                                                showLast4Digits(enrollment.paymentSource)
                                            ) : (
                                                <>&nbsp;</>
                                            )}
                                        </Text>
                                    </td>
                                    <td>
                                        {true ? (
                                            <ChangePaymentMethodButton
                                                paddingX='0'
                                                style={{ border: 0 }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    openModal();
                                                }}>
                                                {t('manageSubscriptions.changePayment')}
                                            </ChangePaymentMethodButton>
                                        ) : (
                                            <>&nbsp;</>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </Box>
    );
};

export default PaymentInformation;
