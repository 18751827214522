import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { promoCodeUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    getPromoCodeSuccess,
    getPromoCodeFail,
    createPromoCodeSuccess,
    createPromoCodeFail,
    deletePromoCodeSuccess,
    deletePromoCodeFail,
} from './action';
import { retrieveCartID } from 'redux/cart';

function* getCartDetails(): any {
    let cartID: string = '';

    try {
        cartID = yield call(retrieveCartID);
        return { cartID };
    } catch (error) {
        console.log('getCartDetails', error);
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* getPromoCodeDetails(): any {
    const { cartID } = yield* getCartDetails();
    try {
        const url = promoCodeUrl + `current/carts/${cartID}/vouchers`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getPromoCodeSuccess(response));
    } catch (error) {
        yield put(getPromoCodeFail(error));
    }
}

function* createPromoCodeDetails({ payload, resolve, reject }: any): any {
    const { voucherId } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = promoCodeUrl + `current/carts/${cartID}/vouchers?voucherId=${voucherId}`;
        const response = yield call(api, url, httpRequest.POST, {}, 0, 0);
        yield put(createPromoCodeSuccess(response));
        resolve(response);
    } catch (Error: any) {
        yield put(createPromoCodeFail(Error));
        if (Error.data && Error.data.errors) {
            reject(Error.data.errors);
        } else {
            reject(Error);
        }
    }
}

function* deletePromoCodeDetails({ payload, resolve, reject }: any): any {
    const { voucherId } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = promoCodeUrl + `current/carts/${cartID}/vouchers/${voucherId}`;
        const response = yield call(api, url, httpRequest.DELETE, {}, 0, 0);
        yield put(deletePromoCodeSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(deletePromoCodeFail(error));
        reject(error);
    }
}

function* getPromoCodeWatcher() {
    yield takeLatest(types.FETCH_PROMO_CODE, getPromoCodeDetails);
}

function* createPromoCodeWatcher() {
    yield takeLatest(types.CREATE_PROMO_CODE, createPromoCodeDetails);
}

function* deletePromoCodeWatcher() {
    yield takeLatest(types.DELETE_PROMO_CODE, deletePromoCodeDetails);
}

export default function* promoCodeSaga() {
    yield all([getPromoCodeWatcher(), createPromoCodeWatcher(), deletePromoCodeWatcher()]);
}
