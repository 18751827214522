export const b2bUserRegistrationPayload = (
    email: string,
    firstName: string,
    lastName: string,
    gUid: string,
    phoneNumber: string,
    addresses: Array<any>,
    proPartnerAccountId?: string,
    status?: string,
    name?: string,
) => ({
    uid: email,
    firstName: firstName,
    lastName: lastName,
    gqlUid: gUid,
    proPortalRole: 'resideo_member_group', // Double check if this value should be dynamic
    language: 'en',
    address: addresses[0],
    b2bUnit: {
        name: name,
        proPartnerAccountId: proPartnerAccountId,
        proPartnerAccountStatus: status,
        market: ['NA_001'],
        addresses: [...addresses],
    },
});
