import { types } from './types';

export const getCategory = () => ({
    type: types.FETCH_CATEGORY
})

export const getCategorySuccess = (payload: any) => ({
    type: types.FETCH_CATEGORY_SUCCESS,
    payload,
})

export const getCategoryFail = (payload: any) => ({
    type: types.FETCH_CATEGORY_FAIL,
    payload,
})