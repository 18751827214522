import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Switch } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppLayout from 'components/layouts/AppLayout';
import './i18n';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

window.React = React
