import React, { FC } from 'react';
import { Text, Image, Link } from '@resideo/blueprint-react';
import styled from 'styled-components';
import backArrow from '../../assets/images/back-arrow.png';
const StyledText = styled(Text)<{ isManageSub?: boolean }>`
  text-transform: uppercase;
  margin-left: ${({ theme }) => theme.space.small};
  font-size: ${({ theme, isManageSub }) =>
    isManageSub ? theme.fontSizes.small : theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme, isManageSub }) =>
    isManageSub ? theme.fontWeights.bold : theme.fontWeights.medium};
  cursor: pointer;
  font-family: 'Roboto';
`;

const StyledLink = styled(Link)`
  @media (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    width: 100%;
    justify-content: center;
  }
`;
interface GoForwardButtonProps {
  url: string;
  text: string;
  isManageSub?: boolean;
}

const GoForwardButton: FC<GoForwardButtonProps> = ({
  url,
  text,
  isManageSub = false,
}) => (
  <StyledLink
    data-test-go-back-btn
    href={url}
    //onClick={() => window.location.reload()}
    className='forward-arrow-animation'>
    <StyledText isManageSub={isManageSub}>{text}</StyledText>
    <Image src={backArrow} alt='forwardArrow' />
  </StyledLink>
);

export default GoForwardButton;
