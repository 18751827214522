import React from 'react';
import styled, { keyframes } from 'styled-components';

const NavContainer = styled.nav`
    display: block;
    @media (max-width: 992px) {
        display: none;
    }
`;

const OrderList = styled.ol`
    margin-bottom: 32px;
    background: transparent;
    padding: 64px 0 0 0;
    flex-wrap: wrap;
    display: flex;
    list-style: none;
    border-radius: 4px;
    margin-top: 0px;
`;

const LineItem = styled.li`
    font-size: 11px;
    color: #084a68;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-top: 6px;
    display: inline-block;
    z-index: 1;
    &.active {
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    & + li:before {
        content: '/ ';
        padding: 0 5px;
    }
    &:before {
        color: #183779;
        padding: 0 8px;
    }
    &:last-child {
        color: #000;
    }
`;

const Breadcrumbs = () => {
    return (
        <NavContainer className='container section-center'>
            <OrderList>
                <LineItem>PRO</LineItem>
                <LineItem>PRODUCTS</LineItem>
                <LineItem>AIR</LineItem>
            </OrderList>
        </NavContainer>
    );
};

export default Breadcrumbs;
