import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import useMedia from 'hooks/useMedia';
import { Flex } from '@resideo/blueprint-react';
import { useLeftNav } from 'context/MobileLeftNav';
import { useTranslation } from 'react-i18next';

const SecondaryNav = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    /* to match pro-portal, use this block */
    /* margin-top: 4px; */
    //z-index: var(--zidx-base);
    justify-content: center;
    /* to match pro-portal, use this block */
    /* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1); */
    /* to match app branding desing, use this block */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    background: #ffffff;
    > ul {
        list-style: none;
        /* to match pro-portal, use this block */
        /* display: flex; */
        /* margin: 0; */
        /* to match app branding desing, use this block */
        margin: 0 0 0 5px;
        padding: 12px 0;
    }
`;

const ListMenuItem = styled(NavLink)`
    display: inline;
    font-weight: 500;
    text-decoration: none;
    /* to match pro-portal, use this block */
    padding: ${({ theme }) => theme.space.medium} 0;
    margin: ${({ theme }) => `0 ${theme.space.large}`};
    /* to match app branding desing, use this block */
    padding: 12px 0;
    margin: 20px;
    transition: border ease-in-out 0.3s;
    border-bottom: 2px solid transparent;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
        border-bottom: 2px solid;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        &:not(&:last-of-type) {
            box-shadow: 0px 1px #00000017;
        }
        display: flex;
        white-space: pre;
        margin: 0 1.25rem;
    }
`;

const ExternalListMenuItem = styled.li<{ isActive?: boolean }>`
    display: inline;
    font-weight: 500;
    /* to match pro-portal, use this block */
    /* padding-top: ${({ theme }) => theme.space.medium}; */
    /* padding-bottom: ${({ theme }) => theme.space.medium}; */
    /* margin: ${({ theme }) => `0 ${theme.space.large}`}; */
    /* to match app branding desing, use this block */
    margin: 20px;
    transition: border ease-in-out 0.3s;
    border-bottom: ${({ isActive }) => (isActive ? '2px solid' : '2px solid transparent')};
    > a {
        color: ${({ isActive, theme }) => (isActive ? 'black' : theme.colors.primary)};
        text-decoration: none;
    }
    &:hover {
        border-bottom: 2px solid;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        &:not(&:last-of-type) {
            box-shadow: 0px 1px #00000017;
        }
        margin-right: 4rem;
    }
`;

const SecondaryNavMobile = styled(Flex)`
    flex-direction: column;
    justify-content: flex-start;
    flex-direction: row;
    max-width: 100vw;
    overflow-x: auto;
    button,
    button:hover,
    button:focus {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.primary};
        border-color: transparent;
        display: flex;
        align-self: center;
        box-shadow: none;
        outline: none;
    }
    @media (max-width: 475px) {
        padding-top: 2rem;
    }
`;

const Container = styled.div`
    /* display: flex; */
    margin-right: auto;
    margin-left: auto;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    max-width: 1200px;
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
    @media (min-width: 1400px) {
        width: 1375px;
    }
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const SecondaryNavigation = ({ menuItems }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const mobileMatch = useMedia('(max-width: 62rem)');

    const { isOpen, setIsOpen } = useLeftNav();

    const toggleLeftNav = () => {
        setIsOpen(!isOpen);
    };

    const toggleMenu = (evt: React.MouseEvent, path: string) => {
        const routeBase = '/mybusiness/';
        const currentLocation = location.pathname;

        const locationsWithLeftNav: string[] = [
            /*'company'*/
        ];

        if (
            locationsWithLeftNav.includes(path) &&
            currentLocation.startsWith(`${routeBase}${path}`)
        ) {
            evt.preventDefault();
            toggleLeftNav();
        }
    };

    const menuOptions = menuItems?.map?.(({ name, path, textKey, external }) => {
        if (!path || !textKey) return null;
        return external ? (
            <ExternalListMenuItem key={name} isActive={location.pathname.endsWith(path)}>
                <a href={path}>{t(textKey)} </a>
            </ExternalListMenuItem>
        ) : (
            <Container className='section-center' key={name}>
                <ListMenuItem
                    onClick={(evt) => toggleMenu(evt, path)}
                    key={name}
                    to={path}
                    activeStyle={{ borderBottom: '2px solid', color: 'black' }}>
                    {t(textKey)}
                </ListMenuItem>
            </Container>
        );
    });

    if (!menuOptions) return null;
    return mobileMatch ? (
        <SecondaryNavMobile>{menuOptions}</SecondaryNavMobile>
    ) : (
        <SecondaryNav className='section-center'>
            <ul>{menuOptions}</ul>
        </SecondaryNav>
    );
};

export default SecondaryNavigation;
