import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Link as BlueprintLink } from '@resideo/blueprint-react';
import { useAuth } from 'utils/auth';
import CustomNavLink from './CustomNavLink';
import ExternalNavLink from './ExternalNavLink';

interface HeaderNavLinkProps {
  linkPath: string;
  name: string;
  linkTextKey: string;
  isExternalLink?: boolean;
  isActive?: boolean;
  isDirect?: boolean;
}

const StyledHeaderNavLink = styled(({ isExternalLink, isDirect, ...props }) =>
  isExternalLink ? <BlueprintLink {...props} /> : isDirect ? <ExternalNavLink {...props} /> : <CustomNavLink {...props} />
)`
  font-weight: bold;
	transition: all .3s ease;
	font-size: 14px;
	letter-spacing: 1px;
  display: inline-block;
  height: 100%;
  letter-spacing: 0.35px;
  line-height: 3.75rem;
  //font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0 ${({ theme }) => theme.space.small};
  text-decoration: none;
  text-transform: uppercase;
  // Note: this was changed from primaryLinkActive to primaryLink
  // In the subs repo, there is a call where the link status gets changed.
  // This not existing was causing our perks header element to be the wrong color.
  color: ${({ theme, isExternalLink, isActive }) =>
    isExternalLink && isActive
      ? theme.colors.header.primaryLink
      : theme.colors.header.primaryLink};
  transition: border-color 0.5s ease-out;
  &.svg {
    position: relative;
    top: 6px;
    fill: ${({ theme }) => theme.colors.header.primaryLink};
  }

  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.header.primaryLinkActive};
    svg {
      fill: ${({ theme }) => theme.colors.header.primaryLinkActive};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xLarge}) {
    margin: 0 ${({ theme }) => theme.space.medium};
  }
  @media(min-width: 992px)and (max-width: 1099px) {
    font-size: 12px;
  }
`;

const HeaderNavLink = ({
  linkPath,
  linkTextKey,
  isExternalLink = false,
  isActive,
  isDirect,
}: HeaderNavLinkProps): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  return isExternalLink ? (
    <StyledHeaderNavLink
      href={linkPath}
      isExternalLink={isExternalLink}
      isAuth={`${isAuthenticated}`}
      path={pathname}
      isActive={`${isActive}`}
      isDirect={isDirect}>
      {t(linkTextKey)}
    </StyledHeaderNavLink>
  ) : (
    <StyledHeaderNavLink
      to={linkPath}
      isExternalLink={isExternalLink}
      isauth={`${isAuthenticated}`}
      path={pathname}
      isactive={`${isActive}`}
      isDirect={isDirect}>
      {t(linkTextKey)}
    </StyledHeaderNavLink>
  );
};

export default HeaderNavLink;
