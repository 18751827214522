import produce from 'immer';
import { subscriptionDetailsUtils } from 'utils/subscriptionDetailsUtils';
import { parseSubscriptionDetails } from 'utils/subscriptionUtils';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchingSubscriptionSuccess: false,
    fetchingSubscriptionList: [],
    fetchingSubscriptionError: null,
    getSubscriptionDetailSuccess: false,
    getSubscriptionDetails: [],
    getSubscriptionDetailFail: null,
    cancelSubscriptionServiceSuccess: false,
    cancelSubscriptionService: [],
    cancelSubscriptionServiceFail: null,
    renewalSubscriptionServiceSuccess: false,
    renewalSubscriptionService: [],
    renewalSubscriptionServiceFail: null,
    getOrdersByOrderNumberSuccess: false,
    getOrdersByOrderNumber: [],
    getOrdersByOrderNumberFail: null,
    getCompleteSubscriptionBillSuccess: false,
    getCompleteSubscriptionBill: [],
    getCompleteSubscriptionBillFail: null,
    getSubscriptionBillingDetailsSuccess: false,
    getSubscriptionBillingDetails: [],
    getSubscriptionBillingDetailsError: null,
    createDRSubscriptionPaymentSuccess: false,
    createDRSubscriptionPayment: [],
    createDRSubscriptionPaymentFail: null,
    refreshSubscriptionDetailSuccess: false,
    refreshSubscriptionDetails: [],
    refreshSubscriptionDetailFail: null,
    availableSubscriptionDetailSuccess: false,
    availableSubscription: [],
    availableSubscriptionDetailFail: null
};

const subscriptionListData = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_SUBSCRIPTION_LIST:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingSubscriptionSuccess = true;
            });
        case types.FETCH_SUBSCRIPTION_LIST_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingSubscriptionSuccess = false;
                //draftState.fetchingSubscriptionList = payload;
                const subscriptionData: any = parseSubscriptionDetails(payload);
                draftState.fetchingSubscriptionList = subscriptionData;
            });
        case types.FETCH_SUBSCRIPTION_LIST_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingSubscriptionSuccess = false;
                draftState.fetchingSubscriptionError = payload;
            });
        case types.GET_SUBSCRIPTION_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getSubscriptionDetailSuccess = true;
            });
        case types.GET_SUBSCRIPTION_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getSubscriptionDetailSuccess = false;
                //draftState.getSubscriptionDetails = payload;
                const subscriptionDetailsData: any = subscriptionDetailsUtils(payload);
                draftState.getSubscriptionDetails = subscriptionDetailsData;
            });
        case types.GET_SUBSCRIPTION_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getSubscriptionDetailSuccess = false;
                draftState.getSubscriptionDetailFail = payload;
            });
        case types.CANCEL_SUBSCRIPTION_SERVICE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.cancelSubscriptionServiceSuccess = true;
            });
        case types.CANCEL_SUBSCRIPTION_SERVICE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.cancelSubscriptionServiceSuccess = false;
                draftState.cancelSubscriptionService = payload;
            });
        case types.CANCEL_SUBSCRIPTION_SERVICE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.cancelSubscriptionServiceSuccess = false;
                draftState.cancelSubscriptionServiceFail = payload;
            });
        case types.RENEWAL_SUBSCRIPTION_SERVICE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.renewalSubscriptionServiceSuccess = true;
            });
        case types.RENEWAL_SUBSCRIPTION_SERVICE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.renewalSubscriptionServiceSuccess = false;
                draftState.renewalSubscriptionService = payload;
            });
        case types.RENEWAL_SUBSCRIPTION_SERVICE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.renewalSubscriptionServiceSuccess = false;
                draftState.renewalSubscriptionServiceFail = payload;
            });
        case types.GET_ORDERS_BY_ORDER_NUMBER:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getOrdersByOrderNumberSuccess = true;
            });
        case types.GET_ORDERS_BY_ORDER_NUMBER_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getOrdersByOrderNumberSuccess = false;
                draftState.getOrdersByOrderNumber = payload;
            });
        case types.GET_ORDERS_BY_ORDER_NUMBER_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getOrdersByOrderNumberSuccess = false;
                draftState.getOrdersByOrderNumberFail = payload;
            });
        case types.GET_COMPLETE_SUBSCRIPTION_BILL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getCompleteSubscriptionBillSuccess = true;
            });
        case types.GET_COMPLETE_SUBSCRIPTION_BILL_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getCompleteSubscriptionBillSuccess = false;
                draftState.getCompleteSubscriptionBill = payload;
            });
        case types.GET_COMPLETE_SUBSCRIPTION_BILL_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getCompleteSubscriptionBillSuccess = false;
                draftState.getCompleteSubscriptionBillFail = payload;
            });
        case types.GET_SUBSCRIPTION_BILLING_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getSubscriptionBillingDetailsSuccess = true;
            });
        case types.GET_SUBSCRIPTION_BILLING_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getSubscriptionBillingDetailsSuccess = false;
                draftState.getSubscriptionBillingDetails = payload;
            });
        case types.GET_SUBSCRIPTION_BILLING_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.getSubscriptionBillingDetailsSuccess = false;
                draftState.getSubscriptionBillingDetailsError = payload;
            });
        case types.CREATE_DR_SUBSCRIPTION_PAYMENT:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createDRSubscriptionPaymentSuccess = true;
            });
        case types.CREATE_DR_SUBSCRIPTION_PAYMENT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createDRSubscriptionPaymentSuccess = false;
                draftState.createDRSubscriptionPayment = payload;
            });
        case types.CREATE_DR_SUBSCRIPTION_PAYMENT_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createDRSubscriptionPaymentSuccess = false;
                draftState.createDRSubscriptionPaymentFail = payload;
            });
        case types.REFRESH_SUBSCRIPTION_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.refreshSubscriptionDetailSuccess = true;
            });
        case types.REFRESH_SUBSCRIPTION_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.refreshSubscriptionDetailSuccess = false;
                const subscriptionDetailsData: any = subscriptionDetailsUtils(payload);
                draftState.getSubscriptionDetails = subscriptionDetailsData;
            });
        case types.REFRESH_SUBSCRIPTION_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.refreshSubscriptionDetailSuccess = false;
                draftState.refreshSubscriptionDetailFail = payload;
            });
         case types.AVAILABLE_SUBSCRIPTION_DETAILS:
                return produce(state, (draftState: typeof initialState) => {
                    draftState.availableSubscriptionDetailSuccess = true;
                });
           case types.AVAILABLE_SUBSCRIPTION_DETAILS_SUCCESS:
                    return produce(state, (draftState: typeof initialState) => {
                        draftState.availableSubscriptionDetailSuccess = false;
                        draftState.availableSubscription = payload;
                    });
            case types.AVAILABLE_SUBSCRIPTION_DETAILS_FAIL:
                return produce(state, (draftState: typeof initialState) => {
                    draftState.availableSubscriptionDetailSuccess = false;
                    draftState.refreshSubscriptionDetailFail = payload;
                });
        default:
            return state;
    }
};

export { subscriptionListData };
