import { useEffect } from 'react';

const useScript = (
  url: string,
  useAsync: boolean,
  crossOrigin?: boolean,
  changeVariable?: string | boolean | number // changeVariable will be used to load script again on change of any variables in UI
) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = useAsync;

    if (crossOrigin) {
      script.crossOrigin = '';
    }

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, changeVariable]);
};
export default useScript;
