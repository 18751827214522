import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@resideo/blueprint-react';
import { Form, Formik } from 'formik';
import { CloseRoundImg, greenTicLargeIcon } from '../../../assets/images';
import { InputField } from '../../../components/common/InputField';
import SeparatorLine from '../../../components/common/SeparatorLine';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseBtn, ModalHeader } from '../shipping/AddAddressModal';
import styled from 'styled-components';

const Inputs = styled(Flex)`
  align-items: center;
  width: 100%;
  position: relative;
  .trash {
    display: none;
    position: absolute;
    top: 35px;
    right: 10px;
    cursor: pointer;
  }
  :hover {
    .trash {
      display: block;
    }
  }
  label {
    width: 100%;
  }
`;

const RecaliberationInstructionsModal = ({
  close,
  hasSubmittedDevice,
  setHasSubmittedDevice,
}) => {
  const { t } = useTranslation();
  const [inputsToDisplay, setInputsToDisplay] = useState<number[]>([]) as any;
  const [count, setCount] = useState<number>(1);
  const [multipleDevices, setMultipleDevices] = useState({
    deviceId1: '',
    deviceId2: '',
    deviceId3: '',
    deviceId4: '',
    deviceId5: '',
    deviceId6: '',
    deviceId7: '',
    deviceId8: '',
    deviceId9: '',
    deviceId10: '',
  });

  const handleSubmit = values => {
    console.log('Submitting device Ids: ', values);
  };

  const addAnotherDevice = () => {
    setCount(count => (count += 1));
    setInputsToDisplay(inputsToDisplay => [...inputsToDisplay, count]);
    setMultipleDevices({ ...multipleDevices, [`deviceId${count}`]: '' });
  };

  const removeInput = i => {
    console.log('i: ', i);
    console.log(
      'd: ',
      inputsToDisplay.filter((num, d) => d + 1 !== i)
    );

    setInputsToDisplay(inputsToDisplay.filter((num, d) => d + 1 !== i));
  };

  const reset = () =>
    setMultipleDevices({
      deviceId1: '',
      deviceId2: '',
      deviceId3: '',
      deviceId4: '',
      deviceId5: '',
      deviceId6: '',
      deviceId7: '',
      deviceId8: '',
      deviceId9: '',
      deviceId10: '',
    });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ deviceId0: '', ...multipleDevices }}>
      {() => (
        <Form>
          <ModalHeader>
            <Text className='add'>Sensor Refresh Instructions</Text>
            <Box onClick={() => reset()}>
              <CloseBtn close={close} setErrors={() => ({})} />
            </Box>
          </ModalHeader>
          <Box paddingY='large' paddingX='xLarge'>
            {hasSubmittedDevice ? (
              <>
                <Box textAlign='center' paddingTop='xLarge'>
                  <Image src={greenTicLargeIcon} alt='success' width='5rem' />
                  <Heading
                    fontSize='large'
                    fontWeight='900'
                    textAlign='center'
                    paddingTop='small'>
                    {t('successExclamationMark')}
                  </Heading>
                  <Box display='flex' justifyContent='center' marginX='xSmall'>
                    <Text
                      marginX='xLarge'
                      fontWeight='400'
                      color='black'
                      maxWidth='30rem'
                      paddingTop='medium'
                      marginBottom='large'
                      textAlign='center'>
                      {t('refreshMonitor.submittedDeviceText')}
                    </Text>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Text maxWidth='30rem' fontSize='1.1rem' lineHeight='2rem'>
                  {t('refreshMonitor.instructionInformationText')}
                </Text>
                <Heading paddingTop='large' marginBottom='small'>
                  {t('refreshMonitor.provideDeviceIdText')}
                </Heading>
                <InputField label='Device ID' name='deviceId0' />
                {inputsToDisplay.map((num, i) => (
                  <Inputs key={i} className='input'>
                    <i
                      onClick={() => removeInput(i + 1)}
                      className='fas fa-trash trash'></i>
                    <InputField label='Device ID' name={`deviceId${i + 1}`} />
                  </Inputs>
                ))}
                <Flex
                  alignItems='center'
                  paddingY='medium'
                  onClick={() => addAnotherDevice()}
                  style={{ cursor: 'pointer' }}
                  width='31%'>
                  <Image src={CloseRoundImg} alt='cart' marginRight='xSmall' />
                  <Text fontWeight='bold' fontSize='medium' color='primary'>
                    {t('refreshMonitor.addAnotherDevice')}
                  </Text>
                </Flex>
              </>
            )}
            <SeparatorLine />
            <Flex width='100%' justifyContent='flex-end'>
              <Button
                variant='primary'
                type='submit'
                onClick={() => {
                  if (hasSubmittedDevice) {
                    close();
                    setHasSubmittedDevice(false);
                  } else {
                    setHasSubmittedDevice(true);
                  }
                }}>
                {hasSubmittedDevice ? 'Continue' : 'Submit'}
              </Button>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default RecaliberationInstructionsModal;
