import { types } from './types';

type CreatePromoCodeProps = {
    voucherId: string;
};

type DeletePromoCodeProps = {
    voucherId: string;
};

export const getPromoCode = () => ({
    type: types.FETCH_PROMO_CODE,
});

export const getPromoCodeSuccess = (payload: any) => ({
    type: types.FETCH_PROMO_CODE_SUCCESS,
    payload,
});

export const getPromoCodeFail = (payload: any) => ({
    type: types.FETCH_PROMO_CODE_FAIL,
    payload,
});

export const createPromoCode = (payload?: CreatePromoCodeProps) => ({
    type: types.CREATE_PROMO_CODE,
    payload,
});

export const createPromoCodeSuccess = (payload: any) => ({
    type: types.CREATE_PROMO_CODE_SUCCESS,
    payload,
});

export const createPromoCodeFail = (payload: any) => ({
    type: types.CREATE_PROMO_CODE_FAIL,
    payload,
});

export const deletePromoCode = (payload?: DeletePromoCodeProps) => ({
    type: types.DELETE_PROMO_CODE,
    payload,
});

export const deletePromoCodeSuccess = (payload: any) => ({
    type: types.DELETE_PROMO_CODE_SUCCESS,
    payload,
});

export const deletePromoCodeFail = (payload: any) => ({
    type: types.DELETE_PROMO_CODE_FAIL,
    payload,
});
