import { useState } from "react";
import {
    ItemHeader,
} from "./ItemsHeader";
import { ListItem } from '../../routes/Products/PLP/styles';
import { useDispatch } from "react-redux";
import { getProductList } from '../../redux/productlist/actions';

type Subcategories = {
    id: string;
    name: string;
    subcategories: Array<Subcategories>;
}
interface IProps {
    id?: string;
    name?: string;
    subcategories?: Array<Subcategories>;
    onCategoryClick?: (args: any) => void;
    isSelectedCategoryID: any;
}

export const Item = ({ id, name, subcategories, onCategoryClick, isSelectedCategoryID }: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSelectedSubCategoryID, setIsSelectedSubCategoryID] = useState<string | undefined | null>('');
    const dispatch = useDispatch();

    const getProducts = async (id) => {
        setIsSelectedSubCategoryID(id);
        await dispatch(getProductList({ id, pageNumber: '0' }));
    };

    return (
        <ListItem>
            <div className="item">
                <div className="item-content">
                    <ItemHeader
                        id={id}
                        title={name}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        onCategoryClick={onCategoryClick}
                        isSelectedCategoryID={isSelectedCategoryID}
                    />
                </div>

                <div>
                    {subcategories && subcategories.length > 0 && subcategories.map((item, i) =>
                        <ul key={i}>
                            {item.subcategories && isOpen && <Item id={item.id} subcategories={item.subcategories} name={item.name} key={i} onCategoryClick={(id) => getProducts(id)} isSelectedCategoryID={isSelectedSubCategoryID} />}
                        </ul>
                    )}
                </div>
            </div>
        </ListItem>
    )
};
