import { Box, Flex, Label, Text } from '@resideo/blueprint-react';
import { InputField } from '../../../components/common/InputField';
import { getFullCountryByCode } from '../../../config/countries';
import { PROVINCES } from '../../../config/provinces';
import { STATES } from '../../../config/states';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BillingAddress = ({ showForm, card, values }) => {
  const { t } = useTranslation();

  return (
    <>
      {showForm && card.type === 'billingAddress' ? (
        <>
          <Box>
            <InputField
              name='billingAddress.contactName'
              label='Contact Name'
              value={values.billingAddress.contactName || ''}
            />
          </Box>
          <Flex flex={1}>
            <Box width={1 / 2} marginRight='small'>
              <InputField
                name='billingAddress.addressLine1'
                label='Address Line 1'
                value={values.billingAddress.addressLine1 || ''}
              />
            </Box>
            <Box width={1 / 2}>
              <InputField
                name='billingAddress.addressLine2'
                label='Address Line 2'
                value={values.billingAddress.addressLine2 || ''}
              />
            </Box>
          </Flex>
          <Box>
            <InputField
              name='billingAddress.city'
              label='City'
              value={values.billingAddress.city || ''}
            />
          </Box>
          <Flex>
            <Box marginRight='small' width='100%'>
              <Label
                htmlFor='billingAddress.stateProvinceRegionCode'
                fontSize='small'
                color='#777'>
                State
              </Label>
              <Field
                style={{
                  padding: '0.75rem 0.25rem',
                  borderRadius: '15px',
                  width: '100%',
                  fontSize: '1rem',
                  color: '#222529',
                }}
                as='select'
                name='billingAddress.stateProvinceRegionCode'>
                {values.billingAddress.countryCode === 'CA'
                  ? PROVINCES.map((obj, i) => (
                      <option key={i} value={obj.value}>
                        {obj.value}
                      </option>
                    ))
                  : STATES.map((obj, i) => (
                      <option
                        disabled={obj.value === 'Select a State'}
                        key={i}
                        value={obj.value}>
                        {obj.value}
                      </option>
                    ))}
              </Field>
            </Box>
            <Box marginRight='small' width='100%'>
              <Label
                htmlFor='billingAddress.stateProvinceRegionCode'
                fontSize='small'
                color='#777'>
                Country
              </Label>
              <Field
                style={{
                  padding: '0.75rem 0.25rem',
                  borderRadius: '15px',
                  width: '100%',
                  fontSize: '1rem',
                  color: '#222529',
                }}
                as='select'
                name='billingAddress.countryCode'>
                <option value='US'>United States</option>
                <option value='CA'>Canada</option>
              </Field>
            </Box>
            <Box width='100%'>
              <InputField
                name='billingAddress.zipPostalCode'
                label='Zip code'
                value={values.billingAddress.zipPostalCode || ''}
              />
            </Box>
          </Flex>
        </>
      ) : (
        <>
          <Text color='textHeading'>
            {card.billingCompanyName && card.billingCompanyName}
          </Text>
          <Text color='textHeading'>{card.billingContactName}</Text>
          <Text color='textHeading'>{card.billingAddressLine1}</Text>
          <Text color='textHeading'>
            {card.billingAddressLine2 && card.billingAddressLine2}
          </Text>
          <Text color='textHeading'>
            {card.billingCity && `${card.billingCity},`}{' '}
            {card.billingStateProvinceRegionCode} {card.billingZipPostalCode}
          </Text>
          <Text color='textHeading'>
            {t(getFullCountryByCode(card.billingCountryCode))}
          </Text>
        </>
      )}
    </>
  );
};

export default BillingAddress;
