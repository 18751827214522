export const types = {
    FETCH_ADDRESS_DETAILS: 'FETCH_ADDRESS_DETAILS',
    FETCH_ADDRESS_DETAILS_SUCCESS: 'FETCH_ADDRESS_DETAILS_SUCCESS',
    FETCH_ADDRESS_DETAILS_FAIL: 'FETCH_ADDRESS_DETAILS_FAIL',
    ADD_DELIVERY_ADDRESS: 'ADD_DELIVERY_ADDRESS',
    ADD_DELIVERY_ADDRESS_SUCCESS: 'ADD_DELIVERY_ADDRESS_SUCCESS',
    ADD_DELIVERY_ADDRESS_FAIL: 'ADD_DELIVERY_ADDRESS_FAIL',
    UPDATE_DELIVERY_ADDRESS: 'UPDATE_DELIVERY_ADDRESS',
    UPDATE_DELIVERY_ADDRESS_SUCCESS: 'UPDATE_DELIVERY_ADDRESS_SUCCESS',
    UPDATE_DELIVERY_ADDRESS_FAIL: 'UPDATE_DELIVERY_ADDRESS_FAIL',
    FETCH_DELIVERY_MODES: 'FETCH_DELIVERY_MODES',
    FETCH_DELIVERY_MODES_SUCCESS: 'FETCH_DELIVERY_MODES_SUCCESS',
    FETCH_DELIVERY_MODES_FAIL: 'FETCH_DELIVERY_MODES_FAIL',
    UPDATE_DELIVERY_MODES: 'UPDATE_DELIVERY_MODES',
    UPDATE_DELIVERY_MODES_SUCCESS: 'UPDATE_DELIVERY_MODES_SUCCESS',
    UPDATE_DELIVERY_MODES_FAIL: 'UPDATE_DELIVERY_MODES_FAIL',
    SELECT_DELIVERY_ADDRESS: 'SELECT_DELIVERY_ADDRESS',
    SELECT_DELIVERY_ADDRESS_SUCCESS: 'SELECT_DELIVERY_ADDRESS_SUCCESS',
    SELECT_DELIVERY_ADDRESS_FAIL: 'SELECT_DELIVERY_ADDRESS_FAIL',
    IS_LOADING: 'IS_LOADING',
    FETCH_CREDIT_CARD_DETAILS: 'FETCH_CREDIT_CARD_DETAILS',
    FETCH_CREDIT_CARD_DETAILS_SUCCESS: 'FETCH_CREDIT_CARD_DETAILS_SUCCESS',
    FETCH_CREDIT_CARD_DETAILS_FAIL: 'FETCH_CREDIT_CARD_DETAILS_FAIL',
    CREATE_CREDIT_CARD_DETAILS: 'CREATE_CREDIT_CARD_DETAILS',
    CREATE_CREDIT_CARD_DETAILS_SUCCESS: 'CREATE_CREDIT_CARD_DETAILS_SUCCESS',
    CREATE_CREDIT_CARD_DETAILS_FAIL: 'CREATE_CREDIT_CARD_DETAILS_FAIL',
    UPDATE_CREDIT_CARD_DETAILS: 'UPDATE_CREDIT_CARD_DETAILS',
    UPDATE_CREDIT_CARD_DETAILS_SUCCESS: 'UPDATE_CREDIT_CARD_DETAILS_SUCCESS',
    UPDATE_CREDIT_CARD_DETAILS_FAIL: 'UPDATE_CREDIT_CARD_DETAILS_FAIL',
    PLACE_ORDER: 'PLACE_ORDER',
    PLACE_ORDER_SUCCESS: 'PLACE_ORDER_SUCCESS',
    PLACE_ORDER_FAIL: 'PLACE_ORDER_FAIL',
    SET_CART_ITEMS: 'SET_CART_ITEMS',
    UPDATE_SHIPPING_INFO: 'UPDATE_SHIPPING_INFO',
    UPDATE_SHIPPING_INFO_SUCCESS: 'UPDATE_SHIPPING_INFO_SUCCESS',
    UPDATE_SHIPPING_INFO_FAIL: 'UPDATE_SHIPPING_INFO_FAIL',
    REVIEW_ORDER_DETAILS: 'REVIEW_ORDER_DETAILS',
    REVIEW_ORDER_DETAILS_SUCCESS: 'REVIEW_ORDER_DETAILS_SUCCESS',
    REVIEW_ORDER_DETAILS_FAIL: 'REVIEW_ORDER_DETAILS_FAIL',
    FETCH_CONSENTS_LIST: 'FETCH_CONSENTS_LIST',
    FETCH_CONSENTS_LIST_SUCCESS: 'FETCH_CONSENTS_LIST_SUCCESS',
    FETCH_CONSENTS_LIST_FAIL: 'FETCH_CONSENTS_LIST_FAIL',
    CREATE_CONSENTS: 'CREATE_CONSENTS',
    CREATE_CONSENTS_SUCCESS: 'CREATE_CONSENTS_SUCCESS',
    CREATE_CONSENTS_FAIL: 'CREATE_CONSENTS_FAIL',
    DELETE_CONSENTS: 'DELETE_CONSENTS',
    DELETE_CONSENTS_SUCCESS: 'DELETE_CONSENTS_SUCCESS',
    DELETE_CONSENTS_FAIL: 'DELETE_CONSENTS_FAIL',
    CREATE_CONTACT_DETAILS: 'CREATE_CONTACT_DETAILS',
    CREATE_CONTACT_DETAILS_SUCCESS: 'CREATE_CONTACT_DETAILS_SUCCESS',
    CREATE_CONTACT_DETAILS_FAIL: 'CREATE_CONTACT_DETAILS_FAIL',
};
