export interface PrimaryNavigationItem {
    name: string;
    linkTextKey: string;
    linkPath: string;
    isExternalLink?: boolean;
    isDirect: boolean;
    subMenu: any;
}
const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';

const mobilePrimaryNavigation: PrimaryNavigationItem[] = [
    {
        name: 'products',
        linkTextKey: 'header.primary.products',
        linkPath: '/',
        isExternalLink: false,
        isDirect: false,
        subMenu: {
            leftItemList: {
                isPresent: true,
                isType: 'list',
                itemList: [
                    {
                        header: true,
                        title: 'AIR',
                        icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Air-Color.svg?rv=90e347d05cc947f3ae026b1491c4a623',
                        subItems: [
                            {
                                name: 'Thermostats',
                                linkTextKey: 'Thermostats',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Humidifiers',
                                linkTextKey: 'Humidifiers',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Dehumidifiers',
                                linkTextKey: 'Dehumidifiers',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Air Filtration',
                                linkTextKey: 'Air Filtration',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'UV air purifiers',
                                linkTextKey: 'UV air purifiers',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Forced air zone panels',
                                linkTextKey: 'Forced air zone panels',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Ventilation',
                                linkTextKey: 'Ventilation',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Thermostat Accesories',
                                linkTextKey: 'Thermostat Accesories',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Energy star thermostats',
                                linkTextKey: 'Energy star thermostats',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'All air products',
                                linkTextKey: 'All air products',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                        ],
                    },
                    {
                        header: true,
                        title: 'SECURITY',
                        icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Security-Color.svg?rv=1f528e78fd3b4653a8354411c0c0b7c1',
                        subItems: [
                            {
                                name: 'Security systems',
                                linkTextKey: 'Security systems',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Security cameras',
                                linkTextKey: 'Security cameras',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Security accessories',
                                linkTextKey: 'Security accessories',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Doorbells',
                                linkTextKey: 'Doorbells',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'All security products',
                                linkTextKey: 'All security products',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                        ],
                    },
                    {
                        header: true,
                        title: 'WATER',
                        icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Water-Color.svg?rv=c50ea760e5824e6a893fb75353fe69c2',
                        subItems: [
                            {
                                name: 'Stop leak detection',
                                linkTextKey: 'Stop leak detection',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Water accessories',
                                linkTextKey: 'Water accessories',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'All water products',
                                linkTextKey: 'All water products',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                        ],
                    },
                    {
                        header: true,
                        title: 'ENERGY',
                        icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Energy-Color.svg?rv=fbd4b40b60c3433db0194150dbcf0f22',
                        subItems: [
                            {
                                name: 'WiFi thermostats',
                                linkTextKey: 'WiFi thermostats',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Services',
                                linkTextKey: 'Services',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Programmable light switch timers',
                                linkTextKey: 'Programmable light switch timers',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'All energy products',
                                linkTextKey: 'All energy products',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                        ],
                    },
                    {
                        header: true,
                        title: 'APPS & SERVICES',
                        icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-App-Color.svg?rv=66611d793e0b4b79b0e3ca54cf60cda7',
                        subItems: [
                            {
                                name: 'Energy Care Program',
                                linkTextKey: 'Energy Care Program',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Honeywell Home App',
                                linkTextKey: 'Honeywell Home App',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Total Connect Comfort App',
                                linkTextKey: 'Total Connect Comfort App',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Total Connect 2.0 App',
                                linkTextKey: 'Total Connect 2.0 App',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'All apps',
                                linkTextKey: 'All apps',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                        ],
                    },
                ],
            },
            rightItemList: {
                isPresent: true,
                isTodayDeal: true,
                title: 'NEW AND FEATURED PRODUCTS',
                itemList: [
                    {
                        name: 'PROA7PLUS ProSeries 7 inch All-in-One Panel',
                        imageLink:
                            'https://digitalassets.resideo.com/damroot/RDEDesktop/10017/PROA7PLUS_HH-c9-6.jpg?koprop=PrimaryImage&rv=190ac63ac0df464497fadffe9fd3d85a&hash=D38EB82E477AB9A4F3172994C679BA62',
                        linkPath: '#',
                        isExternalLink: true,
                    },
                    {
                        name: 'T10 Pro Smart Thermostat with RedLINK® Room Sensor',
                        imageLink:
                            'https://digitalassets.resideo.com/damroot/RDEDesktop/10004/THX321WFS2001-c10-6.jpg?koprop=PrimaryImage&rv=190ac63ac0df464497fadffe9fd3d85a&hash=D38EB82E477AB9A4F3172994C679BA62',
                        linkPath: '#',
                        isExternalLink: true,
                    },
                ],
            },
            bottomItemList: {
                isPresent: true,
                itemList: [
                    {
                        name: 'View All Products',
                        linkPath: '/',
                        isExternalLink: true,
                        buttonType: 'button',
                        icon: '',
                    },
                    {
                        name: 'View Pro Install Products',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                    {
                        name: 'View DIY Install Products',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                    {
                        name: 'View Offers',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                ],
            },
        },
    },
    {
        name: 'solutions',
        linkTextKey: 'header.primary.solutions',
        linkPath: '#',
        isExternalLink: false,
        isDirect: false,
        subMenu: {
            leftItemList: {
                isPresent: true,
                isType: 'card',
                itemList: [
                    {
                        title: 'Air',
                        description: 'Keep it clean',
                        imageLink:
                            'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Air-Color.svg?rv=90e347d05cc947f3ae026b1491c4a623',
                        linkPath: '#',
                        isExternalLink: true,
                    },
                    {
                        title: 'Security',
                        description: 'Share visiblity',
                        imageLink:
                            'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Security-Color.svg?rv=1f528e78fd3b4653a8354411c0c0b7c1',
                        linkPath: '#',
                        isExternalLink: true,
                    },
                    {
                        title: 'Water',
                        description: 'Avert disaster',
                        imageLink:
                            'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Water-Color.svg?rv=c50ea760e5824e6a893fb75353fe69c2',
                        linkPath: '#',
                        isExternalLink: true,
                    },
                    {
                        title: 'Energy',
                        description: 'Save and support',
                        imageLink:
                            'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Color/Nav-Icon-Energy-Color.svg?rv=fbd4b40b60c3433db0194150dbcf0f22',
                        linkPath: '#',
                        isExternalLink: true,
                    },
                ],
            },
            rightItemList: {
                isPresent: true,
                isTodayDeal: false,
                title: 'WELCOME TO THE SUPPORTED HOME',
                imageLink:
                    'https://www.resideo.com/us/en/-/media/Resideo/R5/Pro%20Header/supported-home-icon-670x280.jpg?h=280&w=670&rv=f20f354a43f643508140b1b401eda028&hash=EC0E8C3C082FF865D53F7E0E799A6550',
                desc: 'From behind the walls to in front of their eyes, our solutions help you support the critical systems that are part of the safeguards for your customers’ homes.',
            },
            bottomItemList: {
                isPresent: true,
                itemList: [
                    {
                        name: 'SMART HOME PARTNERS',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: '',
                    },
                    {
                        name: 'LTE UPGRADE',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                    {
                        name: 'PROTENTIAL',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                    {
                        name: 'BUILDER SOLUTIONS',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                    {
                        name: 'ENERGY STAR SOLUTIONS',
                        linkPath: '#',
                        isExternalLink: true,
                        buttonType: 'link',
                        icon: 'https://www.resideo.com/areas/resideo/img/button-arrow-blue.png',
                    },
                ],
            },
        },
    },
    {
        name: 'resources',
        linkTextKey: 'header.primary.resources',
        linkPath: '#',
        isExternalLink: false,
        isDirect: false,
        subMenu: {
            leftItemList: {
                isPresent: true,
                isType: 'list',
                itemList: [
                    {
                        header: true,
                        title: 'Resources',
                        icon: 'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/Black/FAQ.png?h=96&w=96&rv=c335087d23d64d6699114e323c241b6e&hash=67C5F14895C83C9E1CFC253E65B77A59',
                        subItems: [
                            {
                                name: 'Resideo Academy',
                                linkTextKey: 'Resideo Academy',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Security',
                                linkTextKey: 'Security',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'HVAC',
                                linkTextKey: 'HVAC',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Plumbing',
                                linkTextKey: 'Plumbing',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Distributors',
                                linkTextKey: 'Distributors',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Recalls',
                                linkTextKey: 'Recalls',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                            {
                                name: 'Utility programs',
                                linkTextKey: 'Utility programs',
                                linkPath: '#',
                                isExternalLink: true,
                            },
                        ],
                    },
                ],
            },
            rightItemList: {
                isPresent: false,
                title: 'TOP SEARCHED PRODUCTS',
                itemList: [],
            },
            bottomItemList: {
                isPresent: false,
            },
        },
    },
    {
        name: 'perks',
        linkTextKey: 'header.primary.perks',
        linkPath: isDevelopEnvironment
            ? 'https://develop--resideo-pro.netlify.app/perks'
            : `${window.location.origin}/perks`,
        isExternalLink: true,
        isDirect: false,
        subMenu: {},
    },
    {
        name: 'mybusiness',
        linkTextKey: 'header.primary.mybusiness',
        linkPath: `/subscription`,
        isExternalLink: false,
        isDirect: true,
        subMenu: {},
    },
];

export { mobilePrimaryNavigation as default };
