import { Flex, Label, Radio, Text, Box, Image } from '@resideo/blueprint-react';
import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';
import {
  americanCardIcon,
  discoverCardIcon,
  masterCardIcon,
  visaBlueCard,
} from '../../../assets/images';
import { ReactComponent as PaypalIcon } from '../../../assets/images/cart/icon_Paypal.svg';

export const getSmallerCCimg = type => {  
  switch (type) {
    case 'VISA':
      return visaBlueCard;
    case 'MASTERCARD':
      return masterCardIcon;
    case 'AMERICANEXPRESS':
      return americanCardIcon;
    case 'DISCOVER':
      return discoverCardIcon;
    default:
      return;
  }
};

export const CreditCardTypeImage = styled(Image)`
  height: 1.125rem;
  width: 1.875rem;
`;

const PaymentMethod = ({ showForm, card, state, setPaymentMethod, values }) => {
  return (
    <>
      {showForm && card.type === 'paymentMethod' ? (
        <>
          {state?.multipleCards?.length === 0 ? (
            <Field name='paymentMethodRadio' type='radio' value={state.cc}>
              {({ field }) => (
                <Label alignItems='center'>
                  <Radio
                    name='paymentMethodRadio'
                    marginRight={0}
                    {...field}
                    checked={true}
                  />
                  <Flex
                    justifyContent='space-between'
                    width='100%'
                    paddingY='small'>
                    <Flex alignItems='center'>
                      <CreditCardTypeImage
                        src={getSmallerCCimg(state.cc.type)}
                        alt='payment-card'
                        marginLeft='0.625rem'
                        width='1.875rem'
                      />
                      <Text fontWeight='bold'>{state.cc.type} </Text>
                      <Text>&nbsp;ending in {state.cc.lastFourNums}</Text>
                    </Flex>
                    <Text marginRight='large'>{state.cc.name}</Text>
                  </Flex>
                </Label>
              )}
            </Field>
          ) : (
            state?.multipleCards.map((card, i) => (
              <Field key={i} name='paymentMethodRadio' type='radio'>
                {({ field }) => (
                  <Label alignItems='center'>
                    <Radio
                      name='paymentMethodRadio'
                      marginRight={0}
                      {...field}
                      onChange={() => setPaymentMethod(card)}
                      checked={
                        card.lastFourNums === values.card.lastFourNums &&
                        card.cvc === values.card.cvc &&
                        card.exp === values.card.exp
                      }
                    />
                    <Flex
                      justifyContent='space-between'
                      width='100%'
                      paddingY='small'>
                      <Flex alignItems='center'>
                        <CreditCardTypeImage
                          src={card.image}
                          alt='payment-card'
                          marginLeft='0.625rem'
                          width='1.875rem'
                        />
                        <Text fontWeight='bold'>{card.type} </Text>
                        <Text>&nbsp;ending in {card.lastFourNums}</Text>
                      </Flex>
                      <Text marginRight='large'>{card.name}</Text>
                    </Flex>
                  </Label>
                )}
              </Field>
            ))
          )}
        </>
      ) : card.paymentMode === 'PAYPAL' && card.type === 'paymentMethod' ? (
        <Flex flexDirection='column' paddingY='small'>
          <Flex paddingY='small'>
            <PaypalIcon />
          </Flex>
        </Flex>
      ) : (
        card.ccType && (
          <>
            <Flex flexDirection='column' paddingY='small'>
              <Flex paddingY='small'>
                <Box marginRight='small' color='textHeading'>
                  {card.ccType}
                </Box>
                <CreditCardTypeImage
                  src={getSmallerCCimg(card.ccType)}
                  alt='card'
                  width='1.875rem'
                />
              </Flex>
              <Box color='textHeading'>**** **** **** {card.lastFourNums}</Box>
            </Flex>
          </>
        )
      )}
    </>
  );
};

export default PaymentMethod;
