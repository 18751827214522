import React from 'react';
import {
    FooterContainer,
    ProductsButtonContainer,
    ViewAllProductsButton,
    ViewProductsArrow,
    ViewProductsButton,
} from './MenuItemStyle';

const FooterItemList = ({ footerItem }: any) => {
    const RouteExternally = (link) => {
        window.location = link;
    };

    return footerItem.map((item, index) => (
        <FooterContainer key={`footer-item-${index}`}>
            {item.buttonType === 'button' ? (
                <ViewAllProductsButton onClick={() => RouteExternally(item.linkPath)}>
                    {item.name}
                </ViewAllProductsButton>
            ) : (
                <ProductsButtonContainer>
                    <ViewProductsButton onClick={() => RouteExternally(item.linkPath)}>
                        {item.name}
                    </ViewProductsButton>
                    <ViewProductsArrow
                        src='https://www.resideo.com/areas/resideo/img/button-arrow-blue.png'
                        className='move-arw'></ViewProductsArrow>
                </ProductsButtonContainer>
            )}
        </FooterContainer>
    ));
};

export default FooterItemList;
