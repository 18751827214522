import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Flex, Heading, Image, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { resideoBlackLogo } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import SeparatorLine from '../common/SeparatorLine';
import { convertMoneyAmount } from '../../routes/ServicesAndSubscriptions/Cart';
import { useParams } from 'react-router';
import moment from 'moment';
import PageLoading from 'components/common/PageLoading';
import { getOrderInformation } from 'redux/orderSummary/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { getFullCountryByCode } from 'config/countries';

const PrintWrapper = styled(Flex)`
    cursor: pointer;
    font-weight: bold;
    display: block;
    @media print {
        display: block;
    }
`;

const BillBorderBox = styled(Flex)`
    border: 2px solid ${({ theme }) => theme.colors.back};
`;

const WordBreakText = styled(Text)`
    word-break: break-all;
`;

const Order: FC<any> = () => {
    const params = useParams() as any;
    const orderId = params?.id;
    const [order, setOrder] = useState<any>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const today = moment(order?.createdAt).format('MMM DD, YYYY');
    const isLoading = useSelector(
        (state: RootState) => state.orderSummary.fetchingOrderInformation,
    );
    const isError = useSelector(
        (state: RootState) => state.orderSummary.fetchingOrderInformationError,
    );
    const orderDetails = useSelector((state: RootState) => state.orderSummary.orders);
    const lastFourNums = order?.drpaymentInfo?.drcclastFourDigits;
    const hasProduct: any =
        order &&
        order?.unconsignedEntries &&
        order?.unconsignedEntries?.filter((x) => x.product.itemType?.toLowerCase() === 'product')
            .length > 0;

    const invoiceData = useCallback(async () => {
        await dispatch(getOrderInformation(orderId));
    }, [orderId]);

    useEffect(() => {
        if (orderDetails && Object.keys(orderDetails).length === 0) {
            invoiceData();
        }
    }, [orderId]);

    useEffect(() => {
        setOrder(orderDetails);
    }, [orderDetails]);

    const taxData = JSON.parse(`${localStorage.getItem('taxExemptionApplication')}`);

    return isLoading || order === null ? (
        <PageLoading />
    ) : (
        <PrintWrapper width={'100%'} marginBottom='xxxLarge' padding={['1.25rem', '']}>
            <Box paddingBottom={['small', 'small', 'large', 'large']}>
                <Image alt='resideo' src={resideoBlackLogo} />
            </Box>
            <br />
            <Flex>
                <Box display='flex'>
                    <Heading width={'9rem'} fontWeight='700'>
                        {t('cart.completeOrder.orderNumber')}
                    </Heading>
                    <WordBreakText fontWeight={400}>{order?.code}</WordBreakText>
                </Box>
            </Flex>
            <Flex paddingY={'small'}>
                <Box display='flex'>
                    <Heading width={'9rem'} fontWeight='700'>
                        {t('cart.completeOrder.date')}
                    </Heading>
                    <WordBreakText fontWeight={400}>{today}</WordBreakText>
                </Box>
            </Flex>
            <Flex marginBottom={['medium', 'medium', 'large', 'xLarge']}>
                <Box display='flex'>
                    <Heading width={'9rem'} fontWeight='700'>
                        {t('cart.completeOrder.orderStatus')}
                    </Heading>
                    <WordBreakText fontWeight={400}>
                        {t('cart.completeOrder.completed')}
                    </WordBreakText>
                </Box>
            </Flex>
            <BillBorderBox
                marginBottom={['medium', 'medium', 'large', 'large']}
                width={'100%'}
                display={'flex'}
                flexDirection={['column', 'row']}
                justifyContent={'space-between'}
                paddingY={'large'}
                paddingX={'medium'}>
                <Box display='flex'>
                    <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                        <Heading paddingBottom={'small'}>
                            {t('cart.completeOrder.contactInformation')}
                        </Heading>
                        <WordBreakText fontWeight={400}>{order?.email}</WordBreakText>
                        <WordBreakText fontWeight={400}>{order?.phoneNumber}</WordBreakText>
                    </Flex>
                </Box>
                {hasProduct && (
                    <Box display='flex' paddingLeft={['','medium']}>
                        <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                            <Heading color='header.secondaryLinkActive'>
                                {t('cart.order.shippingAddress')}
                            </Heading>
                            <WordBreakText fontWeight={400}>
                                {order?.deliveryAddress?.firstName}{' '}
                                {order?.deliveryAddress?.lastName}
                            </WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {order?.deliveryAddress?.line1}
                            </WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {order?.deliveryAddress?.region?.isocode}
                            </WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {order?.deliveryAddress?.postalCode}
                            </WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {t(getFullCountryByCode(order?.deliveryAddress?.country?.isocode))}
                            </WordBreakText>
                        </Flex>
                    </Box>
                )}
                <Box display='flex' paddingLeft={['','medium']}>
                    <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                        <Heading paddingBottom={'small'}>
                            {t('cart.completeOrder.billingAddress')}
                        </Heading>
                        <WordBreakText fontWeight={400}>
                            {order?.drpaymentInfo?.billingAddress?.firstName}{' '}
                            {order?.drpaymentInfo?.billingAddress?.lastName}
                        </WordBreakText>
                        <WordBreakText fontWeight={400}>
                            {order?.drpaymentInfo?.billingAddress?.line1}
                        </WordBreakText>
                        <WordBreakText fontWeight={400}>
                            {order?.drpaymentInfo?.billingAddress?.region?.isocode}
                        </WordBreakText>
                        <WordBreakText fontWeight={400}>
                            {order?.drpaymentInfo?.billingAddress?.postalCode}
                        </WordBreakText>
                        <WordBreakText fontWeight={400}>
                            {t(
                                getFullCountryByCode(
                                    order?.drpaymentInfo?.billingAddress?.country?.isocode,
                                ),
                            )}
                        </WordBreakText>
                    </Flex>
                </Box>
                <Box display='flex' paddingLeft={['','medium']}>
                    <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                        <Heading paddingBottom={'small'}>
                            {t('cart.completeOrder.paymentMethod')}
                        </Heading>
                        {order?.drpaymentInfo?.drSourceType === 'payPalBilling' ? (
                            <WordBreakText fontWeight={400}>PayPal</WordBreakText>
                        ) : (
                            <>
                                <WordBreakText fontWeight={400}>
                                    {order?.drpaymentInfo?.drccbrand?.toUpperCase()}
                                </WordBreakText>
                                <WordBreakText fontWeight={400}>
                                    **** **** **** {lastFourNums}
                                </WordBreakText>
                            </>
                        )}
                    </Flex>
                </Box>
                {taxData && taxData?.companyName && (
                    <Box display='flex' paddingLeft={['','medium']}>
                        <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                            <Heading paddingBottom={'small'}>
                                {t('cart.completeOrder.taxExemptionInformation')}
                            </Heading>
                            <WordBreakText fontWeight={400}>{taxData?.companyName}</WordBreakText>
                            <WordBreakText fontWeight={400}>{taxData?.taxAuthority}</WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {t('cart.billing.exemptionStartDateLable')}{' '}
                                {moment(taxData?.startDate).format('MMM YYYY')}
                            </WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {t('cart.billing.exemptionEndDateLable')}{' '}
                                {moment(taxData?.endDate).format('MMM YYYY')}
                            </WordBreakText>
                            <WordBreakText fontWeight={400}>
                                {t('cart.billing.certFileName')}
                                {localStorage.getItem('temp-file-name')
                                    ? localStorage.getItem('temp-file-name')
                                    : 'N/A'}
                            </WordBreakText>
                        </Flex>
                    </Box>
                )}
            </BillBorderBox>
            {order?.unconsignedEntries?.map((item, i) => (
                <Flex
                    key={i}
                    paddingTop='medium'
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexDirection={['column', 'row']}
                    alignItems={'center'}>
                    <Box display='flex' width={'17rem'} minWidth={'17rem'} maxWidth={'17rem'}>
                        <Flex
                            flexDirection={['column']}
                            width={1}
                            justifyContent='space-between'
                            marginBottom={['large', 'large', '', '']}>
                            <Heading color={'#221E1F'}>{item?.product?.name}</Heading>
                            <WordBreakText
                                fontSize={'0.875rem'}
                                fontWeight={500}
                                color={'gray'}
                                paddingY={'small'}>
                                {item?.product?.code}
                            </WordBreakText>
                        </Flex>
                    </Box>
                    <Flex
                        flexDirection={['column', 'row']}
                        width={1}
                        justifyContent='space-between'
                        marginBottom={['large', 'large', '', '']}>
                        <Heading color={'#221E1F'}>{t('cart.completeOrder.completed')}</Heading>
                        <Heading color={'#221E1F'}>
                            {t('cart.completeOrder.quantityPurchased')}
                        </Heading>
                        <Heading color={'#221E1F'}>x{item?.quantity || 1}</Heading>
                    </Flex>
                    <Box
                        justifyContent={['flex-start', 'flex-end']}
                        display='flex'
                        width={'7rem'}
                        maxWidth={'7rem'}
                        minWidth={'7rem'}>
                        <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                            <Heading color={'#221E1F'}>
                                {convertMoneyAmount(
                                    {
                                        amount: item?.totalPrice?.value,
                                        currencyCode: item?.totalPrice?.currencyIso,
                                    },
                                    'en-us',
                                )}
                            </Heading>
                        </Flex>
                    </Box>
                </Flex>
            ))}
            <SeparatorLine borderColor={'#000'} />
            <Box>
                {hasProduct && (
                    <>
                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            paddingBottom='small'>
                            <WordBreakText
                                fontWeight={400}
                                width='calc(100% - 7.625rem)'
                                textAlign='right'
                                color='textHeading'>
                                {/* {t('subtotalToday')} */}
                            </WordBreakText>
                            <WordBreakText
                                opacity={0} // need to remove opacity
                                fontWeight={400}
                                width='7.625rem'
                                textAlign='right'
                                color='textHeading'>
                                {order?.subTotalWithoutDiscount?.formattedValue}
                            </WordBreakText>
                        </Box>

                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            paddingBottom='small'>
                            <WordBreakText
                                fontWeight={400}
                                width='calc(100% - 7.625rem)'
                                textAlign='right'
                                color='textHeading'>
                                {t('cart.shipping.shipping')}
                            </WordBreakText>
                            <WordBreakText
                                fontWeight={400}
                                width='7.625rem'
                                textAlign='right'
                                color='textHeading'>
                                {convertMoneyAmount(
                                    {
                                        amount: order?.deliveryCost?.value,
                                        currencyCode: order?.deliveryCost?.currencyIso,
                                    },
                                    'en-us',
                                )}
                            </WordBreakText>
                        </Box>
                    </>
                )}

                    {order && order?.appliedVouchers && order?.appliedVouchers?.length > 0 && (
                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            paddingBottom='small'>
                            <WordBreakText
                                fontWeight={400}
                                width='calc(100% - 7.625rem)'
                                textAlign='right'
                                color='textHeading'>
                                {/* {t('cart.youSaved')} */}
                                {t('discount')}
                            </WordBreakText>
                            <WordBreakText
                                fontWeight={400}
                                width='7.625rem'
                                textAlign='right'
                                color='textHeading'>
                                {'-'}
                                {order?.totalDiscounts?.formattedValue}
                            </WordBreakText>
                        </Box>
                    )}
                        
                <Box display='flex' width='100%' justifyContent='flex-end' paddingBottom='small'>
                    <WordBreakText
                        fontWeight={400}
                        width='calc(100% - 7.625rem)'
                        textAlign='right'
                        color='textHeading'>
                        {t('tax')} {t('notYetCalculated')}
                    </WordBreakText>
                    <WordBreakText
                        fontWeight={400}
                        width='7.625rem'
                        textAlign='right'
                        color='textHeading'>
                        {convertMoneyAmount(
                            {
                                amount: order?.totalTax?.value,
                                currencyCode: order?.totalTax?.currencyIso,
                            },
                            'en-us',
                        )}
                    </WordBreakText>
                </Box>
            </Box>
            <Box>
                <Box display='flex' alignItems='center' width='100%' justifyContent='flex-end'>
                    <Heading
                        fontSize='medium'
                        fontWeight='bold'
                        letterSpacing='normal'
                        color='textHeading'
                        width='calc(100% - 7.625rem)'
                        textAlign='right'>
                        {t('totalToday')}
                    </Heading>
                    <Heading
                        fontSize='medium'
                        fontWeight='bold'
                        letterSpacing='normal'
                        color='textHeading'
                        width='7.625rem'
                        textAlign='right'>
                        {convertMoneyAmount(
                            {
                                amount: order?.totalPriceWithTax?.value,
                                currencyCode: order?.totalPriceWithTax?.currencyIso,
                            },
                            'en-us',
                        )}
                    </Heading>
                </Box>
            </Box>
        </PrintWrapper>
    );
};

export default Order;
