import React from 'react';
import styled, { keyframes } from 'styled-components';

interface StyledCardProps {
    delay?: any
}

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 1rem }
  100% { margin-bottom: 0 }
`;

const DotWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-items: center;
    padding: 0px 46px;
`;

const Dot = styled.div<StyledCardProps>`
    background-color: white;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    margin: 0 0.25rem;
    /* Animation */
    animation: ${BounceAnimation} 0.5s linear infinite;
    animation-delay: ${(props) => props.delay};
`;

const LoadingDots = () => {
    return (
        <DotWrapper>
            <p style={{margin : '2px 0px'}}>&nbsp;</p>
            <Dot delay='0s' />
            <Dot delay='0.1s' />
            <Dot delay='0.2s' />
        </DotWrapper>
    );
};
export default LoadingDots;
