import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
//import PDP from './PDP';
import HomeOwner from './HomeOwner';

const PDPRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      {/* <Route path={`${path}/basic`} component={PDP} />
      <Route path={`${path}/advanced`} component={PDP} />
      <Route path={`${path}/pro`} component={PDP} />
      <Route path={`${path}/device-refresh`} component={PDP} /> */}
      <Route path={`${path}/home-owner`} component={HomeOwner} />
    </Switch>
  );
};

export default PDPRoutes;
