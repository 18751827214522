import { light } from '@resideo/blueprint-theme';

const colors = {
  ...light.colors,
  welcomeBanner: '#f8f8f8',
  button: {
    danger: {
      hover: '#b52e15',
      hoverText: '#ececec',
    },
  },
  header: {
    icons: '#ffffff',
    primaryBackground: '#032a3c',
    primaryLink: '#2a99d4',
    primaryLinkActive: '#ffffff',
    secondaryBackground: '#ffffff',
    secondaryLink: '#217eae',
    secondaryLinkActive: '#000000',
    activeMenuLink: '#032a3b',
    activeTabLink: '#2E2E2E',
  },
  home: {
    icons: '#012a3a',
  },
  list: {
    hover: '#d8e7ef',
  },
  secondaryBackground: '#f8f8f8',
  sidebar: {
    circle: '#add8e6',
    background: '#f5f5f5',
    text: '#ffffff',
    selected: '#f0f8ff',
    block: '#ffffff',
    hover: '#e6e6fa',
    active: '#fffff0',
  },
  sideNav: {
    active: '#e0e0e0',
    arrow: '#767676',
    arrowHovered: '#217eae',
  },
  table: {
    oddRowBackgroundColor: '#f2f2f2',
    rowBorderColor: '#e0e0e0',
    header: '#797979',
  },
  tag: {
    viewer: '#fff',
  },
  badge: {
    primary: '#166a96',
    active: '#3dca5b',
    normal: '#ededed',
  },
  companySideBar: {
    hover: 'rgba(0, 0, 0, 0.1)',
  },
  redAlertError: '#C6361C',
  background: '#fff',
  card: '#ffffff',
  danger: '#ce4a32',
  disabled: '#f4f4f4',
  faded: '#767676',
  hover: '#004266',
  info: '#166a96',
  input: '#ffffff',
  inputBorder: '#4f7285',
  modal: {
    overlay: 'rgba(0, 0, 0, 0.4)',
    shadow: 'rgba(0, 0, 0, 0.6)',
  },
  outline: 'rgba(0, 169, 224, 0.2)',
  primary: '#166a96',
  secondarySeparateLine: '#cecece',
  separator: 'rgba(0, 0, 0, 0.1)',
  success: '#258039',
  text: '#000000',
  blackText: '#111111',
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  gray: '#767676', // rgb(118, 118, 118)
  textHeading: '#221e1f', //rgb(34, 30, 31)
  cartTextHeading: '#000000',
  lightenGray: '#EDEDED', //rgb(237, 237, 237)
  lightenBackgroundGray: '#eae4dd',
  paragraph: 'rgb(149,149,149)',
  border: 'rgb(218, 218, 218)',
  cardBorder: 'rgb(233, 234, 237)',
  summaryParagraph: 'rgb(141, 141, 141)',
  green: 'rgb(22, 106, 150)', // rgb(22, 106, 150)
  blueText: 'rgb(0, 104, 154)',
  bannerBackground: 'rgb(46,46,46)', // rgb(22, 106, 150)
  titleLarge: '#032a3b',
  grayBorder: 'rgb(151, 151, 151)',
  tabName: 'rgb(7, 0, 45)',
  tabActiveBorder: 'rgb(9, 0, 47)',
  headingBackgroundColor: 'rgb(234, 228, 221)',
  productInfoBackgroundColor: 'rgb(46, 46, 46)',
  planTabColor: '#07002D',
  planTabBorder: '#09002F',
  checkBoxBackground: '#DADADA',
  textLight3: '#221E1F',
  lightBlue: '#2e88f5',
  graySecondary: '#8D8D8D',
  collapseBorder: '#E9EAED',
  lightGrayBackgroud: '#CCCCCC',
  cartLink: '#00689a',
};

export default {
  ...light,
  colors,
};
