import React from 'react';
import {
    FeaturedWrapper,
    ScoreLead,
    ProductHighlight,
    ScoreHighlight,
    ScoreHighlightImage,
    ScoreHighlightParagraph,
    MobileViewHomeContainer,
    MobileViewImageWrapper,
    MobileViewSideDealCaption,
    MobileViewSideDealHeading,
    MobileViewDescription,
} from './MobileViewStyle';

const MobileRightMenuItem = ({ data }: any) => {
    return data.isTodayDeal ? (
        <>
            <FeaturedWrapper>
                <ScoreLead>{data.title}</ScoreLead>
                {data.itemList.map((item, index) => (
                    <ProductHighlight key={`index${index}`}>
                        <ScoreHighlight>
                            <ScoreHighlightImage src={item.imageLink} />
                            <ScoreHighlightParagraph>{item.name}</ScoreHighlightParagraph>
                        </ScoreHighlight>
                    </ProductHighlight>
                ))}
            </FeaturedWrapper>
        </>
    ) : (
        <MobileViewHomeContainer>
            <MobileViewImageWrapper src={data.imageLink} />
            <MobileViewSideDealCaption>
                <MobileViewSideDealHeading>{data.title}</MobileViewSideDealHeading>
                <MobileViewDescription>{data.desc}</MobileViewDescription>
            </MobileViewSideDealCaption>
        </MobileViewHomeContainer>
    );
};

export default MobileRightMenuItem;
