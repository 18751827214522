/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
// import { proProductPDPImage } from 'assets/images';
import ServiceIconLarge from 'assets/images/ServiceIconLarge';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const checkStatus = (
    activationDate: string | null | undefined = null,
    status: string | null | undefined = null,
    isLogoApproved: boolean | null | undefined = true,
    enrollment: any | null | undefined = null,
) => {    
    if (status?.toUpperCase() === 'ACTIVE') {
        if (activationDate !== null && isLogoApproved) {
            return 'Active';
        }
        if (activationDate !== null && !isLogoApproved) {
            return 'Pending';
        }
        if (activationDate === null) {
            return 'Not Active';
        }
    } else if (
        enrollment &&
        enrollment.status.toUpperCase() === 'CANCELLED' &&
        enrollment.autoRenew === false &&
        enrollment.subscriptionEndDate
    ) {
        // if we get here and the subscription end date has not
        // yet been reached, then the user turned off auto renew
        // and the subscription has not reach its expiration date.
        // The subscription is still considered to be in an "active"
        // state.
        if (moment(enrollment.subscriptionEndDate).isAfter()) {
            if (activationDate !== null && isLogoApproved) {
                return 'Active';
            }
            if (activationDate !== null && !isLogoApproved) {
                return 'Pending';
            }
            if (activationDate === null) {
                return 'Not Active';
            }
        } else {
            // if the expiration date has been reached, then the enrollment
            // is expired. Currently, an expired subscription is considered
            // cancelled by the design team.  If this changes, return 'Expired'
            // instead.
            ('Canceled');
        }
    } else {
        return status !== null ? 'Canceled' : '';
    }
};

const serviceNames = (t) => ({
    basic: `${t('mybusiness.services.basic')} ${t('mybusiness.services.service')}`,
    advanced: `${t('mybusiness.services.advanced')} ${t('mybusiness.services.service')}`,
    pro: `${t('mybusiness.services.pro')} ${t('mybusiness.services.service')}`,
    branding: `${t('mybusiness.services.branding')} ${t('mybusiness.services.service')}`,
});

export const subscriptionData = (data: any, more?: boolean, showMoreIndex?: number) => {
    const { t } = useTranslation();
    const subscriptions: any = [];
    const unsubscribed: any = [];

    // const enrollments =
    //     data?.viewer?.partnerUsers?.edges[0]?.node?.partnerAccount?.partnerProgramEnrollments
    //         ?.edges;

    data?.map((obj: any, i: number) => {
        const objStatus: any = checkStatus(obj.activationDate, obj.status, obj.logoApproved, obj);
        //if (['ACTIVE'].includes(obj?.node?.status)) {
        if (objStatus !== 'Canceled') {
            if (obj?.productCode) {
                subscriptions.push({
                    _id: obj.id,
                    name: obj?.name, //serviceNames(t).branding,
                    status: objStatus,
                    billingCycle: `Billed ${obj?.billingCycle}`,
                    desc: obj?.description,
                        // !more || showMoreIndex !== i
                        //     ? t('mybusiness.description.brandingMore')
                        //     : {
                        //           text: t('mybusiness.description.brandingLess'),
                        //           text1: t('mybusiness.description.brandingLess1'),
                        //           text2: t('mybusiness.description.brandingLess2'),
                        //       },
                    purchaseDate: moment(obj.purchaseDate).format('MMM DD, YYYY'),
                    btnLeft:
                        objStatus === 'Active'
                            ? t('mybusiness.dashboard.goToDashboard')
                            : t('mybusiness.servicesAndSubscriptions.activateService'),
                    linkPath: '',
                    btnRight: t('mybusiness.servicesAndSubscriptions.manageSubscription'),
                    subLinkPath: 'subscription/manage-subscription',
                    img: ServiceIconLarge,
                    activationDate: moment(obj.activationDate).format('MMMM DD, YYYY'),
                    renewsOn: moment(obj.purchaseDate).add(1, 'year').format('MMMM DD, YYYY'),
                    autoRenew: obj?.autoRenew,
                    productCode: obj?.productCode,
                });
            }
        } /*else if (['CANCELLED', 'EXPIRED'].includes(obj?.node?.status))*/ else {
            // DO NOT change the spelling of CANCELLED avove.  This is checking the status value
            // being returned by the Partner Program service which uses 2 L's because that is
            // how SAP has it spelled in their API.
            if (obj?.productCode) {
                unsubscribed.push({
                    _id: obj.id,
                    name: obj.name, //serviceNames(t).branding,
                    status: objStatus,
                    billingCycle: `Billed ${obj?.billingCycle?.toUpperCase()}`,
                    desc: obj?.description,
                        // more || showMoreIndex !== i
                        //     ? t('mybusiness.description.brandingMore')
                        //     : {
                        //           text: t('mybusiness.description.brandingLess'),
                        //           text1: t('mybusiness.description.brandingLess1'),
                        //           text2: t('mybusiness.description.brandingLess2'),
                        //       },

                    purchaseDate: moment(obj.purchaseDate).format('MMM DD, YYYY'),
                    btnLeft: t('mybusiness.servicesAndSubscriptions.viewDetails'),
                    linkPath: '',
                    btnRight: '',
                    subLinkPath: 'subscription/manage-subscription',
                    img: ServiceIconLarge,
                    activationDate: '',
                    renewsOn: '',
                    autoRenew: false,
                    cancellationDate: moment(obj.cancellationDate).format('MMM DD, YYYY'),
                    productCode: obj?.productCode,
                });
            }
        }
    });

    return { subscriptions, unsubscribed };
};
