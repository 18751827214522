import React, { useState } from 'react';
import { Box, Flex, Heading, Text, useModal } from '@resideo/blueprint-react';
import styled from 'styled-components';
import CustomModal from 'components/managesubscription/CustomModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TabWrapper = styled(Flex)`
  height: 24px;
  width: 84px;
  color: #032a3b;
  font-family: Roboto;
  font-size: 21px;
  font-weight: bold;
  line-height: 24px;
`;

type TabProps = {
  active?: boolean;
  menuActive?: boolean;
};

const Tab = styled.div`
  margin-right: 40px;
  cursor: pointer;
  padding-bottom: 30px;
  border-bottom: ${(p: TabProps) => () =>
    p.active ? '2px solid #032A3B' : '2px solid transparent'};
  color: ${(p: TabProps) => () => (p.active ? '#032A3B' : '#767676')};
`;

const BoxOverview = styled(Box)`
  padding: 20px 250px 70px 250px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 30px 30px 30px;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`;

const Col4 = styled.div`
  flex: 30%;
  max-width: 60%;
  margin-bottom: 25px;
  cursor: pointer;
  @media (max-width: 768px) {
    flex: 100%;
    max-width: 100%;
  }
`;

const Text1 = styled(Text)`
  height: 51px;
  width: 285px;
  color: #166a96;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
`;
const Text2 = styled(Text)`
  height: 16px;
  width: 261px;
  color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`;

const ContentWrapperVideo = styled.div`
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const VideoBox = styled.div`
  width: 316px;
  height: 197px;
  margin-bottom: 50px;
  margin-right: 30px;
  @media (max-width: 768px) {
    margin-top: 20px;
    margin-right: 0;
  }
`;

const VideoMask = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  width: 316px;
  height: 197px;
`;

const contentList = [
  {
    text1: 'Training Options for AirCycle Program (English)',
    text2: 'Published 2020-Mar  |  PDF  1.5Mb',
    pdfLink: `https://prdproseriesfiles.blob.core.windows.net/$web/AirCycle/Training%20Options%20for%20Pro-IQ%20AirCycle%20Program%20(English).pdf?sp=r&st=2021-08-09T16:05:07Z&se=2521-08-10T00:05:07Z&spr=https&sv=2020-08-04&sr=b&sig=M5vNvMFzaj%2B234PpM8A43xcpQuqyYYspJmcfBCt9Exk%3D`,
  },
  {
    text1: 'Training Options for AirCycle Program (French)',
    text2: 'Published 2020-Mar  |  PDF  1.5Mb',
    pdfLink: `https://prdproseriesfiles.blob.core.windows.net/$web/AirCycle/Training%20Options%20for%20Pro-IQ%20AirCycle%20Program%20(French).pdf?sp=r&st=2021-08-09T16:06:03Z&se=2521-08-10T00:06:03Z&spr=https&sv=2020-08-04&sr=b&sig=9jHrstdNdlxHQfBuX3cioucJ6w21mHxxoY6eRK95Dtg%3D`,
  },
  {
    text1: 'Sell Sheet For AirCycle Program (English)',
    text2: 'Published 2020-Mar |  PDF  1.9Mb',
    pdfLink: `https://prdproseriesfiles.blob.core.windows.net/$web/AirCycle/Sell%20Sheet%20for%20Pro-IQ%20AirCycle%20Program%20(English).pdf?sp=r&st=2021-08-09T16:03:15Z&se=2521-08-10T00:03:15Z&spr=https&sv=2020-08-04&sr=b&sig=oZIXfWJlT1ETmM1vLcPHnF9LEq0X%2BMbGmvNznmRrb3A%3D`,
  },
  {
    text1: 'Sell Sheet For AirCycle Program (French)',
    text2: 'Published 2020-Mar |  PDF  1.9Mb',
    pdfLink: `https://prdproseriesfiles.blob.core.windows.net/$web/AirCycle/Sell%20Sheet%20for%20Pro-IQ%20AirCycle%20Program%20(French).pdf?sp=r&st=2021-08-09T16:04:47Z&se=2521-08-10T00:04:47Z&spr=https&sv=2020-08-04&sr=b&sig=vutAO0%2BUdz%2FWQcX4k3bpC65h5kdJEwsEGoblUqzUWnA%3D`,
  },
];

export const getVideoUrl = index => {
  switch (index) {
    case 1:
      return 'https://www.youtube.com/embed/jV3m8UPs_vk';
    case 2:
      return 'https://www.youtube.com/embed/drLosufU54U';
    case 3:
      return 'https://www.youtube.com/embed/QmLE2S_x11E';
    default:
      return 'https://www.youtube.com/embed/jV3m8UPs_vk';
  }
};

const Resources = ({ resourcesRef, data }) => {
  const { t } = useTranslation();
  const { Modal, openModal, closeModal } = useModal();
  const [active, setActive] = useState('sell');
  const [more, setMore] = useState('');
  const [modalVideo, setModalVideo] = useState(0);
  const changeTab = tab => {
    setMore('');
    setActive(tab);
  };
  return (
    <>
      {modalVideo > 0 && (
        <CustomModal
          Modal={Modal}
          title=''
          closeModal={closeModal}
          modalVideo={modalVideo}
          type='video'
        />
      )}
      <Box justifyContent='left' paddingTop='xLarge' backgroundColor='#EDEDED'>
        <Box
          ref={resourcesRef}
          id='Resources'
          justifyContent='left'
          display='flex'
          backgroundColor='#EDEDED'>
          <BoxOverview>
            <Heading
              color='#032A3B'
              fontSize='40px'
              paddingBottom={['large', 'medium', 'medium', 'large']}
              fontFamily='URW_Din_CondBold'
              letterSpacing='2px'>
              {t('resources.resources')}
            </Heading>
            <TabWrapper>
              <Tab active={active === 'sell'} onClick={() => changeTab('sell')}>
                {t('resources.sell')}
              </Tab>
              <Tab
                active={active === 'promote'}
                onClick={() => changeTab('promote')}>
                {t('resources.promote')}
              </Tab>
            </TabWrapper>
            {active === 'sell' && (
              <ContentWrapper>
                {data.map((c, index) => {
                  return (
                    <Col4
                      key={`data-${index}`}
                      onClick={() => window.open(c.url, '_blank')}>
                      <Text1>{c.webUsage}</Text1>
                      <Text2>{c?.startDate !== ''
                        ? `Published ${moment(c?.startDate).format('MMM YYYY')}`
                        : ' '}</Text2>
                    </Col4>
                  );
                })}
                {more ? (
                  <>
                    {data.map((c, index) => {
                      return (
                        <Col4 key={`data-${index}`}>
                          <Text1>{c.webUsage}</Text1>
                          <Text2>{c?.startDate !== ''
                            ? `Published ${moment(c?.startDate).format('MMM YYYY')}`
                            : ' '}</Text2>
                        </Col4>
                      );
                    })}
                  </>
                ) : null}
              </ContentWrapper>
            )}
            {active === 'install' && (
              <ContentWrapper>
                <h1>TAB INSTALL</h1>
              </ContentWrapper>
            )}
            {active === 'promote' && (
              <ContentWrapperVideo>
                {[
                  { number: 1, text: 'Resideo AirCycle Program Testimonial' },
                  { number: 2, text: 'Resideo AirCycle Program Overview' },
                  { number: 3, text: 'Resideo AirCycle Program' },
                ].map((obj, i) => (
                  <VideoBox key={i}>
                    <VideoMask
                      onClick={() => {
                        localStorage.setItem('modalType', 'PROMOTE_VIDEO');
                        openModal();
                        setModalVideo(obj.number);
                      }}
                    />
                    <iframe
                      height='100%'
                      width='100%'
                      src={getVideoUrl(obj.number)}
                      title='YouTube video player'
                      frameBorder={0}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    />
                    <p>{obj.text}</p>
                  </VideoBox>
                ))}
              </ContentWrapperVideo>
            )}
          </BoxOverview>
        </Box>
      </Box>
    </>
  );
};

export default Resources;
