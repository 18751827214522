import { types } from './types';

type ProductListPayload = {
    id: string;
    pageNumber: string;
};

export const getProductList = (payload?: ProductListPayload) => ({
    type: types.FETCH_PRODUCT_LIST,
    payload,
});

export const getProductListSuccess = (payload: any) => ({
    type: types.FETCH_PRODUCT_LIST_SUCCESS,
    payload,
});

export const getProductListFail = (payload: any) => ({
    type: types.FETCH_PRODUCT_LIST_FAIL,
    payload,
});
