import React, { FC, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Authentication from './Authentication';
import Invoice from './ServicesAndSubscriptions/Invoice';
import AppLayout from '../components/layouts/AppLayout';
import { appLaunchFlow } from '../redux/authentication/action';
import SubscriptionsAndServices from './ServicesAndSubscriptions';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Redirect } from 'react-router';
import Products from './Products';
import { RootState } from 'redux/root-reducer';
import { b2bUserRegistrationPayload } from 'data/b2bUserRegistration';
import { createB2BUser, getB2BUserInfo } from 'redux/prouser/action';
import { getUser, getUserWithoutGQl } from 'redux/graphql/authentication/actions';
import { createCardID, getCardDetails } from 'redux/cart/actions';
import _ from 'lodash';
import { useDispatchPromise } from 'hooks/useDispatchPromise';

// import SignIn from './SignIn';

const Routes: FC = () => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const dispatchWithPromise = useDispatchPromise();
    const appLaunch = useCallback(() => dispatch(appLaunchFlow()), [dispatch]);
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn);
    const userDetails: any = useSelector((state: RootState) => state.authentication.userDetails);
    const b2bUserDetails: any = useSelector((state: RootState) => state.b2bUserDetails.b2bUserInfoDetails);
    const { id, countryCode, firstName, lastName, contactEmail, partnerUsers } = userDetails
    const { status, shipToAddress, billToAddress, name } =
        partnerUsers?.edges[0]?.node.partnerAccount || {};
    const primaryPhoneNumber = partnerUsers?.edges[0]?.node.partnerAccount?.primaryPhoneNumber ?? userDetails?.primaryPhoneNumber; 
    const partnerAccountId = partnerUsers?.edges[0]?.node.partnerAccount.id;
    const URL =
        process.env.REACT_APP_SIGNUP_URL || 'https://develop--resideo-pro.netlify.app/get-started';
    const getCartId: any = useSelector((state: RootState) => state.cartDetails.cartIDDetails);


    useEffect(() => {
        appLaunch();
    }, [appLaunch, dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getUser());
            dispatch(createCardID());
            /** Check if current user is already registered */
            dispatchWithPromise(getB2BUserInfo()).then((response) => {
                /** User exists */
            }).catch((error) => {
                /** If not then attempt to register them */
                if (id && billToAddress && primaryPhoneNumber && status === 'ACTIVE' && _.isEmpty(b2bUserDetails)) {
                    const newBillToAdress = { ... billToAddress }
                    const newShipToAddress = { ... shipToAddress}
                    newShipToAddress.phone = primaryPhoneNumber;
                    newBillToAdress.phone = primaryPhoneNumber;
                    newShipToAddress.town = shipToAddress?.city;
                    newBillToAdress.town = billToAddress?.city;
                    newShipToAddress.stateProvinceRegionCode = `${shipToAddress?.countryCode}-${shipToAddress?.stateProvinceRegionCode}`;
                    newBillToAdress.stateProvinceRegionCode = `${billToAddress?.countryCode}-${billToAddress?.stateProvinceRegionCode}`;
                    newShipToAddress.postalCode = shipToAddress?.zipPostalCode;
                    newBillToAdress.postalCode = billToAddress?.zipPostalCode;
                    newShipToAddress.streetName = shipToAddress?.addressLine1;
                    newBillToAdress.streetName = billToAddress?.addressLine1;
                    newShipToAddress.country = {'isocode' : shipToAddress?.countryCode};
                    newBillToAdress.country = {'isocode' : billToAddress?.countryCode};
                    newShipToAddress.region = {'isocode' : `${shipToAddress?.countryCode}-${shipToAddress?.stateProvinceRegionCode}`, 'country': {'isocode': shipToAddress?.countryCode}};
                    newBillToAdress.region = {'isocode' : `${billToAddress?.countryCode}-${billToAddress?.stateProvinceRegionCode}`, 'country': {'isocode': billToAddress?.countryCode}};
                    const allAddress = [newShipToAddress, newBillToAdress];
                    const dataToSend = b2bUserRegistrationPayload(
                        contactEmail,
                        firstName,
                        lastName,
                        id,
                        primaryPhoneNumber,
                        allAddress,
                        partnerAccountId,
                        status,
                        name,
                    );
                    dispatch(createB2BUser({ b2BCustomerWsDTO: dataToSend }));
                }

            });
        }
    }, [isAuthenticated])

    useEffect(() => {
        if(isAuthenticated && status !== undefined && status !== 'ACTIVE'){
            window.location.replace('https://develop--resideo-pro.netlify.app/company/application/status');
            return;
        }
    }, [status, isAuthenticated]);

    useEffect(() => {        
            dispatch(getCardDetails());            
    }, [getCartId]);


    return (
        <Switch>
            <AppLayout>
                <Route path='/auth' component={Authentication} />
                <Route
                    path='/get-started'
                    component={() => {
                        window.location.href = URL;
                        return null;
                    }}
                />
                <Route path='/'>
                    <Products />
                </Route>
                <Route path='/profile'></Route>
                <Route path='/subscription'>
                    <SubscriptionsAndServices />
                </Route>
                <Route path='/admin-console'></Route>
                <Route path={`/invoice/:id`} component={Invoice} />
            </AppLayout>
        </Switch>
    );
};

export default Routes;
