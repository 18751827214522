import React from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from '@resideo/blueprint-react';

import { ReactComponent as SuccessIcon } from '../../components/icons/yes.svg';
import { ReactComponent as ErrorIcon } from '../../components/icons/alert.svg';
import { ReactComponent as InfoIcon } from '../../components/icons/info.svg';
import { ReactComponent as BellIcon } from '../../components/icons/bell.svg';

const StatusBox = styled(Box)<{ color: string }>`
  flex-shrink: 0;
  padding: ${({ theme }) => theme.space.small};

  svg {
    fill: ${({ color, theme }) => theme.colors[color]};
    height: 3rem;
    vertical-align: middle;
    width: 3rem;
  }
`;

const Alert = ({ children, variant, ...rest }) => {
  return (
    <Flex {...rest}>
      {variant === 'success' && (
        <StatusBox backgroundColor='background' color='success'>
          <SuccessIcon />
        </StatusBox>
      )}
      {variant === 'error' && (
        <StatusBox backgroundColor='danger' color='background'>
          <ErrorIcon />
        </StatusBox>
      )}
      {variant === 'perks' && <BellIcon />}
      {variant !== 'success' && variant !== 'error' && variant !== 'perks' && (
        <StatusBox backgroundColor='info' color='background'>
          <InfoIcon />
        </StatusBox>
      )}
      <Flex
        flexDirection='column'
        justifyContent='center'
        paddingRight={variant !== 'perks' ? 'xLarge' : 'large'}
        paddingY='medium'>
        {children}
      </Flex>
    </Flex>
  );
};

export const ErrorAlert = (t, msg?: string) => (
  <Alert variant='error'>
    <Text>{msg ? t(msg) : t('common.toast.error')}</Text>
  </Alert>
);

export const SuccessAlert = (t, msg?: string) => (
  <Alert variant='success'>
    <Text>{msg ? t(msg) : t('common.toast.success')}</Text>
  </Alert>
);

export default Alert;
