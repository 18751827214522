import React, { useState } from 'react';
import { Arrow, Wrapper, ListView, ListItem, HeaderTitle } from './MobileViewStyle';
import MobileHeaderMenuItem from './MobileHeaderMenuItem';

const CustomMobileNavLink = ({menuitem, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Wrapper>
            <ListView>
                <ListItem>
                    <HeaderTitle
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}>
                        {props.children}
                    </HeaderTitle>
                    <Arrow></Arrow>
                    <MobileHeaderMenuItem
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        title={props.children}
                        data={menuitem}
                    />
                </ListItem>
            </ListView>
        </Wrapper>
    );
};

export default CustomMobileNavLink;
