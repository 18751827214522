import React, { FC } from 'react';
import { History } from 'history';
import { BrowserRouter, Router as MemoryRouter } from 'react-router-dom';

// TODO: Check on the location attribute incase in throws an error in the future
const Router: FC<{ history?: History }> = ({ history, children }) => {
    if (history) {
        return (
            <MemoryRouter history={history}>
                {children}
            </MemoryRouter>
        );
    }

    return <BrowserRouter basename={process.env.PUBLIC_URL}>{children}</BrowserRouter>;
};

export default Router;
