export declare type ReduxAction = {
    payload: any;
    type: string;
}

export enum httpRequest {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT',
}