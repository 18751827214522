import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { b2bOrgUserUrl, b2bProUserUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    createB2BUserSuccess,
    createB2BUserFail,
    getB2BUserInfoFail,
    getB2BUserInfoSuccess,
} from './action';

function* createB2BUserDetails({ payload }: any): any {
    const { b2BCustomerWsDTO } = payload;
    try {
        const url = b2bProUserUrl + `b2bprousers`;
        const response = yield call(
            api,
            url,
            httpRequest.POST,
            b2BCustomerWsDTO,
            0,
            0,
            false,
            true,
        );
        yield put(createB2BUserSuccess(response));
    } catch (Error: any) {
        yield put(createB2BUserFail(Error));
    }
}
function* getB2BUserInfoDetails({ resolve, reject}: any) {
    try {
        const url = b2bOrgUserUrl + `current?fields=FULL`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getB2BUserInfoSuccess(response));
        resolve(response)
    } catch (error) {
        yield put(getB2BUserInfoFail(error));
        reject(error)
    }
}

function* createB2BUserWatcher() {
    yield takeLatest(types.CREATE_B2B_PRO_USER, createB2BUserDetails);
}

function* getB2BUserInfoWatcher() {
    yield takeLatest(types.FETCH_B2B_USER_INFO, getB2BUserInfoDetails);
}

export default function* b2bUserSaga() {
    yield all([createB2BUserWatcher(), getB2BUserInfoWatcher()]);
}
