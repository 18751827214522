import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Flex } from '@resideo/blueprint-react';
import { HeaderLogo } from './HeaderLogo';
import { useLocation } from 'react-router-dom';
import AboutUs from './AboutUs';
import Language from './Language';

interface PrimaryNavOuter {
    mobileMenuOpen: boolean;
}

export const Container = styled.div`
    // display: flex;
    // margin-right: auto;
    // margin-left: auto;
    // padding-left: 15px;
    // padding-right: 15px;
    // max-width: 1200px;
`;

const StyledHeader = styled.header`
    // width: 100%;
    // background-color: ${({ theme }) => theme.colors.header.secondaryBackground};
    // z-index: 100;

    transition: all 0.3s ease;
    position: sticky;
    position: -webkit-sticky;
    z-index: 9999;
    top: 0;
    width: 100%;
    transform: translateY(0);
    background-color: ${({ theme }) => theme.colors.header.primaryBackground};
    box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
`;

const FixedHeader = styled.div`
    // width: 100%;
    // //position: fixed;
    // top: 0px;
    // background-color: ${({ theme }) => theme.colors.header.primaryBackground};
    // //background: #032a3c;
    // transition: all .3s ease;
    // position: sticky;
    // z-index: 9999;
    // top: 0;
    // width: 100%;
    // transform: translateY(0);
    // box-shadow: 0 0 30px 10px rgba(0,0,0,.3);

    position: relative;
    overflow: visible;
    z-index: 2;
    transition: all 0.3s ease;
    transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    // @media (min-width: 992px) {
    //     overflow: visible;
    // }
`;

const DummyHeader = styled.div`
    width: 100%;
    height: 3.5rem;
`;

const PrimaryNavOuter = styled.nav<PrimaryNavOuter>`
    // display: flex;
    // align-items: center;
    // width: 100%;
    // height: ${({ mobileMenuOpen }) => (mobileMenuOpen ? 'inherit' : '3.75rem')};
    // background-color: #032a3c;
    // overflow: hidden;
    // box-shadow: 0 0 30px 10px rgb(0 0 0 / 30%);
    // @media (min-width: ${({ theme }) => theme.breakpoints.xLarge}) {
    //     height: 3.75rem;
    // }

    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    &:after {
        content: ' ';
        display: table;
    }
     {
        content: ' ';
        display: table;
    }
    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
    @media (max-width: 991px) {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
    @media (min-width: 1400px) {
        width: 1375px;
    }
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const StyledHeaderLogo = styled(HeaderLogo)`
    margin-right: 2rem;
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    @media (min-width: 992px) {
        float: left;
    }
    @media(max-width: 600px) {
        margin-right:  0;
    }
`;

const BannerContainer = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 0;
    background-color: #ededed;
    width: 100%;
`;

const BannerContentContainer = styled.div`
    margin-bottom: 0;
    height: 100%;
`;

const BannerParagraph = styled.p`
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: inherit;
    text-decoration: underline;
    @media (max-width: 767px) {
        font-size: 12px;
    }
`;

const TabsContainer = styled.div`
    position: relative;
    z-index: 4;
    color: #ffffff;
    background-color: #000000;
    width: 100%;
    @media (min-width: 992px) {
        height: 40px;
    }
`;

const FullWidthContainer = styled.div`
    padding: 0;
    overflow: visible;
    margin: 0;
    @media (max-width: 992px) {
        width: 100%;
    }
`;

const BoxTabContainer = styled.div`
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    margin-left: 0;
    margin-right: 0;
    background: #000;
    z-index: 3;
    @media (min-width: 992px) {
        position: absolute;
        height: 40px;
        margin-left: 40px;
    }
    @media (max-width: 992px) {
        display: flex;
        text-align: center;
    }
`;
const BoxNavContainer = styled.div`
    position: absolute;
    top: 0;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    z-index: 2;
    @media (max-width: 992px) {
        display: none;
    }
`;

const BoxTabText = styled.a`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px 22px;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.43px;
    position: relative;
    text-decoration: none !important;
    text-align: center;
    border-radius: 0;
    transition: all 0.3s ease;

    &:first-of-type {
        //background: #fff;
        //color: #000;
        color: #fff;
    }
    &:last-of-type {
        background: #032a3c;
        color: #fff;
    }
    @media (max-width: 992px) {
        flex-grow: 1;
        &:first-of-type {
            background: #fff;
            color: #000;
        }
        &:last-of-type {
            color: #fff;
            background: #032a3c;
        }
    }
`;

const BrandImageButton = styled.img`
    @media (min-width: 991px) {
        padding: 0;
        width: 150px;
        height: 14px;
    }
    @media (max-width: 480px) {
        padding: 12px !important;
    }
    @media (max-width: 992px) {
        padding: 4px 10px !important;
    }
`;

const Button = styled.button`
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;
    &:hover {
        color: #fff;
        opacity: 0.7;
    }
    &.btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
`;

export const Header: FC<{
    primaryNav: ReactNode;
    mobileMenuOpen: boolean;
}> = ({ primaryNav, mobileMenuOpen }): JSX.Element => {
    const { t } = useTranslation();
    const { pathname: path } = useLocation();
    if (path.includes('invoice')) return <></>;
    return (
        <StyledHeader data-test-header>
            <BannerContainer>
                <BannerContentContainer className='container'>
                    <BannerParagraph>
                        {'Learn more about Resideo’s response to COVID-19'}
                    </BannerParagraph>
                </BannerContentContainer>
            </BannerContainer>

            <TabsContainer>
                <FullWidthContainer className='container'>
                    <BoxTabContainer>
                        <BoxTabText>HOMEOWNERS</BoxTabText>
                        <BoxTabText>PROFESSIONALS</BoxTabText>
                    </BoxTabContainer>

                    <BoxNavContainer>
                    <a href="https://www.honeywellhome.com/us/en/?_ga=2.15043382.146639689.1663741014-444046390.1663741014" target="blank">
                        <BrandImageButton 
                        src='https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/White/honeywellhome-logo-white.svg?rv=bf93b93af0fa414991d3e1fc17cd27a5' />
                        </a>
                        <AboutUs/>
                      
                        <BrandImageButton
                            style={{ width: '22px', height: 'auto' }}
                            src='https://www.resideo.com/us/en/-/media/Resideo/Flags/us-flag.png?rv=b66476b9d9434c9fb777979957045464'
                        />
                        <Language/>
                    </BoxNavContainer>
                </FullWidthContainer>
            </TabsContainer>
            <FixedHeader>
                <PrimaryNavOuter aria-label={t('title')} mobileMenuOpen={mobileMenuOpen}>
                    <Container className='section-center'>
                        <Flex
                            flex={1}
                            as='section'
                            flexWrap='wrap'
                            flexDirection='row'
                            alignItems='center'
                            justifyContent={['space-between']}
                            paddingX={['none', 'none', 'none', 'medium']}>
                            <StyledHeaderLogo />
                            {!path.includes('cart') && primaryNav}
                        </Flex>
                    </Container>
                </PrimaryNavOuter>
            </FixedHeader>
            {/* <DummyHeader /> */}
        </StyledHeader>
    );
};

export default Header;
