import React, { useState } from 'react';
import { AccordionContent, AccordionTitle, List, ListText, ScoreImage } from './MobileViewStyle';

const Accordion = ({ title, subLinks, icon }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <AccordionTitle
                className={`${isActive ? 'active' : ''}`}
                onClick={() => setIsActive(!isActive)}>
                <ScoreImage className='img-responsive' src={icon} />
                <div>{title}</div>
            </AccordionTitle>
            {isActive && (
                <AccordionContent>
                    {subLinks.map((data, index) => (
                        <List>
                            <ListText>{data.name}</ListText>
                        </List>
                    ))}
                </AccordionContent>
            )}
        </>
    );
};

export default Accordion;
