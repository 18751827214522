import React, { useEffect, useState } from 'react';
import { Flex, Text, Button, Link } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardButton = styled(Button) <{ text?: string; t?: any }>`
  box-sizing: border-box;
  height: 45px;
  width: 177px;
  border: ${({ text, t }) =>
    text === t('terms.viewDetails') ? 'none' : '2px solid #166a96'};
  border-radius: 22.5px;
  :hover {
    a {
      color: #fff;
    }
  }
`;

const Tile = styled(Flex) <{ status: string }>`
  border: 1px solid #dadada;
  margin: 0;
  padding: 2rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 32.1875rem;
  margin-right: ${({ theme }) => theme.space.medium};
  margin-bottom: 1.5rem;
  div {
    .square {
      width: 4.0625rem;
      height: 4.0625rem;
      display: block;
      max-width: 4.0625rem;
      max-height: 4.0625rem;
      margin-right: 1.25rem;
    }
    .badge {
      font-size: 0.875rem;
      background: ${({ status, theme }) =>
    status === 'Active'
      ? 'green'
      : ['Pending', 'Not Active'].includes(status)
        ? theme.colors.redAlertError
        : 'none'};
      height: fit-content;
      width: ${({ status }) =>
    status === 'Active'
      ? '4.375rem'
      : ['Pending', 'Not Active'].includes(status)
        ? '7.438rem'
        : ''};
      div {
        color: ${({ status, theme }) =>
    status === 'Active'
      ? theme.colors.background
      : ['Pending', 'Not Active'].includes(status)
        ? theme.colors.background
        : 'transparent'};
        margin: auto;
        padding: 0.125rem 0.5rem;
        font-weight: 500;
        letter-spacing: 0;
      }
    }
  }
`;

const MoreOrLessBtn = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffff;
`;

interface HomeTileProps {
  i: number;
  d: any;
  more: boolean;
  showMore: any;
  setShowMoreIndex: any;
}

export const capitalizeFirstLetterLowercaseSecondWord = billingCycle => {
  if (billingCycle === undefined) return;
  return (
    billingCycle
      .split(' ')[0]
      .charAt(0)
      .toUpperCase() +
    billingCycle.substring(1, 6) +
    billingCycle.split('d')[1].toLowerCase()
  );
};

const HomeTile = ({
  i,
  d,
  more,
  showMore,
  setShowMoreIndex,
}: HomeTileProps) => {
  useEffect(() => {
    document.title = "My Business | Service & Subsprictions | Resideo Pro"
  }, []);
  const history = useHistory();
  const { t } = useTranslation();
  const [isShow, setShowHide] = useState(false);

  // this code is commented out because when we get ready to deploy
  // "manage subscription" this will be used for dynamic links to other apps
  // const isDevelopEnvironment =
  //   process.env.REACT_APP_ENVIRONMENT !== 'production';

  const handleRedirectToPdp = (productId) => {
    localStorage.setItem('productID', productId);
    history.push('/pdp/home-owner');
  };

  const showTextandBtn = (desc, length = 75) => {

    if (desc?.length < length || desc === undefined) {
      return <Text>{desc}</Text>;
    }

    if (!isShow) {
      return (
        <Text>
          {/* {desc} */}
          {desc?.slice(0, length)}
          <MoreOrLessBtn
            onClick={() => {
              setShowMoreIndex(i);
              showMore(!more);
              setShowHide((previous) => !previous);
            }}>
            {t('mybusiness.description.more')}
          </MoreOrLessBtn>
        </Text>
      );
    } else {
      return (
        <Text>
          {desc}
          <MoreOrLessBtn
            onClick={() => {
              setShowMoreIndex(i);
              showMore(!more);
              setShowHide((previous) => !previous);
            }}>
            {t('mybusiness.description.less')}
          </MoreOrLessBtn>
        </Text>
      );
    }
  };

  return (
    <Tile status={d.status} data-test-home-tile>
      <Flex display='grid'>
        <Flex flexDirection='column'>
          <Text fontFamily='Roboto' fontWeight='medium' fontSize='1.125rem'>
            {/* {t('mybusiness.services.branding')} */}{d?.name}
          </Text>
          <Text
            fontSize='small'
            color='gray'
            paddingTop='xSmall'
            paddingBottom='medium'>
            {d.status !== 'subscribe' &&
              d.billingCycle}
          </Text>
          <Flex
            alignItems={!more ? 'center' : ''}
            flexDirection={!more ? 'row' : 'column'}>
            <Text
              data-test-basic-description
              fontSize='small'
              paddingTop='xSmall'>
              {showTextandBtn(d.desc)}
            </Text>
          </Flex>
          <Text fontSize='small' style={{ margin: '1.25rem 0 1.75rem' }}>
            {d?.status === 'Active'
              ? `${t('mybusiness.servicesAndSubscriptions.activatedOn')}${d?.activationDate
              }`
              : d?.status === 'Pending' || d?.status === 'Not Active'
                ? `${t('mybusiness.servicesAndSubscriptions.purchasedOn')}${d?.purchaseDate
                }`
                : d?.status === 'Canceled'
                  ? `${t('mybusiness.servicesAndSubscriptions.canceledOn')}${d?.cancellationDate
                  }`
                  : ''}
          </Text>
        </Flex>
        <Flex
          style={{ gridColumnStart: '4', gridRowStart: '1' }}
          className='badge'>
          <Text
            data-test-subscription-statuses
            fontSize='small'
            color='primary'>
            {d.status === 'Active'
              ? d.status
              : t('mybusiness.servicesAndSubscriptions.actionRequired')}
          </Text>
        </Flex>
      </Flex>
      {d.status !== 'Canceled' && d?.status !== 'subscribe' ? (
        <Flex
          justifyContent={['flex-start']}
          flexDirection={['column', 'row', 'row', 'row']}
          alignItems={['', '', 'center', 'center']}>
          {d.btnLeft === 'Activate Service' ? (
            <Text
              fontSize='medium'
              color='primary'
              fontWeight='500'
              paddingTop={['1rem', '1rem', 0, 0]}
              marginRight='2rem'>
              {t('cart.completeOrder.activate')}
            </Text>
          ) : (
            <CardButton
              t={t}
              text={d.btnLeft}
              data-test-aircycle-dashboard
              data-test-activate-service
              variant='primary'
              fontSize='1rem'
              paddingY='0'
              paddingX='xSmall'
              marginRight='2rem'>
              {d.btnLeft === 'Activate Service' ? (
                <StyledLink href='#!'>{d.btnLeft}</StyledLink>
              ) : (
                <StyledLink href='#!'>{d.btnLeft}</StyledLink>
              )}
            </CardButton>
          )}
          {d.status !== 'Canceled' && (
            <Link
              onClick={() => {
                history.push(`${d.subLinkPath}/${d._id}`);
              }}
              fontSize='medium'
              color='primary'
              fontWeight='500'
              paddingTop={['1rem', '1rem', 0, 0]}>
              {d.btnRight}
            </Link>
          )}
        </Flex>
      ) : (
        <Link
          onClick={() => {
            handleRedirectToPdp(d?.productCode ? d?.productCode : 'HH06');
          }}
          data-test-view-details
          fontSize='medium'
          color='primary'
          fontWeight='500'
          paddingTop={['1rem', '1rem', 0, 0]}
          style={{ width: 'fit-content' }}>
          {d.status === 'subscribe'
            ? t('mybusiness.servicesAndSubscriptions.viewPlans')
            : t('mybusiness.servicesAndSubscriptions.viewDetails')}
        </Link>
      )}
    </Tile>
  );
};

export default HomeTile;
