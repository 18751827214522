import { types } from './types';
  
  export const cartItemsReducer = (state, action) => {
    switch (action.type) {
        case types.SET_CART_ITEMS:
            return {
              ...state,
              cartItems: action.payload,
            };
            case types.SET_SHIPPING_ADDRESS:
            return {
              ...state,
              shippingAddress: action.payload,
            };
            case types.SET_BILLING_ADDRESS:
            return {
              ...state,
              billingAddress: action.payload,
            };
            case types.SET_SHIPPING_METHOD:
            return {
              ...state,
              shippingMethod: action.payload,
            };
            case types.SET_CREDIT_CARD:
            return {
              ...state,
              creditCard: action.payload,
            };
      default:
        return state;
    }
  };
  