import { GraphQLResponse, RequestParameters, Variables } from 'relay-runtime';
import { Auth0ContextInterface } from '@auth0/auth0-react';


interface FetchQueryFactory {
  url: string;
}

export const encodeId = ({ appName, appVersion, md5Hash }) => btoa(`${appName}:${appVersion}:${md5Hash}`);

const formatUrl = (url: string, operation: RequestParameters) =>
  process.env.REACT_APP_USE_PERSISTED_QUERY
    ? `${url}/${encodeId({
        appName: process.env.REACT_APP_NAME,
        appVersion: process.env.REACT_APP_VERSION,
        md5Hash: operation.cacheID,
      })}`
    : url;

export function fetchQueryFactory({ url }: FetchQueryFactory) {
  return async function fetchQuery(operation: RequestParameters, variables: Variables): Promise<GraphQLResponse> {
    const accessToken = localStorage.getItem('jwt');

    const response = await fetch(formatUrl(url, operation), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      },
      body: JSON.stringify({
        ...(!process.env.REACT_APP_USE_PERSISTED_QUERY && { query: operation.text }),
        variables,
      }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // TODO; Call Auth0 with a refresh_token to get a new access_token?
        throw new Error('Received unauthorized status code from GraphQL');
      }
    }

    const result = await response.json();

    return result;
  };
}
