const footerLegalConfig = {
    pdp: [
        {
            textKey: 'footer.legal.links.privacypolicy',
            linkPath:
                'https://store.digitalriver.com/store/defaults/en_US/DisplayDRPrivacyPolicyPage/eCommerceProvider.DR+globalTech+Inc',
        },
        {
            textKey: 'footer.legal.links.termsOfSale',
            linkPath:
                'https://store.digitalriver.com/store/defaults/en_US/DisplayDRTermsAndConditionsPage/eCommerceProvider.DR+globalTech+Inc',
        },
        {
            textKey: 'footer.legal.links.californiaRights',
            linkPath:
                'https://www.digitalriver.com/privacy-policy/#privacy_commitment_to_california_residents',
        },
        {
            textKey: 'footer.legal.links.returnPolicy',
            linkPath:
                'https://www.resideo.com/us/en/pro/order-faq/returns-cancellations/#refund-policy',
        },
        {
            textKey: '',
            linkPath: '#',
            teconsent: true,
        },
    ],
    HAB: [
        {
            textKey: 'footer.legal.links.resideo',
            linkPath: 'https://resideo.com',
        },
        {
            textKey: 'footer.legal.links.honeywellhome',
            linkPath: 'https://honeywellhome.com',
        },
        {
            textKey: 'footer.legal.links.adiglobal',
            linkPath: 'https://www.adiglobal.com',
        },
        {
            textKey: 'footer.legal.links.smallPrivacyPolicy',
            linkPath:
                'https://store.digitalriver.com/store/defaults/en_US/DisplayDRPrivacyPolicyPage/eCommerceProvider.DR+globalTech+Inc',
        },
        {
            textKey: 'footer.legal.links.termsconditions',
            linkPath: 'https://pro.resideo.com/terms',
        },
        {
            textKey: '',
            linkPath: '#',
            teconsent: true,
        },
    ],
    MENUITEM: [
        {
            textKey: 'About Us',
            linkPath: 'https://www.resideo.com/us/en/corporate/about/corporate-overview/',
        },
        {
            textKey: 'Businesses',
            linkPath: 'https://www.resideo.com/us/en/corporate/businesses/',
        },
        {
            textKey: 'Investors',
            linkPath:
                'https://investor.resideo.com/overview/default.aspx?_ga=2.241857218.1551123378.1659360756-482481834.1659095836',
        },
        {
            textKey: 'News',
            linkPath: 'https://www.resideo.com/us/en/corporate/newsroom/',
        },
        {
            textKey: 'Careers',
            linkPath: 'https://www.resideo.com/us/en/corporate/about/careers/',
        },
        {
            textKey: 'For Homeowners',
            linkPath: 'https://www.resideo.com/us/en/',
        },
        {
            textKey: 'Accessibility',
            linkPath: 'https://www.resideo.com/us/en/accessibility/',
        },
    ],
    SOCIAL: [
        {
            textKey: 'twitter',
            imageUrl:
                'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/White/footer-twitter.svg?rv=3ca0178be79b4c92b353827bcc495581',
            linkPath: 'https://twitter.com/ResideoPro',
        },
        {
            textKey: 'facebook',
            imageUrl:
                'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/White/footer-facebook.svg?rv=e16fa08e62d14f8babcb864128acfb47',
            linkPath: 'https://www.facebook.com/ResideoPro',
        },
        {
            textKey: 'youtube',
            imageUrl:
                'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/White/footer-youtube.svg?rv=7455764c3f2749048cb1f8f47849dc54',
            linkPath: 'https://www.youtube.com/channel/UCct6QIRVW2kU2mqkKVLHPLA',
        },
        {
            textKey: 'linkedin',
            imageUrl:
                'https://www.resideo.com/us/en/-/media/Resideo/Consumer-Images/SVG%20Assets/White/footer-linkdin.svg?rv=dc75a2394dc1432887670420fcebb235',
            linkPath:
                'https://www.linkedin.com/authwall?trk=bf&trkInfo=bf&original_referer=https://www.resideo.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fresideo1%2F',
        },
    ],
};

export { footerLegalConfig as default };
