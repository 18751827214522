import React, { FC } from 'react';
import styled from 'styled-components';
import { Spinner } from '@resideo/blueprint-react';

const LoadingCaptureLayer = styled.div`
  z-index: 9000;
  position: fixed;
  top: -10px;
  left: -10px;
  width: 103vw;
  height: 103vh;
  background-color: rgb(0, 0, 0, 0.1);
`;

const LoadingContainer = styled.div`
  z-index: 9001;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: ${({ theme }) => theme.space.small};
`;

export const PageLoading: FC = () => {
  return (
    <LoadingCaptureLayer>
      <LoadingContainer>
        <Spinner color='primary' size='xxLarge' />
      </LoadingContainer>
    </LoadingCaptureLayer>
  );
};

export default PageLoading;
