import React, { FC } from 'react';
import styled from 'styled-components';

const Line = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #ededed;
  margin: 25px 0;
`;

interface SeparatorProps {
  m?: string;
  borderColor?: string;
}

const SeparatorLine: FC<SeparatorProps> = ({ m, borderColor }) => {
  return (
    <Line
      style={{
        margin: m ? m : '',
        borderColor: borderColor || '#ededed',
      }}></Line>
  );
};

export default SeparatorLine;
