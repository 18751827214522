import produce from 'immer';
import { types } from './types';
import { ReduxAction } from '../types';


const initialState = {
    token: '',
    refreshToken: '',
    expires_in: '',
    logginIn: false,
    logInError: null,
    appLaunchFlowComplete: false,
    loggingOut: false,
    logoutError: null,
    jwtErrorMsg: null,
    isLoggedIn: false,
    profile: null,
    loginInProgress: false,
    userDetails: {},
    fetchingUserDetails: false,
}

const authentication = (state = initialState, action: ReduxAction) => {
    const { type, payload } = action;

    switch (type) {
        case types.SET_JWT:
            return produce(state, (draftState: typeof initialState) => {
                const { token, refreshToken, expires_in } = payload;
                draftState.token = token;
                draftState.refreshToken = refreshToken;
                draftState.expires_in = expires_in;
            })
        case types.LOGIN:
            return produce(state, (draftState: typeof initialState) => {
                draftState.logginIn = true
            })
        case types.LOGIN_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const {
                    profile,
                    expiresAt} = payload;
                draftState.logginIn = false;
                draftState.logInError = null;
                draftState.isLoggedIn = true;
                draftState.expires_in = expiresAt;
                draftState.profile = profile;
            })
        case types.LOGIN_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.logginIn = false;
                draftState.logInError = payload;
            })
            case types.LOGOUT:
                return produce(state, (draftState) => {
                    draftState.loggingOut = true;
                });
            case types.LOGOUT_SUCCESS:
                return produce(state, (draftState) => {
                    draftState.loggingOut = false;
                    draftState.token = '';
                    draftState.refreshToken = '';
                });
            case types.LOGOUT_FAIL:
                return produce(state, (draftState) => {
                    draftState.logoutError = payload;
                    draftState.loggingOut = false;
                });
            case types.DELETE_JWT_SUCCESS:
                return produce(state, (draftState) => {
                    draftState.token = '';
                    draftState.refreshToken = '';
                });
            case types.GET_JWT_FAIL:
                return produce(state, (draftState) => {
                    draftState.jwtErrorMsg = payload;
                });
            case types.APP_LAUNCH_FLOW_COMPLETE:
                return produce(state, (draftState) => {
                    draftState.isLoggedIn = true
                })
            case types.LOGIN_PROCESS_COMPLETE:
                return produce(state, (draftState: typeof initialState) => {
                    draftState.loginInProgress = false
                })
            case types.HANDLE_AUTHENTICATION_CALLBACK:
                return produce(state, (draftState: typeof initialState) => {
                    draftState.loginInProgress = true
                })
            case 'FETCH_USER_DETAILS_SUCCESS':
                return produce(state, (draftState: typeof initialState) => {
                    draftState.fetchingUserDetails = false
                    draftState.userDetails = payload.data.viewer;
                })
            case 'FETCH_DETAILS_SUCCESS':
                return produce(state, (draftState: typeof initialState) => {
                    draftState.fetchingUserDetails = true
                })
        default:
            return state;
    }

    // Handle Authentication here
}

export { authentication }