import { types } from './types';

export const appLaunchFlow = () => ({
    type: types.APP_LAUNCH_FLOW,
});

export const appLaunchFlowComplete = (userIsAuthenticated: boolean) => ({
    type: types.APP_LAUNCH_FLOW_COMPLETE,
    payload: userIsAuthenticated,
});

export const deleteJwt = () => ({
    type: types.DELETE_JWT,
});

export const deleteJwtSuccess = () => ({
    type: types.DELETE_JWT_SUCCESS,
});

export const deleteJwtFailed = (error: any) => ({
    type: types.DELETE_JWT_FAILED,
    payload: error,
});

export const getJwt = () => ({
    type: types.GET_JWT,
});

export const setJwt = (payload: any) => ({
    type: types.SET_JWT,
    payload,
});

export const getJwtSuccess = () => ({
    type: types.GET_JWT_SUCCESS,
});

export const getJwtFailed = (error: any) => ({
    type: types.GET_JWT_FAIL,
    payload: error,
});


export const login = (payload: any) => ({
    type: types.LOGIN,
    payload,
});

export const loginSuccess = (payload: any) => ({
    type: types.LOGIN_SUCCESS,
    payload,
});

export const loginFailed = (error: any) => ({
    type: types.LOGIN_FAIL,
    payload: error,
});

export const loginCancelled = () => ({
    type: types.LOGIN_CANCELLED,
});

export const logout = () => ({
    type: types.LOGOUT,
});

export const logoutSuccess = () => ({
    type: types.LOGOUT_SUCCESS,
});

export const logoutFailed = (error: any) => ({
    type: types.LOGOUT_FAIL,
    payload: error,
});

export const handleAuthenticationCallback = () => ({
    type: types.HANDLE_AUTHENTICATION_CALLBACK
})

export const loginProcessComplete = () => ({
    type: types.LOGIN_PROCESS_COMPLETE
})
