import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, Heading, Text } from '@resideo/blueprint-react';

export const PageError = () => {
  const { t } = useTranslation();

  return (
    <Box
      marginX='auto'
      maxWidth='48rem'
      paddingX={[0, 'medium']}
      paddingY={['large', 'large', 'xLarge']}>
      <Card borderRadius={[0, 'medium']} padding='large'>
        <Heading as='h1' fontSize='large' marginBottom='medium'>
          {t('pageError.heading')}
        </Heading>
        <Text>{t('pageError.content')}</Text>
      </Card>
    </Box>
  );
};

export default PageError;
