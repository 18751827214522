/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Button, Box, Text, Checkbox, Label, Flex, Heading, Card } from '@resideo/blueprint-react';

import TaxExemption from '../../../components/cart/payment/TaxExemption';
import OrderSummaryDesktop from '../../../components/cart/OrderSummaryDesktop';
import OrderSummaryMobile from '../../../components/cart/OrderSummaryMobile';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import GoBackButton from '../../../components/common/GoBackButton';

import { cartLinksData } from '../../../config/cartLinks';
import ContactForm from '../../../components/cart/shipping/ContactForm';
import moment from 'moment';

import styled from 'styled-components';
import { getFullStateByCode } from '../../../config/states';
import PaymentMethodFormBody, {
    ExistingCreditCardItem,
    ExpirationStatus,
    getCCType,
    getFirstNumFromBrand,
    getMonthAndYear,
    initialPaymentBodyValues,
    PaymentMethodOptions,
    validatePaymentMethod,
    validExpDate,
} from '../../../components/payment/PaymentMethodFormBody';
import { processPaymentSources } from '../../../components/Modal/PaymentMethodForm';
import paymentData from '../../../data/paymentData.json';

const PageTitle = styled(Heading)`
    text-transform: uppercase;
    padding-bottom: 24px;
    color: ${({ theme }) => theme.colors.textLight3};
    font-family: 'URW_Din_CondBold';
    font-size: 2.75rem;
    letter-spacing: 1.47px;
    @media (max-width: 768px) {
        font-size: 30px;
    }
`;

export const PaymentContainer = styled(Card)<{ isError?: boolean }>`
    border: ${({ isError, theme }) =>
        isError ? '2px solid red' : `2px solid ${theme.colors.lightenGray}`};
    border-radius: 0.9375rem;
    padding: 1.5625rem;
`;

const Payment = ({ updateCheckoutMutation, digitalRiverBillingId, viewer, defaultBillTo }) => {
    const { t } = useTranslation();

    const [digitalRiverPayment, setDigitalRiverPayment] = useState() as any;
    const [isPayPalEnabled, setIsPayPalEnabled] = useState(false);
    const [payPalSourceData, setPayPalSourceData] = useState({
        result: {} as any,
        isAuthorize: false,
    });
    const [ifAgreedToAutoRenewalTerms, setIfAgreedToAutoRenewalTerms] = useState(false);
    const [taxExempt, setTaxExempt] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [uploadedImg, setUploadedImg] = useState('');
    const [savedCards /*, setSavedCards*/] = useState<ExistingCreditCardItem[]>(
        processPaymentSources(
            viewer?.partnerUsers?.edges[0]?.node?.partnerAccount?.partnerBillings?.edges[0]?.node
                ?.paymentSources ?? [],
        ),
    );

    const history = useHistory() as any;

    if (defaultBillTo || isPayPalEnabled) {
        // remove warning
    }

    useEffect(() => {
        if (history.location.state && history.location.state.tax) {
            setTaxExempt(true);
        }
    }, [history.location]);

    const partnerAccount = viewer?.partnerUsers?.edges[0]?.node?.partnerAccount;
    const partnerBilling = partnerAccount?.partnerBillings?.edges[0]?.node;
    const paymentSources = partnerBilling?.paymentSources;

    const taxCert = partnerBilling?.taxCertificate;

    const state = paymentData;

    // useEffect(() => {
    //   if (digitalRiverPayment === undefined) {
    //     // @ts-expect-error because we need to ignore this
    //     const paymentLib = new DigitalRiver(
    //       `${process.env.REACT_APP_DIGITAL_RIVER_API_PUBLIC_KEY}`
    //     );
    //     setDigitalRiverPayment(paymentLib);
    //   }
    // }, []);

    const sourceData = {
        type: 'creditCard',
        sessionId: '',
        futureUse: true,
        usage: 'subscription',
        mandate: {
            terms: t('cart.payment.terms'),
        },
        owner: {
            firstName: '',
            lastName: '',
            email: `${partnerAccount?.contactEmail ?? viewer.contactEmail}`,
            phone: `${partnerAccount?.primaryPhoneNumber ?? viewer.primaryPhoneNumber}`,
            organization: '',
            address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                country: 'US',
                postalCode: 0,
            },
        },
        creditCard: {
            number: '',
            expirationMonth: 0,
            expirationYear: 0,
            cvv: '',
        },
        amount: 0,
        currency: 'USD',
    };

    const validate = (values) => {
        const paymentErrors = validatePaymentMethod(values.payment, t);
        if (paymentErrors) {
            return { payment: paymentErrors };
        }
        return undefined;
    };

    const paymentHandler = () => {
        history.push('/cart/review-order');
    };

    const fromReviewPage = history.location.state && history.location.state.tax;

    const initialPaymentValues = {
        paymentMethod: '' as PaymentMethodOptions,
        savedSourceIndex: -1,
    };

    const currentCardIndex = savedCards.findIndex(
        (x) => x.paymentSourceId === paymentSources[0]?.paymentSourceId,
    );

    if (currentCardIndex >= 0) {
        initialPaymentValues.paymentMethod = 'existingCC';
        initialPaymentValues.savedSourceIndex = currentCardIndex;
    }

    const data = () => ({
        email: '',
        phone: '',
        willReceiveUpdates: false,
        additionalEmails: '',
        shippingMethod: '',
        shippingAddress: {
            companyName: '',
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        shippingAddressEdit: {
            companyName: '',
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        multipleAddresses: state && state.multipleAddresses ? state.multipleAddresses : [],
        saveCardToAccount: false,
        defaultBillTo: {
            companyName: '',
            contactName: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            stateProvinceRegionCode: '',
            countryCode: '',
            zipPostalCode: '',
        },
        autoRenewal: false,
        promoCode: '',
        taxExemptionApplication: {
            companyName: fromReviewPage ? fromReviewPage.companyName : taxCert?.companyName ?? '',
            taxAuthority: fromReviewPage
                ? fromReviewPage.taxAuthority
                : getFullStateByCode(taxCert?.taxAuthority) ?? '',
            startDate: fromReviewPage
                ? moment.utc(fromReviewPage.startDate).format('YYYY-MM-DD')
                : taxCert?.startDate
                ? moment.utc(taxCert.startDate).format('YYYY-MM-DD')
                : '',
            endDate: fromReviewPage
                ? moment.utc(fromReviewPage.endDate).format('YYYY-MM-DD')
                : taxCert?.endDate
                ? moment.utc(taxCert.endDate).subtract(1, 'second').format('YYYY-MM-DD')
                : '',
        },
        taxCertificate: '',
        radio: '',
        // use the paymentMethodBody composable formik values
        payment: { ...initialPaymentBodyValues(initialPaymentValues) },
    });

    const hasProduct = state?.order?.lineItems?.filter((f) => f.type === 'product').length > 0;

    const disableSubmitButton = (formik) => {
        console.log(formik.errors);
        return !ifAgreedToAutoRenewalTerms || state.isFetching || formik.errors?.payment;
    };

    return (
        <>
            <CheckoutSteps step3 />
            <Box data-test-payment-page marginBottom='medium' marginX='large'>
                <PageTitle
                    data-test-payment-page-title
                    paddingTop={['medium', 'medium', 'large', 'large']}>
                    {t('cart.shipping.checkout')}
                </PageTitle>
                <Formik initialValues={data()} onSubmit={paymentHandler} validate={validate}>
                    {(formik) => (
                        <Form>
                            <OrderSummaryMobile values={formik.values} />
                            <Flex
                                justifyContent='space-between'
                                width='100%'
                                flexDirection={['column', 'column', 'column', 'row']}>
                                <Box width={['100%', '100%', '100%', '49%']} marginBottom='medium'>
                                    {!hasProduct && (
                                        <>
                                            <Text
                                                data-test-section-title
                                                paddingBottom='medium'
                                                fontWeight='bold'
                                                fontSize='1.313rem'>
                                                {t('cart.orderComplete.contactInformation')}
                                            </Text>
                                            <ContactForm
                                                values={formik.values}
                                                setFieldValue={formik.setFieldValue}
                                                viewerData={viewer}
                                                fErrors={formik.errors}
                                            />
                                        </>
                                    )}
                                    <Text
                                        data-test-section-title
                                        paddingY='medium'
                                        fontWeight='bold'
                                        fontSize='1.313rem'>
                                        {t('cart.payment.paymentMethods')}
                                    </Text>
                                    <Box data-test-payment-sections>
                                        <PaymentContainer>
                                            <PaymentMethodFormBody
                                                savedCreditCardSources={savedCards}
                                                drJsLibrary={digitalRiverPayment}
                                                formik={formik}
                                                config={{
                                                    valuesProperty: 'payment',
                                                    withSavedCards: true,
                                                    withPayPal: true,
                                                    hideSavedCardsWhenNotActive: true,
                                                    withMakePrimary: true,
                                                    withRenewalTerms: false,
                                                }}
                                                setIsNewCardSelected={undefined}
                                                agreementSelected={undefined}
                                            />
                                        </PaymentContainer>
                                    </Box>
                                    <TaxExemption
                                        data-test-payment-sections
                                        values={formik.values}
                                        setFieldValue={formik.setFieldValue}
                                        errors={formik.errors}
                                        taxExempt={taxExempt}
                                        setTaxExempt={setTaxExempt}
                                        uploadedImg={uploadedImg}
                                        setUploadedImg={setUploadedImg}
                                        setFile={setFile}
                                    />
                                </Box>
                                <Box
                                    width={['100%', '100%', '100%', '49%']}
                                    marginBottom='medium'
                                    paddingTop='2.75rem'>
                                    <OrderSummaryDesktop
                                        setFieldValue={formik.setFieldValue}
                                        values={formik.values}
                                    />
                                    <Box padding='medium'>
                                        <Text
                                            color='header.secondaryLinkActive'
                                            fontSize='small'
                                            lineHeight='21px'>
                                            {t('cart.payment.completePurchaseText')}
                                        </Text>
                                        <Text
                                            color='header.secondaryLinkActive'
                                            fontSize='small'
                                            paddingTop='large'
                                            lineHeight='21px'>
                                            {t('cart.payment.iAgreeText')}
                                        </Text>
                                    </Box>

                                    <Box padding='medium'>
                                        <Label>
                                            <Checkbox
                                                name='autoRenewal'
                                                type='checkbox'
                                                marginRight='0.625rem'
                                                onChange={(e) =>
                                                    formik.setFieldValue(
                                                        'autoRenewal',
                                                        e.target.checked,
                                                    )
                                                }
                                                disabled={state.isFetching}
                                                checked={formik.values.autoRenewal}
                                                onClick={() =>
                                                    setIfAgreedToAutoRenewalTerms(
                                                        !ifAgreedToAutoRenewalTerms,
                                                    )
                                                }
                                            />
                                            <Text
                                                color={'textHeading'}
                                                fontSize='small'
                                                fontWeight='normal'
                                                fontStyle='italic'>
                                                {t('cart.payment.autoRenewalText')}
                                            </Text>
                                        </Label>
                                    </Box>

                                    <Flex
                                        justifyContent='flex-end'
                                        alignItems='center'
                                        marginBottom='medium'
                                        flexDirection={[
                                            'column-reverse',
                                            'column-reverse',
                                            'column-reverse',
                                            'row',
                                        ]}>
                                        <Box paddingTop={['medium', 'medium', 'medium', '0']}>
                                            <GoBackButton
                                                url={`${process.env.PUBLIC_URL ?? ''}${
                                                    hasProduct ? cartLinksData[1] : cartLinksData[0]
                                                }`}
                                                text={
                                                    hasProduct
                                                        ? t('cart.shipping.backToShipping')
                                                        : t('cart.backToCart')
                                                }
                                            />
                                        </Box>

                                        {/* {formik.values.payment.paymentMethod === 'payPal' &&
                    !payPalSourceData.isAuthorize ? (
                      <Paypal
                        drJsLibrary={digitalRiverPayment}
                        viewer={viewer}
                        partnerAccount={partnerAccount}
                        setIsPayPalEnabled={setIsPayPalEnabled}
                        setPayPalSourceData={setPayPalSourceData}
                        values={formik.values.payment}
                      />
                    ) : ( */}
                                        <Button
                                            data-test-go-to-review-order
                                            disabled={disableSubmitButton(formik)}
                                            onClick={() => {
                                                setTaxExempt(false);
                                            }}
                                            type='submit'
                                            width={['100%', 'auto', 'auto', 'auto']}
                                            variant='primary'
                                            marginLeft='medium'>
                                            {t('cart.reviewOrder.reviewOrder')}
                                        </Button>
                                        {/* )} */}
                                    </Flex>
                                </Box>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default Payment;
