import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { httpRequest } from '../types';
import {
    deliveryAddressUrl,
    updateDeliveryAddressUrl,
    creditCardUrl,
    placeOrderUrl,
    consentsUrl
} from '../../config/api/url';
import { removeCartItems, retrieveCartID } from 'redux/cart';
import {
    getAddressDetailsSuccess,
    getAddressDetailsFail,
    addDeliveryAddressSuccess,
    addDeliveryAddressFail,
    updateDeliveryAddressSuccess,
    updateDeliveryAddressFail,
    getDeliveryModeDetailsSuccess,
    getDeliveryModeDetailsFail,
    updateDeliveryModeSuccess,
    updateDeliveryModeFail,
    selectDeliveryAddressSuccess,
    selectDeliveryAddressFail,
    getCreditCardDetailsFail,
    getCreditCardDetailsSuccess,
    createCreditCardSuccess,
    createCreditCardFail,
    updateCreditCardSuccess,
    updateCreditCardFail,
    placeOrderSuccess,
    placeOrderFail,
    updateShippingInfoSuccess,
    updateShippingInfoFail,
    reviewOrderSuccess,
    reviewOrderFail,
    createConsentsSuccess,
    createConsentsFail,
    getConsentsDetailsSuccess,
    getConsentsDetailsFail,
    deleteConsentsSuccess,
    deleteConsentsFail,
    createContactInfoSuccess,
    createContactInfoFail
} from './action';

function* getCartDetails(): any {
    let cartID: string = '';

    try {
        cartID = yield call(retrieveCartID);
        return { cartID };
    } catch (error) {
        console.log('getCartDetails', error);
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* getAddressListDetails({ payload }: any): any {
    try {
        const url = deliveryAddressUrl + `current/addresses`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getAddressDetailsSuccess(response.addresses));
    } catch (error) {
        yield put(getAddressDetailsFail(error));
    }
}

function* addDeliveryAddress({ payload, resolve, reject }: any): any {
    const { body } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = deliveryAddressUrl + `current/carts/${cartID}/addresses/delivery`;
        const response = yield call(api, url, httpRequest.POST, body, 0, 0);
        yield put(addDeliveryAddressSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(addDeliveryAddressFail(error));
        reject(error);
    }
}

function* updateDeliveryAddress({ payload }: any): any {
    const {  addressId, body } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = deliveryAddressUrl + `current/addresses/${addressId}`;
        const response = yield call(api, url, httpRequest.PUT, body, 0, 0);
        yield put(updateDeliveryAddressSuccess(response));
    } catch (error) {
        yield put(updateDeliveryAddressFail(error));
    }
}

function* selectDeliveryAddress({ payload, resolve, reject }: any): any {
    const {  addressId } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url =
            updateDeliveryAddressUrl +
            `current/carts/${cartID}/addresses/delivery?addressId=${addressId}`;
        const response = yield call(api, url, httpRequest.PUT, {}, 0, 0);
        yield put(selectDeliveryAddressSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(selectDeliveryAddressFail(error));
        reject(error);
    }
}

function* getDeliveryModeList({ payload }: any): any {
    const { cartID } = yield* getCartDetails();
    try {
        const url = deliveryAddressUrl + `current/carts/${cartID}/deliverymodes`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getDeliveryModeDetailsSuccess(response.deliveryModes));
    } catch (error) {
        yield put(getDeliveryModeDetailsFail(error));
    }
}

function* updateDeliveryMode({ payload, resolve, reject}: any): any {
    const {  deliveryModeId } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url =
            deliveryAddressUrl +
            `current/carts/${cartID}/deliverymode?deliveryModeId=${deliveryModeId}`;
        const response = yield call(api, url, httpRequest.PUT, {}, 0, 0);
        resolve(response);
        yield put(updateDeliveryModeSuccess(response));
    } catch (error) {
        yield put(updateDeliveryModeFail(error));
        reject(error);
    }
}

function* getCreditCardListDetails({ payload }: any): any {
    try {
        const url = creditCardUrl + `current/paymentdetails?saved=true`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getCreditCardDetailsSuccess(response.payments));
    } catch (error) {
        yield put(getCreditCardDetailsFail(error));
    }
}

function* createCreditCard({ payload, resolve, reject }: any): any {
    const { body } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = creditCardUrl + `current/carts/${cartID}/paymentdetails`;
        const response = yield call(api, url, httpRequest.POST, body, 0, 0);
        yield put(createCreditCardSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(createCreditCardFail(error));
        reject(error);
    }
}

function* updateCreditCard({ payload, resolve, reject }: any): any {
    const { paymentId } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url =
            creditCardUrl + `current/carts/${cartID}/paymentdetails?paymentDetailsId=${paymentId}`;
        const response = yield call(api, url, httpRequest.PUT, {}, 0, 0);
        yield put(updateCreditCardSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(updateCreditCardFail(error));
        reject(error);
    }
}

function* placeOrder({ payload, resolve, reject }: any): any {
    const { termsValue } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = placeOrderUrl + `current/orders?cartId=${cartID}&termsChecked=${termsValue}`;
        const response = yield call(api, url, httpRequest.POST, {}, 0, 0);
        yield put(placeOrderSuccess(response));
        resolve(response);
    } catch (error) {
        yield put(placeOrderFail(error));
        reject(error);
    }
}

function* updateShippingInfo({ payload, resolve, reject }: any) {
    const { addressId, deliveryModeId, userInfoPayload } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const addressUrl =
            updateDeliveryAddressUrl +
            `current/carts/${cartID}/addresses/delivery?addressId=${addressId}`;

        const shipTypeUrl =
            deliveryAddressUrl +
            `current/carts/${cartID}/deliverymode?deliveryModeId=${deliveryModeId}`;

        const userInfoUrl = deliveryAddressUrl + `current/carts/${cartID}/contacts`;
        const [shipAddress, userInfo, shipType] = yield all([
            call(api, addressUrl, httpRequest.PUT, {}, 0, 0),
            call(api, shipTypeUrl, httpRequest.PUT, {}, 0, 0),
            call(api, userInfoUrl, httpRequest.POST, userInfoPayload, 0, 0),
        ]);
        yield put(updateShippingInfoSuccess({ shipAddress, userInfo, shipType }));
        resolve({ shipAddress, userInfo, shipType });
    } catch (error) {
        yield put(updateShippingInfoFail(error));
        reject(error);
    }
}

function* reviewOrder(): any {
    const { cartID } = yield* getCartDetails();
    try {
        const url = creditCardUrl + `current/carts/${cartID}?fields=FULL`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(reviewOrderSuccess(response));
    } catch (error) {
        yield call(removeCartItems);
        yield put(reviewOrderFail(error));
    }
}

function* getConsentList(): any {
    try {
        const url = consentsUrl + `current/consenttemplates`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        yield put(getConsentsDetailsSuccess(response.consentTemplates));
    } catch (error) {
        yield put(getConsentsDetailsFail(error));
    }
}

function* createConsent({ payload }: any): any {
    const { templateId, version } = payload;
    try {
        const url = consentsUrl + `current/consents?consentTemplateId=${templateId}&consentTemplateVersion=${version}`;
        const response = yield call(api, url, httpRequest.POST, {}, 0, 0);
        yield put(createConsentsSuccess(response));
    } catch (error) {
        yield put(createConsentsFail(error));
    }
}

function* deleteConsent({ payload }: any): any {
    const { consentCode } = payload;
    try {
        const url = consentsUrl + `current/consents/${consentCode}`;
        const response = yield call(api, url, httpRequest.DELETE, {}, 0, 0);
        yield put(deleteConsentsSuccess(response));
    } catch (error) {
        yield put(deleteConsentsFail(error));
    }
}

function* createContactDetails({ payload }: any): any {
    const { cartID } = yield* getCartDetails();
    const { contactInfo } = payload;
    try {
        const userInfoUrl = deliveryAddressUrl + `current/carts/${cartID}/contacts`;
        const response = yield call(api, userInfoUrl, httpRequest.POST, contactInfo, 0, 0);
        yield put(createContactInfoSuccess(response));
    } catch (error) {
        yield put(createContactInfoFail(error));
    }
}

function* getAddressDetailsWatcher() {
    yield takeLatest(types.FETCH_ADDRESS_DETAILS, getAddressListDetails);
}

function* addDeliveryAddressWatcher() {
    yield takeLatest(types.ADD_DELIVERY_ADDRESS, addDeliveryAddress);
}

function* updateDeliveryAddressWatcher() {
    yield takeLatest(types.UPDATE_DELIVERY_ADDRESS, updateDeliveryAddress);
}

function* getDeliveryModeWatcher() {
    yield takeLatest(types.FETCH_DELIVERY_MODES, getDeliveryModeList);
}

function* updateDeliveryModeWatcher() {
    yield takeLatest(types.UPDATE_DELIVERY_MODES, updateDeliveryMode);
}

function* selectDeliveryModeWatcher() {
    yield takeLatest(types.SELECT_DELIVERY_ADDRESS, selectDeliveryAddress);
}

function* getCreditCardDetailsWatcher() {
    yield takeLatest(types.FETCH_CREDIT_CARD_DETAILS, getCreditCardListDetails);
}

function* createCreditCardWatcher() {
    yield takeLatest(types.CREATE_CREDIT_CARD_DETAILS, createCreditCard);
}

function* updateCreditCardWatcher() {
    yield takeLatest(types.UPDATE_CREDIT_CARD_DETAILS, updateCreditCard);
}

function* placeOrderWatcher() {
    yield takeLatest(types.PLACE_ORDER, placeOrder);
}

function* updateShippingInfoWatcher() {
    yield takeLatest(types.UPDATE_SHIPPING_INFO, updateShippingInfo);
}

function* reviewOrderWatcher() {
    yield takeLatest(types.REVIEW_ORDER_DETAILS, reviewOrder);
}

function* getConsentListWatcher() {
    yield takeLatest(types.FETCH_CONSENTS_LIST, getConsentList);
}

function* createConsentWatcher() {
    yield takeLatest(types.CREATE_CONSENTS, createConsent);
}

function* deleteConsentWatcher() {
    yield takeLatest(types.DELETE_CONSENTS, deleteConsent);
}

function* createContactDetailsWatcher() {
    yield takeLatest(types.CREATE_CONTACT_DETAILS, createContactDetails);
}

export default function* checkoutListSaga() {
    yield all([
        getAddressDetailsWatcher(),
        addDeliveryAddressWatcher(),
        updateDeliveryAddressWatcher(),
        getDeliveryModeWatcher(),
        updateDeliveryModeWatcher(),
        selectDeliveryModeWatcher(),
        getCreditCardDetailsWatcher(),
        createCreditCardWatcher(),
        updateCreditCardWatcher(),
        placeOrderWatcher(),
        updateShippingInfoWatcher(),
        reviewOrderWatcher(),
        getConsentListWatcher(),
        createConsentWatcher(),
        deleteConsentWatcher(),
        createContactDetailsWatcher(),
    ]);
}
