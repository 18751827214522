import React from 'react';
import {
    RdeCard,
    RdeCardCaption,
    RdeCardDescription,
    RdeCardImage,
    RdeCardImageWrapper,
    RdeCardText,
} from './MobileViewStyle';

const MobileCardView = ({ data } : any) => {    
    return (
        <RdeCard>
            <RdeCardImageWrapper>
                <RdeCardImage src={data.imageLink} />
            </RdeCardImageWrapper>
            <RdeCardCaption>
                <RdeCardText>{data.title}</RdeCardText>
                <RdeCardDescription>{data.description}</RdeCardDescription>
            </RdeCardCaption>
        </RdeCard>
    );
};

export default MobileCardView;
