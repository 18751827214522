import React, { FC } from 'react';
import { Box, Card, Heading, Text, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ShippingOrderItem from './shipping/ShippingOrderItem';
import AddPromoCode from '../../components/common/AddPromoCode';
import SeparatorLine from '../common/SeparatorLine';
import { useDispatch } from 'react-redux';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { deletePromoCode } from 'redux/promocode/action';
import { getCardDetails } from 'redux/cart/actions';

const Container = styled(Card)`
    border: 2px solid ${({ theme }) => theme.colors.collapseBorder};
    border-radius: 0.9375rem;
    background-color: #fff;
`;

const RefText = styled(Text)`
    color: ${({ theme }) => theme.colors.summaryParagraph};
    font-size: ${({ theme }) => theme.fontSizes.small};
    font-weight: bold;
    padding: ${({ theme }) => theme.space.small} 0;
    font-family: 'Roboto';
`;

const TotalText = styled(Heading)`
    color: ${({ theme }) => theme.colors.cartTextHeading};
    letter-spacing: normal;
    font-size: ${({ theme }) => theme.fontSizes.medium};
    text-align: right;
    font-weight: bold;
`;

const Promo = styled(Flex)`
    .show-x {
        position: absolute;
        display: block;
        cursor: pointer;
        z-index:1;
    }
    .remove {
        height: 24px;
        color: #286DAA;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 24px;
      }
    // :hover {
    //     .show-x {
    //         position: absolute;
    //         display: block;
    //         cursor: pointer;
    //     }
    // }
`;

const PromoCodeValue = styled(Text)`
  height: 22px;
  color: #000000;
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21.77px;
`;

interface OrderSummaryDesktopProps {
    setFieldValue?: any;
    values?: any;
}

const OrderSummaryDesktop: FC<OrderSummaryDesktopProps> = ({ setFieldValue, values }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const cartId: any = localStorage.getItem('cartId') ?? '';

    let cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];

    const hasProduct =
        cartItems &&
        cartItems?.entries &&
        cartItems?.entries?.length > 0 &&
        cartItems?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length >
        0;

    const removePromoCode = async (value) => {
        hookDispatch(deletePromoCode({ voucherId: value }))
            .then((response) => {
                setFieldValue('promoCode', '');
                dispatch(getCardDetails());
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }

    return (
        <Container display={['none', 'none', 'none', 'block']} padding='large'>
            <Flex justifyContent='space-between' marginBottom='medium' alignItems={'center'}>
                <Box>
                    <Heading color='textLight3' fontSize='1.125rem' fontFamily='Roboto'>
                        {t('cart.shipping.orderSummary')}
                    </Heading>
                    <RefText>REF # {cartId}</RefText>
                </Box>
                <Box display={'flex'} justifyContent={'flex-end'} minWidth={'22.18rem'}>
                    <AddPromoCode setFieldValue={setFieldValue} />
                </Box>
            </Flex>
            <SeparatorLine />
            {cartItems &&
            cartItems?.entries?.length > 0 &&
                cartItems?.entries?.map((item, i) => <ShippingOrderItem data={item} key={i} />)}

            <Box data-test-shipping-form-sections>
                {/* {cartItems && cartItems?.appliedVouchers && (
                    cartItems?.appliedVouchers.map((item, index) => (
                        <Promo paddingBottom='small' key={`promo-${index}`}>
                            <Box onClick={() => removePromoCode(item.code)} className='show-x'>
                                <Text className='remove' textAlign='right'>
                                    <i className='fas fa-times'></i>{' Remove'}
                                </Text>
                            </Box>
                            <Box flex='75%'>
                                <Text textAlign='right'>Promo Code </Text>
                            </Box>
                            <Box flex='25%'>
                                <PromoCodeValue textAlign='right'>{item.voucherCode}</PromoCodeValue>
                            </Box>
                        </Promo>
                    ))
                )} */}
                {hasProduct && (
                    <>
                        {/* <Flex paddingBottom='small'>
                            <Box flex='75%'>
                                <Text color='cartTextHeading' textAlign='right'>
                                    {t('subtotalToday')}
                                </Text>
                            </Box>
                            <Box flex='25%'>
                                <Text color='cartTextHeading' textAlign='right'>
                                    {cartItems?.subTotalWithoutDiscount?.formattedValue}
                                </Text>
                            </Box>
                        </Flex> */}

                        <Flex paddingBottom='small'>
                            <Box flex='75%'>
                                <Text textAlign='right' color='cartTextHeading'>
                                    {t('cart.shipping.shipping')}
                                </Text>
                            </Box>
                            <Box flex='25%'>
                                <Text textAlign='right' color='cartTextHeading'>
                                    {cartItems?.deliveryCost?.formattedValue || '$0.00'}
                                </Text>
                            </Box>
                        </Flex>
                    </>
                )}

                {cartItems && cartItems?.appliedVouchers && cartItems?.appliedVouchers?.length > 0 && (
                    <Flex paddingBottom='small'>
                        <Box flex='75%'>
                            <Text textAlign='right'>
                                {/* {t('cart.youSaved')} */}
                                {t('discount')}
                            </Text>
                        </Box>
                        <Box flex='25%'>
                            <Text textAlign='right'>
                                {'-'}{cartItems?.totalDiscounts?.formattedValue}
                            </Text>
                        </Box>
                    </Flex>
                )}

                <Flex paddingBottom='medium'>
                    <Box flex='75%'>
                        <Text textAlign='right' color='cartTextHeading'>
                            {t('tax')}
                        </Text>
                    </Box>
                    <Box flex='25%'>
                        <Text textAlign='right' color='cartTextHeading'>
                            {cartItems?.totalTax?.formattedValue}
                        </Text>
                    </Box>
                </Flex>
                <Flex>
                    <Box flex='75%'>
                        <TotalText color='cartTextHeading'>{t('totalToday')}</TotalText>
                    </Box>
                    <Box flex='25%'>
                        <TotalText>{cartItems?.totalPriceWithTax?.formattedValue}</TotalText>
                    </Box>
                </Flex>
            </Box>
        </Container>
    );
};

export default OrderSummaryDesktop;