import React from 'react';
import { Heading, Box, Text, Image, Link } from '@resideo/blueprint-react';
import styled from 'styled-components';
import appleAppStoreBadge from '../../assets/images/productDetails/Download_on_the_App_Store_Badge.svg';
import googlePlayBadge from '../../assets/images/productDetails/google-play-badge.svg';
import { useTranslation } from 'react-i18next';

const Container = styled(Box)`
  background-color: #ccc;
  padding: 3rem 0;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  padding: 4.375rem 15.625rem;
  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const HeadingHGS = styled(Heading)`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 35px;
    padding-bottom: 1.3rem;
  }
`;

const HeadingTitle = styled(Heading)`
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 30px;
  }
`;

const BoxOverview = styled(Box)`
  padding: 0px 50px 70px 0px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const GetStarted = ({ getStartedRef }) => {
  const { t } = useTranslation();
  return (
    <Container
      ref={getStartedRef}
      data-test-co-related-product-page
      id='Get Started'>
      <HeadingHGS
        paddingBottom='3.75rem'
        fontFamily='URW_Din_CondBold'
        fontSize='3.75rem'
        color='header.secondaryLinkActive'
        lineHeight='5rem'
        data-test-co-related-product-title>
        {t('getStartedDetail.howTogetStarted')}
      </HeadingHGS>

      <Box justifyContent='left'>
        <Box id='Resources' justifyContent='left' display='flex'>
          <BoxOverview>
            <HeadingTitle
              fontFamily='URW_DIN_CONDBOLD'
              fontSize='2.813rem'
              color='header.secondaryLinkActive'
              lineHeight='2.813rem'>
              {t('getStartedDetail.getHeading1')}
            </HeadingTitle>
            <Heading
              as='h4'
              paddingTop='large'
              color='header.secondaryLinkActive'
              fontFamily='Roboto'
              fontWeight='700'
              fontSize='1rem'>
              {t('getStartedDetail.getHeadingTitle1')}
            </Heading>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              lineHeight='2.188rem'
              paddingTop='xSmall'
              fontFamily='Roboto'
              fontWeight='400'
              fontSize='1.313rem'>
              {t('getStartedDetail.getHeadingDesc1')}
            </Text>

            <Heading
              as='h4'
              paddingTop='large'
              color='header.secondaryLinkActive'
              fontFamily='Roboto'
              fontWeight='700'
              fontSize='1rem'>
              {t('getStartedDetail.getHeadingTitle2')}
            </Heading>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              lineHeight='2.188rem'
              paddingTop='xSmall'
              fontFamily='Roboto'
              fontWeight='400'
              fontSize='1.313rem'>
              {t('getStartedDetail.getHeadingDesc2')}{' '}
              <Link
                href={t('getStartedDetail.signUpLink')}
                target='blank'
                color='#0091FF'
                style={{ textDecoration: 'none' }}
                fontFamily='Roboto'
                fontWeight='400'
                fontSize='1.313rem'>
                {t('getStartedDetail.signUpHere')}
              </Link>
            </Text>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              lineHeight='2.188rem'
              paddingTop='2rem'
              fontFamily='Roboto'
              fontSize='1.313rem'
              fontWeight='400'>
              {t('getStartedDetail.getHeadingDesc3')}
            </Text>
            <HeadingTitle
              color='header.secondaryLinkActive'
              paddingTop='4rem'
              lineHeight='2.813rem'
              fontFamily='URW_DIN_CONDBOLD'
              fontSize='2.813rem'>
              {t('getStartedDetail.getHeading2')}
            </HeadingTitle>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              lineHeight='2.188rem'
              fontFamily='Roboto'
              fontWeight='400'
              fontSize='1.313rem'
              paddingTop='0.75rem'>
              {t('getStartedDetail.getHeadingDesc4')}
            </Text>
            <Box paddingTop='1.25rem'>
              <Link
                href={'https://apps.apple.com/us/app/resideo-pro/id1470259201'}
                target={'_blank'}>
                <Image
                  src={appleAppStoreBadge}
                  alt='appleAppStoreBadge'
                  paddingRight='0.875rem'
                />
              </Link>
              <Link
                href={
                  'https://play.google.com/store/apps/details?id=com.resideo.rezi_pro'
                }
                target={'_blank'}>
                <Image src={googlePlayBadge} alt='googlePlayBadge' />
              </Link>
            </Box>
            <HeadingTitle
              fontSize='2.813rem'
              fontFamily='URW_DIN_CONDBOLD'
              color='header.secondaryLinkActive'
              paddingTop='4rem'
              lineHeight='2.813rem'>
              {t('getStartedDetail.getHeading3')}
            </HeadingTitle>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              fontFamily='Roboto'
              fontWeight='400'
              fontSize='1.313rem'
              lineHeight='2.188rem'
              paddingTop='1.375rem'>
              {t('getStartedDetail.getHeadingDesc6')}
            </Text>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              fontFamily='Roboto'
              fontWeight='400'
              fontSize='1.313rem'
              lineHeight='2.188rem'>
              {t('getStartedDetail.getHeadingDesc7')}
            </Text>
            <Text
              data-test-cart-item-sku
              color='header.secondaryLinkActive'
              fontFamily='Roboto'
              fontWeight='400'
              fontSize='1.313rem'
              lineHeight='2.188rem'>
              {t('getStartedDetail.getHeadingDesc8')}
            </Text>
          </BoxOverview>
        </Box>
      </Box>
    </Container>
  );
};

export default GetStarted;
