import React from 'react';
import styled, { keyframes } from 'styled-components';

const slideLeft = keyframes`
0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
`;

const slideRight = keyframes`
0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
`;

export const Container = styled.div`
    position: absolute;
    display: none;
    top: 0;
    //left: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
    height: 100%;
    margin: 0;
    //padding: 1rem;
    padding-top: 4rem;
    opacity: 1;
    //overflow-y: auto;
    visibility: visible;
    transform: translateX(0%);
    box-shadow: none;
    z-index: 2;
    background: #ffffff;
    transition: all 0.3s ease;
    top: 75px;

    &.active {
        animation: ${slideLeft} 0.5s ease forwards;
        display: block;
    }
    &.close {
        animation: ${slideRight} 0.5s ease forwards;
    }
    &:before {
        content: ' ';
        display: table;
    }
    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
    @media (max-device-width: 480px) and (orientation: landscape) {
        max-height: 200px;
    }
`;
export const ListItemContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 360px);
    display: block;
    width: 100%;
    background: #fff;
    margin-top: -65px;
    left: 0;
    overflow: scroll;
    width: 100%;
    height: calc(100vh - 90px);
`;
export const HeaderTextContainer = styled.a`
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 0;
    padding: 0 55px;
    background: #166a96;
    transition: all 0.3s ease;
    position: relative;
    font-weight: 500;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:before {
        position: absolute;
        top: 50%;
        left: 32px;
        width: 10px;
        height: 17px;
        content: '';
        transform: translateY(-45%);
        background: url('https://www.resideo.com/areas/resideo/img/arrow-left-white.svg') center
            center no-repeat;
        background-size: 10px;
    }
`;

export const AccordionTitle = styled.a`
    display: flex;
    align-items: center;
    flex-direction: row;
    //justify-content: space-between;
    cursor: pointer;
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;
    font-size: 24px;
    font-weight: 700;
    color: #060202 !important;
    height: 100px;
    padding: 0 32px;
    border-bottom: 1px solid #fff;
    background: #ededed;
    &:after {
        position: absolute;
        top: 50%;
        right: 32px;
        width: 10px;
        height: 17px;
        content: '';
        transform: translateY(-45%) rotate(90deg);
        background: url('https://www.resideo.com/areas/resideo/img/arrow-right-black.svg') center
            center no-repeat;
        background-size: 10px;
        transition: all 0.3s ease;
    }
    &.active {
        color: #166a96 !important;
        &:after {
            transform: translateY(-45%) rotate(-90deg);
        }
    }
`;

export const ScoreImage = styled.img`
    width: 43px;
    height: auto;
    margin: 0 16px 0 0;
    &.img-responsive {
        display: block;
        max-width: 100%;
        height: auto;
    }
`;

export const AccordionContent = styled.div`
    max-height: inherit;
    padding: 32px;
    overflow: hidden;
    transition: all 0.3s ease;
    background: #fff;
`;

export const List = styled.li`
    display: block;
    list-style: none;
    &:last-child a {
        color: #000;
        font-weight: 700;
        text-decoration: underline;
        margin-bottom: 0;
    }
`;

export const ListText = styled.a`
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #166a96;
    line-height: 16px;
    height: auto;
    text-transform: inherit;
    background: transparent;
    margin-bottom: 26px;
    padding: 0;
    &:hover {
        color: #004266;
    }
`;

export const FeaturedWrapper = styled.div`
    flex-grow: 1;
    //width: calc(100% - 32px);
    background: #ededed;
    margin: 16px;
    padding: 16px;
    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
    &:before {
        content: ' ';
        display: table;
    }
`;

export const ScoreLead = styled.p`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 18px;
    margin: 30px 0 16px 0;
    margin-top: 0px;
`;

export const ProductHighlight = styled.div`
    display: inline-flex;
    flex-direction: column;
    width: calc(50% - 16px);
    float: left;
    margin-right: 8px;
    justify-content: flex-start;
`;

export const ScoreHighlight = styled.div`
    display: block;
    height: auto !important;
    cursor: pointer;
    margin-right: 0;
    margin-left: 0;
    padding: 0 !important;
    flex-direction: column;
    margin-bottom: 8px;
    align-items: center;
    &:first-of-type {
        float: left;
    }
    &:last-of-type {
        float: right;
    }
`;

export const ScoreHighlightImage = styled.img`
    width: 140px;
    height: auto;
    min-width: inherit;
    min-height: inherit;
    margin-bottom: 8px;
    transition: all 0.3s ease;
    background: #fff;
    max-width: 100%;
    padding: 0;
    margin: 0 auto 16px auto;
    float: none !important;
    display: block !important;
`;

export const ScoreHighlightParagraph = styled.p`
    width: 100%;
    text-align: center;
    display: block;
    min-height: auto;
    color: #000;
    margin: 0;
    overflow: hidden;
    font-size: 16px;
    font-weight: bold;
    line-height: 21px;
    max-height: inherit;
    text-align: center !important;
`;

export const Arrow = styled.span`
    position: relative;
    top: 0;
    width: 20px;
    height: 12px;
    transform: rotate(-90deg);
    border: none;
    background: url('https://www.resideo.com/areas/resideo/img/arrow-down-white.svg') center center
        no-repeat;
    background-size: 20px;
    transition: all 0.3s ease;
`;

export const Wrapper = styled.div`
    border-top: 0;
    box-shadow: none;
`;

export const ListView = styled.ul`
    height: auto;
    margin: 0;
    position: static;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    &:before {
        content: '';
        display: table;
    }
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    background: #166a96 !important;
    border-bottom: 1px solid #fff;
`;

export const HeaderTitle = styled.a`
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #fff !important;
    transition: all 0.3s ease;
    position: relative;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: calc(90% - 54px);
    height: 50px;
    padding: 0 32px;
`;

export const FooterMenuContainer = styled.div`
    display: block;
    align-items: center;
    margin-top: 16px;
    padding-top: 16px;
    min-height: initial;
`;

export const FooterMenuItemText = styled.a`
    display: block;
    margin: 0 32px 32px 32px;
    position: relative;
    text-decoration: none !important;
    text-align: center;
    border-radius: 0;
    transition: all 0.3s ease;
    color: #166a96;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-right: 0;
    margin-right: 25px;
    &:after {
        background: url('https://www.resideo.com/areas/resideo/img/button-arrow-blue.png') center
            center no-repeat;
        background-size: 20px;
        position: relative;
        top: 0;
        right: -12px;
        display: inline-block;
        width: 20px;
        height: 10px;
        content: '';
        transition: all 0.3s ease;
    }
`;

export const FooterMenuButtonStyle = styled.div`
    display: block;
    margin: 0 32px 32px 32px;
    padding: 12px 32px;
    letter-spacing: 1px;
    font-weight: bold;
    justify-content: center;
    color: #166a96;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    border: 2px solid #166a96;
    z-index: 1;
    overflow: hidden;
    position: relative;
    text-decoration: none !important;
    text-align: center;
    border-radius: 0;
    transition: all 0.3s ease;
`;

export const MobileViewHomeContainer = styled.div`
    flex-grow: 1;
    margin: 16px;
    padding: 16px;
    margin-bottom: 0;
    &:after {
        content: ' ';
        display: table;
        clear: both;
    }
    &:before {
        content: ' ';
        display: table;
    }
`;
export const MobileViewImageWrapper = styled.img`
    position: relative;
    width: 100%;
`;
export const MobileViewSideDealCaption = styled.div`
    flex-grow: 1;
    text-align: center;
    background: #2e2e2e;
    padding: 16px;
`;
export const MobileViewSideDealHeading = styled.h2`
    position: relative;
    font-family: 'roboto', sans-serif;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 4px 0;
    padding: 0 20px 0 0;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    &:after {
        position: absolute;
        top: 50%;
        width: 20px;
        height: 10px;
        background: url('https://www.resideo.com/areas/resideo/img/button-arrow-white.png') center
            center no-repeat;
        transition: all 0.3s ease;
        background-size: 20px 10px;
        transform: translateY(-50%);
        content: '';
        margin-left: 12px;
    }
`;

export const MobileViewDescription = styled.p`
    font-size: 14px;
    color: #fff;
    line-height: 21px;
    margin: 0;
    white-space: normal;
`;

export const RdeCard = styled.div`
    display: flex;
    justify-content: center;
    background: #ededed;
    transition: all 0.3s ease;
    flex-direction: row;
    align-items: center;
    height: 140px;
    position: relative;
    flex-grow: 0;
    padding: 8px;
    margin: 0px;
    border-bottom: 1px solid #fff;
    padding: 0 16px;

    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    }
`;

export const RdeCardImageWrapper = styled.div`
    width: 43px;
    height: auto;
    margin: 0 16px 0 0;
`;
export const RdeCardImage = styled.img`
    width: 100%;
    height: 100%;
    max-width: 100%;
`;

export const RdeCardCaption = styled.div`
    width: 100%;
    width: calc(100% - 92px);
    text-align: left;
`;

export const RdeCardText = styled.h2`
    margin: 0 0 16px 0;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    z-index: 1;
    font-weight: bold;
    margin-bottom: 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

export const RdeCardDescription = styled.p`
    display: block;
    z-index: 1;
    font-size: 14px;
    margin: 0;
`;
