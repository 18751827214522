import styled from 'styled-components';
import { Heading } from '@resideo/blueprint-react';

export const Title = styled(Heading)`
  color: ${({ theme }) => theme.colors.textHeading};
  font-size: 1.3125rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  font-family: 'Roboto';
`;
