import React, { FC, useEffect, useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Menu, MenuButton, MenuLink, MenuList } from '@reach/menu-button';

import { Avatar } from '@resideo/blueprint-react';
import ProfileAvatar from '../../components/common/ProfileAvatar';
import profileMenuItems from 'config/profileMenu';
import profileMenuItemsSecurity from 'config/profileMenuSecurity';
import { Link } from 'react-router-dom';
import { signIn, signOut } from '../../config/Auth';
import { logout } from '../../redux/authentication/action';
import { useDispatch } from 'react-redux';

const MenuLinkRow = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-items: center;
`;

const ProfileMenuButton = styled(MenuButton)`
    appearance: none;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    border: none;
    border-radius: 50%;
    max-width: 2.25rem;
    max-height: 2.25rem;
    outline: none;
    &:focus {
        box-shadow: 0 0 0.1rem 0.1rem #ccc;
    }
`;

const ProfileMenuDropDownList = styled(MenuList)`
    position: absolute;
    right: -10px;
    text-align: left;
    z-index: var(--zidx-profile-menu);
    &:after {
        content: '';
        position: absolute;
        right: 20px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${({ theme }) => theme.colors.header.primaryBackground};
        clear: both;
    }
`;

interface ProfileMenuState {
    firstName?: string;
    lastName?: string;
    photoUrl?: string;
    partnerUser?: any;
    isAdmin?: boolean;
    isOwner?: boolean;
    partnerAccountId?: string | null;
}

const ProfileMenu: FC = (rest) => {
    useEffect(() => {
        document.title = `User Profile | Resideo Pro`;
    }, []);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSignOut = () => {
        dispatch(logout());
        signOut();
    };

    const [profileMenuState, setProfileMenuState] = useState<ProfileMenuState>({
        firstName: '',
        lastName: '',
        photoUrl: '',
        isAdmin: false,
        isOwner: false,
        partnerAccountId: '',
    });
    const viewerHasPermissions = profileMenuState.isOwner || profileMenuState.isAdmin;
    return (
        <Menu>
            <ProfileMenuButton data-test-profile-menu-button {...rest}>
                <Suspense
                    fallback={<Avatar aria-label={t('header.primary.settings')} width='100%' />}>
                    <ProfileAvatar
                        firstName={profileMenuState.firstName}
                        lastName={profileMenuState.lastName}
                        photoUrl={profileMenuState.photoUrl}
                        variant='small'
                    />
                </Suspense>
            </ProfileMenuButton>
            <ProfileMenuDropDownList data-test-profile-menu>
                {profileMenuItems(viewerHasPermissions ?? false).map((data): JSX.Element | null => {
                    if (!data.linkPath || !data.linkTextKey) return null;
                    return !data.isExternalLink ? (
                        <MenuLink
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            as={Link as any}
                            key={data.name}
                            to={data.linkPath}>
                            {t(data.linkTextKey)}
                        </MenuLink>
                    ) : (
                        <MenuLink key={data.name} href={data.linkPath} onClick={handleSignOut}>
                            {t(data.linkTextKey)}
                        </MenuLink>
                    );
                })}
            </ProfileMenuDropDownList>
        </Menu>
    );
};

export default ProfileMenu;
