export const types = {
    FETCH_CART_ID: 'FETCH_CART_ID',
    FETCH_CART_ID_SUCCESS: 'FETCH_CART_ID_SUCCESS',
    FETCH_CART_ID_FAIL: 'FETCH_CART_ID_FAIL',
    CREATE_CART_ID: 'CREATE_CART_ID',
    CREATE_CART_ID_SUCCESS: 'CREATE_CART_ID_SUCCESS',
    CREATE_CART_ID_FAIL: 'CREATE_CART_ID_FAIL',
    ADD_PRODUCT_IN_CART: 'ADD_PRODUCT_IN_CART',
    ADD_PRODUCT_IN_CART_SUCCESS: 'ADD_PRODUCT_IN_CART_SUCCESS',
    ADD_PRODUCT_IN_CART_FAIL: 'ADD_PRODUCT_IN_CART_FAIL',
    UPDATE_CART_QUANTITY: 'UPDATE_CART_QUANTITY',
    UPDATE_CART_QUANTITY_SUCCESS: 'UPDATE_CART_QUANTITY_SUCCESS',
    UPDATE_CART_QUANTITY_FAIL: 'UPDATE_CART_QUANTITY_FAIL',
    REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
    REMOVE_CART_ITEM_SUCCESS: 'REMOVE_CART_ITEM_SUCCESS',
    REMOVE_CART_ITEM_FAIL: 'REMOVE_CART_ITEM_FAIL',
    FETCH_CART_DETAILS: 'FETCH_CART_DETAILS',
    FETCH_CART_DETAILS_SUCCESS: 'FETCH_CART_DETAILS_SUCCESS',
    FETCH_CART_DETAILS_FAIL: 'FETCH_CART_DETAILS_FAIL',
    UPDATE_CART_FLAG: 'UPDATE_CART_FLAG',
}