import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputField } from './common/InputField';

export const ErrorInputField = styled(InputField)<{
  isError?: boolean;
  errorColor?: any;
}>`
  border: ${({ isError, errorColor }) =>
    isError
      ? `1px solid ${errorColor ? '#C6361C' : 'red'} !important`
      : '1px solid #166a96'};
  box-shadow: ${({ isError, errorColor }) =>
    isError && !errorColor ? '0px 0px 0px 1px red inset' : 'none'};
  font-size: '1rem',
  font-family: 'Roboto',
  :focus {
    box-shadow: ${({ isError, errorColor }) =>
      isError && !errorColor ? '0px 0px 0px 1px red inset' : 'none'};
  }
`;

export const useSwitchPlaceholder = (str, errors) => {
  const [placeholder, setPlaceholder] = useState(str);
  useEffect(() => {
    let mounted = true;
    if (Object.keys(errors).length > 0) {
      setPlaceholder('Required');

      setTimeout(() => {
        if (!mounted) return;
        setPlaceholder(str);
      }, 2000);
    }

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line
  }, [str, errors]);

  return placeholder;
};
