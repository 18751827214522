import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, MenuButton,MenuList,MenuLink } from '@reach/menu-button';
import { Image} from '@resideo/blueprint-react';
import { downArrowIcon, upArrowIcon } from '../../assets/images';

const Button = styled(MenuButton)`
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 22px;
    &:hover {
        color: #fff;
        opacity: 0.7;
    }
    &.btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
`;
const CustomMenuList = styled(MenuList)`
    position: fixed;
    right: 10px;
    top: 60px;
    background-color:white;
    margin-top: 30px;
    margin-right: 107px;
    padding: 18px;
    box-sizing: border-box;
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        right: 70px;
        top: 50px;
        width: auto;
    }
`;

const CustomMenuLink= styled(MenuLink)`
font-size: 14px;
font-weight: 500;
line-height: 28px;
letter-spacing: 1px;
display:flex;
text-decoration: none;
background-color: transparent;
color: #166a96;
word-wrap: break-word;
&:hover {
      color: #004266;
      text-decoration: underline;
      cursor: pointer;
  }
`;
const aboutUsItems=[
  {
    button:'Who We Are',
    buttonLink:'https://www.resideo.com/us/en/home-for-the-home/'
  },
  {
    button:'Company Overview',
    buttonLink:'https://www.resideo.com/us/en/corporate/about/corporate-overview/'
  },
  {
    button:'Careers',
    buttonLink:'https://www.resideo.com/us/en/corporate/about/careers'
  },
  {
    button:'Newsroom',
    buttonLink:'https://www.resideo.com/us/en/corporate/newsroom/'
  },
  {
    button:'Investors',
    buttonLink:'https://investor.resideo.com/overview/default.aspx?_ga=2.73116947.1643675536.1663827564-606748399.1663827564'
  },
]


const About=()=>
{
  
  const [about, setAbout] = useState(false);
  const handleAbout=()=>
  {
    setAbout(!about);
  }
  return(
    <Menu>
      <Button onClick={handleAbout}>
        About Us 
        <Image
            src={about ? upArrowIcon : downArrowIcon}
            alt='down-arrow'
            paddingLeft={['small', 'small', 'medium', 'medium']}
        />
      </Button>
      <CustomMenuList portal={false}>
        {aboutUsItems.map((item, index)=>
          <CustomMenuLink as="a" target='_blank' key={index}
          href={item.buttonLink}>
          {item.button}
        </CustomMenuLink>
          )
        }
       </CustomMenuList>
  </Menu>
  )
}
export default About;