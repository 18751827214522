import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProductList } from 'redux/productlist/actions';
import {
    Container,
    DescriptionText,
    DescriptionWrapper,
    HeaderText,
    List,
    ListItem,
    Title,
    Wrapper,
    ArrowButton,
} from './ProductTypeTopNav.styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';

const StyledSlider = styled(Slider)`
    @media (min-width: 991px) {
        display: none !important;
    }
`;

const ProductTypeTopNav = () => {
    const [activeId, setActiveId] = useState(0);
    const dispatch = useDispatch();
    const slider: any = useRef();

    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <ArrowButton onClick={onClick} className='slick-prev slick-arrow'>
                <span className='slick-prev-icon' aria-hidden='true'></span>
                <span className='slick-sr-only'>Previous Slide</span>
            </ArrowButton>
        );
    };

    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <ArrowButton onClick={onClick} className='slick-next slick-arrow'>
                <span className='slick-next-icon' aria-hidden='true'></span>
                <span className='slick-sr-only'>Next Slide</span>
            </ArrowButton>
        );
    };

    const next = () => {
        slider.current.slickNext();
    };
    const previous = () => {
        slider.current.slickPrev();
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: false,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    const menuItems = [
        {
            title: 'Air',
            headerText: 'PRO SMART HOME AIR PRODUCTS',
            description:
                'Keep your customer`s home air cleaner and their comfort more supported with Honeywell Home products from Resideo: Thermostats, ventilation and filtration systems for the supported smart home from a trusted brand name.',
            descImageUrl: 'https://www.resideo.com/areas/resideo/img/cat-description-air-bg.jpg',
            link: 'RB2B.Air',
        },
        {
            title: 'Security',
            headerText: 'PRO SMART HOME SECURITY PRODUCTS',
            description:
                'Help your customers feel safer and more secure by helping protect what matters most with Honeywell Home products by Resideo: All-in-one security systems, sound and motion sensors and video monitors for the supported smart home from a trusted brand name.',
            descImageUrl:
                'https://www.resideo.com/areas/resideo/img/cat-description-security-bg.jpg',
            link: 'RB2B.Security',
        },
        {
            title: 'Water',
            headerText: 'PRO SMART HOME WATER PRODUCTS',
            description:
                'Help prevent water waste and damage in your customer’s home with water leak detectors from Resideo: Products for the supported smart home from a trusted brand name.',
            descImageUrl: 'https://www.resideo.com/areas/resideo/img/cat-description-water-bg.jpg',
            link: 'RB2B.Water',
        },
        {
            title: 'Energy',
            headerText: 'PRO SMART HOME ENERGY PRODUCTS',
            description:
                'Give your customers the power to control all of their electrical systems and help them reduce energy usage with Honeywell Home products from Resideo: Products that support critical infrastructure like water heaters and furnaces from a trusted brand name.',
            descImageUrl: 'https://www.resideo.com/areas/resideo/img/cat-description-energy-bg.jpg',
            link: 'RB2B.Energy',
        },
    ];

    const getProducts = async (id) => {
        await dispatch(getProductList({ id, pageNumber: '0' }));
    };

    const renderItems = () => {
        return menuItems.map((card, index) => (
            <>
                <div key={index} data-test-top-nav>
                    <ListItem
                        key={index}
                        onClick={() => {
                            setActiveId(index);
                            getProducts(card.link);
                        }}>
                        <Title
                            className={`item${index}background ${
                                activeId === index ? 'active' : ''
                            }`}>
                            {card.title}
                        </Title>
                    </ListItem>
                </div>
            </>
        ));
    };

    return (
        <Container product-type-top-navigation>
            <Wrapper>
                <List>
                    {menuItems.map((menu, index) => {
                        return (
                            <ListItem
                                key={index}
                                onClick={() => {
                                    setActiveId(index);
                                    getProducts(menu.link);
                                }}>
                                <Title
                                    className={`item${index}background ${
                                        activeId === index ? 'active' : ''
                                    }`}>
                                    {menu.title}
                                </Title>
                            </ListItem>
                        );
                    })}
                </List>

                <StyledSlider ref={(c) => (slider.current = c)} {...settings} data-test-slider>
                    {renderItems}
                </StyledSlider>
            </Wrapper>
            <DescriptionWrapper
                title={menuItems[activeId].title}
                imageUrl={menuItems[activeId].descImageUrl}>
                <HeaderText>{menuItems[activeId].headerText}</HeaderText>
                <DescriptionText>{menuItems[activeId].description} </DescriptionText>
            </DescriptionWrapper>
        </Container>
    );
};

export default ProductTypeTopNav;
