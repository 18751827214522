import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { getProductDetailsUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import { getProductDetailSuccess, getProductDetailFail } from './actions';

function* getProductDetails({ payload }: any): any {
    try {
        const url = getProductDetailsUrl + `${payload}`;
        const response = yield call(api, url, httpRequest.GET, null, 2,0, false, true);
        yield put(getProductDetailSuccess(response));
    } catch (error) {
        yield put(getProductDetailFail(error));
    }
}

function* getProductDetailsWatcher() {
    yield takeLatest(types.FETCH_PRODUCT_DETAILS, getProductDetails);
}

export default function* productDetailSaga() {
    yield all([getProductDetailsWatcher()]);
}
