import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { Text, Heading, Flex } from '@resideo/blueprint-react';

export const FacetFilterContainer = styled.div`
    background: transparent;
    margin: 0 0 32px 0;
    border: none;
    border-radius: 0;
    @media (max-width: 991px) {
        margin: 0;
    }
    &.facetEmpty {
        display: none;
    }
`;

export const FacetFilterHeaderContainer = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    background: transparent;
    margin: 0 0 4px 0;
    padding: 0 0 4px 0;
    border-bottom: 1px solid rgba(52, 48, 44, 0.5);
    border-radius: 0;
    display: flex;
`;

export const FacetFilterHeaderText = styled.h2`
    font-size: 16px;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1;
`;

export const ButtonContainer = styled.button`
    color: #0059b3;
    text-decoration: none;
    cursor: pointer;
    overflow: visible;
    width: auto;
    margin: 0;
    background: none;
    border: none;
    color: #296896;
    padding: 0 5px;
    font-size: 14px;
    &:visited:hover {
        color: #609;
    }
    &:hover {
        text-decoration: underline;
    }
    &.selected {
        color: #0059b3;
    }
`;

export const SvgIcon = styled.img`
    display: inline-block;
    vertical-align: middle;
`;

export const UnOrderList = styled.ul`
    padding: 0;
    margin: 0;
    white-space: normal;
    list-style: none;
`;

export const ListItem = styled.li`
    font-size: 16px;
    line-height: 24px;
    background: transparent !important;
    margin: 0 0 8px 0;
    padding: 0;
`;

export const ItemContainer = styled.div`
    display: flex;
    font-family: 'roboto', sans-serif;
    font-weight: 400;
    padding: 5px 0;
    width: 100%;
    cursor: pointer;
    align-items: center;
    color: #373737;
    line-height: 16px;
    vertical-align: middle;
    &:hover > span {
        opacity: 0.7;
    }
    &.selected {
        font-weight: 700;
    }
`;

export const ItemValue = styled.span`
    order: 1;
    color: #000;
    flex-grow: 1;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2em;
    font-size: 16px;
`;

export const ItemValueSuffix = styled.span`
    order: 2;
    color: #000;
    margin: 0 5px;
    margin-left: 8px;
    font-size: 16px;
`;

export const CheckBoxButton = styled.button`
    order: 3;
    width: 16px;
    height: 16px;
    min-width: 16px;
    border: 1px solid rgba(52, 48, 44, 0.5);
    border-radius: 0;
    margin-right: 0;
    margin-left: auto;
    position: relative;
    padding: 0;
    background: #fff;
    outline: none;
    transition: all 0.2s;

    &:before {
        top: 0px;
        left: 4px;
        border: solid #000;
        border-width: 0 2px 2px 0;
    }
    &:after {
        position: absolute;
        top: 7px;
        left: 7px;
        display: inline-block;
        width: 0;
        height: 0;
        content: '';
        background-color: transparent;
        border-radius: 100%;
        transition: all 0.3s ease;
    }
    &.checked {
        &:before {
            position: absolute;
            box-sizing: content-box;
            display: block;
            width: 4px;
            height: 9px;
            content: '';
            transform: rotate(43deg);
        }
    }
`;
