import React, { Dispatch, FC, SetStateAction } from 'react';
import { Text, Image, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { CloseRoundGreyImg } from 'assets/images';
import PaymentMethodForm from 'components/Modal/PaymentMethodForm';
import { ExistingPaymentSource } from 'components/payment/PaymentMethodFormBody';

const ModalHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  height: 104px;
  position: relative;
  img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const ModalTitle = styled(Text)`
  text-transform: uppercase;
  font-family: 'URW_Din_CondBold', sans-serif;
  letter-spacing: 2px;
  font-size: 2.8125rem;
  font-weight: 400;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ModalBody = styled.div`
  padding: 3.125rem;
  @media (max-width: 768px) {
    padding: 1.125rem;
  }
`;

interface ChangePaymentMethodModalProps {
  Modal: any;
  currentSourceId: string | null;
  paymentSources: ExistingPaymentSource[];
  billingId?: string | null;
  enrollment?: any | null;
  handleEnrollmentUpdate: any;
  viewer?: any;
  closeModal: () => void;
  setLoading?: Dispatch<SetStateAction<boolean>>;
}

const ChangePaymentMethodModal: FC<ChangePaymentMethodModalProps> = ({
  currentSourceId,
  paymentSources,
  enrollment,
  billingId,
  handleEnrollmentUpdate,
  viewer,
  ...props
}) => {
  const { Modal, closeModal } = props;

  return (
    <Modal
      className='modalBody'
      style={{
        borderRadius: 0,
        maxWidth: '896px',
        boxShadow: '-20px 20px 27px 0 rgba(0,0,0,0.5)',
        top: '100px',
      }}>
      <ModalHeader>
        <ModalTitle>CHANGE PAYMENT METHOD</ModalTitle>
        <Image onClick={closeModal} src={CloseRoundGreyImg} alt='close' />
      </ModalHeader>
      <ModalBody>
        <PaymentMethodForm
          isFromChangePaymentMethod={true}
          currentSourceId={currentSourceId ?? ''}
          paymentSources={paymentSources}
          enrollment={enrollment}
          billingId={billingId}
          handleEnrollmentUpdate={handleEnrollmentUpdate}
          onCancel={closeModal}
          onSubmit={sourceId => {
            closeModal();
          }}
          setLoading={props.setLoading}
          viewer={viewer}
        />
      </ModalBody>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
