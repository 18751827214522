// const BASE_URL_DEV = 'https://api.c8ck9e9y0x-rsivideot1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2/';
// const BASE_URL_PROD = 'https://api.c8ck9e9y0x-rsivideot1-d1-public.model-t.cc.commerce.ondemand.com/occ/v2/';

let BASE_URL = `${process.env.REACT_APP_SAP_COMMERCE_BASE_URL}/occ/v2/`;

export const getLanguageUrl = `${BASE_URL}resideo/languages`;
export const getCategoryUrl = `${BASE_URL}resideo/catalogs/resideoProductCatalog/Online`;
export const getProductListUrl = `${BASE_URL}resideo/categories/`;
export const getProductDetailsUrl = `${BASE_URL}resideo/products/?productCode=`;
export const getCartIDUrl = `${BASE_URL}resideo/users/`;
export const getCartUrl = `${BASE_URL}resideo/users/`;
export const addProductInCartUrl = `${BASE_URL}resideo/orgUsers/`;
export const deliveryAddressUrl = `${BASE_URL}resideo/users/`;
export const updateDeliveryAddressUrl = `${BASE_URL}resideo/orgUsers/`;
export const creditCardUrl = `${BASE_URL}resideo/users/`;
export const placeOrderUrl = `${BASE_URL}resideo/orgUsers/`;
export const getOrderInformationUrl = `${BASE_URL}resideo/users/`;
export const subscriptionUrl = `${BASE_URL}resideo/orgUsers/`;
export const ordersUrl = `${BASE_URL}resideo/users/`;
export const consentsUrl = `${BASE_URL}resideo/users/`;
export const digitalRiverUrl = `${BASE_URL}resideo/orgUsers/`;
export const promoCodeUrl = `${BASE_URL}resideo/users/`;
export const getOrderInformationUrlMod = `${BASE_URL}resideo/users/`;
export const b2bProUserUrl = `${BASE_URL}resideo/`;
export const b2bOrgUserUrl = `${BASE_URL}resideo/orgUsers/`;

export const authenticateUrl = `${process.env.REACT_APP_SAP_COMMERCE_BASE_URL}authorizationserver/oauth/token`;
export const refreshTokenUrl = `${process.env.REACT_APP_SAP_COMMERCE_BASE_URL}authorizationserver/oauth/token`;
export const getUserProfileUrl = `${process.env.REACT_APP_SAP_COMMERCE_BASE_URL}resideo/users/current?fields=DEFAULT`;
/** The below is not currently in use */
export const getCurrentUser =  `${process.env.REACT_APP_SAP_COMMERCE_BASE_URL}resideo/orgUsers/current/`;