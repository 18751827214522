import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { ReactComponent as ActiveNotificationBell } from 'components/icons/active-notifications-bell.svg';
import { ReactComponent as NotificationsBell } from 'components/icons/notifications-bell.svg';
import { Button, Flex, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useToast } from 'components/common/Toast';
import { useIsMounted } from 'hooks/useIsMounted';

const StyledMenuButton = styled(MenuButton)`
    cursor: pointer;
    transform: scale(0.77);
`;

const NotificationRowComponent = ({ node, fetch }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const isMounted = useIsMounted();

    return (
        <MenuItem
            onSelect={() => {
                // acceptInvite();
            }}>
            <Flex
                justifyContent='space-between'
                flexDirection={['column', 'row']}
                data-test-invite-notification>
                <Flex
                    flexDirection='column'
                    marginRight='large'
                    alignItems='self-start'
                    paddingBottom={['medium', 0]}>
                    <Text color='white'>
                        {t('header.notifications.invitedTo')}
                        {` ${node.partnerAccount.name}`}
                    </Text>
                    <Text color='faded' fontSize='small'>
                        {t('header.notifications.invitedBy')}
                    </Text>
                </Flex>
                <Button>{t('header.notifications.acceptInvite')}</Button>
            </Flex>
        </MenuItem>
    );
};

const CustomMenuList = styled(MenuList)`
    position: fixed;
    right: 0;
    top: 50px;
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        right: 70px;
        top: 50px;
        width: auto;
    }
`;

const useMountEffect = (cb: () => void) => useEffect(cb, []);

const Notifications: FC = () => {
    const isMounted = useIsMounted();
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState<any>();

    const fetchNotifications = () => {};

    useMountEffect(fetchNotifications);

    const notExpired = (edge) => {
        const now = Date.now();
        const currentExpiration = new Date(edge.node?.expirationTime || 0);
        return currentExpiration.getTime() > now;
    };

    const invites = (notifications?.viewer?.partnerUsers?.edges || []).filter(notExpired);

    return (
        <Flex
            display={['none', 'none', 'none', 'block']}
            marginRight='medium'
            data-test-notifications-menu>
            <Menu>
                <StyledMenuButton onClick={fetchNotifications}>
                    {invites.length > 0 ? <ActiveNotificationBell /> : <NotificationsBell />}
                </StyledMenuButton>
                {invites.length > 0 ? (
                    <CustomMenuList portal={false}>
                        {invites.map((notification, idx) => (
                            <NotificationRowComponent
                                fetch={fetchNotifications}
                                key={idx as any}
                                node={notification?.node}
                            />
                        ))}
                    </CustomMenuList>
                ) : (
                    <CustomMenuList portal={false}>
                        <Text marginX='large' color='white' data-test-no-notifications>
                            {t("You don't have any notifications")}
                        </Text>
                    </CustomMenuList>
                )}
            </Menu>
        </Flex>
    );
};

export default Notifications;
