import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchingOrderInformation: false,
    fetchingOrderInformationError: null,
    orders: {},
};

const reducer = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_ORDER_INFORMATION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingOrderInformation = true;
            });
        case types.FETCH_ORDER_INFORMATION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingOrderInformation = false;
                draftState.orders = payload;
            });
        case types.FETCH_ORDER_INFORMATION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingOrderInformation = false;
                draftState.fetchingOrderInformationError = payload;
            });
        default:
            return state;
    }
};

export { reducer };
