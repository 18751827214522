import React, { FC } from 'react';
import { Label } from '@resideo/blueprint-react';
import styled from 'styled-components';

const NewLabel = styled(Label)`
  padding-left: 14px;
  padding-bottom: 5px;
  color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
`;

const CustomLabel: FC<any> = ({ title, htmlFor }) => {
  return (
    <NewLabel fontSize={'small'} htmlFor={htmlFor}>
      {title}
    </NewLabel>
  );
};

export default CustomLabel;
