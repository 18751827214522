/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { creteDRNewBilling, creteDRBillingResponse } from 'redux/digitalriver/action';
import { RootState } from 'redux/root-reducer';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import styled from 'styled-components';
import { useToast } from './common/Toast';
import { placeOrder } from 'redux/checkout/action';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from './common/Alert';
import { Text } from '@resideo/blueprint-react';


const PaypalDiv = styled.div`
    width: 20em;
    position: relative;
    margin-left: 20px;
    z-index: 1;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
`;

interface PayPalButtonProps {
    drJsLibrary: any;
    viewer: {
        firstName: string;
        lastName: string;
        contactEmail: string;
        primaryPhoneNumber: string;
    };
    partnerAccount: {
        name: string;
        contactEmail: string;
        primaryPhoneNumber: string;
    };
    setPayPalSourceData: any;
    setIsPayPalEnabled: any;
    values?: any;
}

const Paypal = (props: PayPalButtonProps) => {
    const toast = useToast();
    const hookDispatch = useDispatchPromise();
    const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';
    const { t } = useTranslation();
    const { drJsLibrary, partnerAccount, viewer, setPayPalSourceData, setIsPayPalEnabled, values } = props;
    const order =
        localStorage.getItem('cartItems') &&
            localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];
    const { b2bUserInfoDetails }: any = useSelector((state: RootState) => state.b2bUserDetails);

    const items = order?.entries?.map((element) => {
        return {
            name: element?.product?.name,
            quantity: element?.quantity,
            unitAmount: element?.product?.price?.value,
        };
    });

    const paypalSourceData = {
        type: 'payPalBilling',
        amount: order?.totalPrice?.value,
        currency: order?.totalPrice?.currencyIso,
        payPalBilling: {
            returnUrl: window.location.href,
            cancelUrl: window.location.href,
            items: [...items],
            taxAmount: order?.totalTax?.value,
            shippingAmount: order?.deliveryCost?.value ?? 0,
            amountsEstimated: true,
            shipping: {
                recipient: `${b2bUserInfoDetails?.name ?? ''}`,
                firstName: b2bUserInfoDetails?.firstName ?? '',
                lastName: b2bUserInfoDetails?.lastName ?? '',
                email: b2bUserInfoDetails?.displayUid ?? b2bUserInfoDetails?.uid ?? '',
                phoneNumber: values?.phone ?? '000-000-0000',
                organization: b2bUserInfoDetails?.orgUnit?.name ?? '',
                address: {
                    line1: b2bUserInfoDetails?.orgUnit?.addresses?.length > 0 ? b2bUserInfoDetails?.orgUnit?.addresses[0]?.line1 : '',
                    line2: b2bUserInfoDetails?.orgUnit?.addresses?.length > 0 ? b2bUserInfoDetails?.orgUnit?.addresses[0]?.line2 : '',
                    city: b2bUserInfoDetails?.orgUnit?.addresses?.length > 0 ? b2bUserInfoDetails?.orgUnit?.addresses[0]?.town : '',
                    state: b2bUserInfoDetails?.orgUnit?.addresses?.length > 0 ? b2bUserInfoDetails?.orgUnit?.addresses[0]?.region?.isocodeShort : '',
                    postalCode: b2bUserInfoDetails?.orgUnit?.addresses?.length > 0 ? b2bUserInfoDetails?.orgUnit?.addresses[0]?.postalCode : '',
                    country: b2bUserInfoDetails?.orgUnit?.addresses?.length > 0 ? b2bUserInfoDetails?.orgUnit?.addresses[0]?.country?.isocode : '',
                },
            }
        },
    };

    const drNewBilling = async (newBillingAddress) => {
        const newAddressData = {
            address: {
                city: newBillingAddress.address.city,
                country: newBillingAddress.address.country,
                line1: newBillingAddress.address.line1,
                line2: newBillingAddress.address.line1 ?? '',
                postalCode: newBillingAddress.address.postalCode,
                state: newBillingAddress.address.state,
            },
            email: newBillingAddress.email ?? '',
            firstName: newBillingAddress.firstName,
            lastName: newBillingAddress.lastName,
            phoneNumber: newBillingAddress?.phoneNumber ?? '000-000-0000',
        };
        hookDispatch(creteDRNewBilling({ billingAddress: newAddressData }))
            .then((response) => {
                toast(
                    <Alert variant='success'>
                      <Text>{t('cart.payment.paymentSuccess')}</Text>
                    </Alert>,
                    { duration: 1000 },
                  );
                console.log('DR-Billing-Success');
            })
            .catch((error) => {
                toast(
                    <Alert variant='error'>
                        <Text>{t('error.payment')}</Text>
                    </Alert>,
                    {
                        duration: 10000,
                    },
                );
                console.log('Error', error);
            });
    };

    useEffect(() => {
        let paypalSourceId;
        const { paypal } = window as any;
        // paypal
        //     .Buttons({
        //         env: 'sandbox',
        //         locale: 'en_US',
        //         style: {
        //             label: 'paypal',
        //             size: 'responsive',
        //             shape: 'rect',
        //             layout: 'horizontal',
        //             tagline: 'false',
        //         },
        //         funding: {
        //             allowed: [paypal.FUNDING.CARD],
        //             disallowed: [paypal.FUNDING.CREDIT],
        //         },
        //         createOrder: function (data, actions) {
        //             return drJsLibrary?.createSource(paypalSourceData).then(async function (result) {
        //                 if (result.error) {
        //                     console.log('dr-error', result);
        //                 } else {
        //                     paypalSourceId = result.source.id;
        //                     setPayPalSourceData((prev) => ({
        //                         ...prev,
        //                         result,
        //                     }));
        //                     await drNewBilling(paypalSourceData.payPalBilling.shipping, result.source);
        //                     return result.source.payPalBilling.token;
        //                 }
        //             });
        //         },
        //         // Execute the payment
        //         onApprove: function (data, actions) {
        //             console.log('dr-data', data);
        //             createOrder();
        //             setPayPalSourceData((prev) => ({
        //                 ...prev,
        //                 isAuthorize: true,
        //             }));
        //             setIsPayPalEnabled(false);
        //         },
        //         onCancel: function (data, actions) {
        //             setIsPayPalEnabled(false);
        //             console.log('cancelled paypal', data);
        //         },
        //         onError: function (err) {
        //             console.log('onError paypal', err);
        //         }
        //     })
        //     .render('#paypal-button');

        paypal.Button.render({
            env: isDevelopEnvironment ? 'sandbox' : 'production',
            locale: 'en_US',
            style: {
                label: 'paypal',
                size: 'responsive',
                color: 'gold',
                shape: 'rect',
                layout: 'horizontal',
                tagline: 'false'
            },
            // Set up a payment
            payment: function (data, actions) {
                return drJsLibrary?.createSource(paypalSourceData).then(async function (result) {
                    if (result.error) {
                        console.log('DR-Error', result.error);
                        toast(
                            <Alert variant='error'>
                                <Text>{t('error.payment')}</Text>
                            </Alert>,
                            {
                                duration: 10000,
                            },
                        );
                    } else {
                        paypalSourceId = result.source.id;
                        setPayPalSourceData((prev) => ({
                            ...prev,
                            result,
                        }));
                        return result.source.payPalBilling.token;
                    }
                });
            },
            // Execute the payment
            onAuthorize: async function (data, actions) {
                //the payment has been authorized, use the source and attach it to your customer's account.
                setPayPalSourceData((prev) => ({
                    ...prev,
                    isAuthorize: true,
                }));
                await drNewBilling(paypalSourceData.payPalBilling.shipping);
                setIsPayPalEnabled(false);
            },
            onCancel: function (data) {
                //the payment has been cancelled, do something to try and get the shopper back on track
                setIsPayPalEnabled(false);
                toast(
                    <Alert variant='error'>
                        <Text>{t('error.canceledPayment')}</Text>
                    </Alert>,
                    {
                        duration: 10000,
                    },
                )
                console.log('DR-onCancel', data)
            }
        }, '#paypal-button');
    }, []);
    return <PaypalDiv id='paypal-button'></PaypalDiv>;
};

export default Paypal;
