import AsyncLocalStorage from '@createnextapp/async-local-storage';

export const storeCartID = (cartID: any) =>
    new Promise((resolve, reject) =>
        AsyncLocalStorage.setItem('cartId', cartID)
            .then(() => resolve(cartID))
            .catch((error) => reject(error)),
    );
export const retrieveCartID = () =>
    new Promise<void>((resolve, reject) =>
        AsyncLocalStorage.getItem('cartId')
            .then((cartID) => resolve(cartID))
            .catch((error) => reject(error)),
    );

export const storeCartItems = (cartItems: any) =>
    new Promise((resolve, reject) =>
        AsyncLocalStorage.setItem('cartItems', cartItems)
            .then(() => resolve(cartItems))
            .catch((error) => reject(error)),
    );
export const retrieveCartItems = () =>
    new Promise<void>((resolve, reject) =>
        AsyncLocalStorage.getItem('cartItems')
            .then((cartItems) => resolve(cartItems))
            .catch((error) => reject(error)),
    );

export const removeCartID = () =>
    new Promise<void>((resolve, reject) =>
        AsyncLocalStorage.removeItem('cartId')
            .then(() => resolve())
            .catch((error) => reject(error)),
    );

export const removeCartItems = () =>
    new Promise<void>((resolve, reject) =>
        AsyncLocalStorage.removeItem('cartItems')
            .then(() => resolve())
            .catch((error) => reject(error)),
    );

export const removeProductID = () =>
    new Promise<void>((resolve, reject) =>
        AsyncLocalStorage.removeItem('productID')
            .then(() => resolve())
            .catch((error) => reject(error)),
    );
