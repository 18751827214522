import React from 'react';
import footerLegalLinks from 'config/footer';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { Flex, Text } from '@resideo/blueprint-react';

const FooterOuter = styled.footer`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    color: #ffffff;
    background-color: #032a3b;
    position: relative;
    z-index: 2;
    padding: 45px 0;
`;

interface Link {
    externalLink?: boolean;
}

const DrTextBox = styled(Text)`
    white-space: nowrap;
`;

/*
  for the styled component definition below, theme.fontSizes.xSmall
  is the same as 12px or 0.75rem.

  as defined in themes/base.ts, here are the size equivalents for the named values

  xxSmall  = 0.625rem (10px)
  xSmall   = 0.75rem  (12px)
  small    = 0.875rem (14px)
  medium   = 1rem     (16px)
  large    = 1.25rem  (20px)
  xLarge   = 1.5rem   (24px)
  xxLarge  = 2rem     (32px)
  xxxLarge = 2.5rem   (40px)
*/
const Link = styled.a<Link>`
    color: ${({ theme }) => theme.colors.white};
    font-family: Roboto;
    font-size: ${({ theme }) => theme.fontSizes.xSmall};
    font-weight: 400;
    text-transform: uppercase; /* show links in all CAPS */
    text-decoration: none; /* do not underline links */
    svg {
        margin-left: ${({ theme, externalLink }) => (externalLink ? `${theme.space.small}` : '0')};
    }
`;

const BottomLinks = styled.div`
    word-break: break-all;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space.small};
    margin-top: ${({ theme }) => theme.space.medium};
    padding-left: ${({ theme }) => theme.space.medium};
    padding-right: ${({ theme }) => theme.space.medium};
    text-align: center;
    font-weight: 500;
    a {
        word-break: break-all;
        font-size: ${({ theme }) => theme.fontSizes.xSmall};
        font-weight: 500;
        &:after {
            word-break: break-all;
            content: '|';
            padding: 0 ${({ theme }) => theme.space.small};
        }
        &:last-of-type:after {
            word-break: break-all;
            content: '';
        }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        display: block;
        word-break: break-all;
    }
`;

const TrademarkSection = styled.p`
    word-break: break-all;
    align-items: center;
    font-size: 0.875rem;
    margin-top: ${({ theme }) => theme.space.xSmall};
    padding: 0 ${({ theme }) => theme.space.small};
    font-family: Roboto;
    font-weight: normal;
    text-align: center;
`;

const FooterBoxContainer = styled.div`
    @media (min-width: 1200px) {
        display: flex;
        padding: 0 64px !important;
    }
`;

const FooterBox = styled.div`
    flex-direction: column;
    width: 100%;
    padding: 0;
    //border-bottom: 1px solid #888;

    &:last-of-type {
        margin-bottom: 20px;
    }

    @media (min-width: 1200px) {
        position: relative;
        flex: 1 1 auto;
        flex-direction: row;
        width: 30%;
        margin-bottom: 34px;
        padding: 0 4%;
        border-bottom: none;
        &:first-of-type {
            display: flex;
            flex: 1 1 auto;
            //width: 50%;
            padding-right: 12%;
            padding-left: 0;
        }
        &:first-of-type:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 90%;
            content: '';
            background-color: #00a9e0;
        }
        &:last-of-type {
            flex: 1 1 auto;
            width: 20%;
            margin-bottom: 0;
            padding-right: 0;
            padding-left: 5%;
        }
        &:last-of-type:after {
            display: none;
        }
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 90%;
            content: '';
            background-color: #00a9e0;
        }
        width: 30.3%;
        &:last-of-type {
            width: 20%;
        }
        &:first-of-type:after {
            display: none;
        }
    }

    @media (max-width: 1199px) {
        &:first-of-type {
            border-bottom: none;
        }

        &:last-of-type {
            padding-left: 0;
        }
    }

    @media (min-width: 1200px) and (min-width: 1400px) {
        padding-left: 8%;
    }

    @media (min-width: 1400px) {
        width: 35%;
        padding: 0 8%;

        &:first-of-type {
            width: 40%;
            padding-right: 8%;
        }
    }
`;

const FooterBoxMenu = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    position: relative;

    &:first-of-type {
        border-bottom: 1px solid #888;
    }
    &:last-of-type {
        margin-bottom: 20px;
    }
    @media (min-width: 1200px) {
        &:first-of-type {
            border-bottom: none;
        }
        &:last-of-type:after {
            display: none;
        }
    }
    @media (min-width: 1200px) and (min-width: 1400px) {
        padding-left: 8%;
    }
`;

const HeaderText = styled.span`
    font-family: 'roboto', sans-serif;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    align-self: flex-start;
    width: 100%;
    margin-bottom: 0;
    padding: 20px 0;
    text-align: left;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    &:hover {
        opacity: 1;
        color: #fff;
    }
    // &:after {
    //     position: absolute;
    //     top: 24px;
    //     right: 0;
    //     width: 14px;
    //     height: 14px;
    //     margin-left: auto;
    //     content: '';
    //     transition: all ease 0.3s;
    //     background-image: url('https://www.resideo.com/areas/resideo/img/rde-plus-footer.svg');
    //     background-repeat: no-repeat;
    //     background-size: 14px;
    // }

    @media (min-width: 1200px) {
        font-size: 14px;
        margin-bottom: 24px;
        padding: 0;
        cursor: default;

        &:after {
            display: none;
        }
    }
`;

const UnOderList = styled.ul`
    position: static;
    z-index: 1;
    display: block;
    float: none;
    //overflow: hidden;
    max-height: 100%;
    margin: 0;
    padding: 0;
    transition: all ease 0.4s;
    color: #fff;
    background-color: transparent;
    border: none;
    @media (min-width: 1200px) {
        display: flex;
        overflow: visible;
        flex-flow: wrap column;
        flex-direction: column;
        min-width: 180px;
        height: 184px;
        max-height: 100%;
        margin-left: 0;
    }
`;

const ListItem = styled.li`
    display: flex;
    margin: 0 0 16px 16px;
    @media (min-width: 1200px) {
        display: inline-flex;
        margin: 0 32px 16px 0;
    }
`;

const LinkText = styled.a`
    font-family: 'roboto', sans-serif;
    font-size: 14px;
    padding: 0;
    transition: all ease 0.3s;
    text-decoration: none !important;
    color: #fff;
    &:hover {
        opacity: 0.7;
        background-color: transparent;
    }
    @media (min-width: 1200px) {
        font-family: 'roboto', sans-serif;
        font-size: 14px;
        padding: 0;
        color: #fff;
    }
    &.image-button {
        display: inline-block;
        margin-right: 24px;
        transition: all 0.3s ease;
        &:hover {
            opacity: 0.7;
        }
        &:first-child {
            margin-right: 20px;
        }
        @media (max-width: 767px) {
            &:first-child {
                margin-right: 16px;
            }
        }
    }
`;

const FooterButtonGroup = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
`;

const ImageIcon = styled.img`
    max-width: 182px;
    height: auto;
    @media (max-width: 767px) {
        max-width: 120px;
    }
`;

export const FooterSignUpButton = styled.a`
    position: relative;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-family: roboto;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 12px 46px;
    border: 2px solid #fff;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
    transition: color 250ms ease-in-out;
    text-decoration: none !important;
    text-align: center;
    border-radius: 0;
    transition: all 0.3s ease;
    ::before {
        content: '';
        position: absolute;
        background-color: #fff;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 250ms ease-in-out;
    }
    :hover {
        color: #000;
    }
    :hover::before,
    :focus::before {
        transform: scaleX(1);
    }
`;

const Cookies = styled.p`
    font-weight: bold;
`;

const trademarkYear = new Date().getFullYear();

export const Footer = (): JSX.Element => {
    const { t } = useTranslation();
    const { pathname: path } = useLocation();

    return (
        <FooterOuter data-test-footer>
            {path.includes('cart') ? (
                <>
                    <Flex paddingTop='xLarge' justifyContent='center' textAlign='center'>
                        <Text maxWidth='50rem' fontSize='small' lineHeight='1.313rem'>
                            {t('cart.cartFooterInformation')}
                        </Text>
                    </Flex>
                    <Flex paddingTop='0' justifyContent='center'>
                        <Text
                            maxWidth='50rem'
                            fontSize='small'
                            display='flex'
                            lineHeight='1.313rem'>
                            <a
                                style={{
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                }}
                                target='blank'
                                href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRAboutDigitalRiverPage/eCommerceProvider.DR+globalTech+Inc'>
                                <DrTextBox color='primary' display='flex' lineHeight='1.313rem'>
                                    {' '}
                                    {t('cart.drGlobalTech')}&nbsp;
                                </DrTextBox>
                            </a>{' '}
                            {t('cart.drGlobalTechText')}
                        </Text>
                    </Flex>
                </>
            ) : (
                <>
                    <FooterBoxContainer
                        className='container'
                        data-test-secondary-footer-menu-nav-links>
                        <FooterBox>
                            <FooterBoxMenu>
                                <HeaderText>Meet Resideo</HeaderText>
                                <UnOderList>
                                    {footerLegalLinks.MENUITEM.map(function (link, i): JSX.Element {
                                        return (
                                            <ListItem key={`index${i}`}>
                                                <LinkText
                                                    href={link.linkPath}
                                                    id={''}
                                                    target={'_blank'}
                                                    key={i}>
                                                    {t(link.textKey)}
                                                </LinkText>
                                            </ListItem>
                                        );
                                    })}
                                </UnOderList>
                            </FooterBoxMenu>
                        </FooterBox>
                        <FooterBox></FooterBox>
                        <FooterBox>
                            <FooterBoxMenu>
                                <HeaderText>CONNECT WITH US</HeaderText>
                                <FooterButtonGroup>
                                    {footerLegalLinks.SOCIAL.map(function (link, i): JSX.Element {
                                        return (
                                            <LinkText
                                                key={i}
                                                className='image-button'
                                                id={''}
                                                href={link.linkPath}
                                                target={'_blank'}>
                                                <ImageIcon
                                                    style={{
                                                        width: '24px',
                                                        height: '18px',
                                                    }}
                                                    src={link.imageUrl}></ImageIcon>
                                            </LinkText>
                                        );
                                    })}
                                </FooterButtonGroup>
                                <FooterSignUpButton
                                    href={'https://www.resideo.com/us/en/pro/stay-informed/'}>
                                    Sign up to stay informed
                                </FooterSignUpButton>
                            </FooterBoxMenu>
                        </FooterBox>
                    </FooterBoxContainer>
                    <BottomLinks data-test-secondary-footer-nav-links>
                        {footerLegalLinks.HAB.map(function (link, i): JSX.Element {
                            return (
                                <Link
                                    href={link.linkPath}
                                    id={link.teconsent ? 'teconsent' : ''}
                                    target={!link.teconsent ? '_blank' : '_self'}
                                    key={i}>
                                    {t(link.textKey)}
                                </Link>
                            );
                        })}
                    </BottomLinks>
                    <TrademarkSection data-test-footer-copyright>
                        {t('footer.copyright', { year: trademarkYear })}
                        <Cookies>
                            <div id='teconsent'></div>
                        </Cookies>
                    </TrademarkSection>
                </>
            )}
        </FooterOuter>
    );
};
export default Footer;
