import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchingPromoCodeSuccess: false,
    fetchingPromoCode: [],
    fetchingPromoCodeFail: null,
    createPromoCodeSuccess: false,
    createPromoCode: [],
    createPromoCodeFail: null,
    deletePromoCodeSuccess: false,
    deletePromoCode: [],
    deletePromoCodeFail: null,
};

const promoCodeDetails = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_PROMO_CODE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingPromoCodeSuccess = true;
            });
        case types.FETCH_PROMO_CODE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingPromoCodeSuccess = false;
                draftState.fetchingPromoCode = payload;
            });
        case types.FETCH_PROMO_CODE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingPromoCodeSuccess = false;
                draftState.fetchingPromoCodeFail = payload;
            });
        case types.CREATE_PROMO_CODE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createPromoCodeSuccess = true;
            });
        case types.CREATE_PROMO_CODE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createPromoCodeSuccess = false;
                draftState.createPromoCode = payload;
            });
        case types.CREATE_PROMO_CODE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createPromoCodeSuccess = false;
                draftState.createPromoCodeFail = payload;
            });
        case types.DELETE_PROMO_CODE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletePromoCodeSuccess = true;
            });
        case types.DELETE_PROMO_CODE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletePromoCodeSuccess = false;
                draftState.deletePromoCode = payload;
            });
        case types.DELETE_PROMO_CODE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deletePromoCodeSuccess = false;
                draftState.deletePromoCodeFail = payload;
            });
        default:
            return state;
    }
};

export { promoCodeDetails };
