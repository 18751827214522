import { Box, Flex, Image } from '@resideo/blueprint-react';
import React, { FC } from 'react';
import styled from 'styled-components';
import {
    cartImg,
    tabCartWhite,
    tabPaymentWhite,
    tabShippingWhite,
    tabReviewWhite,
} from '../../assets/images';
import { ReviewOrderTabIcon } from '../../assets/images/reviewOrder';
import { PaymentTabIcon } from '../../assets/images/payment';
import { ShippingTabIcon } from '../../assets/images/shipping';
import { useTranslation } from 'react-i18next';
import { cartLinksData } from '../../config/cartLinks';
import { useHistory } from 'react-router-dom';

const Tab = styled(Box)`
    font-weight: bold;
    background-color: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    height: 2.5rem;
    width: 200px;
    @media (max-width: 768px) {
        width: 120px;
    }
    img {
        padding-right: 0.5rem;
        height: 20px;
    }
    &:focus {
        outline: 0;
    }
    &.active-tab {
        cursor: pointer;
        border: 1px solid #000;
        background-color: #fff;
        height: 3.2rem;
        padding: 0;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    &.validate-tab {
        cursor: pointer;
        color: white;
        background-color: black;
        border: none;
        :hover {
            transform: scale(1.05);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
    }
`;

const TabName = styled.span`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`;

interface CheckoutStepsProps {
    step1?: boolean;
    step2?: boolean;
    step3?: boolean;
    step4?: boolean;
    hide?: boolean;
}

const CheckoutSteps: FC<CheckoutStepsProps> = ({ step1, step2, step3, step4 }) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    let cartItems = localStorage.getItem('cartItems') &&
        localStorage.getItem('cartItems') !== 'undefined'
        ? JSON.parse(localStorage.getItem('cartItems') || '')
        : [];

    const hasProduct = cartItems && cartItems?.entries?.length > 0 &&
        cartItems?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length > 0;

    return (
        <Flex justifyContent='center' paddingY='large' alignItems='center'>
            {step1 ? (
                <Tab className='active-tab'>
                    <Image src={cartImg} alt='cart' />
                    <TabName>{t('cart.cart')}</TabName>
                </Tab>
            ) : (
                <Tab className='validate-tab' onClick={() => push(`${cartLinksData[0]}`)}>
                    <Image src={tabCartWhite} alt='cart' />
                    <TabName>{t('cart.cart')}</TabName>
                </Tab>
            )}
            {hasProduct &&
                (step2 ? (
                    <Tab className='active-tab'>
                        <Image src={ShippingTabIcon} alt='shipping' />
                        <TabName>{t('cart.shipping.shipping')}</TabName>
                    </Tab>
                ) : (
                    <Tab
                        className={step1 ? 'default' : 'validate-tab'}
                        onClick={() => (step3 || step4) && push(`${cartLinksData[1]}`)}>
                        <Image
                            src={step3 || step4 ? tabShippingWhite : ShippingTabIcon}
                            alt='shipping'
                        />
                        <TabName>{t('cart.shipping.shipping')}</TabName>
                    </Tab>
                ))}
            {step3 ? (
                <Tab className='active-tab'>
                    <Image src={PaymentTabIcon} alt='payment' />
                    <TabName>{t('cart.payment.payment')}</TabName>
                </Tab>
            ) : (
                <Tab
                    className={step1 || step2 ? 'default' : 'validate-tab'}
                    onClick={() => step4 && push(`${cartLinksData[2]}`)}>
                    <Image src={!step4 ? PaymentTabIcon : tabPaymentWhite} alt='payment' />
                    <TabName>{t('cart.payment.payment')}</TabName>
                </Tab>
            )}
            {step4 ? (
                <Tab className='active-tab'>
                    <Image src={ReviewOrderTabIcon} alt='review' />
                    <TabName>{t('cart.reviewOrder.reviewOrder')}</TabName>
                </Tab>
            ) : (
                <Tab className={!step4 ? 'default' : 'validate-tab'}>
                    <Image src={tabReviewWhite} alt='review' />
                    <TabName>{t('cart.reviewOrder.reviewOrder')}</TabName>
                </Tab>
            )}
        </Flex>
    );
};
export default CheckoutSteps;
