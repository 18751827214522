import { Box, Flex } from '@resideo/blueprint-react';
import AnonymousHeader from 'components/header/AnonymousHeader';
import AuthenticatedHeader from 'components/header/AuthenticatedHeader';
import React, { FC, ReactNode, useEffect } from 'react';
import { useAuth } from 'utils/auth';
import Footer from '../footer/Footer';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/root-reducer';

export const AppLayout: FC<{ showHeader?: boolean; children: ReactNode }> = ({
  showHeader = true,
  children,
}): JSX.Element => {
  const auth = useAuth();
  const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn,);
  const { pathname: path } = useLocation();

  useEffect(() => {
   // alert(`auth status is: ${auth.isAuthenticated}`)
}, []);

  return (
    <Flex flexDirection='column' minHeight='100vh'>
      {showHeader &&
        (isAuthenticated ? (
          <AuthenticatedHeader />
        ) : (
          <AnonymousHeader />
        ))}
      <Flex flex='1'>
        <Box flex='1'>{children}</Box>
      </Flex>
       <Footer />
    </Flex>
  );
};

export default AppLayout;
