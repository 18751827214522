import React, { FC, useEffect, useState, useRef, useContext } from 'react';
import { Box, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import ProductDescription from '../../../components/productdetails/ProductDescription';
import HashRouter from '../../../components/productdetails/HashRouter';
import ProgramOverview from '../../../components/productdetails/ProgramOverview';
import GetStarted from '../../../components/productdetails/GetStarted';
//import { CartContext } from 'routes/ServicesAndSubscriptions/CartProvider';
//import { useAuth } from 'utils/auth';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductDetails } from '../../../redux/productdetails/actions';
import { RootState } from '../../../redux/root-reducer';
import PageLoading from '../../../components/common/PageLoading';
import PageError from '../../../components/common/PageError';


export const StyledBox = styled(Box)`
  cursor: pointer;
`;

export const StyledTextUpper = styled(Text)`
  text-transform: uppercase;
`;

const ProductDetails: FC = () => {
  const [tab, setTab] = useState('Overview');
  const overviewRef = useRef(null);
  const getStartedRef = useRef(null);
  const resourcesRef = useRef(null);
  //const { isAuthenticated: auth } = useAuth();
  const [service, setService] = useState('');
  const { path } = useRouteMatch();
  const history = useHistory() as any;
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.productDetails.fetchingProductDetails);
  const error = useSelector((state: RootState) => state.productDetails.fetchingProductDetailError);
  const productDetailsData: any = useSelector((state: RootState) => state.productDetails.productDetail);
  const productID: any = localStorage.getItem('productID') ?? '';


  //const { state } = useContext(CartContext);

  useEffect(() => {
    // if (path.includes('basic')) {
    //   setService('BASIC_MONTHLY');
    // } else if (path.includes('advanced')) {
    //   setService('ADVANCED_MONTHLY');
    // } else if (path.includes('pro')) {
    //   setService('PRO');
    // } else if (path.includes('home-owner')) {
    //   setService('HAB');
    // } else {
    //   setService('REFRESH');
    // }
    setService('PRO');
  }, [path]);

  useEffect(() => {
    if (history.location && productID) {
      dispatch(getProductDetails(productID));
    }
  }, [history.location]);

  return isLoading && productDetailsData ? (
    <PageLoading />
  ) : error ? (
    <PageError />
  ) :(
    <Box data-test-pdp>
      {productDetailsData && (
        <ProductDescription
          auth={true}
          service={service}
          setService={setService}
          productDetails={productDetailsData}
        />
      )}
      {/* <HashRouter
        setTab={setTab}
        tab={tab}
        overviewRef={overviewRef}
        relatedProductRef={getStartedRef}
        resourcesRef={resourcesRef}
        getStartedRef={getStartedRef}
      />
      <ProgramOverview overviewRef={overviewRef} service={service} /> */}

      <GetStarted getStartedRef={getStartedRef} />
    </Box>
  );
};

export default ProductDetails;
