import React, { useEffect, useState } from 'react';
import { Image, Flex } from '@resideo/blueprint-react';
import { useHistory } from 'react-router-dom';
import {
    Wrapper,
    ProductTitle,
    PriceList,
    PromoPrice,
    TextContainer,
    ButtonContainer,
    BestSeller,
    ImageContainer,
    MoreTextContainer,
    RelatedProductBox,
    ProductTile,
} from '../../routes/Products/PLP/styles';
import { ImageComing } from '../../assets/images';
import { RootState } from '../../redux/root-reducer';
import { getCardID } from '../../redux/cart/actions';
import { useDispatch, useSelector } from 'react-redux';
import { addProductInCart, getCardDetails } from '../../redux/cart/actions';
import LoadingDots from './LoadingDots';
import ReactStars from "react-rating-stars-component";
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { useTranslation } from 'react-i18next';
import Alert from './Alert';
import { Text } from '@resideo/blueprint-react';
import { useToast } from './Toast';

export type Product = {
    name: string;
    images?: Images;
    code: string;
    price: Price;
    stock: Stock;
};

export type Images = {
    format: string;
    imageType: string;
    url: string;
};
export type Price = {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number;
};

export type Stock = {
    isValueRounded: boolean;
    stockLevel: number;
    stockLevelStatus: string;
};
interface IProps {
    product: Product;
    index: number;
}

const ProductCard = ({ product, index }: IProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const toast = useToast();
    const { t } = useTranslation();
    const [selectedCartId, setSelectedCartId] = useState(-1);
    const isLoading = useSelector(
        (state: RootState) => state.cartDetails.addProductInCart,
    );
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn,);

    useEffect(() => {
        if (!isLoading) {
            setSelectedCartId(-1);
        }
    }, [isLoading]);

    const handleRedirectToPdp = (productId) => {
        localStorage.setItem('productID', productId);
        history.push('/pdp/home-owner');
    };

    const handleAddProduct = async (e) => {
        const { tabIndex } = e.target;
        setSelectedCartId(tabIndex)

        let dataToSend = {
            orderEntries: [
                {
                    product: {
                        code: product.code,
                    },
                    quantity: 1,
                },
            ],
        };

        hookDispatch(
            addProductInCart({ body: dataToSend }),
        ).then((response) => {
            dispatch(getCardDetails());
        }).catch((error) => {
            console.log('Error', error);
            if (error?.data?.errors[0]?.type === 'DomainError') {
                toast(
                    <Alert variant='error'>
                        <Text>{error?.data?.errors[0]?.message ?? t('cart.unknownError')}</Text>
                    </Alert>,
                    { duration: 1000 },
                );
            }
        });
    };

    return (
        <Wrapper flexDirection='column' data-plp-product-card>
            <RelatedProductBox
                data-test-product-list-card
                onClick={() => {
                    handleRedirectToPdp(product?.code);
                }}>
                {false && <BestSeller>Best Seller</BestSeller>}

                {/* <Flex alignSelf='center' style={{ width: '220px', height: '220px' }}>
                        <Image src={product?.images?.[0].url ? product?.images?.[0].url : ImageComing} alt='product' height='100%' width='100%' />
                    </Flex> */}
                <ProductTile>

                    <ImageContainer>
                        <Image
                            className='img'
                            src={product?.images?.[0].url ? product?.images?.[0].url : ImageComing}
                            alt={product.name}
                        />
                    </ImageContainer>

                    <TextContainer>

                        <ProductTitle>{product.name}</ProductTitle>

                        {isAuthenticated &&
                            <Flex flexDirection={'column'} width={'30%'}>
                                {/* <PriceList>{product?.price?.formattedValue}</PriceList> */}
                                <PromoPrice>{product?.price?.formattedValue}</PromoPrice>
                            </Flex>
                        }
                    </TextContainer>

                    {/* <Flex display={'inline-flex'} flexDirection={'row'} alignItems='center'>
                        <ReactStars
                            count={5}
                            value={4.5}
                            size={22}
                            edit={false}
                            isHalf={true}
                            activeColor='#166a96'
                        />
                        <Text>{'(2387)'}</Text>
                    </Flex> */}
                    <MoreTextContainer>More options available</MoreTextContainer>
                </ProductTile>
            </RelatedProductBox>
            {isAuthenticated && (
                <Flex flexDirection={'row'} paddingX={16} paddingBottom={16} justifyContent={'center'}>
                    <ButtonContainer className='rdePrimary' tabIndex={index} onClick={handleAddProduct} type='button'>
                        {selectedCartId === index && isLoading ? <LoadingDots /> : 'Add To Cart'}
                    </ButtonContainer>
                </Flex>
            )}
        </Wrapper>
    );
};

export default ProductCard;