export const COUNTRIES = [
  { value: 'US', text: 'common.countries.US' },
  { value: 'CA', text: 'common.countries.CA' },
];

export function getFullCountryByCode(code) {
  try {
    return COUNTRIES.find(country => country.value === code)?.text;
  } catch (e) {
    return code;
  }
}
