import React from 'react';

const ServiceIconLarge = () => {
  return (
    <svg
      width='60px'
      height='115px'
      viewBox='0 0 106 106'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <title>Service_Icon_Large</title>
      <g
        id='Service-Only-Purchase-Flow'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'>
        <g
          id='Service-Purchase---Digital-Only---Cart-01'
          transform='translate(-87.000000, -402.000000)'
          stroke='#000000'
          strokeWidth='5'>
          <g
            id='line-item-1-copy-2'
            transform='translate(40.000000, 354.000000)'>
            <g id='Service_Icon' transform='translate(50.000000, 50.000000)'>
              <path
                d='M34.195183,77.0047508 L58.6847474,77.0047508 C61.9599524,77.0047508 64.6150307,74.3590414 64.6150307,71.0953935 L64.6150307,71.0953935 C64.5966521,67.8447546 61.9469492,65.2192769 58.6847474,65.2193285 L32.3075153,65.2193285 L18.1917705,60.2255055 L0,60.2255055 L0,88.5238358 L13.7983494,88.5238358 L33.4100469,98.6779426 C39.6288635,101.904755 47.0672507,101.760526 53.1553846,98.2950828 L96.5550355,73.5423668 C98.1815221,72.6637172 99.361784,71.1434954 99.8067648,69.3540168 C100.251746,67.5645381 99.9203497,65.671068 98.8937388,64.1373335 L98.8937388,64.1373335 C96.9991878,61.3699631 93.3363218,60.4360915 90.3407668,61.9566974 L60.1380844,77.0047508'
                id='Path'></path>
              <line
                x1='73.4513274'
                y1='29.2368421'
                x2='73.4513274'
                y2='70.877193'
                id='Path'></line>
              <line
                x1='18.5840708'
                y1='60.245614'
                x2='18.5840708'
                y2='29.2368421'
                id='Path'></line>
              <polyline
                id='Path'
                points='6.26438379 41.1158094 45.8385843 1.68125375 85.4127849 41.1158094'></polyline>
              <line
                x1='67.4882947'
                y1='0'
                x2='67.4882947'
                y2='22.5387878'
                id='Path'></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ServiceIconLarge;
