import React, { FC, useState } from 'react';
import { Box, Flex, Heading, Image, Text } from '@resideo/blueprint-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import AddPromoCode from '../../components/common/AddPromoCode';
import ShippingOrderItem from './shipping/ShippingOrderItem';
import { downArrowIcon, upArrowIcon } from '../../assets/images';
import { useDispatch } from 'react-redux';
import { useDispatchPromise } from 'hooks/useDispatchPromise';
import { deletePromoCode } from 'redux/promocode/action';
import { getCardDetails } from 'redux/cart/actions';

const StyledCursorBox = styled(Box)`
    cursor: pointer;
    border: 2px solid #e9eaed;
    border-radius: 15px;
    padding: 24px 16px;
`;

const Promo = styled(Flex)`
    .show-x {
        position: absolute;
        display: block;
        cursor: pointer;
        z-index:1;
    }
    .remove {
        height: 24px;
        color: #286DAA;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.44px;
        line-height: 24px;
      }
    // :hover {
    //     .show-x {
    //         position: absolute;
    //         display: block;
    //         cursor: pointer;
    //     }
    // }
`;

const PromoCodeValue = styled(Text)`
  color: #000000;
  font-family: Roboto;
  font-weight: bold;
`;
interface OrderSummaryMobileProps {
    setFieldValue?: any;
    values: any;
}

const OrderSummaryMobile: FC<OrderSummaryMobileProps> = ({ setFieldValue, values }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const [orderCollapse, setOrderCollapse] = useState(false);
    const cartId: any = localStorage.getItem('cartId') ?? '';

    let cartItems =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];

    const hasProduct =
        cartItems && cartItems?.entries &&
        cartItems?.entries?.length > 0 &&
        cartItems?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length > 0;

    const removePromoCode = async (value) => {
        hookDispatch(deletePromoCode({ voucherId: value }))
            .then((response) => {
                setFieldValue('promoCode', '');
                dispatch(getCardDetails());
            })
            .catch((error) => {
                console.log('Error', error);
            });
    }

    return (
        <Box display={['block', 'block', 'block', 'none']} paddingBottom='1rem'>
            <Collapsible
                open={orderCollapse}
                onOpening={() => setOrderCollapse(true)}
                onClosing={() => setOrderCollapse(false)}
                trigger={
                    <StyledCursorBox
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'>
                        <Box
                            display='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            width='100%'>
                            <Heading
                                letterSpacing='normal'
                                fontWeight='500'
                                fontSize={'medium'}
                                color='textLight3'>
                                View Order Summary
                            </Heading>
                            <Heading
                                letterSpacing='normal'
                                fontWeight='500'
                                fontSize={'medium'}
                                color='textLight3'>
                                {cartItems?.subTotalWithoutDiscount?.formattedValue}
                            </Heading>
                        </Box>

                        <Image
                            src={orderCollapse ? upArrowIcon : downArrowIcon}
                            alt='down-arrow'
                            paddingLeft={['small', 'small', 'medium', 'medium']}
                        />
                    </StyledCursorBox>
                }>
                <Box paddingTop='large'>
                    <Box
                        display={['block', 'block', 'flex', 'flex']}
                        justifyContent='space-between'
                        marginBottom='medium'>
                        <Box>
                            <Heading
                                color='textHeading'
                                letterSpacing='normal'
                                fontSize='1.125rem'
                                fontWeight='bold'>
                                {t('cart.shipping.orderSummary')}
                            </Heading>
                            <Text
                                color='summaryParagraph'
                                fontSize='small'
                                fontWeight='bold'
                                paddingY='small'>
                                REF # {cartId}
                            </Text>
                        </Box>
                        <Box display='flex' justifyContent='flex-end' alignItems='center' flex={1}>
                            <AddPromoCode setFieldValue={setFieldValue} />
                        </Box>
                        <Box></Box>
                    </Box>
                    {cartItems && cartItems?.entries?.length > 0 && cartItems?.entries?.map((item, i) => <ShippingOrderItem data={item} key={i} />)}
                    <Box marginBottom='medium'>
                        {/* {cartItems && cartItems?.appliedVouchers && (
                            cartItems?.appliedVouchers.map((item, index) => (
                                <Promo paddingBottom='small' key={`promo-${index}`}>
                                    <Box onClick={() => removePromoCode(item.code)} className='show-x'>
                                        <Text className='remove' textAlign='right'>
                                            <i className='fas fa-times'></i>{' Remove'}
                                        </Text>
                                    </Box>
                                    <Box flex='65%'>
                                        <Text
                                            color='textLight3'
                                            letterSpacing='normal'
                                            textAlign='right'>Promo Code </Text>
                                    </Box>
                                    <Box flex='25%'>
                                        <PromoCodeValue
                                            color='textLight3'
                                            letterSpacing='normal'
                                            textAlign='right'>{item.voucherCode}</PromoCodeValue>
                                    </Box>
                                </Promo>
                            ))
                        )} */}
                        {hasProduct && (
                            <>
                                {/* <Flex flex='1' paddingBottom='small'>
                                    <Box flex='75%'>
                                        <Text color='textLight3' textAlign='right'>
                                            {t('subtotalToday')}
                                        </Text>
                                    </Box>
                                    <Box flex='25%'>
                                        <Text color='textLight3' textAlign='right'>
                                            {cartItems?.subTotalWithoutDiscount?.formattedValue}
                                        </Text>
                                    </Box>
                                </Flex> */}
                                <Flex flex='1' paddingBottom='small'>
                                    <Box flex='75%'>
                                        <Text textAlign='right'>{t('cart.shipping.shipping')}</Text>
                                    </Box>
                                    <Box flex='25%'>
                                        <Text textAlign='right'>
                                            {cartItems?.deliveryCost?.formattedValue || '$0.00'}
                                        </Text>
                                    </Box>
                                </Flex>
                            </>
                        )}
                        {cartItems && cartItems?.appliedVouchers && cartItems?.appliedVouchers?.length > 0 && (
                            <Flex flex='1' paddingBottom='small'>
                                <Box flex='75%'>
                                    <Text textAlign='right'>
                                        {/* {t('cart.youSaved')} */}
                                        {t('discount')}
                                    </Text>
                                </Box>
                                <Box flex='25%'>
                                    <Text textAlign='right'>
                                        {cartItems?.totalDiscounts?.formattedValue}
                                    </Text>
                                </Box>
                            </Flex>
                        )}
                        <Flex flex='1' paddingBottom='medium'>
                            <Box flex='75%'>
                                <Text textAlign='right'>{t('tax')}</Text>
                            </Box>
                            <Box flex='25%'>
                                <Text textAlign='right'>
                                    {cartItems?.totalTax?.formattedValue}
                                </Text>
                            </Box>
                        </Flex>
                        <Flex flex='1' paddingBottom='0'>
                            <Box flex='75%'>
                                <Heading
                                    color='textLight3'
                                    letterSpacing='normal'
                                    fontSize='large'
                                    textAlign='right'>
                                    {t('totalToday')}
                                </Heading>
                            </Box>
                            <Box flex='25%'>
                                <Heading
                                    color='textLight3'
                                    letterSpacing='normal'
                                    fontSize='large'
                                    textAlign='right'>
                                    {cartItems?.totalPriceWithTax?.formattedValue}
                                </Heading>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Collapsible>
        </Box>
    );
};

export default OrderSummaryMobile;