import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { digitalRiverUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    updateDRPaymentSuccess,
    updateDRPaymentFail,
    creteDRNewBillingSuccess,
    creteDRNewBillingFail,
    creteDRBillingResponseSuccess,
    creteDRBillingResponseFail,
} from './action';
import { retrieveCartID } from 'redux/cart';

function* getCartDetails(): any {
    let cartID: string = '';

    try {
        cartID = yield call(retrieveCartID);
        return { cartID };
    } catch (error) {
        console.log('getCartDetails', error);
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* updateDRpayment({ payload, resolve, reject }: any): any {
    const { selectedPaymentMethodId } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url =
            digitalRiverUrl +
            `current/carts/${cartID}/dr/chooseDRPayment?selectedPaymentMethodId=${selectedPaymentMethodId}`;
        const response = yield call(api, url, httpRequest.GET, null, 2);
        resolve(response)
        yield put(updateDRPaymentSuccess(response));
    } catch (error) {
        yield put(updateDRPaymentFail(error));
        reject(error)
    }
}

function* addDRnewBilling({ payload, resolve, reject}: any): any {
    const { billingAddress } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = digitalRiverUrl + `current/carts/${cartID}/dr/dRnewBilling`;
        const response = yield call(api, url, httpRequest.POST, billingAddress, 0, 0);
        yield put(creteDRNewBillingSuccess(response));
        resolve(response);
    } catch (error) {
        reject(error);
        yield put(creteDRNewBillingFail(error));
    }
}

function* addDRBillingResponse({ payload, resolve, reject }: any): any {
    const { dRPaymentDetailsForm } = payload;
    const { cartID } = yield* getCartDetails();
    try {
        const url = digitalRiverUrl + `current/carts/${cartID}/dr/dRresponse`;
        const response = yield call(api, url, httpRequest.POST, dRPaymentDetailsForm, 0, 0);
        yield put(creteDRBillingResponseSuccess(response));
        resolve(response);
    } catch (error) {
        reject(error);
        yield put(creteDRBillingResponseFail(error));
    }
}

function* updateDRpaymentWatcher() {
    yield takeLatest(types.UPDATE_DR_PAYMENT, updateDRpayment);
}

function* addDRnewBillingWatcher() {
    yield takeLatest(types.CREATE_DR_NEW_BILLING, addDRnewBilling);
}

function* addDRBillingResponseWatcher() {
    yield takeLatest(types.CREATE_DR_BILLING_RESPONSE, addDRBillingResponse);
}

export default function* digitalRiverSaga() {
    yield all([updateDRpaymentWatcher(), addDRnewBillingWatcher(), addDRBillingResponseWatcher()]);
}
