import React, { useState, useEffect } from 'react';
import { Box, Text, Image, Flex } from '@resideo/blueprint-react';
import ReactImageMagnify from 'react-image-magnify';
import {
  HomeOwnerImage2,
  HomeOwnerImage3,
  homeOwnerZoomedLogo,
  ImageComing,
} from '../../assets/images';
import styled from 'styled-components';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

export const StyledBox = styled(Box)`
  cursor: pointer;
`;

export const StyledTextUpper = styled(Text)`
  text-transform: uppercase;
`;

const StyledCursorBox = styled(Box)`
  cursor: pointer;
  padding-top: 16px;
  @media (max-width: 768px) {
    padding-bottom: 3.5rem;
  }
`;

const SelectedStyledBox = styled(Box)`
  cursor: pointer;
  margin-top: -12px;
  padding-top: 16px;
  box-shadow: -10px 21px 6px -12px rgba(0, 0, 0, 0.2) !important;
`;

const TextPro = styled.div`
  text-align: center;
  font-size: 13px;
  font-family: Roboto;
  height: 20px;
  line-height: 15px;
  color: #221e1f;
  margin-top: 32px;
  margin-bottom: 10px;
  letter-spacing: 0.22px;
`;

const HABCarousel = ({ images }) => {
  const [resideoProduct, setResideoProduct] = useState(ImageComing);
  const [selectedImage, setSelectedImage] = useState(0);

  //const pdpImages = [homeOwnerZoomedLogo, HomeOwnerImage2, HomeOwnerImage3];
  const pdpImages = images ? images.map((item: { url: any; }) => item.url) : [];

  const { t } = useTranslation();

  useEffect(() => {
    if (pdpImages.length) {
      setResideoProduct(pdpImages[0]);
    } else {
      setResideoProduct(ImageComing);
    }
  }, [pdpImages]);

  return (
    <Box style={{ margin: '0px auto' }}>
      <Box
        display='flex'
        justifyContent={['center', 'center', 'flex-end', 'flex-end']}
        alignItems='center'
        paddingTop={['medium', 'medium', 'large', 'xLarge']}
        width='100%'>
        <ReactImageMagnify
          {...{
            enlargedImageContainerStyle: {
              zIndex: 100,
            },
            enlargedImageContainerDimensions: {
              width: '100%',
              height: '100%',
            },
            smallImage: {
              src: resideoProduct,
              height: 390,
              width: 290,
              //isFluidWidth: false,
            },
            largeImage: {
              src: resideoProduct,
              width: 1200,
              height: 1800,
            },
          }}
        />
      </Box>
      <TextPro>{t('programOverview.rollOverToImg')}</TextPro>
      <Box display='flex' justifyContent='center'>
        <Box width='auto'>
          <Box maxWidth='18rem' style={{ paddingTop: '20px' }}>
            {/* <Slider
              style={{ height: '200px' }}
              slidesToShow={3}
              swipeToSlide={false}
              focusOnSelect={true}> */}
            <Flex>
              {pdpImages && pdpImages.map((r, i) => (
                <React.Fragment key={i}>
                  {i === selectedImage ? (
                    <SelectedStyledBox onClick={() => setSelectedImage(i)}>
                      <Image
                        height='65px'
                        width='65px'
                        src={r}
                        marginRight='21px'
                        onClick={() => setResideoProduct(r)}
                      />
                    </SelectedStyledBox>
                  ) : (
                    <StyledCursorBox onClick={() => setSelectedImage(i)}>
                      <Image
                        height='65px'
                        width='65px'
                        src={r}
                        marginRight='21px'
                        onClick={() => setResideoProduct(r)}
                      />
                    </StyledCursorBox>
                  )}
                </React.Fragment>
              ))}
            </Flex>
            {/* </Slider> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HABCarousel;
