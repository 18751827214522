import { types } from './types';

type ProductDetailsPayload = {
    productId: string;
};

export const getProductDetails = (payload?: ProductDetailsPayload) => ({
    type: types.FETCH_PRODUCT_DETAILS,
    payload,
});

export const getProductDetailSuccess = (payload: any) => ({
    type: types.FETCH_PRODUCT_DETAILS_SUCCESS,
    payload,
});

export const getProductDetailFail = (payload: any) => ({
    type: types.FETCH_PRODUCT_DETAILS_FAIL,
    payload,
});
