export const types = {
    FETCH_PROMO_CODE: 'FETCH_PROMO_CODE',
    FETCH_PROMO_CODE_SUCCESS: 'FETCH_PROMO_CODE_SUCCESS',
    FETCH_PROMO_CODE_FAIL: 'FETCH_PROMO_CODE_FAIL',
    CREATE_PROMO_CODE: 'CREATE_PROMO_CODE',
    CREATE_PROMO_CODE_SUCCESS: 'CREATE_PROMO_CODE_SUCCESS',
    CREATE_PROMO_CODE_FAIL: 'CREATE_PROMO_CODE_FAIL',
    DELETE_PROMO_CODE: 'DELETE_PROMO_CODE',
    DELETE_PROMO_CODE_SUCCESS: 'DELETE_PROMO_CODE_SUCCESS',
    DELETE_PROMO_CODE_FAIL: 'DELETE_PROMO_CODE_FAIL',
};
