import { Box, Flex, Text } from '@resideo/blueprint-react';
import { InputField } from '../../../components/common/InputField';
import React from 'react';
import { getFullCountryByCode } from '../../../config/countries';
import { useTranslation } from 'react-i18next';

const ShippingAddress = ({ showForm, type, values, card }) => {
  const { t } = useTranslation();

  return (
    <>
      {showForm && type === 'shippingAddress' ? (
        <>
          <Box>
            <InputField
              name='shippingAddress.contactName'
              label='Contact Name'
              value={values.shippingAddress.contactName || ''}
            />
          </Box>
          <Flex flex={1}>
            <Box width={1 / 2} marginRight='small'>
              <InputField
                name='shippingAddress.addressLine1'
                label='Address Line 1'
                value={values.shippingAddress.addressLine1 || ''}
              />
            </Box>
            <Box width={1 / 2}>
              <InputField
                name='shippingAddress.addressLine2'
                label='Address Line 2'
                value={values.shippingAddress.addressLine2 || ''}
              />
            </Box>
          </Flex>
          <Box>
            <InputField
              name='shippingAddress.city'
              label='City'
              value={values.shippingAddress.city || ''}
            />
          </Box>
          <Flex>
            <Box marginRight='small'>
              <InputField
                name='shippingAddress.stateProvinceRegionCode'
                label='State'
                value={values.shippingAddress.stateProvinceRegionCode || ''}
              />
            </Box>
            <Box marginRight='small'>
              <InputField
                name='shippingAddress.countryCode'
                label='Country'
                value={values.shippingAddress.countryCode || ''}
              />
            </Box>
            <Box>
              <InputField
                name='shippingAddress.zipPostalCode'
                label='Zip code'
                value={values.shippingAddress.zipPostalCode || ''}
              />
            </Box>
          </Flex>
        </>
      ) : (
        <>
          <Text>{card?.shippingCompanyName}</Text>
          <Text data-test-review-shipping-address-contact-name>
            {card?.shippingContactName}
          </Text>
          <Text>{card?.shippingAddressLine1}</Text>
          <Text>{card?.shippingAddressLine2}</Text>
          <Text>
            {card?.shippingCity && `${card?.shippingCity},`}{' '}
            {card?.shippingStateProvinceRegionCode}
          </Text>
          <Text>{card?.shippingZipPostalCode}</Text>
          <Text>{t(getFullCountryByCode(card?.shippingCountryCode))}</Text>
        </>
      )}
    </>
  );
};

export default ShippingAddress;
