import { types } from './types';

type CartPayloadProps = {
    body: any; 
};

type UpdatePayloadProps = {
    body: any;
};

type RemoveItemProps = {
    entryNumber: number;
};

export const getCardID = () => ({
    type: types.FETCH_CART_ID,
});

export const getCardIDSuccess = (payload: any) => ({
    type: types.FETCH_CART_ID_SUCCESS,
    payload,
});

export const getCardIDFail = (payload: any) => ({
    type: types.FETCH_CART_ID_FAIL,
    payload,
});

export const createCardID = () => ({
    type: types.CREATE_CART_ID,
});

export const createCardIDSuccess = (payload: any) => ({
    type: types.CREATE_CART_ID_SUCCESS,
    payload,
});

export const createCardIDFail = (payload: any) => ({
    type: types.CREATE_CART_ID_FAIL,
    payload,
});

export const addProductInCart = (payload?: CartPayloadProps) => ({
    type: types.ADD_PRODUCT_IN_CART,
    payload,
});

export const addProductInCartSuccess = (payload: any) => ({
    type: types.ADD_PRODUCT_IN_CART_SUCCESS,
    payload,
});

export const addProductInCartFail = (payload: any) => ({
    type: types.ADD_PRODUCT_IN_CART_FAIL,
    payload,
});

export const updateCartQuantity = (payload?: UpdatePayloadProps) => ({
    type: types.UPDATE_CART_QUANTITY,
    payload,
});

export const updateCartQuantitySuccess = (payload: any) => ({
    type: types.UPDATE_CART_QUANTITY_SUCCESS,
    payload,
});

export const updateCartQuantityFail = (payload: any) => ({
    type: types.UPDATE_CART_QUANTITY_FAIL,
    payload,
});

export const removeCartItem = (payload?: RemoveItemProps) => ({
    type: types.REMOVE_CART_ITEM,
    payload,
});

export const removeCartItemSuccess = (payload: any) => ({
    type: types.REMOVE_CART_ITEM_SUCCESS,
    payload,
});

export const removeCartItemFail = (payload: any) => ({
    type: types.REMOVE_CART_ITEM_FAIL,
    payload,
});

export const getCardDetails = (payload?: any) => ({
    type: types.FETCH_CART_DETAILS,
    payload,
});

export const getCardDetailsSuccess = (payload: any) => ({
    type: types.FETCH_CART_DETAILS_SUCCESS,
    payload,
});

export const getCardDetailsFail = (payload: any) => ({
    type: types.FETCH_CART_DETAILS_FAIL,
    payload,
});

export const updateCartValue = () => ({
    type: types.UPDATE_CART_FLAG,
});