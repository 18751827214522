export const subscriptionDetailsUtils = (rawData: any) => {
    const model = {
        viewer: {
            firstName: '',
            lastName: '',
            contactEmail: '',
            primaryPhoneNumber: '',
            phoneNumbers: null,
        },
        enrollment: {
            id: '',
            name: '',
            status: '',
            activationDate: null,
            contractDuration: '',
            billingCycle: '',
            purchaseDate: '',
            cancellationDate: null,
            cancellationReason: null,
            subscriptionEndDate: null,
            billableQuantity: 0,
            autoRenew: true,
            logoApproved: false,
            amount: 0,
            currencyCode: '',
            renewalDate: '',
            estimatedBillPriceCode: '',
            estimatedBillPriceCodeValue: '',
            paymentSource: {
                paymentSourceId: '',
                clientSecret: '',
                paymentSessionId: '',
                payPalBillingToken: null,
                type: '',
                creditCard: {
                    lastFourDigits: 0,
                    expirationMonth: 0,
                    brand: '',
                    expirationYear: 0,
                },
                billTo: {
                    firstName: '',
                    lastName: ' ',
                    organization: '',
                    email: null,
                    address: {
                        addressLine1: '',
                        addressLine2: null,
                        city: '',
                        stateProvinceRegionCode: '',
                        zipPostalCode: '',
                        countryCode: '',
                    },
                },
            },
        },
    };

    if (!rawData) return model;
    if (rawData) {
        model.viewer.firstName = rawData?.order?.orgCustomer?.firstName;
        model.viewer.lastName = rawData?.order?.orgCustomer?.lastName;
        model.enrollment.name = rawData?.name;
        model.enrollment.contractDuration = rawData?.contractDuration;
        model.enrollment.autoRenew = rawData?.renewalType?.toLowerCase() === 'non_renewing' ? false :  true;
        model.enrollment.status = rawData?.subscriptionStatus?.toUpperCase();
        model.enrollment.activationDate = rawData?.startDate;
        model.enrollment.purchaseDate = rawData?.placedOn;
        model.enrollment.cancellationDate = rawData?.cancelledDate;
        model.enrollment.subscriptionEndDate = rawData?.endDate;
        model.enrollment.logoApproved = false;
        model.enrollment.billingCycle = Capitalize(rawData?.billingFrequency?.code);
        model.enrollment.renewalDate = rawData?.nextInstallmentDate;
    }

    if (rawData.paymentInfo) {
        model.enrollment.paymentSource.paymentSourceId = rawData?.paymentInfo?.drSourceId;
        model.enrollment.paymentSource.type =
            rawData?.paymentInfo?.drSourceType === 'creditCard'
                ? 'CREDIT_CARD'
                : rawData?.paymentInfo?.drSourceType;
        model.enrollment.paymentSource.creditCard.brand =
            rawData?.paymentInfo?.drccbrand?.toUpperCase();
        model.enrollment.paymentSource.creditCard.expirationMonth =
            rawData?.paymentInfo?.drccexpiryMonth;
        model.enrollment.paymentSource.creditCard.expirationYear =
            rawData?.paymentInfo?.drccexpiryYear;
        model.enrollment.paymentSource.creditCard.lastFourDigits =
            rawData?.paymentInfo?.drcclastFourDigits;

        model.enrollment.paymentSource.billTo.firstName =
            rawData?.paymentInfo?.billingAddress?.firstName;
        model.enrollment.paymentSource.billTo.lastName =
            rawData?.paymentInfo?.billingAddress?.lastName;
        model.enrollment.paymentSource.billTo.email = rawData?.paymentInfo?.billingAddress?.email;
        model.enrollment.paymentSource.billTo.address.addressLine1 =
            rawData?.paymentInfo?.billingAddress?.line1;
        model.enrollment.paymentSource.billTo.address.addressLine2 =
            rawData?.paymentInfo?.billingAddress?.line2;
        model.enrollment.paymentSource.billTo.address.city =
            rawData?.paymentInfo?.billingAddress?.town;
        model.enrollment.paymentSource.billTo.address.stateProvinceRegionCode =
            rawData?.paymentInfo?.billingAddress?.region?.isocodeShort;
        model.enrollment.paymentSource.billTo.address.zipPostalCode =
            rawData?.paymentInfo?.billingAddress?.postalCode;
        model.enrollment.paymentSource.billTo.address.countryCode =
            rawData?.paymentInfo?.billingAddress?.region?.countryIso;
    }
    if (rawData.paymentInfo) {
        model.enrollment.amount =
            rawData?.ratePlans[0]?.recurringChargeEntries[0]?.price?.value;
        model.enrollment.currencyCode =
            rawData?.ratePlans[0]?.recurringChargeEntries[0]?.price?.currencyIso;
        model.enrollment.billableQuantity = rawData?.ratePlans[0]?.recurringChargeEntries[0]?.quantity;
        model.enrollment.estimatedBillPriceCode = rawData?.ratePlans[0]?.recurringChargeEntries[0]?.recurringPriceCode[0]?.priceCode;
        model.enrollment.estimatedBillPriceCodeValue = rawData?.ratePlans[0]?.recurringChargeEntries[0]?.recurringPriceCode[0]?.priceCodeValue;
    }

    return model;
};

export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
