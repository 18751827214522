import produce from 'immer';
import { types } from './types';

const initialState = {
    refreshingToken: false,
};

const reducer = (state = initialState, action: { type: any }) => {
    const { type } = action;

    switch (type) {
        case types.REFRESH_TOKEN:
            return produce(state, (draftState) => {
                draftState.refreshingToken = true;
            });
        case types.REFRESH_TOKEN_COMPLETE:
            return produce(state, (draftState) => {
                draftState.refreshingToken = false;
            });
        default:
            return state;
    }
};

export { reducer };
