import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { ReactComponent as SearchIcon } from 'components/icons/search-icon.svg';
import { Button, Flex, Input, Text } from '@resideo/blueprint-react';
// import { SuccessAlert, ErrorAlert } from 'components/common/Alert';
import styled, { keyframes } from 'styled-components';
import { useToast } from 'components/common/Toast';
import { useIsMounted } from 'hooks/useIsMounted';

const StyledMenuButton = styled(MenuButton)`
    cursor: pointer;
    transform: scale(0.77);
`;

const SearchIconSVG = styled(SearchIcon)`
    color: #fff !important;
    width: 18px;
    height: 18px;
    fill: #fff !important;
`;

const animateCartDropdownDesktop = keyframes`
  0% {
    top: 85px;
  }

  100% {
    top: 70px;
  }
`;

const SearchBoxContainer = styled(MenuList)`
    position: absolute;
    top: 70px;
    right: -100px;
    overflow: hidden;
    width: calc(90vw - 120px);
    display: none !important;
    transition: all 0.3s ease;
    z-index: 1;
    background: #fff;
    padding: 10px 32px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.3);

    &:before {
        position: absolute;
        top: -14px;
        right: 100px;
        content: '';
        border-right: 14px solid transparent;
        border-bottom: 14px solid #fff;
        border-left: 14px solid transparent;
    }
    &.open {
        display: block !important;
        top: 85px;
        overflow: visible;
        height: auto;
        animation: ${animateCartDropdownDesktop} 0.3s ease;
        animation-delay: 0.1s;
        animation-fill-mode: forwards;
    }

    @media (max-width: 768px) {
        right: -150px;
        padding: 10px;
        width: calc(94vw - 32px);
        &:before {
            right: 150px;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        padding: 10px;
    }
    @media (min-width: 992px) {
        padding: 10px 32px;
    }
`;

const TextBoxContainer = styled.div`
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
`;
const SearchButton = styled.a`
    color: #fff;
    border: 1px solid #7e8c9a;
    border-left: none;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    height: 50px;
    width: 60px;
    cursor: pointer;
    line-height: 0;
    z-index: 2;
    &:before {
        content: '';
        vertical-align: middle;
        height: 100%;
        display: inline-block;
    }
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 0;
    background: #032a3c;
    &:hover {
        background: #004266;
    }
`;

const SearchButtonIcon = styled.span`
    vertical-align: middle;
    animation: none;
    display: inline-block;
`;

const SearchInput = styled(Input)`
    background: #fff;
    height: 48px;
    overflow: hidden;
    position: relative;
    font-size: 21px;
    line-height: 35px;
    color: #000;
    padding-left: 16px;
    border: none !important;
    &:focus {
        border: none !important;
        box-shadow: none;
    }

    @media (max-width: 767px) {
        font-size: 16px;
        color: #000;
        line-height: 21px;
        text-indent: 0;
        padding: 12px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
        font-size: 16px;
        color: #000;
        line-height: 21px;
        text-indent: 0;
        padding: 12px;
    }
`;

const NotificationRowComponent = ({ node, fetch }) => {
    const { t } = useTranslation();
    const toast = useToast();

    return (
        <MenuItem
            onSelect={() => {
                // acceptInvite();
            }}>
            <Flex
                justifyContent='space-between'
                flexDirection={['column', 'row']}
                data-test-invite-notification>
                <Flex
                    flexDirection='column'
                    marginRight='large'
                    alignItems='self-start'
                    paddingBottom={['medium', 0]}>
                    <Text color='white'>
                        {t('header.notifications.invitedTo')}
                        {` ${node.partnerAccount.name}`}
                    </Text>
                    <Text color='faded' fontSize='small'>
                        {t('header.notifications.invitedBy')}
                    </Text>
                </Flex>
                <Button>{t('header.notifications.acceptInvite')}</Button>
            </Flex>
        </MenuItem>
    );
};

const useMountEffect = (cb: () => void) => useEffect(cb, []);

const Notifications: FC = () => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState();
    const [searchQuery, setSearchQuery] = useState('');
    const searchIconClicked = () => {
        console.log(`searchIconClicked`);
    };

    return (
        <Flex marginRight={['small', 'small', 'medium', 'medium']} data-test-notifications-menu>
            <Menu>
                <StyledMenuButton onClick={searchIconClicked}>
                    <SearchIcon />
                </StyledMenuButton>
                <SearchBoxContainer className='open' portal={false}>
                    <TextBoxContainer>
                        <SearchInput
                            onChange={(e) => setSearchQuery(e.target.value)}
                            value={searchQuery}
                            placeholder='What are you looking for today?'
                            name='searchQuery'
                            type='text'
                        />
                        <SearchButton>
                            <SearchButtonIcon>
                                <SearchIconSVG />
                            </SearchButtonIcon>
                        </SearchButton>
                    </TextBoxContainer>
                </SearchBoxContainer>
            </Menu>
        </Flex>
    );
};

export default Notifications;
