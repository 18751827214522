import { REQUESTACCESS_FLAG } from 'config';

/*
    This UI is mounted at the path /mybusiness/services-subscriptions
    in Pro-Portal, the /mybusiness path has the secondary navigation links
    but this UI does not have a special route to contain the link data.
    For that reason, we have created the link data as a seaparate file
*/
export const subNavLinks = () => {
  const isDevelopEnvironment =
    process.env.REACT_APP_ENVIRONMENT !== 'production';

  return [
    {
      name: 'subscriptions',
      external: false,
      path: '/subscription',
      textKey: 'header.secondary.servicesSubscriptions',
    },
  ];
};
