import React from 'react';
import { Menu, MenuButton, MenuLink, MenuList } from '@reach/menu-button';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const NavMenuButton = styled(MenuButton)`
    margin-right: 16px;
`;


const ExternalNavLink = (rest) => {
  const history = useHistory();

  return (
    <Menu>
      <NavMenuButton onClick={() => history.push(rest.to)} data-test-profile-menu-button {...rest}>
        {rest.children}
      </NavMenuButton>
    </Menu>
  )
}

export default ExternalNavLink;
