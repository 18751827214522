import React, { useState } from 'react';
import { Box, Heading, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';

const BoxOverview = styled(Box)`
    padding: 70px 195px;
    width: 100%;
    @media (max-width: 768px) {
        padding: 30px;
    }
`;

type ContentProps = {
  more?: boolean;
};

const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    max-height: auto;
    //flex-direction: ${(p: ContentProps) => () => p.more ? 'row' : 'column'};
    //max-height: ${(p: ContentProps) => () => p.more ? 'auto' : '500px'};
`;

const Row = styled.div`
    display: flex;
    flex: 100%;
    min-width: 100%;
    max-width: 100%;
    border-bottom: 0.5px solid lightgray;
    align-items: center;
    padding: 15px 0;
    @media (max-width: 768px) {
        flex: 100%;
        max-width: 100%;
    }
`;

const Col6 = styled.div`
    display: flex;
    flex: 48%;
    min-width: 48%;
    max-width: 48%;
    border-bottom: 0.5px solid lightgray;
    align-items: center;
    padding: 15px 0;
    /* margin: 10px 0; */
    @media (max-width: 768px) {
        flex: 100%;
        max-width: 100%;
    }
`;

const ColMore = styled.div`
    flex: 100%;
    height: 24px;
    width: 82px;
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.16px;
    line-height: 24px;
    cursor: pointer;
    margin: 20px 0;
    padding: 10px 0;
`;

const Text1 = styled(Text)`
    min-width: 40%;
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 24px;
`;
const Text2 = styled(Text)`
    color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.2px;
    line-height: 24px;
`;

const Specifications = ({ specificationsRef, data }) => {
  const [more, setMore] = useState(false);
  const dataForDisplay = more ? data : data.slice(0, 10);
  return (
    <Box ref={specificationsRef} justifyContent='left' id='Specifications'>
      <Box justifyContent='left' display='flex'>
        <BoxOverview>
          <Heading
            color='#032A3B'
            fontSize='40px'
            paddingBottom={['small', 'medium', 'medium', 'large']}
            fontFamily='URW_Din_CondBold'
            letterSpacing='2px'>
            SPECIFICATIONS
          </Heading>
          <ContentWrapper more={more}>
            {dataForDisplay.map((c, index) => {
              return (
                <Row key={`data-${index}`}>
                  <Text1>{c.attribute}</Text1>
                  <Text2>{c.value}</Text2>
                </Row>
              );
            })}
            {/* {more ? (
              <>
                {dataForDisplay.map((c, index) => {
                  return (
                    <Col6 key={`data-${index}`}>
                      <Text1>{c.text1}</Text1>
                      <Text2>{c.text2}</Text2>
                    </Col6>
                  );
                })}
              </>
            ) : null} */}
          </ContentWrapper>
          {!more && (
            <div>
              <ColMore onClick={() => setMore(true)}>+ VIEW ALL</ColMore>
            </div>
          )}
        </BoxOverview>
      </Box>
    </Box>
  );
};

export default Specifications;
