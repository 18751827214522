import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchingProductDetails: false,
    productDetail: [],
    fetchingProductDetailError: null,
}

const productDetails = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch(type) {
        case types.FETCH_PRODUCT_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingProductDetails = true;
            })
        case types.FETCH_PRODUCT_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingProductDetails = false;
                draftState.productDetail   = payload
            })
        case types.FETCH_PRODUCT_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingProductDetails = false;
                draftState.fetchingProductDetailError   = payload
            })
        default:
            return state;
    }
}

export { productDetails }