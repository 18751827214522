import { call, all, put, takeLatest} from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { getCategoryUrl } from '../../config/api/url';
import { httpRequest } from '../types';
import {
    getCategorySuccess,
    getCategoryFail
} from './actions';

function* getCategory(): any {
    try {
        const response = yield call(api, getCategoryUrl, httpRequest.GET, null, 2,0, false, true);
        yield put(getCategorySuccess(response))
    } catch (error) {
        yield put(getCategoryFail(error))
    }
}

function* getCategoryWatcher() {
    yield takeLatest(types.FETCH_CATEGORY, getCategory);
}

export default function* categorySaga() {
    yield all([
        getCategoryWatcher(),
    ])
}
