import React, { FC, useEffect, useState } from 'react';
import {
    blackCrossImage,
    btnMinusBlack,
    btnPlusBlack,
} from 'assets/images';
import { Box, Image, Heading, Text, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ServiceIconLarge from 'assets/images/ServiceIconLarge';
import { convertMoneyAmount } from 'routes/Products/Cart';
import { getCardDetails, removeCartItem, updateCartQuantity } from 'redux/cart/actions';
import { RootState } from 'redux/root-reducer';
import PageLoading from 'components/common/PageLoading';
import { useDispatchPromise } from 'hooks/useDispatchPromise';

const Container = styled(Flex)`
    align-items: flex-start;
    position: relative;
    .product-img {
        object-fit: cover;
    }
`;

const FlexProductImage = styled(Flex)`
    align-items: center;
`;

const StyledCursorImage = styled(Image)`
    cursor: pointer;
    width: 32px;
    height: 32px
    &.closeImage {
        position: absolute;
        top: 2rem;
        right: -0.875rem;
        height: 1rem;
        width: 1rem;
    }
    &.disabled {
        opacity:0.5;
        pointer-events: none;
      }
`;

const StyledCursorImageClose = styled(Image)`
    cursor: pointer;
    width: 32px;
    height: 32px;
    align-self: center;
    &.closeImage {
        position: absolute;
        top: 2rem;
        right: -0.875rem;
        height: 1rem;
        width: 1rem;
    }
`;

const BoxItemDesc = styled(Box)`
    padding: 1rem;
    white-space: normal;
`;

const QtyAndTotal = styled(Heading)`
    font-family: Roboto;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1rem;
    text-align: right;
    color: ${({ theme }) => theme.colors.text};
    display: flex;
    align-items: center;
    padding-right: ${({ theme }) => theme.space.small};
`;

interface CartItemsProps {
    item: cartItemList;
}

type cartItemList = {
    cancellableQuantity: number;
    configurationInfos: any[];
    entryNumber: number;
    product: Product;
    quantity: number;
    returnableQuantity: number;
    statusSummaryList: any[];
    totalPrice: TotalPrice;
};

type Product = {
    availableForPickup: boolean;
    baseProduct: string;
    channelDisplayCategory: string;
    code: string;
    configurable: boolean;
    images: Images[];
    isNew: boolean;
    manufacturer: string;
    metaDescription: string;
    metaTitle: string;
    name: string;
    ogDescription: string;
    ogTitle: string;
    purchasable: boolean;
    resources: any[];
    soldDirectlyToCustomer: boolean;
    specs: any[];
    syndicationChannel: string;
    url: string;
    itemType: string;
};

type Images = {
    format: string;
    imageType: string;
    url: string;
};

type TotalPrice = {
    currencyIso: string;
    value: number;
};

const CartItemPreview: FC<any> = ({ item }: CartItemsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const [isLoading, setIsLoading] = useState(false);
    const { updateCartItemSuccess, removedCartItemSuccess } = useSelector((state: RootState) => state.cartDetails);

    const removeItem = async (entryNumber) => {
        hookDispatch(
            await removeCartItem({ entryNumber: entryNumber }),
        ).then(async (response) => {
            await dispatch(getCardDetails());
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }).catch((error) => {
            console.log('Error', error);
            setIsLoading(false);
        });
    };

    const updateQuantity = async (type) => {
        let updateQtyValue = type === 'add' ? item?.quantity + 1 : item?.quantity - 1;

        if (updateQtyValue < 1) {
            hookDispatch(
                await removeCartItem({ entryNumber: item.entryNumber }),
            ).then(async (response) => {
                await dispatch(getCardDetails());
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            }).catch((error) => {
                console.log('Error', error);
                setIsLoading(false);
            });
        } else {
            if (updateQtyValue) {
                let dataToSend = {
                    orderEntries: [
                        {
                            entryNumber: item.entryNumber,
                            product: {
                                code: item.product.code,
                            },
                            quantity: updateQtyValue,
                        },
                    ],
                };
                await dispatch(updateCartQuantity({ body: dataToSend }));
            }
        }
    };

    // useEffect(() => {
    //     if (removedCartItemSuccess) {
    //         dispatch(getCardDetails());
    //     }
    // }, [dispatch, removedCartItemSuccess]);

    useEffect(() => {
        if (updateCartItemSuccess) {
            dispatch(getCardDetails());
        }
    }, [dispatch, updateCartItemSuccess]);

    return (
        <Container alignItems='center'>
            {isLoading && <PageLoading />}
            <FlexProductImage
                width={'8rem'}
                minHeight='100px'
                paddingY={'0.5rem'}
                paddingX={'0.5rem'}
                justifyContent='center'
                style={{ border: '2px solid #ededed' }}>
                {/* TODO : Need to enable when dynamic images available */}
                {/* {item && item?.product?.images ? (
                    <Image
                        src={item?.product?.images[0].url}
                        alt='box image'
                        maxWidth='100px'
                        height='100px'
                    />
                ) : (
                    <ServiceIconLarge />
                )} */}
                <ServiceIconLarge />
            </FlexProductImage>
            <Flex flexWrap='nowrap' alignItems={'flex-start'} flexDirection={['column', 'row']}>
                <BoxItemDesc display='block' padding='small' flex='2' textAlign={'left'}>
                    <Heading
                        data-test-cart-item-name
                        letterSpacing='0'
                        fontSize='medium'
                        color='cartTextHeading'>
                        {item?.product?.name
                            ?.split(/\s+/)
                            ?.map((w) => w[0]?.toUpperCase() + w.substr(1)?.toLowerCase())
                            ?.join(' ')}
                    </Heading>
                    <Text
                        data-test-cart-item-sku
                        color='gray'
                        fontSize='small'
                        fontWeight='500'
                        paddingTop='xSmall'>
                        {item?.product?.code}
                    </Text>
                </BoxItemDesc>
                <Flex paddingLeft={['small', 'small', 'small', 'small']}>
                    <Flex display={['block', 'block', 'block', 'flex']} paddingRight='small'>
                        <>
                            {item && item?.product?.itemType?.toLowerCase() === 'product' ? (
                                <Box display='flex' alignItems='center' padding={'10px'}>
                                    <StyledCursorImage
                                        onClick={() => updateQuantity('minus')}
                                        src={btnMinusBlack}
                                        alt='minus'
                                    />
                                    <Heading
                                        data-test-cart-item-qty
                                        fontSize='1rem'
                                        padding='small'
                                        color='cartTextHeading'
                                        fontWeight='bold'>
                                        {item?.quantity}
                                    </Heading>
                                    <StyledCursorImage
                                        onClick={() => updateQuantity('add')}
                                        src={btnPlusBlack}
                                        alt='minus'
                                    />
                                </Box>
                            ) : (
                                <Box display='flex' alignItems='center'>
                                    <Heading
                                        data-test-cart-item-qty
                                        fontSize='1rem'
                                        color='cartTextHeading'
                                        padding='small'
                                        paddingRight='xLarge'
                                        fontWeight='bold'>
                                        x{item?.quantity}
                                    </Heading>
                                </Box>
                            )}
                        </>

                        <QtyAndTotal
                            data-test-cart-item-total
                            padding={'1rem'}
                            textAlign={['left', 'left', 'left', 'left']}
                        >
                            {convertMoneyAmount(
                                {
                                    amount: item?.totalPrice?.value,
                                    currencyCode: item?.totalPrice?.currencyIso ?? 'USD',
                                },
                                'en-us',
                            )}
                        </QtyAndTotal>
                    </Flex>
                    <StyledCursorImageClose
                        src={blackCrossImage}
                        alt='close'
                        onClick={() => {
                            removeItem(item?.entryNumber);
                            setIsLoading(true);
                        }}
                    />
                </Flex>
            </Flex>
        </Container>
    );
};
export default CartItemPreview;
