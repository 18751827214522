import { Flex, Label, Radio, Text } from '@resideo/blueprint-react';
import { Field } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShippingMethod = ({ showForm, type, card }) => {
  const { t } = useTranslation();
  return (
    <>
      {showForm && type === 'shippingMethod' ? (
        <>
          <Field name='shippingMethod' type='radio' value='standard'>
            {({ field }) => (
              <Label>
                <Radio name='shippingMethod' marginRight={0} {...field} />
                <Flex fontSize='14px' justifyContent='space-between' flex={1}>
                  {t('cart.shipping.standardGroundBusinessDays')}
                  <Text fontSize='14px'>$10.00</Text>
                </Flex>
              </Label>
            )}
          </Field>
          <Field name='shippingMethod' type='radio' value='expressSecondDay'>
            {({ field }) => (
              <Label>
                <Radio name='shippingMethod' marginRight={0} {...field} />
                <Flex fontSize='14px' justifyContent='space-between' flex={1}>
                  {t('cart.shipping.domesticSecondDayAir')}
                  <Text fontSize='14px'>$20.00</Text>
                </Flex>
              </Label>
            )}
          </Field>
        </>
      ) : (
        <Text>{card.shippingMethod}</Text>
      )}
    </>
  );
};

export default ShippingMethod;
