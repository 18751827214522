import { createGlobalStyle } from 'styled-components';
import lightTheme from '../../themes/light';

type Theme = typeof lightTheme;

const GlobalStyles = createGlobalStyle<{
  theme: Theme;
  hideScrollBar?: boolean;
}>`
  /* stylelint-disable */
  body {
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.body};
    overflow: ${({ hideScrollBar }) => (hideScrollBar ? 'hidden' : '')};
  }
  select, input {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }
  .hscroll {
    overflow-x:auto;
  }
  .table-responsive {
    width: 100%;
    border-collapse: collapse;
    tr {
      th {
        white-space: nowrap;
        font-weight: bold;
        padding: 15px 25px;
        font-size: ${({ theme }) => theme.fontSizes[2]};
        background-color: ${({ theme }) => theme.colors.lightenGray};
        text-align: left;
      }
      td {
        white-space: nowrap;
        padding: 15px 25px;
        text-align: left;
        font-size: ${({ theme }) => theme.fontSizes[3]};
      }
    }
    .tr-border-bottom {
      border-bottom: 2px solid ${({ theme }) => theme.colors.lightenGray};
    }
  }

  /* animations styles */
  .back-arrow-animation {
    display: flex;
    text-decoration: none;
    img {
      margin-left: 0px;
      transition-duration: 400ms;
    }
    &:hover {
      img {
        margin-left: 0px;
        margin-right: 5px;
      }
    }
  }
  .forward-arrow-animation {
    display: flex;
    text-decoration: none;
    img {
      margin-left: 5px;
      transition-duration: 400ms;
      -webkit-transform:rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    &:hover {
      img {
        margin-left: 10px;
        margin-right: 5px;
      }
    }
  }
  .custom-date-field {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    background-color: #ffffff;
    display: block;
    width: 100%;
    padding: 0.5625rem 0.75rem;
    font-family: "Roboto",sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-color: #4f7285;
    border-radius: 0.75rem;
    border-style: solid;
    border-width: 1px;
    color: #000000;
    &:focus {
      border-color: #166a96;
      box-shadow: 0 0 0 3px rgba(0,169,224,0.2);
      outline: none;
    }
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .no-print {
    @media print {
      display: none;
    }
  }

  .avoid-print-break {
    @media print {
      page-break-inside: avoid;
    }
  }
  /* --------- */
  .custom-scroll-bar {
    &::-webkit-scrollbar {
      width: 0.75em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 17px ${({ theme }) => theme.colors.lightenGray};;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.lightenGray};
      outline: 1px solid ${({ theme }) => theme.colors.lightenGray};
    }
  }

  .black-place-holder {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${({ theme }) => theme.colors.black};
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      color: ${({ theme }) => theme.colors.black};
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: ${({ theme }) => theme.colors.black};
    }
  }
  #teconsent {
    text-transform: uppercase;
  }
`;

export default GlobalStyles;
