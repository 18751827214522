import { useContext } from 'react';
import AuthenticationContext, { AuthenticationState } from './context';
import { useAuth0 } from '@auth0/auth0-react';
import { ENABLE_MOCKS } from 'config';

let useAuth;

if (ENABLE_MOCKS) {
  useAuth = (): AuthenticationState => useContext(AuthenticationContext);
} else {
  useAuth = useAuth0;
}

export default useAuth;
