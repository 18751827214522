import { types } from './types';

export const getLanguage = () => ({
    type: types.FETCH_LANGUAGE
})

export const getLanguageSuccess = (payload: any) => ({
    type: types.FETCH_LANGUAGE_SUCCESS,
    payload,
})

export const getLanguageFail = (payload: any) => ({
    type: types.FETCH_LANGUAGE_FAIL,
    payload,
})