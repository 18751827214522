/* eslint-disable no-template-curly-in-string */
import { call, all, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../config/api';
import { types } from './types';
import { httpRequest } from '../types';
import { getOrderInformationSuccess, getOrderInformationFail } from './actions';
import { getOrderInformationUrlMod } from 'config/api/url';

function* getOrderInformation({ payload }: any): any {
    try {
        const OrderInformationUrl =
            getOrderInformationUrlMod + `current/orders/${payload}/?fields=FULL`;
        const response = yield call(api, OrderInformationUrl, httpRequest.GET, null, 2);
        yield put(getOrderInformationSuccess(response));
    } catch (error) {
        yield put(getOrderInformationFail(error));
    }
}

function* getOrderInformationWatcher() {
    yield takeLatest(types.FETCH_ORDER_INFORMATION, getOrderInformation);
}

export default function* orderInformationSaga() {
    yield all([getOrderInformationWatcher()]);
}
