import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Cart from './Cart';
import Shipping from './Shipping';
import Payment from './Payment';
import ReviewOrder from './ReviewOrder';
import Order from './Order';
import { Text } from '@resideo/blueprint-react';
import PageLoading from '../../../components/common/PageLoading';
import Alert from '../../../components/common/Alert';
import { useTranslation } from 'react-i18next';
import Page from '../../../components/common/Page';
import cartData from '../../../data/cartRoutesState.json';

interface MoneyProps {
    amount: number | string;
    currencyCode: string;
}

export const convertMoneyAmount = (value: MoneyProps, locale) => {
    if (value === null || value === undefined || locale === undefined || !value?.currencyCode)
        return '';

    const money = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: value?.currencyCode || 'USD',
    }).format(+value?.amount || 0);

    return money;
};

interface CartState {
    billingId?: string | null;
    defaultShipTo: any;
    defaultBillTo: any;
    partnerAccount: any;
    enrollments: any;
    viewer: any;
    isPartnerAccountIncomplete: boolean;
}

const CartRoutes: FC = () => {
    const { path } = useRouteMatch();

    const { t } = useTranslation();

    const [cartRoutesState, setCartRoutesState] = useState<CartState>({
        billingId: null,
        defaultShipTo: null,
        defaultBillTo: null,
        partnerAccount: null,
        enrollments: null,
        viewer: null,
        isPartnerAccountIncomplete: true,
    });

    const viewerData = () => {
        setCartRoutesState({
            billingId: cartData.billingId,
            defaultShipTo: cartData.defaultShipTo,
            defaultBillTo: cartData.defaultBillTo,
            partnerAccount: cartData.partnerAccount,
            enrollments: cartData.enrollments,
            viewer: cartData.viewer,
            isPartnerAccountIncomplete: cartData.isPartnerAccountIncomplete,
        });
    };

    useEffect(() => {
        viewerData();
    }, []);

    const showOnboardingState = cartRoutesState.isPartnerAccountIncomplete;
    return !cartRoutesState.viewer ? (
        <PageLoading />
    ) : (
        <Page as='main' maxWidth='1375px' data-test-cart-wrapper>
            {false && <PageLoading />}
            <Switch>
                <Route
                    exact
                    path={path}
                    component={() => (
                        <Cart
                            showOnboardingState={showOnboardingState}
                            userPlans={cartRoutesState.enrollments}
                            digitalRiverBillingId={cartRoutesState.billingId}
                            partnerAccountId={cartRoutesState.partnerAccount?.id}
                            createCheckoutMutation={[0]}
                            deleteCheckoutMutation={[0]}
                        />
                    )}
                />
                {/* <Route
          path={`${path}/shipping`}
          component={() => (
            <Shipping
              // partnerAccountId={partnerAccountId}
              digitalRiverBillingId={cartRoutesState.billingId}
              updateCheckoutMutation={[0]}
              defaultShipTo={cartRoutesState.defaultShipTo}
              defaultBillTo={cartRoutesState.defaultBillTo}
              viewerData={cartRoutesState.viewer}
            />
          )}
        /> */}
                <Route
                    path={`${path}/payment`}
                    component={() => (
                        <Payment
                            digitalRiverBillingId={cartRoutesState.billingId}
                            updateCheckoutMutation={[0]}
                            defaultBillTo={cartRoutesState.defaultBillTo}
                            viewer={cartRoutesState.viewer}
                        />
                    )}
                />
                <Route
                    path={`${path}/review-order`}
                    component={() => (
                        <ReviewOrder
                            data-test-review-order-page
                            createOrderMutation={[0]}
                            updateCheckoutMutation={[0]}
                            viewerData={cartRoutesState.viewer}
                            digitalRiverBillingId={cartRoutesState.billingId}
                        />
                    )}
                />
                <Route
                    path={`${path}/order/:id`}
                    component={() => <Order viewerData={cartRoutesState.viewer} />}
                />
                {/* <Route
          path={`${path}/print-friendly-view/:id`}
          component={() => (
            <PrintFriendlyView viewerData={cartRoutesState.viewer} />
          )}
        /> */}
            </Switch>
        </Page>
    );
};

export default CartRoutes;
