import React, { useState } from 'react';
import { useModal, Text } from '@resideo/blueprint-react';
import { Formik, Form } from 'formik';
import CustomModal from './CustomModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Title } from 'components/common/Title';
import { useToast } from 'components/common/Toast';
import Alert from 'components/common/Alert';
import PlanInformationProgramEnrollmentsTable from './PlanInformationProgramEnrollmentsTable';

export const renewsOrExpiresOn = (purchaseDate, cycle) => {
    const dayOfPurchase = +moment(purchaseDate).format('DD');
    const monthOfPurchase = +moment(purchaseDate).format('MM');
    const currentDay = +moment().format('DD');
    const currentMonth = +moment().format('MM');

    const monthsAhead = currentMonth - monthOfPurchase;

    const months =
        monthsAhead === 0
            ? 1
            : monthsAhead < 0
                ? 12 - Math.abs(monthsAhead) + (currentDay > dayOfPurchase ? 1 : 0)
                : monthsAhead;

    // This looks even more confusing, but it's really not.
    // If monthsAhead is 0 we return 1 so that the date can be the following month.
    // If we go into January the monthsAhead become a negative number so I use
    // Math.abs to get the number to be the absolute value a.k.a. not a negative number,
    // then subtract if by 12 and either add 1 or 0 to get to the following month.
    // You can uncomment the variables up above to test and make sure it works as expected.

    switch (cycle) {
        case 'Annually':
            return moment(purchaseDate).add(1, 'year').format('MMM D, YYYY');
        case 'Quarterly':
            return moment(purchaseDate).add(3, 'months').format('MMM D, YYYY');
        default:
            return moment().date(dayOfPurchase).add(months, 'months').format('MMM D, YYYY');
    }
};

const PlanInformation = ({ enrollment, isLogoApproved, handleEnrollmentUpdate, setLoading }) => {
    const { Modal, openModal, closeModal } = useModal();
    const { t } = useTranslation();
    const toast = useToast();
    const [modalData, setModalData] = useState<any>(null);
    const submitForm = (values) => {
        console.debug('TEST-DEBUG:', values);
    };

    const initialForm = {
        autoRenew: enrollment?.autoRenew ?? false,
        cancelSub: 'Other',
        otherReason: '',
    };

    return (
        <Formik enableReinitialize onSubmit={submitForm} initialValues={initialForm}>
            {({ values, setFieldValue }) => (
                <Form style={{ width: '100%' }}>
                    {modalData && (
                        <CustomModal
                            Modal={Modal}
                            autoRenew={values.autoRenew}
                            cancelSub={values.cancelSub}
                            title={
                                localStorage.getItem('modalType') === 'CANCEL_SUB'
                                    ? t('manageSubscriptions.planInfo.cancelSub').toUpperCase()
                                    : values.autoRenew
                                        ? t('manageSubscriptions.planInfo.autoOff')
                                        : t('manageSubscriptions.planInfo.autoOn')
                            }
                            closeModal={closeModal}
                            setFieldValue={setFieldValue}
                            setAutoRenew={(
                                result,
                            ) => {
                                setFieldValue('autoRenew', !values.autoRenew ? false : true);
                                if (
                                    result?.setPartnerProgramEnrollmentAutoRenew
                                        ?.partnerProgramEnrollment
                                ) {
                                    handleEnrollmentUpdate(
                                        result.setPartnerProgramEnrollmentAutoRenew
                                            .partnerProgramEnrollment,
                                    );
                                }

                                if (values.autoRenew) {
                                    toast(
                                        <Alert variant='success'>
                                            <Text>{t('common.toast.autoRenewOff')}</Text>
                                        </Alert>,
                                        { duration: 10000 },
                                    );
                                }
                            }}
                            otherReason={values.otherReason}
                            program={modalData.name}
                            expires={
                                moment(enrollment?.renewalDate).format('MMM D, YYYY')
                                // modalData.purchaseDate
                                //     ? renewsOrExpiresOn(
                                //           modalData.purchaseDate,
                                //           modalData.billingCycle,
                                //       )
                                //     : renewsOrExpiresOn(
                                //           modalData.purchaseDate,
                                //           modalData.billingCycle,
                                //       )
                            }
                            programEnrollmentId={modalData.id}
                            handleSubscriptionCancel={(
                                result,
                            ) => {
                                if (
                                    result?.cancelPartnerProgramEnrollment?.partnerProgramEnrollment
                                ) {
                                    handleEnrollmentUpdate(
                                        result.cancelPartnerProgramEnrollment
                                            .partnerProgramEnrollment,
                                    );
                                    toast(
                                        <Alert variant='success'>
                                            <Text>{t('common.toast.subscriptionCanceled')}</Text>
                                        </Alert>,
                                        { duration: 10000 },
                                    );
                                }
                            }}
                            index={modalData.index}
                            setLoading={setLoading}
                        />
                    )}
                    {enrollment && (
                        <>
                            <Title data-test-plan-info-title>
                                {t('manageSubscriptions.planInfo.title')}
                            </Title>
                            <PlanInformationProgramEnrollmentsTable
                                enrollment={enrollment}
                                values={values}
                                setModalData={setModalData}
                                openModal={openModal}
                                setFieldValue={setFieldValue}
                                isLogoApproved={isLogoApproved}
                            />
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default PlanInformation;
