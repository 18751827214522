import { useDispatch } from 'react-redux';

export const useDispatchPromise = () => {
    const dispatch = useDispatch();
    const callDispatch = (props: any) =>
        new Promise((resolve, reject) => {
            dispatch({ ...props, resolve, reject });
        });

    return callDispatch;
};
