import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Heading, Text, Flex, Button, Image, Link } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CartItem from '../../../components/cart/CartItem';
import AddPromoCode from '../../../components/common/AddPromoCode';
import CheckoutSteps from '../../../components/cart/CheckoutSteps';
import GoBackButton from '../../../components/common/GoBackButton';
import { convertMoneyAmount } from '.';
import SeparatorLine from '../../../components/common/SeparatorLine';
import { Form, Formik } from 'formik';
import { whiteWarningIcon } from '../../../assets/images';
import GoForwardButton from '../../../components/common/GoForwardButton';
import { getCardDetails } from 'redux/cart/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/root-reducer';
import { CartContext } from '../CartProvider';
import { getUser } from 'redux/graphql/authentication/actions';

const StyledHeading = styled(Heading)`
    text-transform: uppercase;
    font-family: 'URW_Din_CondBold';
`;

const CartEmptyBack = styled.div`
    margin-top: 20px;
`;

const ErrorBox = styled(Box)`
    display: flex;
    padding: 1.875rem;
    align-items: center;
`;
const SubTotalText = styled(Text)`
    margin-top: -1.375rem;
    @media (max-width: 768px) {
        margin-left:-0.5rem;
    }
`;

const DiscountText = styled(Text)`
    font-size:1rem;
    padding-right:3.75rem;
    @media (max-width: 768px) {
        margin-left: 5.8125rem;
    }
`;

const Cart: FC<any> = (): JSX.Element => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const cartItems: any = useSelector((state: RootState) => state.cartDetails.cartDetails);
    const cartId: any = localStorage.getItem('cartId') ?? '';
    const { cartActions } = useContext(CartContext);

    const [hasEnrollmentAlready, setHasEnrollmentAlready] = useState(false); //need to check
    let cartItemList =
        localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== 'undefined'
            ? JSON.parse(localStorage.getItem('cartItems') || '')
            : [];

    const hasProduct = cartItemList && cartItemList?.entries?.length > 0 && cartItemList?.entries?.filter((x) => x.product.itemType?.toLowerCase() === 'product').length > 0;

    useEffect(() => {
        sessionStorage.setItem('temp-name-on-card', '');
        sessionStorage.setItem('temp-make-primary', '');
        sessionStorage.setItem('temp-country-code', '');
        sessionStorage.setItem('temp-city', '');
        sessionStorage.setItem('temp-security-code', '');
        sessionStorage.setItem('temp-address-line-1', '');
        sessionStorage.setItem('temp-address-line-2', '');
        sessionStorage.setItem('temp-month-year', '');
        sessionStorage.setItem('temp-state-province-region-code', '');
        sessionStorage.setItem('temp-card-number', '');
        sessionStorage.setItem('temp-zip-postal-code', '');
        sessionStorage.setItem('agreement-terms-checked', 'false');
        sessionStorage.setItem('payment-type', '');
        sessionStorage.setItem('temp-company-name', '');
        sessionStorage.setItem('temp-tax-start-date', '');
        sessionStorage.setItem('temp-tax-end-date', '');
        sessionStorage.setItem('temp-tax-state', '');
        sessionStorage.setItem('temp-file-name', '');
        localStorage.removeItem('taxExemptionApplication');

        if (cartItems && Object.keys(cartItems).length === 0) {
            dispatch(getCardDetails());
        }        

    }, []);

    const handleSubmit = () => {

        cartActions.setCartItem(JSON.stringify(cartItems));
        if (!hasProduct) {
            history.push(`${path}/payment`);
            window.scroll({ top: 0, behavior: 'smooth' })
        } else {
            history.push(`${path}/shipping`);
            window.scroll({ top: 0, behavior: 'smooth' })
        }
    };

    return (
        <Box data-test-cart>
            <CheckoutSteps step1 />
            <Box marginBottom='medium' marginX='large'>
                {cartItems && !cartItems?.entries?.length ? (
                    <>
                        <StyledHeading
                            color='textHeading'
                            paddingTop={['medium', 'medium', 'large', 'large']}
                            paddingBottom='xSmall'
                            fontSize='2.813rem'
                            textAlign={['left', 'left', 'left', 'left']}>
                            {t('cart.emptyCart')}
                        </StyledHeading>
                        <CartEmptyBack>
                            <GoForwardButton
                                data-test-cart-continue-btn
                                url={`/`}
                                text='Continue Shopping'
                            />
                        </CartEmptyBack>
                    </>
                ) : (
                    <>
                        <StyledHeading
                            paddingTop={['medium', 'medium', 'large', 'large']}
                            paddingBottom='xSmall'
                            fontSize='2.25rem'
                            color='textHeading'
                            textAlign={['center', 'center', 'left', 'left']}>
                            {t('cart.yourShoppingCart')}
                        </StyledHeading>
                        <Text
                            marginBottom='1.313rem'
                            data-test-cart-ref-number
                            paddingTop='xSmall'
                            color='gray'
                            fontWeight='500'
                            fontSize='medium'
                            textAlign={['center', 'center', 'left', 'left']}>
                            REF # {cartId}
                        </Text>
                    </>
                )}
                {hasEnrollmentAlready && (
                    <ErrorBox backgroundColor='redAlertError'>
                        <Image
                            src={whiteWarningIcon}
                            minHeight='0.875rem'
                            minWidth='0.875rem'
                            alt='error'
                        />
                        <Text
                            marginLeft='0.625rem'
                            alignItems='center'
                            color='white'
                            fontWeight='bold'
                            fontSize='0.875rem'>
                            {t('cart.hasExistingServiceError')}
                        </Text>
                    </ErrorBox>
                )}
                {cartItems && !cartItems?.entries?.length ? (
                    <Box height='500px' />
                ) : (
                    <Formik initialValues={{ promoCode: '' }} onSubmit={handleSubmit}>
                        {({ setFieldValue }) => (
                            <Form>
                                <Box paddingTop={['large', 'xLarge', 'xLarge', 'xLarge']}>
                                    {cartItems && !cartItems?.entries?.length ? (
                                        <Heading fontSize='xLarge' textAlign='center' color='gray'>
                                            {t('cart.emptyCart')}
                                        </Heading>
                                    ) : (
                                        cartItems &&
                                        cartItems?.entries?.map((item, i) => (
                                            <Box key={i}>
                                                <CartItem
                                                    setHasEnrollmentAlready={
                                                        setHasEnrollmentAlready
                                                    }
                                                    isAlert={
                                                        hasEnrollmentAlready && item.type === 'PRO'
                                                    }
                                                    item={item}
                                                    fromThankYou={false}
                                                />
                                                <Box paddingTop='small'>{/* seprator */}</Box>
                                                <SeparatorLine />
                                            </Box>
                                        ))
                                    )}
                                </Box>
                                <Flex
                                    flexDirection={['column', 'column', 'row', 'row']}
                                    alignItems='center'
                                    justifyContent='space-between'>
                                    <AddPromoCode
                                        setFieldValue={setFieldValue}
                                    />

                                    <Box>
                                        {cartItems && cartItems?.appliedVouchers && cartItems?.appliedVouchers?.length > 0 && (
                                            <Flex
                                                paddingY='medium'
                                                justifyContent={['center', 'center', 'flex-end']}
                                                alignItems='center'
                                            >
                                                <DiscountText
                                                    fontSize='1rem'
                                                >
                                                    {t('discount')}
                                                </DiscountText>
                                                <Text fontSize='1rem'>
                                                    {'-'}{cartItems?.totalDiscounts?.formattedValue}
                                                </Text>
                                            </Flex>
                                        )
                                        }
                                        <Flex
                                            paddingY='medium'
                                            justifyContent={['center', 'center', 'flex-end']}
                                            alignItems='center'
                                        >
                                            <SubTotalText
                                                color='cartTextHeading'
                                                fontWeight='500'
                                                fontSize='1rem'
                                                paddingRight='2.8125rem'
                                            >
                                                {t('cart.subtotalNoTax')}
                                            </SubTotalText>
                                            <SubTotalText
                                                fontSize='1rem'
                                                fontWeight='500'
                                            >
                                                {cartItems?.subTotalWithoutDiscount?.formattedValue}
                                            </SubTotalText>
                                        </Flex>
                                    </Box>

                                </Flex>
                                <SeparatorLine />
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    flexDirection={[
                                        'column-reverse',
                                        'column-reverse',
                                        'row',
                                        'row',
                                    ]}
                                    paddingY={['medium', 'medium', 'large', 'large']}>
                                    <GoBackButton
                                        data-test-cart-continue-btn
                                        url={`/`}
                                        text='Continue Shopping'
                                    />
                                    <Button
                                        data-test-cart-continue-btn
                                        disabled={
                                            hasEnrollmentAlready || cartItems?.length === 0
                                        }
                                        variant='primary'
                                        paddingX={['auto', 'auto', 'large', 'xLarge']}
                                        width={['100%', '100%', 'auto', 'auto']}
                                        marginBottom={['large', 'large', 0, 0]}
                                        type='submit'>
                                        {t('continue')}
                                    </Button>
                                </Box>
                            </Form>
                        )}
                    </Formik>
                )}
            </Box>
        </Box>
    );
};

export default Cart;
