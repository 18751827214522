const parseBoolean = (str = '') => str.toLowerCase() === 'true';

// Environment
export const ENABLE_MOCKS = parseBoolean(process.env.REACT_APP_ENABLE_MOCKS);

export const GRAPHQL_SCHEMA_PACKAGE =
    process.env.REACT_APP_GRAPHQL_SCHEMA_PACKAGE || 'zeus-gateway-production';

export const GRAPHQL_MOCK_SCENARIO = process.env.REACT_APP_MOCK_SCENARIO ?? 'default';

export const USE_PERSISTED_QUERIES = parseBoolean(process.env.REACT_APP_USE_PERSISTED_QUERIES);
export const PERSISTED_QUERIES_APP_NAME = process.env.REACT_APP_NAME || '';
export const PERSISTED_QUERIES_APP_VERSION =
    process.env.REACT_APP_VERSION || '';

export const GATEWAY_URL = process.env.REACT_APP_GATEWAY_URL || '/gateway/graphql';
console.log('process.env', process.env);
const config = {
    //Auth0
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    connection: process.env.REACT_APP_AUTH0_CONNECTION || 'Username-Password-Authentication',
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    namespace: `${process.env.REACT_APP_AUTH0_DOMAIN}/`,
    scope: 'openid profile email',
};

export const REQUESTACCESS_FLAG = parseBoolean(process.env.REACT_APP_FEATURE_REQUESTACCESS);

export default config;
