export const types = {
    FETCH_SUBSCRIPTION_LIST: 'FETCH_SUBSCRIPTION_LIST',
    FETCH_SUBSCRIPTION_LIST_SUCCESS: 'FETCH_SUBSCRIPTION_LIST_SUCCESS',
    FETCH_SUBSCRIPTION_LIST_FAIL: 'FETCH_SUBSCRIPTION_LIST_FAIL',
    GET_COMPLETE_SUBSCRIPTION_BILL: 'GET_COMPLETE_SUBSCRIPTION_BILL',
    GET_COMPLETE_SUBSCRIPTION_BILL_SUCCESS: 'GET_COMPLETE_SUBSCRIPTION_BILL_SUCCESS',
    GET_COMPLETE_SUBSCRIPTION_BILL_FAIL: 'GET_COMPLETE_SUBSCRIPTION_BILL_FAIL',
    GET_ORDERS_BY_ORDER_NUMBER: 'ORDERS_BY_ORDER_NUMBER',
    GET_ORDERS_BY_ORDER_NUMBER_SUCCESS: 'ORDERS_BY_ORDER_NUMBER_SUCCESS',
    GET_ORDERS_BY_ORDER_NUMBER_FAIL: 'ORDERS_BY_ORDER_NUMBER_FAIL',
    GET_SUBSCRIPTION_DETAILS: 'GET_SUBSCRIPTION_DETAILS',
    GET_SUBSCRIPTION_DETAILS_SUCCESS: 'GET_SUBSCRIPTION_DETAILS_SUCCESS',
    GET_SUBSCRIPTION_DETAILS_FAIL: 'GET_SUBSCRIPTION_DETAILS_SUCCESS',
    CANCEL_SUBSCRIPTION_SERVICE: 'CANCEL_SUBSCRIPTION_SERVICE',
    CANCEL_SUBSCRIPTION_SERVICE_SUCCESS: 'CANCEL_SUBSCRIPTION_SERVICE_SUCCESS',
    CANCEL_SUBSCRIPTION_SERVICE_FAIL: 'CANCEL_SUBSCRIPTION_SERVICE_FAIL',
    RENEWAL_SUBSCRIPTION_SERVICE: 'RENEWAL_SUBSCRIPTION_SERVICE',
    RENEWAL_SUBSCRIPTION_SERVICE_SUCCESS: 'RENEWAL_SUBSCRIPTION_SERVICE_SUCCESS',
    RENEWAL_SUBSCRIPTION_SERVICE_FAIL: 'RENEWAL_SUBSCRIPTION_SERVICE_FAIL',
    GET_SUBSCRIPTION_BILLING_DETAILS: 'GET_SUBSCRIPTION_BILLING_DETAILS',
    GET_SUBSCRIPTION_BILLING_DETAILS_SUCCESS: 'GET_SUBSCRIPTION_BILLING_DETAILS_SUCCESS',
    GET_SUBSCRIPTION_BILLING_DETAILS_FAIL: 'GET_SUBSCRIPTION_BILLING_DETAILS_FAIL',
    CREATE_DR_SUBSCRIPTION_PAYMENT: 'CREATE_DR_SUBSCRIPTION_PAYMENT',
    CREATE_DR_SUBSCRIPTION_PAYMENT_SUCCESS: 'CREATE_DR_SUBSCRIPTION_PAYMENT_SUCCESS',
    CREATE_DR_SUBSCRIPTION_PAYMENT_FAIL: 'CREATE_DR_SUBSCRIPTION_PAYMENT_FAIL',
    REFRESH_SUBSCRIPTION_DETAILS: 'REFRESH_SUBSCRIPTION_DETAILS',
    REFRESH_SUBSCRIPTION_DETAILS_SUCCESS: 'REFRESH_SUBSCRIPTION_DETAILS_SUCCESS',
    REFRESH_SUBSCRIPTION_DETAILS_FAIL: 'REFRESH_SUBSCRIPTION_DETAILS_FAIL',
    AVAILABLE_SUBSCRIPTION_DETAILS: 'AVAILABLE_SUBSCRIPTION_DETAILS',
    AVAILABLE_SUBSCRIPTION_DETAILS_SUCCESS: 'AVAILABLE_SUBSCRIPTION_DETAILS_SUCCESS',
    AVAILABLE_SUBSCRIPTION_DETAILS_FAIL: 'AVAILABLE_SUBSCRIPTION_DETAILS_FAIL',
};
