import React from 'react';
import { HeaderContainer } from './MenuItemStyle';
interface IProps {
    left: JSX.Element;
    right: JSX.Element;
    bottom: JSX.Element;
}

const Layout = ({ left, right, bottom }: IProps) => {

    return (
        <>
            <HeaderContainer>
                {left && left}
                {right && right}
            </HeaderContainer>
            {bottom && bottom}
        </>
    );
};

export default Layout;
