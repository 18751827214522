import React from 'react';
import { useHistory } from 'react-router-dom';
import { FooterMenuButtonStyle, FooterMenuContainer, FooterMenuItemText } from './MobileViewStyle';

const MobileBottomMenuItemList = ({ footerItem }: any) => {
    const history = useHistory();

    return (
        <FooterMenuContainer>
            {footerItem.itemList.map((item, index) => (
                <div key={`index${index}`}>
                    {item.buttonType === 'button' ? (
                        <FooterMenuButtonStyle onClick={() => history.push(item.linkPath)}>
                            {item.name}
                        </FooterMenuButtonStyle>
                    ) : (
                        <FooterMenuItemText>{item.name}</FooterMenuItemText>
                    )}
                </div>
            ))}
        </FooterMenuContainer>
    );
};

export default MobileBottomMenuItemList;
