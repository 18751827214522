import { types } from './types';

type CancellationProps = {
    subscriptionId: string;
    cancelReason: string;
    cancelledDate: string;
};

type BillingProps = {
    subscriptionId: string;
};

type InvoiceProps = {
    invoiceId: string;
};

type RenewalProps = {
    subscriptionId: string;
    renewalType: string;
};

type CreateDRSubscriptionPaymentProps = {
    dRPaymentDetailsForm: any;
    subscriptionId: string;
};

export const getSubscriptionList = () => ({
    type: types.FETCH_SUBSCRIPTION_LIST,
});

export const getSubscriptionListSuccess = (payload: any) => ({
    type: types.FETCH_SUBSCRIPTION_LIST_SUCCESS,
    payload,
});

export const getSubscriptionListFail = (payload: any) => ({
    type: types.FETCH_SUBSCRIPTION_LIST_FAIL,
    payload,
});

export const getOrdersByOrderNumber = (payload: InvoiceProps) => ({
    type: types.GET_ORDERS_BY_ORDER_NUMBER,
    payload,
});

export const getOrdersByOrderNumberSuccess = (payload: any) => ({
    type: types.GET_ORDERS_BY_ORDER_NUMBER_SUCCESS,
    payload,
});

export const getOrdersByOrderNumberFail = (payload: any) => ({
    type: types.GET_ORDERS_BY_ORDER_NUMBER_FAIL,
    payload,
});

export const getCompleteSubscriptionBill = (payload: BillingProps) => ({
    type: types.GET_COMPLETE_SUBSCRIPTION_BILL,
    payload,
});

export const getCompleteSubscriptionBillSuccess = (payload: any) => ({
    type: types.GET_COMPLETE_SUBSCRIPTION_BILL_SUCCESS,
    payload,
});

export const getCompleteSubscriptionBillFail = (payload: any) => ({
    type: types.GET_COMPLETE_SUBSCRIPTION_BILL_FAIL,
    payload,
});

export const getSubscriptionDetails = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_DETAILS,
    payload,
});

export const getSubscriptionDetailsSuccess = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
});

export const getSubscriptionDetailsFail = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_DETAILS_FAIL,
    payload,
});

export const cancelSubscriptionService = (payload: CancellationProps) => ({
    type: types.CANCEL_SUBSCRIPTION_SERVICE,
    payload,
});

export const cancelSubscriptionServiceSuccess = (payload: any) => ({
    type: types.CANCEL_SUBSCRIPTION_SERVICE_SUCCESS,
    payload,
});

export const cancelSubscriptionServiceFail = (payload: any) => ({
    type: types.CANCEL_SUBSCRIPTION_SERVICE_FAIL,
    payload,
});

export const renewalSubscriptionService = (payload: RenewalProps) => ({
    type: types.RENEWAL_SUBSCRIPTION_SERVICE,
    payload,
});

export const renewalSubscriptionServiceSuccess = (payload: any) => ({
    type: types.RENEWAL_SUBSCRIPTION_SERVICE_SUCCESS,
    payload,
});

export const renewalSubscriptionServiceFail = (payload: any) => ({
    type: types.RENEWAL_SUBSCRIPTION_SERVICE_FAIL,
    payload,
});

export const getSubscriptionBillingDetails = (payload: BillingProps) => ({
    type: types.GET_SUBSCRIPTION_BILLING_DETAILS,
    payload,
});

export const getSubscriptionBillingDetailsSuccess = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_BILLING_DETAILS_SUCCESS,
    payload,
});

export const getSubscriptionBillingDetailsFail = (payload: any) => ({
    type: types.GET_SUBSCRIPTION_BILLING_DETAILS_FAIL,
    payload,
});

export const createDRSubscriptionPayment = (payload?: CreateDRSubscriptionPaymentProps) => ({
    type: types.CREATE_DR_SUBSCRIPTION_PAYMENT,
    payload,
});

export const createDRSubscriptionPaymentSuccess = (payload: any) => ({
    type: types.CREATE_DR_SUBSCRIPTION_PAYMENT_SUCCESS,
    payload,
});

export const createDRSubscriptionPaymentFail = (payload: any) => ({
    type: types.CREATE_DR_SUBSCRIPTION_PAYMENT_FAIL,
    payload,
});

export const refreshSubscriptionDetails = (payload: any) => ({
    type: types.REFRESH_SUBSCRIPTION_DETAILS,
    payload,
});

export const refreshSubscriptionDetailsSuccess = (payload: any) => ({
    type: types.REFRESH_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
});

export const refreshSubscriptionDetailsFail = (payload: any) => ({
    type: types.REFRESH_SUBSCRIPTION_DETAILS_FAIL,
    payload,
});
export const availableSubscriptionDetails = () => ({
    type: types.AVAILABLE_SUBSCRIPTION_DETAILS,
});

export const availableSubscriptionDetailsSuccess = (payload: any) => ({
    type: types.AVAILABLE_SUBSCRIPTION_DETAILS_SUCCESS,
    payload,
});

export const availableSubscriptionDetailsFail = (payload: any) => ({
    type: types.AVAILABLE_SUBSCRIPTION_DETAILS_FAIL,
    payload,
});
// export const renewalSubscriptionService = (payload: BillingProps) => ({
//     type: types.GET_SUBSCRIPTION_BILLING_DETAILS,
//     payload,
// });

// export const getSubscriptionBillingDetailsSuccess = (payload: any) => ({
//     type: types.GET_SUBSCRIPTION_BILLING_DETAILS_SUCCESS,
//     payload,
// });

// export const getSubscriptionBillingDetailsFail = (payload: any) => ({
//     type: types.GET_SUBSCRIPTION_BILLING_DETAILS_FAIL,
//     payload,
// });
