import styled from 'styled-components';
import colors from '../../../themes/index';
import Popup from 'reactjs-popup';

type Theme = typeof colors;

const CustomPopup = styled(Popup)<{ theme: Theme }>`
  &-overlay {
    background-color: ${({ theme }) => theme.colors.modal.overlay};
    overflow: auto;
  }
  &-content {
    top: 160px;
    max-width: 768px;
    width: 100%;
    background: #fff;
    padding: 0;
    .modal {
      padding: 0;
      margin: -1px;
      .close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .content {
        background-color: ${({ theme }) => theme.colors.black};
        padding: 20px 115px 60px 115px;
      }
    }
  }
  // @media (max-width: 1350px) {
  //   &-content {
  //     overflow-y: auto;
  //     max-height: 500Px;
  // }
  // @media (max-width: 450px) {
  //   &-content {
  //     overflow-y: auto;
  //     max-height: 350Px;
  // }
}
`;

export default CustomPopup;
