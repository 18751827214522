/* eslint-disable no-console */
import axios from 'axios';
import axiosRetry from 'axios-retry';
// eslint-disable-next-line import/no-cycle
import { store } from '../../redux/store';

const METHODS_WITH_BODY = ['POST', 'PATCH', 'PUT'];

axiosRetry(axios, {
    retries: 0,
    retryDelay: (count) => count * 1000,
    retryCondition: (error) => {
        const shouldRetry =
            error.code !== 'ECONNABORTED' &&
            (!error.response || (error.response.status >= 500 && error.response.status <= 599));
        if (shouldRetry) console.log('Retrying api...', error);
        return shouldRetry;
    },
});

const fetchWithTimeout = (url: string, requestInfo: any): any =>
    axios({
        method: requestInfo.method,
        url,
        timeout: requestInfo.timeout,
        headers: requestInfo.headers,
        data: requestInfo.body,
        'axios-retry': requestInfo['axios-retry'],
        withCredentials: false, // toogle this when setting auth
    });

export const api = (
    url: string,
    method: string,
    request: any,
    retryCount: number = 0,
    timeout: any = null,
    isSignIn: boolean = false,
    noHeader: boolean = false,
) => {
    const { authentication } = store.getState();
    const stateToken = authentication.token && authentication.token;
    const localToken = localStorage.getItem('jwt');
    const token = stateToken || localToken;    
    const refreshToken = authentication.refreshToken && authentication.refreshToken;

    const createFormData = (body: any) => {
        const params = new URLSearchParams();
        params.append('username', body.username);
        params.append('password', body.password);
        params.append('client_id', 'trusted_client');
        params.append('client_secret', 'secret');
        params.append('grant_type', 'password');

        return params;
    };

    const content_type = isSignIn ? 'application/x-www-form-urlencoded' : 'application/json';

    const defaultHeaders: any = {
        'Content-Type': content_type,
        // 'Accept-Encoding': 'application/json',
        // 'client_id': 'trusted_client',
        // 'client_secret': 'secret',
        // 'grant_type':'password',
    };

    if (token && !noHeader) {
        defaultHeaders.Authorization = `Bearer ${token}`;
    }
    const refreshParam = url.split('/');
    if (refreshParam[refreshParam.length - 1] === 'refresh' && refreshToken)
        defaultHeaders.Cookie = `jwt-refresh-token=${refreshToken}`;

    const _timeout = timeout !== null ? timeout : 0;

    const requestInfo: any = {
        method,
        headers: defaultHeaders,
        timeout: _timeout * 1000,
        'axios-retry': {
            retries: retryCount,
        },
    };

    if (METHODS_WITH_BODY.includes(method) && request) {
        // request.body = JSON.stringify(request);
        requestInfo.body = JSON.stringify(request);
        if (isSignIn) {
            requestInfo.body = createFormData(request);
        }
    }

    if (METHODS_WITH_BODY.includes(method) && request.params) {
        requestInfo.params = JSON.stringify(request.params);
    }

    //TODO: Ensure this runs in dev_mode

    // if (__DEV__) {
    //     console.log('API URL', url);
    //     console.log('API REQUEST', requestInfo);
    // }

    return fetchWithTimeout(url, requestInfo)
        .then((response: any) => response.data)
        .catch((error: any) => {
            console.log(error);
            // if (__DEV__) {
            //     console.log('API URL', url);
            //     console.log('API ERROR', error);
            // }
            throw error.response;
        });
};
