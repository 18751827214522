import React from 'react';
import { Flex, Heading, Text } from '@resideo/blueprint-react';
import SeparatorLine from 'components/common/SeparatorLine';
import { useTranslation } from 'react-i18next';
import GoBackButton from 'components/common/GoBackButton';

const TopNav = () => {
  const { t } = useTranslation();
  return (
    <>
      <GoBackButton
        url='/subscription'
        text={t('manageSubscriptions.backToServicesAndSubscriptions')}
        isManageSub={true}
      />
      <Flex alignItems='flex-start' flexDirection='column'>
        <Heading
          data-test-page-title
          paddingTop='small'
          fontWeight='bold'
          fontSize='xxxLarge'
          fontFamily='URW_Din_CondBold'
          letterSpacing='1px'
          color='black'
          marginBottom='small'>
          {t('manageSubscriptions.topNav.homeownerAppBrandingTitle')}
        </Heading>
        <Text data-test-page-description fontSize='medium' color='textHeading'>
          {t('manageSubscriptions.topNav.homeownerAppBrandingSubTitle')}
        </Text>
      </Flex>
      <SeparatorLine m='1.5rem 0' />
    </>
  );
};

export default TopNav;
