import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@resideo/blueprint-react';
import styled from 'styled-components';

import { ReactComponent as ResideoProLogo } from './resideo-pro-logo.svg';
import { ReactComponent as ResideoKnockoutLogo } from './resideo-knockout-logo.svg';

const StyledResideoKnockoutLogo = styled(ResideoKnockoutLogo)`
  fill: ${({ theme }) => theme.colors.header.icons};
  margin-right: ${({ theme }) => theme.space.medium};
  @media(max-width: 991px) {
    display: none;
  }
`;

const LogoContainer = styled(Link)`
  position: relative;
	display: flex;
	align-items: center;
	//width: 205px;
	margin: 16px 25px 16px 0;
	padding-left: 22px;
	background: transparent;
  @media(min-width: 992px) {
      margin: 6px 0;
  }
  @media(max-width: 991px) {
		padding: 0;
    margin: 16px 8px 16px 0;
  }
  @media(min-width: 992px)and (max-width: 1199px) {
    margin-right: 16px !important;
  }
`;

const StyledResideoProLogo = styled(ResideoProLogo)`
  fill: ${({ theme }) => theme.colors.header.icons};
`;

export const HeaderLogo: FC = props => {
  const { t } = useTranslation();
  const isDevelopEnvironment =
    process.env.REACT_APP_ENVIRONMENT !== 'production';

  return (
    <LogoContainer
      aria-label={t('header.primary.home')}
      href={'/'}
      {...props}>
      <StyledResideoKnockoutLogo />
      <StyledResideoProLogo />
    </LogoContainer>
  );
};

export default HeaderLogo;
