import React, { FC } from 'react';
import { Flex } from '@resideo/blueprint-react';
import CompleteOrder from 'components/ComponentsToPrint/CompleteOrder';

const PrintFriendlyView: FC<any> = ({ viewerData }) => {
  return (
    <>
      <Flex display={'flex'} paddingTop={['0','0','large','large']}>
        <CompleteOrder viewerData={viewerData} />
      </Flex>
    </>
  );
};

export default PrintFriendlyView;
