/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/* @relayHash a77bd88ce9334000958b9ac4162c195b */

import { ConcreteRequest } from "relay-runtime";

export type ApprovalStatus = "ACTIVE" | "ARCHIVED" | "INACTIVE" | "PENDING" | "%future added value";
export type CountryCode = "AE" | "AR" | "AT" | "AU" | "BE" | "BG" | "BR" | "CA" | "CH" | "CL" | "CN" | "CO" | "CR" | "CZ" | "DE" | "DK" | "EC" | "EE" | "EG" | "ES" | "FI" | "FL" | "FR" | "GB" | "GR" | "GT" | "HR" | "HU" | "IE" | "IN" | "IT" | "JP" | "KE" | "KR" | "LT" | "LU" | "LV" | "MA" | "MX" | "MY" | "NL" | "NO" | "NZ" | "PA" | "PE" | "PL" | "PT" | "RO" | "SA" | "SE" | "SG" | "SI" | "SK" | "TN" | "TR" | "UA" | "UK" | "UM" | "US" | "VG" | "VI" | "ZA" | "%future added value";
export type PartnerRole = "COMPANY_ADMIN" | "MARKETING" | "MEMBER" | "OPERATIONS" | "OWNER" | "SALES" | "TECHNICIAN" | "%future added value";
export type ProfileQueryVariables = {};
export type ProfileQueryResponse = {
    readonly viewer: {
        readonly id: string | null;
        readonly countryCode: CountryCode | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly contactEmail: string | null;
        readonly photoUrl: string | null;
        readonly primaryPhoneNumber: string | null;
        readonly secondaryPhoneNumber: string | null;
        readonly partnerUsers: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly role?: ReadonlyArray<PartnerRole | null> | null | undefined;
                    readonly status?: ApprovalStatus | null | undefined;
                    readonly partnerAccount?: {
                        readonly name: string | null;
                        readonly status: ApprovalStatus | null;
                        readonly primaryPhoneNumber: string | null;
                        readonly billToAddress: {
                            readonly addressLine1: string | null;
                            readonly addressLine2: string | null;
                            readonly city: string | null;
                            readonly stateProvinceRegionCode: string | null;
                            readonly zipPostalCode: string | null;
                            readonly countryCode: CountryCode | null;
                            readonly mailingAddressName: string | null;
                            readonly contactName: string | null;
                        } | null;
                        readonly shipToAddress: {
                            readonly addressLine1: string | null;
                            readonly addressLine2: string | null;
                            readonly city: string | null;
                            readonly stateProvinceRegionCode: string | null;
                            readonly zipPostalCode: string | null;
                            readonly countryCode: CountryCode | null;
                            readonly mailingAddressName: string | null;
                            readonly contactName: string | null;
                        } | null;
                        readonly partnerBrandProfile: {
                            readonly logo: string | null;
                        } | null;
                    } | null | undefined;
                };
            } | null>;
        } | null;
    } | null;
};
export type ProfileQuery = {
    readonly response: ProfileQueryResponse;
    readonly variables: ProfileQueryVariables;
};



/*
query ProfileQuery {
  viewer {
    id
    countryCode
    firstName
    lastName
    contactEmail
    photoUrl
    primaryPhoneNumber
    secondaryPhoneNumber
    partnerUsers {
      edges {
        node {
          __typename
          ... on PartnerUser {
            role
            status
            partnerAccount {
              name
              status
              primaryPhoneNumber
              billToAddress {
                addressLine1
                addressLine2
                city
                stateProvinceRegionCode
                zipPostalCode
                countryCode
                mailingAddressName
                contactName
              }
              shipToAddress {
                addressLine1
                addressLine2
                city
                stateProvinceRegionCode
                zipPostalCode
                countryCode
                mailingAddressName
                contactName
              }
              partnerBrandProfile {
                logo
              }
              id
            }
          }
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactEmail",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "photoUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primaryPhoneNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondaryPhoneNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "addressLine1",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "addressLine2",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "stateProvinceRegionCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipPostalCode",
    "storageKey": null
  },
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mailingAddressName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contactName",
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "billToAddress",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "shipToAddress",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PartnerBrandProfile",
  "kind": "LinkedField",
  "name": "partnerBrandProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CorePartnerUserConnection",
            "kind": "LinkedField",
            "name": "partnerUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorePartnerUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PartnerAccount",
                            "kind": "LinkedField",
                            "name": "partnerAccount",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v9/*: any*/),
                              (v6/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PartnerUser",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CorePartnerUserConnection",
            "kind": "LinkedField",
            "name": "partnerUsers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CorePartnerUserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PartnerAccount",
                            "kind": "LinkedField",
                            "name": "partnerAccount",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              (v9/*: any*/),
                              (v6/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "PartnerUser",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "a77bd88ce9334000958b9ac4162c195b",
    "metadata": {},
    "name": "ProfileQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
(node as any).hash = 'f2bb384b0ab7c0556a7e18fc73a096a6';
export default node;
