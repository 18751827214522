import React, { FC } from 'react';
import { checkStatus } from 'data/SubscriptionData';
import { capitalizeFirstLetterLowercaseSecondWord } from 'components/HomeTile';
import {
  Heading,
  Text,
  Button,
  Label,
  Checkbox,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { renewsOrExpiresOn } from './PlanInformation';
import { useHistory } from 'react-router-dom';

const DivWithDash = styled.div`
  display: flex;
  justify-content: center;
  width: 70px;
`;

const StyledBtn = styled(Button)`
  color: ${({ theme }) => theme.colors.primary};
  :hover {
    background: none;
    border: 0;
    color: ${({ theme }) => theme.colors.primary};
  }
  :focus {
    box-shadow: none;
  }
`;

const TableBody = styled.tbody`
  tr {
    td {
      .view-plans {
        display: none;
      }
    }
  }
  & tr:nth-child(1) ${StyledBtn} {
    display: block;
  }
`;

const TableDataWithButton = styled.td`
  display: flex;
  justify-content: flex-end;
`;

const CheckboxLabel = styled(Label) <{ isdisabled: boolean }>`
  div {
    svg {
      path {
        fill: ${({ isdisabled }) => (isdisabled ? '#ededed' : '#166a9')};
      }
    }
  }
`;

const OnOff = styled(Text)`
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.875rem;
`;

interface PlanInformationProgramEnrollmentsTableProps {
  enrollment: any;
  values: any;
  setModalData: any;
  openModal: any;
  setFieldValue: any;
  isLogoApproved: boolean;
  handleSubscriptionCancel?: any;
}

const PlanInformationProgramEnrollmentsTable: FC<PlanInformationProgramEnrollmentsTableProps> = ({
  enrollment,
  values,
  setModalData,
  openModal,
  setFieldValue,
  isLogoApproved,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const objStatus = checkStatus(
    enrollment?.activationDate,
    enrollment?.status,
    enrollment?.logoApproved,
    enrollment
  );

  return (
    <div className="hscroll">
      <table className='table-responsive'>
        <thead>
          <tr>
            <th>{t('manageSubscriptions.planInfo.table.1')}</th>
            <th>{t('manageSubscriptions.planInfo.table.2')}</th>
            <th>{t('manageSubscriptions.planInfo.table.3')}</th>
            <th>{t('manageSubscriptions.planInfo.table.4')}</th>
            <th>{t('manageSubscriptions.planInfo.table.5')}</th>
            <th></th>
          </tr>
        </thead>
        <TableBody>
          <tr className='tr-border-bottom'>
            <td>
              <Heading
                data-test-plan-info-name
                fontWeight='bold'
                fontSize='medium'
                letterSpacing='0'
                color='textHeading'>
                {enrollment?.name ?? <>&nbsp;</>}
              </Heading>
              <Text
                data-test-plan-info-billing-status
                color='gray'
                fontSize='small'
                paddingTop='xSmall'>
                {enrollment?.billingCycle ? (
                  enrollment.billingCycle
                ) : (
                  <>&nbsp;</>
                )}
              </Text>
            </td>
            <td>
              <Text
                data-test-plan-info-status
                color={
                  enrollment?.status?.toUpperCase() === 'ACTIVE' && values.autoRenew
                    ? 'success'
                    : 'redAlertError'
                }
                fontWeight='medium'
                fontSize='medium'>
                {enrollment?.status ? (
                  objStatus !== 'Canceled' && enrollment.autoRenew === false ? (
                    enrollment?.subscriptionEndDate ? `Expires ${moment(enrollment.subscriptionEndDate).format(
                      'MMM D, YYYY'
                    )}` : <>&nbsp;</>
                  ) : enrollment.status?.toUpperCase() === 'CANCELLED' ? (
                    'Canceled'
                  ) : (
                    'Active'
                  )
                ) : (
                  <>&nbsp;</>
                )}
              </Text>
            </td>
            <td>
              <Text data-test-plan-info-activation-date fontSize='medium'>
                {enrollment?.purchaseDate
                  ? moment(enrollment.purchaseDate).format('MMMM DD, YYYY')
                  : '-'}
              </Text>
            </td>
            <td>
              <Text data-test-plan-info-renews-on fontSize='medium'>
                {enrollment.status?.toUpperCase() !== 'CANCELLED' &&
                  enrollment?.purchaseDate &&
                  enrollment?.billingCycle ? (
                  !values.autoRenew ? (
                    '—'
                  ) : (
                    // renewsOrExpiresOn(
                    //   enrollment.purchaseDate,
                    //   enrollment.billingCycle
                    // )
                    moment(enrollment?.renewalDate).format('MMM D, YYYY')
                  )
                ) : (
                  <DivWithDash>-</DivWithDash>
                )}
              </Text>
            </td>
            <td>
              {enrollment?.status ? (
                <CheckboxLabel isdisabled={objStatus === 'Canceled'}>
                  <Checkbox
                    data-test-auto-renew-checkbox
                    type='checkbox'
                    checked={values.autoRenew}
                    marginRight={0}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      localStorage.setItem('modalType', 'AUTO_RENEW');
                      setModalData({ ...enrollment, index: 0 });
                      openModal();
                    }}
                    onChange={() => setFieldValue('autoRenew', values.autoRenew)}
                    disabled={objStatus === 'Canceled'}
                  />
                  <OnOff>
                    {values.autoRenew
                      ? t('manageSubscriptions.planInfo.on')
                      : t('manageSubscriptions.planInfo.off')}
                  </OnOff>
                </CheckboxLabel>
              ) : (
                <>&nbsp;</>
              )}
            </td>
            <TableDataWithButton>
              {enrollment ? (
                <StyledBtn
                  className='view-plans'
                  paddingX='0'
                  style={{ border: 0 }}
                  onClick={e => {
                    if (objStatus !== 'Canceled') {
                      e.preventDefault();
                      e.stopPropagation();
                      localStorage.setItem('modalType', 'CANCEL_SUB');
                      setModalData({
                        ...enrollment,
                        index: 0,
                      });
                      openModal();
                    } else {
                      history.push('/subscription');
                    }
                  }}>
                  {objStatus !== 'Canceled'
                    ? t('manageSubscriptions.planInfo.cancelService')
                    : t('manageSubscriptions.planInfo.viewPlans')}
                </StyledBtn>
              ) : (
                <>&nbsp;</>
              )}
            </TableDataWithButton>
          </tr>
        </TableBody>
      </table>
    </div>
  );
};

export default PlanInformationProgramEnrollmentsTable;
