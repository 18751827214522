import { types } from './types';

export const getOrderInformation = (payload: any) => ({
    type: types.FETCH_ORDER_INFORMATION,
    payload,
})

export const getOrderInformationSuccess = (payload: any) => ({
    type: types.FETCH_ORDER_INFORMATION_SUCCESS,
    payload,
})

export const getOrderInformationFail = (payload: any) => ({
    type: types.FETCH_ORDER_INFORMATION_FAIL,
    payload,
})