import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import primaryNavigation from 'config/navigation';
import { Link as ExternalLink } from '@resideo/blueprint-react';
import { useCurrentUser } from 'hooks/useCurrentUser';
import profileMenuItems from 'config/profileMenuAir';
import CustomMobileNavLink from './mobileHeader/CustomMobileNavLink';
import mobilePrimaryNavigation from 'config/mobileNavigation';
import { signOut } from 'config/Auth';
import { logout } from 'redux/authentication/action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';

interface StyledMobileMenu {
    show: boolean;
}

const StyledMobileMenu = styled.div<StyledMobileMenu>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    margin: ${({ theme }) => theme.space.medium} 0;
    width: 100%;
    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
        display: none;
    }

    height: calc(100vh - 60px);
    border: none;
    background: #166a96;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 991px) {
        margin: 0px;
    }
`;

const MenuItem = styled(({ isExternalLink, isDirect, ...props }) =>
    isExternalLink ? (
        <ExternalLink {...props} />
    ) : isDirect ? (
        <NavLink {...props} />
    ) : (
        <CustomMobileNavLink {...props} />
    ),
)`
    display: block;
    //width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.header.primaryLink};
    padding: ${({ theme }) => `${theme.space.medium} ${theme.space.small}`};
    &:hover {
        color: ${({ theme }) => theme.colors.header.primaryLinkActive};
    }
    &.active {
        color: ${({ theme }) => theme.colors.header.primaryLinkActive};
    }
    background: #166a96 !important;
    border-bottom: 1px solid #fff;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #fff !important;
    transition: all 0.3s ease;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    height: 50px;
    padding: 0 32px;
`;

const MobileMenuToggleContainer = styled.div`
    display: block;
    margin-top: ${({ theme }) => theme.space.xSmall};
    margin-left: ${({ theme }) => theme.space.medium};
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        display: none;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
        margin-left: 0.5rem;
    }
`;

const StyledMobileMenuToggle = styled.div`
    display: inline-block;
    cursor: pointer;
    outline: none;
    div {
        width: 20px;
        height: 2px;
        background-color: ${({ theme }) => theme.colors.header.primaryLink};
        margin: ${({ theme }) => theme.space.xSmall} 0;
        transition: 0.4s;
    }
`;

const MobileMenu = ({ show, onClick }: { show: boolean; onClick: () => void }) => {
    const { t } = useTranslation();
    const [{ isOwner, isAdmin }] = useCurrentUser();
    const viewerHasPermissions = isOwner || isAdmin;
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state: RootState) => state.authentication.isLoggedIn);

    const handleSignOut = () => {
        dispatch(logout());
        signOut();
    };

    return (
        <StyledMobileMenu show={show}>
            {mobilePrimaryNavigation.map((data): JSX.Element | null => {
                if (!data.linkPath || !data.linkTextKey) return null;
                return !data.isExternalLink ? (
                    <MenuItem
                        key={data.name}
                        to={data.linkPath}
                        exact={true}
                        onClick={onClick}
                        isDirect={data.isDirect}
                        menuitem={data.subMenu}>
                        {t(data.linkTextKey)}
                    </MenuItem>
                ) : (
                    <MenuItem
                        key={data.name}
                        href={data.linkPath}
                        isExternalLink={data.isExternalLink}
                        isDirect={data.isDirect}
                        menuitem={data.subMenu}>
                        {t(data.linkTextKey)}
                    </MenuItem>
                );
            })}
            {isAuthenticated && (
            <MenuItem 
                key={'signout'} 
                href={'#'} 
                isExternalLink={true} 
                menuitem={''} 
                isDirect={false} 
                onClick={handleSignOut}>
                {t('header.signout')}
            </MenuItem>
        )}
        </StyledMobileMenu>
    );
};

const MobileMenuToggle = ({
    onClick,
}: {
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => {
    return (
        <MobileMenuToggleContainer>
            <StyledMobileMenuToggle onClick={onClick} role='button' tabIndex={0}>
                <div></div>
                <div></div>
                <div></div>
            </StyledMobileMenuToggle>
        </MobileMenuToggleContainer>
    );
};

export { MobileMenu as default, MobileMenuToggle };
