import React, { FC, useEffect } from 'react';
import { Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { Route } from 'react-router-dom';
import ManageSubscription from './ManageSubscription';
import styled from 'styled-components';
import SecondaryNavigation from '../../components/common/SecondaryNavigation';
import { subNavLinks } from 'config/secondaryNavigation';
import { Flex } from '@resideo/blueprint-react';
import Cart from './Cart';
import PDP from './PDP';
import Home from './Home';
import { getJWtDetails } from 'utils/utils';
import { signIn } from 'config/Auth';

const MainContainer = styled(Flex)`
    border-bottom: 1px solid lightgray;
    height: 100%;
`;

const ContentWrapper = styled(Flex)<{
    withSubNav?: boolean;
}>`
    height: 100%;
    padding-top: ${({ withSubNav }) => (withSubNav ? '4rem' : '0')};

    @media (max-width: ${({ theme }) => theme.breakpoints.large}) {
        padding-top: 0;
    }
`;

const Container = styled.div`
    //display: flex; //Todo need to enable
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1200px;
    @media (min-width: 768px) {
        width: 750px;
    }
    @media (min-width: 992px) {
        width: 970px;
    }
    @media (min-width: 1200px) {
        width: 1170px;
    }
    @media (min-width: 1400px) {
        width: 1375px;
    }
    @media (max-width: 992px) {
        flex-direction: column;
    }
`;

const MyBusinessLayout: FC<any> = ({ children, menuItems, pathName }) => {
    const path: string = pathName ?? '';
    const withSubNav = !(
        path.includes('cart') ||
        path.includes('pdp') ||
        path.includes('select-plan') ||
        path.includes('invoice')
    );
    return (
        <MainContainer flexDirection='column' width={1}>
            <SecondaryNavigation menuItems={menuItems} />
            <Container className='section-center'>
                <ContentWrapper withSubNav={withSubNav}>{children}</ContentWrapper>
            </Container>
        </MainContainer>
    );
};

const ServicesAndSubscriptionRoutes: FC = () => {
    const { path } = useRouteMatch();
    const { pathname } = useLocation();
    const { isSignedIn } = getJWtDetails();

    useEffect(() => {
        if (!isSignedIn) {
            signIn();
        }
    }, []);

    return (
        <MyBusinessLayout menuItems={subNavLinks()} pathName={pathname}>
            <Switch>
                <Route exact path={path} component={Home} />
                <Route
                    exact
                    path={`${path}/manage-subscription/:id`}
                    component={ManageSubscription}
                />
                <Route path={`${path}cart`} component={Cart} />
                <Route path={`${path}pdp`} component={PDP} />
            </Switch>
        </MyBusinessLayout>
    );
};

export default ServicesAndSubscriptionRoutes;
