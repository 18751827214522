import React, { Dispatch, FC, SetStateAction } from 'react';
import { Text, Image, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import ModalBody from './ModalBody';
import { CloseRoundGreyImg } from 'assets/images';

const ModalHeader = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 2rem;
    justify-content: space-between;
    align-items: center;
    height: 104px;
    position: relative;
    margin-top: 85px;
    img {
        height: 2rem;
        width: 2rem;
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
`;

const ModalTitle = styled(Text)`
    text-transform: uppercase;
    font-family: 'URW_Din_CondBold', sans-serif;
    letter-spacing: 2px;
    font-size: 2.8125rem;
    font-weight: 400;
    color: #fff;
    @media (max-width: 768px) {
        font-size: 2rem;
    }
    @media (max-width: 560px) {
        font-size: 1.2rem;
    }
`;

interface CustomModalProps {
    Modal: any;
    autoRenew?: boolean;
    cancelSub?: string;
    title: string;
    closeModal: () => void;
    setFieldValue?: any;
    program?: string;
    expires?: string;
    setAutoRenew?: any;
    otherReason?: string;
    programEnrollmentId?: string;
    modalVideo?: number;
    type?: string;
    handleSubscriptionCancel?: any;
    index?: any;
    setLoading?: Dispatch<SetStateAction<boolean>>;
}

const CustomModal: FC<CustomModalProps> = ({ ...props }) => {
    const { Modal, title, closeModal } = props;
    const modalType = localStorage.getItem('modalType');
    return (
        <Modal
            style={{
                borderRadius: 0,
                maxWidth: modalType === 'AUTO_RENEW' ? '896px' : 'auto',
                boxShadow: '-20px 20px 27px 0 rgba(0,0,0,0.5)',
                margin: '3rem',
            }}>
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <Image onClick={closeModal} src={CloseRoundGreyImg} alt='close' />
            </ModalHeader>
            <ModalBody {...props} />
        </Modal>
    );
};

export default CustomModal;
