/* eslint-disable import/no-cycle */
import { buffers } from '@redux-saga/core';
import { takeLatest, put, call, actionChannel, select, all } from '@redux-saga/core/effects';
import { api } from '../../config/api';
import { refreshTokenUrl } from '../../config/api/url';
import { getJWtDetails } from '../../utils/utils';
import { setExp, storeJwt, storeRefreshToken } from '../authentication';
import { logout, setJwt } from '../authentication/action';
import { httpRequest } from '../types';
import { dispatchQueuedActions as dispatchAction, refreshTokenComplete } from './action';
import { types } from './types';
import moment from 'moment';


const buffer: any = buffers.sliding();

function* _refreshToken(): any {
    try {
        const { authentication } = yield select();
        const { token, refreshToken, expires_in } = authentication;
        const { isSignedIn } = getJWtDetails();
        if (refreshToken) {
            const response = yield call(api, refreshTokenUrl, httpRequest.GET, null, 5, true);
            if (response) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const { token, refreshToken, expires_in } = response;
                const expires_in_format = moment().add(expires_in, 'seconds')
                if (token && refreshToken) {
                    yield call(setExp, expires_in_format);
                    yield call(storeJwt, token);
                    yield call(storeRefreshToken, refreshToken);
                    yield put(setJwt({ token, refreshToken }));
                    yield put(refreshTokenComplete());
                    yield put(dispatchAction());
                } else {
                    throw new Error('NO JWT or refreshToken');
                }
            } else {
                throw new Error('No refresh Token');
            }
        } else if (!refreshToken && !isSignedIn) {
            const error: any = {
                data: { message: ' Please login again' },
                statusText: 'Logged out',
            };
            yield put(refreshTokenComplete());
        }
    } catch (error) {
        yield put(refreshTokenComplete());
        logout();
    }
}

function* enqueueFailedAction() {
    yield actionChannel(types.ENQUEUE_FAILED_ACTIONS, buffer);
}

function* dispatchQueuedActions() {
    while (!buffer.isEmpty()) {
        const { payload } = yield call(buffer.take);
        yield put(payload);
    }
}

/** ******************************** WATCHERS ************************************* */

function* refreshTokenWatcher() {
    yield takeLatest(types.REFRESH_TOKEN, _refreshToken);
}

function* dispatchQueuedActionWatcher() {
    yield takeLatest(types.DISPATCH_QUEUED_ACTIONS, dispatchQueuedActions);
}

export default function* tokenRefreshSaga() {
    yield all([refreshTokenWatcher(), dispatchQueuedActionWatcher(), enqueueFailedAction()]);
}
