import React, { FC, FunctionComponent, useEffect, useState } from 'react';
import { FormikErrors, useField, FormikProps } from 'formik';
import {
  Checkbox,
  Radio,
  Box,
  Card,
  Text,
  Label,
  Flex,
  Link,
  Image,
  Heading,
} from '@resideo/blueprint-react';
import styled from 'styled-components';
import { TFunction, useTranslation } from 'react-i18next';
import SeparatorLine from '../common/SeparatorLine';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { blueTooltipFilled } from '../../assets/images';
import { ReactComponent as AmericanCardIcon } from '../../assets/images/cart/icon_AmericanExpress.svg';
import { ReactComponent as DiscoverCardIcon } from '../../assets/images/cart/icon_Discover.svg';
import { ReactComponent as MastercardIcon } from '../../assets/images/cart/icon_MasterCard.svg';
import { ReactComponent as VisaBlueIcon } from '../../assets/images/cart/icon_Visa.svg';
import { ReactComponent as PaypalIcon } from '../../assets/images/cart/icon_Paypal.svg';
import { ReactComponent as RedAlertIcon } from '../../assets/images/alert_icons/ic_warning_red.svg';

import { PROVINCES } from '../../config/provinces';
import { STATES } from '../../config/states';
import ReactTooltip from 'react-tooltip';
import { InputField, SelectField } from '../../components/common/InputField';

export const Container = styled(Card)<{ isError?: boolean }>`
  border: ${({ isError }) => (isError ? '2px solid red' : ``)};
  border-radius: 0.9375rem;
`;

const FloatingCardIcon = styled.span`
  float: right;
  z-index: 70;
  margin-top: 30px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 15px;
`;

export type CreditCardBrands =
  | 'VISA'
  | 'AMERICAN_EXPRESS'
  | 'MASTERCARD'
  | 'DISCOVER'
  | '';

interface CreditCardBrandIcon {
  brand: CreditCardBrands;
  icon: FunctionComponent | JSX.Element;
}

export type PaymentMethodOptions = 'existingCC' | 'newCC' | 'payPal' | '';

export interface ExistingPaymentSource {
  id?: string | null;
  paymentSourceId?: string | null;
  paymentSessionId?: string | null;
  clientSecret?: string | null;
  type: 'CREDIT_CARD' | 'PAYPAL' | null;
  creditCard?: {
    lastFourDigits?: string | null;
    expirationMonth?: number | null;
    expirationYear?: number | null;
    brand?: CreditCardBrands | null;
  };
  billTo?: {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    organization?: string | null;
    address?: {
      addressLine1?: string | null;
      addressLine2?: string | null;
      city?: string | null;
      stateProvinceRegionCode?: string | null;
      countryCode?: string | null;
      zipPostalCode?: string | null;
    };
  };
  payPalBillingToken?: string | null;
}

export interface ExistingCreditCardItem {
  id: string;
  paymentSourceId: string;
  paymentSessionId?: string | null;
  clientSecret: string;
  maskedCardNumber: JSX.Element;
  cardNumber: string;
  lastFour: string;
  monthYear: string;
  brand: CreditCardBrands;
  icon: JSX.Element;
  expired: boolean;
  contactName: string;
  companyName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateProvinceRegionCode: string;
  countryCode: string;
  zipPostalCode: string;
}

export interface PaymentMethodInitialValues {
  paymentMethod?: PaymentMethodOptions;
  savedSourceIndex?: number;
  payPalSourceId?: string;
  cardNumber?: string;
  monthYear?: string;
  security?: string;
  brand?: CreditCardBrands;
  icon?: JSX.Element;
  contactName?: string;
  companyName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateProvinceRegionCode?: string;
  countryCode?: string;
  zipPostalCode?: string;
  saveToAccount?: boolean;
  makePrimary?: boolean;
  acceptAgreementTerms?: boolean;
}

export interface PaymentMethodBodyValues {
  paymentMethod: PaymentMethodOptions;
  savedSourceIndex: number;
  payPalSourceId: string;
  cardNumber: string;
  monthYear: string;
  security: string;
  brand: CreditCardBrands;
  icon: JSX.Element;
  contactName: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateProvinceRegionCode: string;
  countryCode: string;
  zipPostalCode: string;
  saveToAccount: boolean;
  makePrimary: boolean;
  acceptAgreementTerms: boolean;
}

export type formikSetFieldValueFunc = (
  field: string,
  value: any,
  shouldValidate?: boolean | undefined
) => void;

export type formikSetFieldTouchedFunc = (
  field: string,
  isTouched?: boolean | undefined,
  shouldValidate?: boolean | undefined
) => void;

const PaymentMethodRadioButton = ({ children, ...props }) => {
  const [field, meta /*, helpers*/] = useField(props.name);
  return (
    <>
      <Label paddingY='small' alignItems={'center'} display={'flex'}>
        <Radio {...field} {...props} />
        {children}
      </Label>
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </>
  );
};

const maskedNumberString = (
  last4?: string | null,
  brand?: CreditCardBrands | null
): string => {
  if (!last4 || !brand) return '';
  switch (brand) {
    case 'VISA':
      return `4*** **** **** ${last4}`;
    case 'DISCOVER':
      return `6*** **** **** ${last4}`;
    case 'MASTERCARD':
      return `5*** **** **** ${last4}`;
    case 'AMERICAN_EXPRESS':
      return `3*** ****** *${last4}`;
    default:
      return '';
  }
};

const maskedNumberElement = (
  last4?: string | null,
  brand?: CreditCardBrands | null
): JSX.Element => {
  if (!last4 || !brand) return <></>;
  switch (brand) {
    case 'VISA':
      return (
        <>
          {<>Visa&nbsp;****&nbsp;</>}
          {last4}
        </>
      );
    case 'DISCOVER':
      return (
        <>
          {<>Discover&nbsp;****&nbsp;</>}
          {last4}
        </>
      );
    case 'MASTERCARD':
      return (
        <>
          {<>Mastercard&nbsp;****&nbsp;</>}
          {last4}
        </>
      );
    case 'AMERICAN_EXPRESS':
      return (
        <>
          {<>American&nbsp;Express&nbsp;****&nbsp;</>}
          {last4}
        </>
      );
    default:
      return <></>;
  }
};

export const createExistingCreditCardItem = (
  paymentSource: ExistingPaymentSource
) => {
  const { paymentSourceId, paymentSessionId, clientSecret, id } = paymentSource;

  // deconstruct card data
  const { expirationMonth, expirationYear, lastFourDigits, brand } =
    paymentSource.creditCard ?? {};

  // deconstruct top level billing info
  const { firstName, lastName, organization } = paymentSource.billTo ?? {};

  // convert numeric month/year into string MM/YY
  let monthYear;
  let expired = false;
  if (expirationMonth && expirationYear) {
    const card_m = expirationMonth;
    const card_y =
      expirationYear < 100 ? expirationYear + 2000 : expirationYear;

    const current_date = moment();
    const current_year = current_date.year();
    const current_month = current_date.month() + 1; // moment months are 0 based.  Add 1 to get the correct month number.

    expired =
      card_y < current_year ||
      (card_y === current_year && card_m <= current_month);

    const twoDigitYear = card_y > 1999 ? card_y - 2000 : card_y;

    const month = `${card_m < 10 ? '0' : ''}${card_m}`;
    const year = `${twoDigitYear < 10 ? '0' : ''}${twoDigitYear}`;

    monthYear = `${month}/${year}`;
  }

  return {
    ...defaultExistingCreditCardItem,
    id,
    paymentSourceId,
    paymentSessionId,
    clientSecret,
    maskedCardNumber: maskedNumberElement(lastFourDigits, brand),
    cardNumber: maskedNumberString(lastFourDigits, brand),
    lastFour: lastFourDigits,
    monthYear,
    brand,
    icon: BrandIcons[brand ?? ''],
    expired,
    contactName: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
    companyName: organization,
    ...paymentSource.billTo?.address,
  } as ExistingCreditCardItem;
};

export const getFirstNumFromBrand = brand => {
  switch (brand.toUpperCase().replace(' ', '_')) {
    case 'VISA':
      return '4';
    case 'DISCOVER':
      return '6';
    case 'MASTERCARD':
      return '5';
    case 'AMERICAN_EXPRESS':
      return '3';
    default:
      return '';
  }
};

export const getCCType = (cardNumber: string): CreditCardBrandIcon => {
  const result: CreditCardBrandIcon = {
    brand: '',
    icon: <></>,
  };

  if (cardNumber && cardNumber.length > 0)
    switch (cardNumber[0]) {
      case '4':
        result.brand = 'VISA';
        result.icon = <VisaBlueIcon />;
        break;
      case '6':
        result.brand = 'DISCOVER';
        result.icon = <DiscoverCardIcon />;
        break;
      case '5':
        result.brand = 'MASTERCARD';
        result.icon = <MastercardIcon />;
        break;
      case '3':
        result.brand = 'AMERICAN_EXPRESS';
        result.icon = <AmericanCardIcon />;
        break;
    }
  return result;
};

export const getMonthAndYear = str => {
  if (str.includes('/')) {
    return {
      expirationMonth: str.split('/')[0],
      expirationYear: str.split('/')[1],
    };
  } else {
    return {
      expirationMonth: null,
      expirationYear: null,
    };
  }
};

export enum ExpirationStatus {
  INVALID = -1,
  EXPIRED = 0,
  VALID = 1,
}

export const validExpDate = (expirationString): ExpirationStatus => {
  const expDate = getMonthAndYear(expirationString);
  const month = parseInt(
    // state?.cc?.exp.substring(0, state?.cc?.exp.length > 3 ? 2 : 1)
    expDate.expirationMonth ?? '-1'
  );

  let year = parseInt(expDate.expirationYear ?? '-1');

  if (month < 1 || month > 12 || year < 0) {
    return ExpirationStatus.INVALID;
  }

  if (year < 100) year += 2000; // card year is a 2 digit year.  need a 4 digit year for comparison
  const cc_month = moment()
    .month(month - 1)
    .year(year);

  const current_date = moment(); // get today's date
  const active = cc_month.isAfter(current_date);
  if (!active) {
    return ExpirationStatus.EXPIRED;
  }
  return ExpirationStatus.VALID;
};

export const validatePaymentMethod = (
  values: PaymentMethodBodyValues,
  t: TFunction
) => {
  const validExpFormat = exp => {
    if (
      !exp ||
      exp === '' ||
      !exp.includes('/') ||
      exp.length !== 5 ||
      (exp.length === 5 && parseInt(exp.split('/')[0]) > 12)
    ) {
      return false;
    }
    return true;
  };

  const validCCnumber = (number?: string) => {
    const squashedNumber =
      number?.replaceAll?.(' ', '')?.replaceAll?.('-', '') ?? '';
    if (squashedNumber.length < 15) {
      return false;
    } else if (
      squashedNumber.charAt(0) === '5' ||
      squashedNumber.charAt(0) === '4' ||
      squashedNumber.charAt(0) === '6'
    )
      return squashedNumber.length === 16;
    else if (squashedNumber.charAt(0) === '3')
      return squashedNumber.length === 15;
    return false;
  };

  const checkCvcNumber = (brand, security?: string) => {
    if (
      (brand === 'AMERICAN_EXPRESS' && security?.length === 4) ||
      (brand !== 'AMERICAN_EXPRESS' && security?.length === 3)
    ) {
      return true;
    }

    return false;
  };

  // HERE IS THE FUNCTION
  const isValidPostalCode = (
    postalCode: string | null,
    countryCode: string | null
  ) => {
    let postalCodeRegex;
    switch (countryCode) {
      case 'US':
        postalCodeRegex = /^[0-9]{5}(?:[- ]?[0-9]{4})?$/;
        break;
      case 'CA':
        postalCodeRegex = /^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy][0-9][ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][ ]?[0-9][ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][0-9]$/;
        break;
      default:
        postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    }
    return postalCodeRegex.test(postalCode);
  };

  const isValidFirstAndLastName = (name: any) => {
    const nameRegex = /^[\p{L} ,.'-]+$/u;
    return nameRegex.test(name);
  };

  let errors: FormikErrors<PaymentMethodBodyValues> | undefined;

  if (values.paymentMethod === 'newCC') {
    if (!validCCnumber(values.cardNumber ?? ''))
      errors = {
        ...errors,
        cardNumber: t('cart.payment.paymentValidCardError'),
      };

    if (
      !validExpFormat(values.monthYear ?? '') ||
      !validExpDate(values.monthYear)
    )
      errors = {
        ...errors,
        monthYear: t('cart.payment.paymentEnterExpiration'),
      };

    if (!checkCvcNumber(values.brand ?? '', values.security ?? ''))
      errors = {
        ...errors,
        security: t('cart.payment.paymentSecurityCode'),
      };

    if ((values.contactName ?? '').trim().length === 0 ||
    !isValidFirstAndLastName(values.contactName))
      errors = {
        ...errors,
        contactName: t('cart.payment.paymentValidCardName'),
      };

      if (values.contactName.trim().indexOf(' ') >= 0) {
        //
      } else {
        errors = {
          ...errors,
          contactName: t('cart.payment.paymentValidFirstAndLastName'),
        };
      }

    if ((values.addressLine1 ?? '').trim().length === 0)
      errors = {
        ...errors,
        addressLine1: t('common.address.addressRequired'),
      };

    if ((values.city ?? '').trim().length === 0)
      errors = {
        ...errors,
        city: t('common.address.cityRequired'),
      };

    if ((values.stateProvinceRegionCode ?? '').trim().length === 0)
      errors = {
        ...errors,
        stateProvinceRegionCode:
          values.countryCode === 'CA'
            ? t('common.address.selectAProvince')
            : values.countryCode === 'US'
            ? t('common.address.selectAState')
            : t('common.address.selectACountry'),
      };
    if ((values.countryCode ?? '').trim().length === 0)
      errors = {
        ...errors,
        countryCode: t('common.address.selectACountry'),
      };

      if (
        !isValidPostalCode(
          sessionStorage.getItem('temp-zip-postal-code') !== null ||
            sessionStorage.getItem('temp-zip-postal-code') === ''
            ? sessionStorage.getItem('temp-zip-postal-code')
            : '',
          sessionStorage.getItem('temp-country-code') !== null ||
            sessionStorage.getItem('temp-country-code') === ''
            ? sessionStorage.getItem('temp-country-code')
            : ''
        ) ||
        (values.zipPostalCode ?? '').trim().length === 0
      ) {
        errors = {
          ...errors,
          zipPostalCode: t('common.address.zipRequired'),
        };
      }
  }

  return errors;
};

export const BrandIcons = {
  VISA: <VisaBlueIcon />,
  DISCOVER: <DiscoverCardIcon />,
  MASTERCARD: <MastercardIcon />,
  AMERICAN_EXPRESS: <AmericanCardIcon />,
  '': <></>,
};

export const drJsElementOptions = {
  classes: {
    base: 'DRElement',
    complete: 'complete',
    empty: 'empty',
    focus: 'focus',
    invalid: 'invalid',
    webkitAutofill: 'autofill',
  },
  style: {
    base: {
      color: 'red',
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '20px',
      fontSmoothing: 'auto',
      fontStyle: 'italic',
      fontVariant: 'normal',
      letterSpacing: '3px',
    },
    empty: {
      color: 'red',
    },
    complete: {
      color: 'green',
    },
    invalid: {
      color: 'red',
    },
  },
};

const decryptionFn = () => {
  const encryptCCNum = sessionStorage.getItem('temp-card-number');
  const secret = 'pbj834gjd933746';
  const decrypted = CryptoJS.AES.decrypt(encryptCCNum, secret).toString(
    CryptoJS.enc.Utf8
  );
  return decrypted;
};

export const initialPaymentBodyValues = (
  values?: PaymentMethodInitialValues
): PaymentMethodBodyValues => {
  return {
    ...{
      paymentMethod: '',
      savedSourceId: '',
      savedSourceIndex: -1,
      payPalSourceId: '',
      cardNumber: sessionStorage.getItem('temp-card-number')
        ? decryptionFn()
        : '',
      monthYear: sessionStorage.getItem('temp-month-year') ?? '',
      security: sessionStorage.getItem('temp-security-code') ?? '',
      brand: '',
      icon: <></>,
      contactName: sessionStorage.getItem('temp-name-on-card') ?? '',
      companyName: '',
      addressLine1: sessionStorage.getItem('temp-address-line-1') ?? '',
      addressLine2: sessionStorage.getItem('temp-address-line-2') ?? '',
      city: sessionStorage.getItem('temp-city') ?? '',
      stateProvinceRegionCode:
        sessionStorage.getItem('temp-state-province-region-code') ?? '',
      countryCode: sessionStorage.getItem('temp-country-code') ?? '',
      zipPostalCode: sessionStorage.getItem('temp-zip-postal-code') ?? '',
      saveToAccount: false,
      makePrimary: false,
      acceptAgreementTerms: false,
    },
    ...values,
  };
};

export const defaultExistingCreditCardItem: ExistingCreditCardItem = {
  id: '',
  paymentSourceId: '',
  paymentSessionId: '',
  clientSecret: '',
  maskedCardNumber: <></>,
  cardNumber: '',
  lastFour: '',
  monthYear: '',
  brand: '',
  icon: <></>,
  expired: false,
  contactName: '',
  companyName: '',
  addressLine1: '',
  city: '',
  stateProvinceRegionCode: '',
  countryCode: '',
  zipPostalCode: '',
};

export interface PaymentMethodFormBodyOptions {
  valuesProperty?: string;
  withSavedCards?: boolean;
  hideSavedCardsWhenNotActive?: boolean;
  withPayPal?: boolean;
  withMakePrimary?: boolean;
  withRenewalTerms?: boolean;
  withEditSavedCard?: boolean;
}

export interface PaymentMethodFormBodyProps {
  setIsNewCardSelected: any;
  savedCreditCardSources: ExistingCreditCardItem[];
  drJsLibrary: any;
  formik: FormikProps<any>;
  config?: PaymentMethodFormBodyOptions;
  agreementSelected: any;
  initialCreditCard?: any;
}

const PaymentMethodFormBody: FC<PaymentMethodFormBodyProps> = ({
  setIsNewCardSelected,
  savedCreditCardSources,
  drJsLibrary,
  formik,
  config = {},
  agreementSelected,
  initialCreditCard,
}) => {
  const { t } = useTranslation();
  const {
    valuesProperty,
    withSavedCards = true,
    hideSavedCardsWhenNotActive = false,
    withPayPal,
    withMakePrimary = true,
    withRenewalTerms,
  } = config;

  // state variables
  const [DRTermsAccepted, setDRTermsAccepted] = useState(false) as any;
  const [cardNumber, setCardNumber] = useState() as any;
  const [cardCvv, setCardCvv] = useState() as any;
  const [cardExpiration, setCardExpiration] = useState() as any;
  const [fieldPrefix] = useState<string>(
    `${valuesProperty ? valuesProperty + '.' : ''}`
  );

  const DRTermsToggled = e => {
    sessionStorage.setItem('temp-acceptance-toggled', e.target.value);
    setDRTermsAccepted(!DRTermsAccepted);
    if (DRTermsAccepted) {
      if (agreementSelected) {
        agreementSelected(false); // Pushes to the parent PaymentMethodForm
      }
      formik.setFieldValue(`${fieldPrefix}acceptAgreementTerms`, false); // This changes the checkbox
    } else {
      if (agreementSelected) {
        agreementSelected(true);
      }
      formik.setFieldValue(`${fieldPrefix}acceptAgreementTerms`, true);
    }
  };

  //* DigitalRiver.js input element event handlers
  const cardNumberOnChange = event => {
    console.log('card number change', event);
  };

  const cardExpOnChange = event => {
    console.log('card exp change', event);
  };

  const cardCvvOnChange = event => {
    console.log('card cvv change', event);
  };

  useEffect(() => {
    if(savedCreditCardSources.length > 0){
      formik.setFieldValue(`${fieldPrefix}paymentMethod`, 'existingCC');
      formik.setFieldValue(`${fieldPrefix}savedSourceIndex`, 0);
    }

    if (
      formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value === '' &&
      savedCreditCardSources.length === 0
    ) {
      formik.setFieldValue(`${fieldPrefix}paymentMethod`, 'newCC');
    }

  }, [savedCreditCardSources]);

  //* When the DigitalRiver.js object has been instantiated, create credit card
  //* input field elements and save them in state variables for later use.
  useEffect(() => {
    if (drJsLibrary) {      
      setCardNumber(
        drJsLibrary.createElement('cardnumber', drJsElementOptions)
      );
      setCardCvv(drJsLibrary.createElement('cardcvv', drJsElementOptions));
      setCardExpiration(
        drJsLibrary.createElement('cardexpiration', drJsElementOptions)
      );
    }
  }, [drJsLibrary]);

  //* When the DigitalRiver.js input elements have all been created, mount them
  //* into the appropriate form elements and set the event handlers.
  useEffect(() => {
    if (
      cardNumber !== undefined &&
      cardCvv !== undefined &&
      cardExpiration !== undefined
    ) {      
      cardNumber.mount('card-number');
      cardNumber.on('change', cardNumberOnChange);
      cardCvv.mount('card-cvv');
      cardCvv.on('change', cardExpOnChange);
      cardExpiration.mount('card-expiration');
      cardExpiration.on('change', cardCvvOnChange);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumber, cardCvv, cardExpiration]);

  const initialCardIndex = initialCreditCard
  ? savedCreditCardSources.findIndex(
      card => card.paymentSourceId === initialCreditCard.paymentSourceId
    )
  : 0;

useEffect(() => {
  if (formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value === 'newCC') {
    if (agreementSelected) {
      agreementSelected(false);
    }
    formik.setFieldValue(`${fieldPrefix}acceptAgreementTerms`, false);
  }
  if (
    formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value === 'existingCC'
  ) {
    if (agreementSelected) {
      agreementSelected(false);
    }
    formik.setFieldValue(`${fieldPrefix}acceptAgreementTerms`, false);
    formik.setFieldValue(`${fieldPrefix}savedSourceIndex`, initialCardIndex);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [
  formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value,
  initialCardIndex,
]);

useEffect(() => {
  if (sessionStorage.getItem('temp-country-code')) {
    formik.setFieldValue(
      `${fieldPrefix}countryCode`,
      sessionStorage.getItem('temp-country-code')
    );
  }
  if (sessionStorage.getItem('temp-make-primary') === 'true') {
    formik.setFieldValue(`${fieldPrefix}makePrimary`, false);
  } else if (sessionStorage.getItem('temp-make-primary') === 'false') {
    formik.setFieldValue(`${fieldPrefix}makePrimary`, true);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  if (sessionStorage.getItem('payment-type') === 'existingCC') {
    formik.setFieldValue(`${fieldPrefix}paymentMethod`, 'existingCC');
  }
  if (sessionStorage.getItem('payment-type') === 'newCC') {
    formik.setFieldValue(`${fieldPrefix}paymentMethod`, 'newCC');
  }
  if (sessionStorage.getItem('payment-type') === 'payPal') {
    formik.setFieldValue(`${fieldPrefix}paymentMethod`, 'payPal');
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

useEffect(() => {
  if (
    formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value === 'existingCC'
  ) {
    if (setIsNewCardSelected) {
      setIsNewCardSelected(false);
    }
  } else if (
    formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value === 'newCC'
  ) {
    if (setIsNewCardSelected) {
      setIsNewCardSelected(true);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value]);

useEffect(() => {
  sessionStorage.setItem('temp-state-province-region-code', '');
  sessionStorage.setItem('temp-zip-postal-code', '');
  formik.setFieldValue(`${fieldPrefix}zipPostalCode`, '');
  formik.setFieldValue(`${fieldPrefix}stateProvinceRegionCode`, '');
}, [formik.getFieldMeta(`${fieldPrefix}countryCode`).value]);

  const savedSourceIndex = (formik.getFieldMeta(
    `${fieldPrefix}savedSourceIndex`
  ).value ?? -1) as number;

  return (
    <Container>
      {withSavedCards && savedCreditCardSources.length > 0 && (
        <>
          <PaymentMethodRadioButton
          onClick={() => sessionStorage.setItem('payment-type', 'existingCC')}
            marginRight={0}
            {...{
              ...formik.getFieldProps(`${fieldPrefix}paymentMethod`),
              value: 'existingCC',
            }}
            checked={
              formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value ===
              'existingCC'
            }
          >
            {formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !==
              'existingCC' && hideSavedCardsWhenNotActive ? (
              <Box fontWeight='700' color={'#221E1F'}>
                {t('cart.payment.useSavedCard')}
              </Box>
            ) : (
              <Flex
                alignItems={['flex-start', 'center', 'center', 'center']}
                justifyContent='space-between'
                flex={1}
                flexDirection={['column', 'row', 'row', 'row']}>
                <Flex alignItems='center'>
                  <Box paddingRight='small'>
                    {savedCreditCardSources[savedSourceIndex]?.icon ?? <></>}
                  </Box>
                  <Text fontWeight='400' fontSize='medium'>
                    {savedCreditCardSources[savedSourceIndex]?.maskedCardNumber}
                  </Text>
                </Flex>
                <Box>
                  <Text
                    fontWeight='400'
                    fontSize={'medium'}
                    color={
                      savedCreditCardSources[savedSourceIndex]?.expired
                        ? 'redAlertError'
                        : undefined
                    }
                    //
                  >
                    {savedCreditCardSources[savedSourceIndex]?.monthYear && (
                      <>
                        {t('cart.payment.expirationDate')}:{<>&nbsp;</>}
                        {savedCreditCardSources[savedSourceIndex]?.monthYear}
                      </>
                    )}
                  </Text>
                  {savedCreditCardSources[savedSourceIndex]?.expired && (
                    <Text
                      display='flex'
                      fontWeight='500'
                      fontSize='small'
                      color={'redAlertError'}>
                      <Box marginRight='xSmall'>
                        <RedAlertIcon />
                      </Box>
                      {t('cart.payment.cardExpired')}
                    </Text>
                  )}
                </Box>
              </Flex>
            )}
          </PaymentMethodRadioButton>
          <div
            style={{
              display:
                formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !==
                'existingCC'
                  ? hideSavedCardsWhenNotActive
                    ? 'none'
                    : ''
                  : '',
            }}>
            <SelectField
              label=''
              color={'header.secondaryLinkActive'}
              {...formik.getFieldProps(`${fieldPrefix}savedSourceIndex`)}
            >
              <option disabled value={'-1'}>
                {t('cart.payment.creditDebitCard')}
              </option>
              {savedCreditCardSources?.map((obj, i) => (
                <option value={i} key={i}>
                  {obj.brand} - {obj.lastFour}
                </option>
              ))}
            </SelectField>
          </div>
          <SeparatorLine />
        </>
      )}
      <Flex
        alignItems={['flex-start', 'center', 'center', 'center']}
        justifyContent='space-between'
        flex={1}
        flexDirection={['column', 'row', 'row', 'row']}
        paddingY='xsmall'>
        <PaymentMethodRadioButton
        onClick={() => sessionStorage.setItem('payment-type', 'newCC')}
          marginRight={0}
          {...{
            ...formik.getFieldProps(`${fieldPrefix}paymentMethod`),
            value: 'newCC',
          }}
          checked={
            formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value === 'newCC'
          }
          //
        >
          <Box fontWeight='700' color={'#221E1F'}>
            {t('cart.payment.useNewCard')}
          </Box>
        </PaymentMethodRadioButton>
        <Flex>
          <Box paddingRight='small'>
            <MastercardIcon />
          </Box>
          <Box paddingRight='small'>
            <VisaBlueIcon />
          </Box>
          <Box paddingRight='small'>
            <AmericanCardIcon />
          </Box>
          <Box>
            <DiscoverCardIcon />
          </Box>
        </Flex>
      </Flex>
      <div
        style={{
          display:
            formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !== 'newCC'
              ? 'none'
              : '',
        }}>
        <Box marginBottom='medium'>
          <Text fontSize='small' paddingBottom='medium' fontStyle={'italic'}>
            {t('common.requiredFields')}
          </Text>
          <div className='layered'>
            <InputField
              id='card-number'
              label={t('cart.payment.cardNumber')}
              disabled={
                false &&
                formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !==
                  'newCC'
              }
              placeholder=''
              min={0}
              error={
                formik.getFieldMeta(`${fieldPrefix}cardNumber`).touched &&
                formik.getFieldMeta(`${fieldPrefix}cardNumber`).error
              }
              color={
                formik.getFieldMeta(`${fieldPrefix}cardNumber`).error
                  ? 'redAlertError'
                  : ''
              }
              {...formik.getFieldProps(`${fieldPrefix}cardNumber`)}
              onChange={e => {
                const re = /^[ 0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  const firstNum = e.target.value.charAt(0);
                  const squashedNumber =
                    e.target.value
                      ?.replaceAll?.(' ', '')
                      ?.replaceAll?.('-', '') ?? '';
                  const validCCNumber =
                    firstNum === '3' ||
                    firstNum === '4' ||
                    firstNum === '5' ||
                    firstNum === '6';

                  if (validCCNumber) {
                    const secret = 'pbj834gjd933746';
                    const encryptCCNum = CryptoJS.AES.encrypt(
                      squashedNumber,
                      secret
                    ).toString();
                    sessionStorage.setItem('temp-card-number', encryptCCNum);
                  }

                  formik.handleChange(e);
                  const cctypeInfo: CreditCardBrandIcon = getCCType(
                    e.target.value
                  );
                  formik.setFieldValue(
                    `${fieldPrefix}brand`,
                    cctypeInfo.brand,
                    false
                  );
                  formik.setFieldValue(
                    `${fieldPrefix}icon`,
                    cctypeInfo.icon,
                    false
                  );
                }
              }}
              className='payment-input'
            />
            <FloatingCardIcon>
              <>{formik.getFieldMeta(`${fieldPrefix}icon`).value}</>
            </FloatingCardIcon>
          </div>
        </Box>
        <Box marginBottom='medium'>
          <InputField
            disabled={
              formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !==
              'newCC'
            }
            label={t('cart.payment.nameOnCard')}
            type='text'
            color={
              formik.getFieldMeta(`${fieldPrefix}contactName`).error
                ? 'redAlertError'
                : ''
            }
            {...formik.getFieldProps(`${fieldPrefix}contactName`)}
            onChange={e => {
              sessionStorage.setItem('temp-name-on-card', e.target.value);
              formik.handleChange(e);
            }}
          />
        </Box>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'>
          <Box marginBottom='medium' flex={1} marginRight='small'>
            <InputField
              label={t('cart.payment.cardExpiration')}
              disabled={
                formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !==
                'newCC'
              }
              color={
                formik.getFieldMeta(`${fieldPrefix}monthYear`).error
                  ? 'redAlertError'
                  : ''
              }
              type='text'
              placeholder=''
              maxLength={5}
              {...formik.getFieldProps(`${fieldPrefix}monthYear`)}
              onChange={e => {
                const re = /^[/0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  const val = e.target.value;
                  let updatedVal = '';

                  if (val === '/') return;
                  if (
                    e.target.value.length > 2 &&
                    !e.target.value.includes('/')
                  ) {
                    updatedVal = val.substring(0, 2) + '/' + val.substring(2);
                    e.target.value = updatedVal;
                  } else if (
                    e.target.value.length === 2 &&
                    e.target.value.endsWith('/')
                  ) {
                    updatedVal = `0${e.target.value}`;
                    e.target.value = updatedVal;
                  }
                  sessionStorage.setItem('temp-month-year', val);
                  formik.handleChange(e);
                }
              }}
              id='card-expiration'
              className='payment-input'
            />
          </Box>
          <Box marginBottom='medium' flex={1} marginLeft='small'>
            <InputField
              label={
                <Label
                  fontSize='small'
                  htmlFor='security'
                  color='black'
                  paddingBottom='xxSmall'
                  //paddingLeft='medium'
                  //
                >
                  {t('cart.payment.securityCode')}
                  <a data-tip data-for='global'>
                    <Image
                      paddingLeft='small'
                      src={blueTooltipFilled}
                      alt='tip'
                      maxHeight='16px'
                    />
                  </a>
                  <ReactTooltip
                    id='global'
                    aria-haspopup='true'
                    role='example'
                    type='light'
                    effect='solid'
                    backgroundColor='#FFF'
                    className='customToolTipTheme'>
                    <Flex
                      maxWidth='25rem'
                      display='flex'
                      flexDirection='column'
                      backgroundColor='#fff'>
                      <Heading>
                        {t('manageSubscriptions.visaMasterCard')}
                      </Heading>
                      <Box>
                        <Text>{t('manageSubscriptions.cardToolTipText1')}</Text>
                      </Box>
                      <Heading paddingTop='medium'>
                        {t('manageSubscriptions.americanExpress')}
                      </Heading>
                      <Box>
                        <Text>{t('manageSubscriptions.cardToolTipText2')}</Text>
                      </Box>
                    </Flex>
                  </ReactTooltip>
                </Label>
              }
              disabled={
                formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value !==
                'newCC'
              }
              color={
                formik.getFieldMeta(`${fieldPrefix}security`).error
                  ? 'redAlertError'
                  : ''
              }
              type='password'
              placeholder=''
              maxLength={4}
              {...formik.getFieldProps(`${fieldPrefix}security`)}
              onChange={e => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  sessionStorage.setItem('temp-security-code', e.target.value);
                  formik.handleChange(e);
                }
              }}
              id='card-cvv'
              className='payment-input'
            />
          </Box>
        </Box>
        <Box fontWeight='700' color={'#221E1F'}>
            {t('cart.payment.billingAddress')}
          </Box>
        <Box
          data-test-billing-country-code-select
          marginBottom='medium'
          flex={1}>
          <SelectField
           id='Country-Code'
            label={t('common.address.country')}
            color={
              formik.getFieldMeta(`${fieldPrefix}countryCode`).touched &&
              formik.getFieldMeta(`${fieldPrefix}countryCode`).error
                ? 'redAlertError'
                : ''
            }
            {...formik.getFieldProps(`${fieldPrefix}countryCode`)}
            onChange={e => {
              sessionStorage.setItem('temp-country-code', e.target.value);
              formik.handleChange(e);
            }}
            //
          >
            <option value=''>Select...</option>
            <option value='US'>United States</option>
            {/* <option value='CA'>Canada</option> */}
          </SelectField>
        </Box>
        <Box data-test-billing-address-line-1 marginBottom='medium' flex={1}>
          <InputField
            label={t('common.address.address1')}
            color={
              formik.getFieldMeta(`${fieldPrefix}addressLine1`).error
                ? 'redAlertError'
                : ''
            }
            {...formik.getFieldProps(`${fieldPrefix}addressLine1`)}
            onChange={e => {
              sessionStorage.setItem('temp-address-line-1', e.target.value);
              formik.handleChange(e);
            }}
          />
        </Box>
        <Box display={['block', 'block', 'block', 'flex']} alignItems='flex-start'>
          <Box data-test-billing-address-line-2 marginBottom='medium' flex={1}>
            <InputField
              placeholder=''
              label={t('common.address.address2')}
              color={
                formik.getFieldMeta(`${fieldPrefix}address2`).error
                  ? 'redAlertError'
                  : ''
              }
              {...formik.getFieldProps(`${fieldPrefix}address2`)}
              onChange={e => {
                sessionStorage.setItem('temp-address-line-2', e.target.value);
                formik.handleChange(e);
              }}
            />
          </Box>
          <Box data-test-billing-address-city marginBottom='medium' flex={1}>
            <InputField
              placeholder=''
              label={t('common.address.city')}
              color={
                formik.getFieldMeta(`${fieldPrefix}city`).error
                  ? 'redAlertError'
                  : ''
              }
              marginLeft={['none', 'none', 'none', 'small']}
              {...formik.getFieldProps(`${fieldPrefix}city`)}
              onChange={e => {
                const re = /^[a-zA-Z',.\s-]{1,25}$/g;
                if (e.target.value === '' || re.test(e.target.value)) {
                sessionStorage.setItem('temp-city', e.target.value);
                formik.handleChange(e);
                }
              }}
            />
          </Box>
        </Box>
        <Box display={['block', 'block', 'block', 'flex']} alignItems='flex-start'>
          <Box
            data-test-billing-state-province-code
            marginBottom='medium'
            flex={1}>
            <SelectField
              label={t('common.address.stateProvince')}
              color={
                formik.getFieldMeta(`${fieldPrefix}stateProvinceRegionCode`)
                  .touched &&
                formik.getFieldMeta(`${fieldPrefix}stateProvinceRegionCode`)
                  .error
                  ? 'redAlertError'
                  : ''
              }
              {...formik.getFieldProps(`${fieldPrefix}stateProvinceRegionCode`)}
              onChange={e => {
                sessionStorage.setItem(
                  'temp-state-province-region-code',
                  e.target.value
                );
                formik.handleChange(e);
              }}
              value={
                sessionStorage.getItem('temp-state-province-region-code')
                  ? sessionStorage.getItem('temp-state-province-region-code')
                  : formik.values.name
              }
              //
            >
              <option disabled value=''>
                {['CA', 'US'].includes(
                  formik.getFieldMeta(`${fieldPrefix}countryCode`)
                    .value as string
                )
                  ? 'Select...'
                  : t('common.address.selectACountry')}
              </option>

              {formik.getFieldMeta(`${fieldPrefix}countryCode`).value ===
              'CA' ? (
                PROVINCES.map((obj, i) => (
                  <option disabled={obj.value === 'Select a Province'} key={i} value={obj.value}>
                    {obj.value}
                  </option>
                ))
              ) : formik.getFieldMeta(`${fieldPrefix}countryCode`).value ===
                'US' ? (
                STATES.map((obj, i) => (
                  <option key={i} value={obj.value}>
                    {obj.value}
                  </option>
                ))
              ) : (
                <></>
              )}
            </SelectField>
          </Box>
          <Box
            data-test-billing-address-postal-zip
            marginBottom='medium'
            flex={1}>
            <InputField
              placeholder=''
              label={t('common.address.zip')}
              color={
                // formik.getFieldMeta(`${fieldPrefix}zipPostalCode`).error
                //   ? 'redAlertError'
                //   : ''
                'green'
              }
              marginLeft={['none', 'none', 'none', 'small']}
              {...formik.getFieldProps(`${fieldPrefix}zipPostalCode`)}
              onChange={e => {
                sessionStorage.setItem('temp-zip-postal-code', e.target.value);
                formik.handleChange(e);
              }}
            />
          </Box>
        </Box>
        {withMakePrimary && (
          <Label marginBottom='small' paddingTop='medium'>
            <Checkbox
              type='checkbox'
              checked={
                formik.getFieldMeta(`${fieldPrefix}makePrimary`)
                  .value as boolean
              }
              marginRight={0}
              {...formik.getFieldProps(`${fieldPrefix}makePrimary`)}
              onChange={e => {
                sessionStorage.setItem('temp-make-primary', e.target.value); // This value is inverted, such that true is false, vice versa..
                formik.handleChange(e);
              }}
            />
            <Text
              fontSize='medium'
              fontStyle='italic'
              color='header.secondaryLinkActive'>
              {t('manageSubscriptions.makePrimaryCard')}
            </Text>
          </Label>
        )}
        {withRenewalTerms && (
          <Label
            marginBottom='small'
            paddingTop='medium'
            fontStyle='italic'
            fontSize='small'>
            <Checkbox
              type='checkbox'
              value={`${fieldPrefix}acceptAgreementTerms`}
              onBlur={formik.handleBlur(`${fieldPrefix}acceptAgreementTerms`)}
              onChange={e => DRTermsToggled(e)}
              checked={
                formik.getFieldMeta(`${fieldPrefix}acceptAgreementTerms`)
                  .value as boolean
              }
              marginRight={0}
              // {...formik.getFieldProps(`${fieldPrefix}acceptAgreementTerms`)}
              // Note I left this ^ for reference, we are reverse-destructuring/refactoring this out into value,
              // onBlur and onChange to access the onChange function explicitly to set state of the checked item
            />
            <Text marginLeft='small' paddingLeft='medium' color='black'>
              {t('manageSubscriptions.termsCondition')}{' '}
              <Link
                fontSize='small'
                color='#296A98'
                target='blank'
                href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRAboutDigitalRiverPage/eCommerceProvider.DR+globalTech+Inc'>
                {t('mybusiness.Products.globalTech')}
              </Link>
              ,{' '}
              <Link
                fontSize='small'
                color='#296A98'
                target='blank'
                href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRTermsAndConditionsPage/eCommerceProvider.DR+globalTech+Inc'>
                {t('manageSubscriptions.termsOfSale')}
              </Link>
              ,{' '}
              <Link
                fontSize='small'
                color='#296A98'
                target='blank'
                href='https://store.digitalriver.com/store/defaults/en_US/DisplayDRPrivacyPolicyPage/eCommerceProvider.DR+globalTech+Inc'>
                {t('manageSubscriptions.privacypolicy')}
              </Link>{' '}
              {t('manageSubscriptions.termsCondition2')}
            </Text>
          </Label>
        )}
      </div>
      {withPayPal && (
        <>
          <SeparatorLine />
          <Flex
            alignItems={['flex-start', 'center', 'center', 'center']}
            justifyContent='space-between'
            flex={1}
            flexDirection={['column', 'row', 'row', 'row']}
            paddingY='xsmall'>
            <PaymentMethodRadioButton
            onClick={() => sessionStorage.setItem('payment-type', 'payPal')}
              marginRight={0}
              {...{
                ...formik.getFieldProps(`${fieldPrefix}paymentMethod`),
                value: 'payPal',
              }}
              checked={
                formik.getFieldMeta(`${fieldPrefix}paymentMethod`).value ===
                'payPal'
              }
            >
              <Box fontWeight='bold' color='textLight3' fontFamily={'Roboto'}>
                {t('cart.payment.payPal')}
              </Box>
            </PaymentMethodRadioButton>
            <Flex>
              <PaypalIcon />
            </Flex>
          </Flex>
        </>
      )}
    </Container>
  );
};

export default PaymentMethodFormBody;
