/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useCallback, useEffect, useState } from 'react';
//@ts-ignore
import { Box, Flex, Heading, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SeparatorLine from '../../../components/common/SeparatorLine';
import { convertMoneyAmount } from '../../../routes/ServicesAndSubscriptions/Cart';
import RecaliberationInstructionsModal from '../../../components/cart/order/RecaliberationInstructionsModal';
import { ReactComponent as PaypalIcon } from '../../../assets/images/cart/icon_Paypal.svg';
import CustomPopup from '../../../components/common/customPopup/customPopup.style';
import CartItem from '../../../components/cart/CartItem';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import PageLoading from '../../../components/common/PageLoading';
import {
    CreditCardTypeImage,
    getSmallerCCimg,
} from '../../../components/cart/reviewOrder/PaymentMethod';
import { maskPhoneNumber } from '../../../utils/maskPhoneNumber';
import { getFullCountryByCode } from '../../../config/countries';
import { getOrderInformation } from '../../../redux/orderSummary/actions';
import { RootState } from '../../../redux/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionOrderProducts } from '../../../data/SelectPlanData';
//Order and Customer Data objects below as placeholders until the team is able to pull this info
//from SAP API
const orderCompleteQuery = {
    thankyou: 'Thank you!',
    orderPlaced: 'Your order was placed successfully. We’ve sent a confirmation email to',
    orderNumber: 'Order Number:',
    date: 'Date:',
    orderStatus: 'Order Status:',
    printFriendlyView: 'Print Friendly View',
    contactInformation: 'Contact Information',
    goToAirCycleDashboard: 'Go To AirCycle Dashboard',
    invoiceNumber: '123456789098',
    paymentSourceType: 'Credit Card',
    paymentSourceBrand: 'VISA',
    paymentSourceLast4Digits: '1111',
    totalShipping: {
        amount: '0',
        currencyCode: 'USD',
    },
    tax: {
        amount: '0.00',
        currencyCode: 'USD',
    },
    total: {
        amount: '0.00',
        currencyCode: 'USD',
    },
};

const customerDetailsQuery = {
    email: 'tjshegog1234@yahoo.com',
    phoneNumber: '123-456-7890',
    billingAddress: {
        addressLine1: 'East 4th St',
        addressLine2: '',
        city: 'Austin',
        contactName: 'Thomas Shegog',
        countryCode: '+1',
        mailingAddressName: 'Resideo-Mail',
        stateProvinceRegionCode: 'TX',
        zipPostalCode: '78664',
    },
    firstName: 'Thomas',
    lastName: 'Shegog',
    organization: 'Teeej Studios',
};

const orders = {
    deliveryAddress: {
        country: {
            isocode: 'US',
        },
        defaultAddress: false,
        email: 'gi.sun@pronto-hw.com',
        firstName: 'Gi',
        formattedAddress: '700 E 50th Street, New York, 10022',
        id: '8796420734999',
        lastName: 'Sun',
        line1: '700 E 50th Street',
        postalCode: '10022',
        town: 'New York',
    },
    deliveryCost: {
        currencyIso: 'USD',
        formattedValue: '$9.99',
        priceType: 'BUY',
        value: 9.99,
    },
    createdAt: '2022-02-17T18:08:18+0000',
    orgCustomer: {
        name: 'Customer CanBuy',
        uid: 'testb2brsduser2@mailinator.com',
        currency: {
            active: false,
            isocode: 'USD',
            name: 'US Dollar',
            symbol: '$',
        },
        defaultAddress: {
            country: {
                isocode: 'US',
            },
            defaultAddress: false,
            firstName: 'Some',
            formattedAddress: 'Hidden Pond Road 1724, , Arkansas, BOYDS, 20841',
            id: '8796387966999',
            lastName: 'One',
            line1: 'Hidden Pond Road 1724',
            line2: '',
            phone: '3213213123',
            postalCode: '20841',
            region: {
                isocode: 'US-AR',
            },
            town: 'BOYDS',
        },
        displayUid: 'testb2brsduser2@mailinator.com',
        firstName: 'Customer',
        lastName: 'CanBuy',
        orgUnit: {
            name: 'Pronto Goods',
            uid: 'Pronto Goods',
        },
    },
    purchaseOrderNumber: '2423432423',
    lineItems: [
        {
            product: {
                code: '47591000',
                description: 'Endurance PR Waterproof 6" Steel Toe',
                name: 'Endurance PR Waterproof 6" Steel Toe',
            },
        },
    ],
    totalPriceWithTax: {
        currencyIso: 'USD',
        formattedValue: '$106.99',
        priceType: 'BUY',
        value: 106.99,
    },
    totalTax: {
        currencyIso: 'USD',
        formattedValue: '$0.00',
        priceType: 'BUY',
        value: 0.0,
    },
};

const PageTitle = styled.div`
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    color: ${({ theme }) => theme.colors.textLight3};
    font-family: 'URW_Din_CondBold';
    font-size: 2.75rem;
    line-height: 5rem;
    letter-spacing: 1.47px;
`;

const Print = styled(Link)`
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.cartLink};
`;

const FlexBox = styled(Flex)`
    @media (max-width: 768px) {
        border-radius: 15px;
    }
`;

const HeadingBox = styled(Heading)`
    margin-bottom: 1.063rem;
    font-family: 'Roboto';
    font-weight: bold;
`;
const TextBox = styled(Text)`
    line-height: 24px;
`;

const LabelStrong = styled.span`
    font-family: 'Roboto';
    font-weight: 700;
`;

const LabelValue = styled.span`
    margin-left: 0.75rem;
`;

const ThankYou: FC<any> = ({ viewerData }) => {
    console.log(viewerData);
    //useDispatch and useSelector functions to fire off API Request and retrieve selected data values

    // const dispatch = useDispatch();
    // const state = useSelector((state: RootState) => state.orderSummary);
    // const {email, firstName, lastName}: any = state;
    // const { fetchingOrderInformation } = state;

    useEffect(() => {
        document.title = 'Review Order | Resideo Pro';
    }, []);

    const params = useParams() as any;
    const orderId = params?.id;
    const [isLoading, setLoading] = useState(true);
    const [order, setOrder] = useState<any>(null);

    const { t } = useTranslation();

    const [hasSubmittedDevice, setHasSubmittedDevice] = useState(false);

    const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
    const closeAddAddressModal = () => setOpenInstructionsModal(false);
    const today = moment(order?.createdAt).format('MMM DD, YYYY');

    const hasProduct = order?.lineItems?.filter((f: any) => f.type === 'product').length > 0;

    const taxData = JSON.parse(`${localStorage.getItem('taxExemptionApplication')}`);

    return !orders === null ? (
        <PageLoading />
    ) : (
        <>
            {openInstructionsModal && (
                <CustomPopup open={openInstructionsModal} closeOnDocumentClick={false}>
                    <RecaliberationInstructionsModal
                        close={closeAddAddressModal}
                        hasSubmittedDevice={hasSubmittedDevice}
                        setHasSubmittedDevice={setHasSubmittedDevice}
                    />
                </CustomPopup>
            )}
            <Box padding='40px'>
                <PageTitle>{t('cart.orderComplete.thankyou')}</PageTitle>
                <Text color='textHeading' fontFamily='Roboto'>
                    {t('cart.orderComplete.orderPlaced')} {orders.deliveryAddress.email}
                    <Text color='textHeading' fontFamily='Roboto'></Text>
                </Text>
                <SeparatorLine />
                <Flex paddingBottom='small' display={['', '', 'none', 'none']}>
                    <Print
                        to={`/cart/print-friendly-view/${orderId}`}
                        color='primary'
                        target='_blank'>
                        {t('cart.orderComplete.printFriendlyView')}
                    </Print>
                </Flex>
                <Flex justifyContent='space-between' alignItems='flex-end'>
                    <Flex flexDirection='column'>
                        <Text color='header.secondaryLinkActive'>
                            <LabelStrong>{t('cart.order.orderNumber')}</LabelStrong>
                            {/* Digital River API? */}
                            <LabelValue>{orderCompleteQuery.invoiceNumber}</LabelValue>
                        </Text>
                        <Text paddingY='small' color='header.secondaryLinkActive'>
                            <LabelStrong>{t('cart.order.date')}</LabelStrong>
                            <LabelValue>{today}</LabelValue>
                        </Text>
                        <Text color='header.secondaryLinkActive'>
                            <LabelStrong>{t('cart.order.orderStatus')}</LabelStrong>
                            <LabelValue>{t('cart.order.completed')}</LabelValue>
                        </Text>
                    </Flex>
                    <Flex display={['none', 'none', 'flex', 'flex']}>
                        <Print
                            to={`/cart/print-friendly-view/${orderId}`}
                            color='primary'
                            target='_blank'>
                            {t('cart.orderComplete.printFriendlyView')}
                        </Print>
                    </Flex>
                </Flex>
                <FlexBox
                    flexWrap='wrap'
                    padding='large'
                    backgroundColor='badge.normal'
                    style={{ margin: '2rem 0' }}>
                    <Flex
                        flexDirection='column'
                        marginBottom={['large', 'large', '', '']}
                        marginRight='xLarge'>
                        <HeadingBox color='header.secondaryLinkActive'>
                            {t('cart.order.contactInformation')}
                        </HeadingBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {orders.deliveryAddress.email}
                        </TextBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {orders.orgCustomer.defaultAddress.phone}
                        </TextBox>
                    </Flex>
                    {hasProduct && (
                        <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                            <HeadingBox color='header.secondaryLinkActive'>
                                {t('cart.order.shippingAddress')}
                            </HeadingBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {orders.orgCustomer.name}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {orders.deliveryAddress.firstName} {orders.deliveryAddress.lastName}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {orders.deliveryAddress.formattedAddress}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {/* FIX - Not sure if proper api vlaue to use */}
                                {orders.orgCustomer.defaultAddress.region.isocode}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {orders.deliveryAddress.postalCode}
                            </TextBox>
                            <TextBox color='header.secondaryLinkActive'>
                                {orders.deliveryAddress.country.isocode}
                            </TextBox>
                        </Flex>
                    )}
                    <Flex flexDirection='column' marginBottom={['large', 'large', '', '']}>
                        <HeadingBox color='header.secondaryLinkActive'>
                            {t('cart.order.billingAddress')}
                        </HeadingBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {orders.orgCustomer.name}
                        </TextBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {orders.deliveryAddress.firstName} {orders.deliveryAddress.lastName}
                        </TextBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {orders.deliveryAddress.line1}
                        </TextBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {orders.deliveryAddress.town}
                            {', '}
                            {/*Fix - Not sure of api value*/}
                            {orders.orgCustomer.defaultAddress.region.isocode}{' '}
                            {orders.deliveryAddress.postalCode}
                        </TextBox>
                        <TextBox color='header.secondaryLinkActive'>
                            {t(getFullCountryByCode(orders?.deliveryAddress.country.isocode))}
                        </TextBox>
                    </Flex>
                    <Flex marginBottom={['large', 'large', '', '']} marginLeft='xLarge'>
                        <Flex flexDirection='column'>
                            <HeadingBox color='header.secondaryLinkActive'>
                                {t('cart.payment.paymentMethod')}
                            </HeadingBox>
                            {/*Digital River API?*/}
                            {orderCompleteQuery.paymentSourceType === 'PAYPAL' ? (
                                <Flex>
                                    <PaypalIcon />
                                </Flex>
                            ) : (
                                <>
                                    <Flex>
                                        <TextBox color='header.secondaryLinkActive'>
                                            {/*Digital River API?*/}
                                            {orderCompleteQuery.paymentSourceBrand}
                                        </TextBox>
                                        <CreditCardTypeImage
                                            src={getSmallerCCimg(
                                                // Digital River API?
                                                orderCompleteQuery.paymentSourceBrand,
                                            )}
                                            alt='payment-card'
                                            marginLeft='0.625rem'
                                            width='1.875rem'
                                        />
                                    </Flex>
                                    <TextBox color='header.secondaryLinkActive'>
                                        {/*Digital River API?*/}
                                        **** **** **** {orderCompleteQuery.paymentSourceLast4Digits}
                                    </TextBox>
                                </>
                            )}
                        </Flex>
                    </Flex>
                    <Flex marginBottom={['large', 'large', '', '']} marginLeft='xLarge'>
                        <Flex flexDirection='column'>
                            <HeadingBox color='text'>
                                {t('cart.order.taxExemptionInformation')}
                            </HeadingBox>
                            <TextBox color='text'>{taxData?.companyName}</TextBox>
                            <TextBox color='text'>{taxData?.taxAuthority}</TextBox>
                            <TextBox color='text'>
                                {t('cart.billing.exemptionStartDateLable')}{' '}
                                {taxData?.startDate !== ''
                                    ? moment(taxData?.startDate).format('MMM YYYY')
                                    : ' '}
                            </TextBox>
                            <TextBox color='text'>
                                {t('cart.billing.exemptionEndDateLable')}{' '}
                                {taxData?.endDate !== ''
                                    ? moment(taxData?.endDate).format('MMM YYYY')
                                    : ' '}
                            </TextBox>
                            <TextBox color='text'>
                                {t('cart.billing.certFileName')}{' '}
                                {taxData?.taxAuthority !== '' ? 'fileName.jpg' : ' '}
                            </TextBox>
                        </Flex>
                    </Flex>
                </FlexBox>
                <Flex display='flex' flexDirection='column' style={{ marginTop: 40 }}>
                    {order?.lineItems?.map((item: any, i: any) => (
                        <Flex key={i} display={'flex'} flexDirection={'column'}>
                            <CartItem fromThankYou key={i} item={item} />
                            <SeparatorLine />
                        </Flex>
                    ))}
                </Flex>
                <Box>
                    {hasProduct && (
                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            paddingBottom='small'>
                            <Text width='calc(100% - 7.625rem)' textAlign='right'>
                                {t('cart.shipping.shipping')}
                            </Text>
                            <Text width='7.625rem' textAlign='right'>
                                {convertMoneyAmount(
                                    {
                                        amount: orders.deliveryCost.formattedValue,
                                        currencyCode: orders.deliveryCost.currencyIso,
                                    },
                                    'en-us',
                                )}
                            </Text>
                        </Box>
                    )}
                    <Box
                        display='flex'
                        width='100%'
                        justifyContent='flex-end'
                        paddingBottom='small'>
                        <Text width='calc(100% - 7.625rem)' textAlign='right'>
                            {t('tax')} {t('notYetCalculated')}
                        </Text>

                        <Text width='7.625rem' textAlign='right'>
                            {convertMoneyAmount(
                                {
                                    amount: orders.totalTax.formattedValue,
                                    currencyCode: orders.totalTax.currencyIso,
                                },
                                'en-us',
                            )}
                        </Text>
                    </Box>
                </Box>
                <Box>
                    <Box display='flex' alignItems='center' width='100%' justifyContent='flex-end'>
                        <Heading
                            letterSpacing='normal'
                            fontSize='medium'
                            width='calc(100% - 7.625rem)'
                            textAlign='right'>
                            {t('totalToday')}
                        </Heading>
                        <Heading
                            letterSpacing='normal'
                            width='7.625rem'
                            fontSize='medium'
                            textAlign='right'>
                            {convertMoneyAmount(
                                {
                                    amount: orders.totalPriceWithTax.formattedValue,
                                    currencyCode: orders.totalPriceWithTax.currencyIso,
                                },
                                'en-us',
                            )}
                        </Heading>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ThankYou;
