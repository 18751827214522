import { boolean } from 'yup';
import { types } from './types';

type AddDeliveryAddressProps = {
    body: any;
};

type UpdateDeliveryAddressProps = {
    addressId: string;
    body: any;
};

type UpdateDeliveryModeProps = {
    deliveryModeId: string;
};

type SelectDeliveryAddressProps = {
    addressId: string;
};

type CreateCreditCardProps = {
    body: any;
};

type placeOrderProps = {
    termsValue: Boolean;
};

type UserInfoProps = {
    addressId: string;
    userInfoPayload: any;
    deliveryModeId: string;
};

type CreateConsentProps = {
    templateId: string
    version: number;
};

type DeleteConsentProps = {
    consentCode : string
};

type CreateContactInfoProps = {
    contactInfo : any;
};

export const getAddressDetails = (payload?: any) => ({
    type: types.FETCH_ADDRESS_DETAILS,
    payload,
});

export const getAddressDetailsSuccess = (payload: any) => ({
    type: types.FETCH_ADDRESS_DETAILS_SUCCESS,
    payload,
});

export const getAddressDetailsFail = (payload: any) => ({
    type: types.FETCH_ADDRESS_DETAILS_FAIL,
    payload,
});

export const addDeliveryAddress = (payload: AddDeliveryAddressProps) => ({
    type: types.ADD_DELIVERY_ADDRESS,
    payload,
});

export const addDeliveryAddressSuccess = (payload: any) => ({
    type: types.ADD_DELIVERY_ADDRESS_SUCCESS,
    payload,
});

export const addDeliveryAddressFail = (payload: any) => ({
    type: types.ADD_DELIVERY_ADDRESS_FAIL,
    payload,
});

export const updateDeliveryAddress = (payload: UpdateDeliveryAddressProps) => ({
    type: types.UPDATE_DELIVERY_ADDRESS,
    payload,
});

export const updateDeliveryAddressSuccess = (payload: any) => ({
    type: types.UPDATE_DELIVERY_ADDRESS_SUCCESS,
    payload,
});

export const updateDeliveryAddressFail = (payload: any) => ({
    type: types.UPDATE_DELIVERY_ADDRESS_FAIL,
    payload,
});

export const getDeliveryModeDetails = () => ({
    type: types.FETCH_DELIVERY_MODES,
});

export const getDeliveryModeDetailsSuccess = (payload: any) => ({
    type: types.FETCH_DELIVERY_MODES_SUCCESS,
    payload,
});

export const getDeliveryModeDetailsFail = (payload: any) => ({
    type: types.FETCH_DELIVERY_MODES_FAIL,
    payload,
});

export const updateDeliveryMode = (payload: UpdateDeliveryModeProps) => ({
    type: types.UPDATE_DELIVERY_MODES,
    payload,
});

export const updateDeliveryModeSuccess = (payload: any) => ({
    type: types.UPDATE_DELIVERY_MODES_SUCCESS,
    payload,
});

export const updateDeliveryModeFail = (payload: any) => ({
    type: types.UPDATE_DELIVERY_MODES_FAIL,
    payload,
});

export const setLoading = (bool = true) => ({
    type: types.IS_LOADING,
    payload: bool,
});

export const selectDeliveryAddress = (payload: SelectDeliveryAddressProps) => ({
    type: types.SELECT_DELIVERY_ADDRESS,
    payload,
});

export const selectDeliveryAddressSuccess = (payload: any) => ({
    type: types.SELECT_DELIVERY_ADDRESS_SUCCESS,
    payload,
});

export const selectDeliveryAddressFail = (payload: any) => ({
    type: types.SELECT_DELIVERY_ADDRESS_FAIL,
    payload,
});

export const getCreditCardDetails = (payload?: any) => ({
    type: types.FETCH_CREDIT_CARD_DETAILS,
    payload,
});

export const getCreditCardDetailsSuccess = (payload: any) => ({
    type: types.FETCH_CREDIT_CARD_DETAILS_SUCCESS,
    payload,
});

export const getCreditCardDetailsFail = (payload: any) => ({
    type: types.FETCH_CREDIT_CARD_DETAILS_FAIL,
    payload,
});

export const createCreditCard = (payload: CreateCreditCardProps) => ({
    type: types.CREATE_CREDIT_CARD_DETAILS,
    payload,
});

export const createCreditCardSuccess = (payload: any) => ({
    type: types.CREATE_CREDIT_CARD_DETAILS_SUCCESS,
    payload,
});

export const createCreditCardFail = (payload: any) => ({
    type: types.CREATE_CREDIT_CARD_DETAILS_FAIL,
    payload,
});

export const updateCreditCard = (payload: any) => ({
    type: types.UPDATE_CREDIT_CARD_DETAILS,
    payload,
});

export const updateCreditCardSuccess = (payload: any) => ({
    type: types.UPDATE_CREDIT_CARD_DETAILS_SUCCESS,
    payload,
});

export const updateCreditCardFail = (payload: any) => ({
    type: types.UPDATE_CREDIT_CARD_DETAILS_FAIL,
    payload,
});

export const placeOrder = (payload: placeOrderProps) => ({
    type: types.PLACE_ORDER,
    payload,
});

export const placeOrderSuccess = (payload: any) => ({
    type: types.PLACE_ORDER_SUCCESS,
    payload,
});

export const placeOrderFail = (payload: any) => ({
    type: types.PLACE_ORDER_FAIL,
    payload,
});

export const setCartItems = (payload: any) => ({
    type: types.SET_CART_ITEMS,
    payload,
});

export const updateShippingInfo = (payload: UserInfoProps) => ({
    type: types.UPDATE_SHIPPING_INFO,
    payload,
});

export const updateShippingInfoSuccess = (payload: any) => ({
    type: types.UPDATE_SHIPPING_INFO_SUCCESS,
    payload,
});

export const updateShippingInfoFail = (payload: any) => ({
    type: types.UPDATE_CREDIT_CARD_DETAILS_FAIL,
    payload,
});

export const reviewOrder = () => ({
    type: types.REVIEW_ORDER_DETAILS,
});

export const reviewOrderSuccess = (payload: any) => ({
    type: types.REVIEW_ORDER_DETAILS_SUCCESS,
    payload,
});

export const reviewOrderFail = (payload: any) => ({
    type: types.REVIEW_ORDER_DETAILS_FAIL,
    payload,
});

export const getConsentsDetails = () => ({
    type: types.FETCH_CONSENTS_LIST,
});

export const getConsentsDetailsSuccess = (payload: any) => ({
    type: types.FETCH_CONSENTS_LIST_SUCCESS,
    payload,
});

export const getConsentsDetailsFail = (payload: any) => ({
    type: types.FETCH_CONSENTS_LIST_FAIL,
    payload,
});

export const createConsents = (payload?: CreateConsentProps) => ({
    type: types.CREATE_CONSENTS,
    payload,
});

export const createConsentsSuccess = (payload: any) => ({
    type: types.CREATE_CONSENTS_SUCCESS,
    payload,
});

export const createConsentsFail = (payload: any) => ({
    type: types.CREATE_CONSENTS_FAIL,
    payload,
});

export const deleteConsents = (payload?: DeleteConsentProps) => ({
    type: types.DELETE_CONSENTS,
    payload,
});

export const deleteConsentsSuccess = (payload: any) => ({
    type: types.DELETE_CONSENTS_SUCCESS,
    payload,
});

export const deleteConsentsFail = (payload: any) => ({
    type: types.DELETE_CONSENTS_FAIL,
    payload,
});

export const createContactInfo= (payload: CreateContactInfoProps) => ({
    type: types.CREATE_CONTACT_DETAILS,
    payload,
});

export const createContactInfoSuccess = (payload: any) => ({
    type: types.CREATE_CONTACT_DETAILS_SUCCESS,
    payload,
});

export const createContactInfoFail = (payload: any) => ({
    type: types.CREATE_CONTACT_DETAILS_FAIL,
    payload,
});