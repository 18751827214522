import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    updateDRpaymentSuccess: false,
    updateDRpayment: [],
    updateDRpaymentError: null,
    creteDRnewBillingSuccess: false,
    creteDRnewBilling: [],
    creteDRnewBillingError: null,
    creteDRBillingResponseSuccess: false,
    creteDRBillingResponse: [],
    creteDRBillingResponseError: null,
};

const digitalRiver = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.UPDATE_DR_PAYMENT:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDRpaymentSuccess = true;
            });
        case types.UPDATE_DR_PAYMENT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDRpaymentSuccess = false;
                draftState.updateDRpayment = payload;
            });
        case types.UPDATE_DR_PAYMENT_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDRpaymentSuccess = false;
                draftState.updateDRpaymentError = payload;
            });
        case types.CREATE_DR_NEW_BILLING:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creteDRnewBillingSuccess = true;
            });
        case types.CREATE_DR_NEW_BILLING_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creteDRnewBillingSuccess = false;
                draftState.creteDRnewBilling = payload;
            });
        case types.CREATE_DR_NEW_BILLING_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creteDRnewBillingSuccess = false;
                draftState.creteDRnewBillingError = payload;
            });
        case types.CREATE_DR_BILLING_RESPONSE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creteDRBillingResponseSuccess = true;
            });
        case types.CREATE_DR_BILLING_RESPONSE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creteDRBillingResponseSuccess = false;
                draftState.creteDRBillingResponse = payload;
            });
        case types.CREATE_DR_BILLING_RESPONSE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.creteDRBillingResponseSuccess = false;
                draftState.creteDRBillingResponseError = payload;
            });
        default:
            return state;
    }
};

export { digitalRiver };
