import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';

const initialState = {
    fetchinglanguage: false,
    languages: [],
    fetchingLanguageError: null,
}

const reducer = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch(type) {
        case types.FETCH_LANGUAGE:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchinglanguage = true;
            })
        case types.FETCH_LANGUAGE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchinglanguage = false;
                draftState.languages   = payload
            })
        case types.FETCH_LANGUAGE_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchinglanguage = false;
                draftState.fetchingLanguageError   = payload
            })
        default:
            return state;
    }
}

export { reducer }