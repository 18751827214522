export const subscriptionUtils = (rawData: any) => {
    const model = {
        activationDate: null,
        autoRenew: false,
        billableQuantity: 0,
        billingCycle: '',
        cancellationDate: '',
        cancellationReason: '',
        enrollmentMarket: '',
        id: '',
        logoApproved: false,
        partnerProgram: {},
        purchaseDate: '',
        status: '',
        subscriptionEndDate: '',
        billingFrequency: '',
        contractDuration: '',
        description: '',
        name: '',
        orderNumber: '',
        productCode: '',
        renewalType: '',
    };

    if (!rawData) return model;
    if (rawData.id) {
        model.id = rawData.id;
        model.activationDate = rawData?.startDate;
        model.billingCycle = Capitalize(rawData?.billingFrequency);
        model.cancellationDate = rawData?.cancelledDate;
        model.purchaseDate = rawData?.placedOn;
        model.status = Capitalize(rawData?.subscriptionStatus);
        model.subscriptionEndDate = rawData?.endDate;
        model.autoRenew = rawData?.renewalType?.toLowerCase() === 'non_renewing' ? false : true;
        model.billableQuantity = rawData.orderEntryNumber;
        model.billingFrequency = rawData.contractFrequency;
        model.contractDuration = rawData.contractDuration;
        model.name = rawData.name;
        model.orderNumber = rawData.orderNumber;
        model.productCode = rawData.productCode;
        model.renewalType = rawData.renewalType;
        model.description = rawData.description;
    }

    return model;
};

export const parseSubscriptionDetails = (rawData: Array<any>) => {
    const finalArray: Array<any> = [];
    rawData.forEach((subscriptionData) => {
        finalArray.push(subscriptionUtils(subscriptionData));
    });
    return finalArray;
};

export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};
