import { call, all, put, takeLatest, take, cancel } from 'redux-saga/effects';
import { authenticateUrl } from '../../config/api/url';
import {
    loginSuccess,
    loginFailed,
    logoutSuccess,
    logoutFailed,
    setJwt,
    getJwtSuccess,
    getJwtFailed,
    deleteJwtSuccess,
    appLaunchFlowComplete,
    handleAuthenticationCallback as handleAuthenticationCallbackAction,
} from './action';
import { types } from './types';
import {
    removeJwt,
    removeRefreshToken,
    retrieveJwt,
    retrieveRefreshToken,
    storeJwt,
    storeRefreshToken,
    retrieveExpiresIn,
    setExp,
} from '.';
import { api } from '../../config/api';
import { httpRequest } from '../types';
import { handleAuthentication, signOut as signOutAction } from '../../config/Auth';
import { getJWtDetails } from 'utils/utils';
import { removeCartID, removeCartItems, removeProductID } from 'redux/cart';
import { getUserProfile } from 'redux/user/action';

export function* handleAuthenticationCallback(): any {
    try {
        const user = yield call(handleAuthentication);
        if (user) {
            yield parseLoginResponse(user);
            yield put(loginSuccess(user));
        }
        return user;
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function* appLaunchFlow(): any {
    try {
        const isSignedIn = yield* getAndSetupToken();
        if (isSignedIn) {
            // get User Profile etc
            yield put(getUserProfile())
            yield put(appLaunchFlowComplete(true));
        } else {
            yield* handleAuthenticationCallback();
        }
    } catch (error) {
        console.log('ERROR ME');
        yield put(appLaunchFlowComplete(false));
    }
}

export function* refreshJWT(): any {
    try {
        const response = yield call(api, authenticateUrl, httpRequest.POST, {}, 0, 0);
        return response;
    } catch (error) {
        return null;
    }
}

export function* getJWT(): any {
    let token: string = '';
    let refreshToken: string = '';
    let expires_in: number = 0;

    try {
        token = yield call(retrieveJwt);
        refreshToken = yield call(retrieveRefreshToken);
        expires_in = yield call(retrieveExpiresIn);
        if (token) yield put(getJwtSuccess());
        if (token && refreshToken) yield put(setJwt({ token, refreshToken, expires_in }));
        return { token, refreshToken, expires_in };
    } catch (error) {
        yield put(getJwtFailed(error));
    }
    return { token: null, refreshToken: null, expires_in: null };
}

function* parseLoginResponse(response: any) {
    const { idToken, authenticated, expiresAt } = response;
    yield all([
        call(setExp, expiresAt),
        call(storeJwt, idToken),
        call(storeRefreshToken, idToken),
        put(setJwt({ token: idToken, refreshToken: idToken, expires_in: expiresAt })),
        put(loginSuccess(response)),
    ]);
}

function* login(action: any): any {
    const { payload } = action;
    try {
        const response = yield call(api, authenticateUrl, httpRequest.POST, payload, 0, 0, true);
        yield parseLoginResponse(response);
    } catch (error) {
        yield put(loginFailed(error));
    }
}

function tokenRefreshRequired(expires_in: any) {
    const result = new Date(expires_in * 60 * 60 * 100000) <= new Date(Date.now());
    return result;
}

function* logout() {
    try {
        const jwt = yield* getJWT();
        if (jwt) {
            yield call(removeCartID);
            yield call(removeCartItems);
            yield call(removeProductID);
            yield call(removeJwt);
            yield call(removeRefreshToken);
            yield put(logoutSuccess());
            yield put(deleteJwtSuccess());
            yield call(signOutAction);
        } else {
            yield put(logoutFailed('Something went wrong'));
        }
    } catch (error) {
        yield put(logoutFailed(error));
    }
}

function* getAndSetupToken(): any {
    const { token } = yield* getJWT();
    let isCurrentSignIn: boolean = false;
    try {
        if (!token) {
            throw 'NO JWT';
        } else {
            const { isSignedIn } = getJWtDetails(token);
            isCurrentSignIn = isSignedIn;
            if (!isSignedIn) {
                yield put(logoutFailed('token Expired'));
            } else {
                yield put(loginSuccess(token));
            }
        }
        return isCurrentSignIn;
    } catch (error) {
        console.log(error);
    }
}

function* appLaunchFlowWatcher() {
    yield takeLatest(types.APP_LAUNCH_FLOW, appLaunchFlow);
}

function* loginWatcher(): any {
    const loginTask = yield takeLatest(types.LOGIN, login);
    yield take(types.LOGIN_CANCELLED);
    yield cancel(loginTask);
}

function* logoutWatcher() {
    yield takeLatest(types.LOGOUT, logout);
}

function* handleAuthenticationCallbackWatcher() {
    yield takeLatest(types.HANDLE_AUTHENTICATION_CALLBACK, handleAuthenticationCallback);
}

export default function* authenticationSaga() {
    yield all([
        loginWatcher(),
        logoutWatcher(),
        appLaunchFlowWatcher(),
        handleAuthenticationCallbackWatcher(),
    ]);
}
