export function maskPhoneNumber(phone: string, phoneNumberLocation: string) {
  // The ask was to fix the number formating on the checkout review page, however, I found
  //   that phone numbers were not only improperly formatted everywhere, they were cutting off
  //   the last digit.  The logic was wrong.
  if (phoneNumberLocation === 'ContactForm') {
    try {
      let number = phone.replace(/\D/g, '');
      if (number.length === 11) {
        number = number.slice(1);
      }
      const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      } else {
        return phone;
      }
    } catch (e) {
      return phone;
    }
  } else {
    try {
      let number = phone.replace(/\D/g, '');
      if (number.length === 11) {
        number = number.slice(1);
      }
      const match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
      } else {
        return phone;
      }
    } catch (e) {
      return phone;
    }
  }
}
