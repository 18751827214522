import React, { FC, useEffect, useState } from 'react';
import { blackCrossImage, redAlertIcon, resideoBlackLogo } from '../../assets/images';
import { Box, Image, Heading, Text, Flex } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { btnMinusBlack, btnPlusBlack } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { convertMoneyAmount } from '../../routes/Products/Cart';
import ServiceIconLarge from '../../assets/images/ServiceIconLarge';
import { removeCartItem, updateCartValue } from 'redux/cart/actions';
import { useDispatch, useSelector } from 'react-redux';
import { updateCartQuantity } from 'redux/cart/actions';
import { RootState } from 'redux/root-reducer';
import { getCardDetails } from 'redux/cart/actions';
import { useHistory } from 'react-router-dom';
import PageLoading from 'components/common/PageLoading';
import { useDispatchPromise } from 'hooks/useDispatchPromise';

const Container = styled(Flex)`
    align-items: center;
    position: relative;
    .product-img {
        object-fit: cover;
    }
    @media (max-width: 768px) {
        align-items: flex-start;
    }
`;

const StyledCursorImage = styled(Image)`
    cursor: pointer;
    &.closeImage {
        position: absolute;
        top: 2rem;
        right: -0.875rem;
        height: 2rem;
        width: 2rem;
    }
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;

const StyledCursorImageClose = styled(Image)`
    cursor: pointer;
    &.closeImage {
        position: absolute;
        top: 2rem;
        right: -0.875rem;
        height: 2rem;
        width: 2rem;
    }
    @media (max-width: 991px) {
        display: none;
    }
`;

const StyledCursorImageCloseMobile = styled(Image)`
    cursor: pointer;
    display: none;
    @media (max-width: 991px) {
        display: flex;
        position: absolute;
        top: -2rem;
        right: 0;
        height: 25px;
        width: 25px;
    }
`;

interface Link {
    externalLink?: boolean;
}

const StyledLink = styled.a<Link>`
    color: #00689a;
    font-weight: 500;
    cursor: pointer;
`;

const BoxItemDesc = styled(Box)`
    padding: 2rem;
    @media (max-width: 768px) {
        padding: 0 0 2rem 2rem;
    }
`;

const FlexProductImage = styled(Flex)`
    align-items: center;
`;

const QtyAndTotal = styled(Heading)`
    font-family: Roboto;
    font-weight: bold;
    font-size: 1.3125rem;
    line-height: 1.360625rem;
    text-align: right;
    color: ${({ theme }) => theme.colors.text};
    display: flex;
    align-items: center;
    padding-right: ${({ theme }) => theme.space.medium};
`;

interface CartItemsProps {
    setHasEnrollmentAlready: any;
    isAlert: any;
    item: cartItemList;
    fromThankYou: any;
}

type cartItemList = {
    cancellableQuantity: number;
    configurationInfos: any[];
    entryNumber: number;
    product: Product;
    quantity: number;
    returnableQuantity: number;
    statusSummaryList: any[];
    totalPrice: TotalPrice;
};

type Product = {
    availableForPickup: boolean;
    baseProduct: string;
    channelDisplayCategory: string;
    code: string;
    configurable: boolean;
    images: Images[];
    isNew: boolean;
    manufacturer: string;
    metaDescription: string;
    metaTitle: string;
    name: string;
    ogDescription: string;
    ogTitle: string;
    purchasable: boolean;
    resources: any[];
    soldDirectlyToCustomer: boolean;
    specs: any[];
    syndicationChannel: string;
    url: string;
    itemType: string;
    price: Price;
};

type Price = {
    oneTimeChargeEntries: OneTimeChargeEntry[];
    recurringChargeEntries: any[];
    usageCharges: UsageCharge[];
};

type UsageCharge = {
    usageChargeEntries: UsageChargeEntry[];
    usageUnit: UsageUnit;
};

type OneTimeChargeEntry = {
    billingTime: BillingTime;
    cycleEnd: number;
    cycleStart: number;
    name: string;
    price: Price2;
    tierEnd: number;
    tierStart: number;
};

type Price2 = {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number;
};

type BillingTime = {
    code: string;
    description: string;
    name: string;
    nameInOrder: string;
    orderNumber: number;
};

type UsageChargeEntry = {
    cycleEnd: number;
    cycleStart: number;
    price: Price3;
    tierEnd: number;
    tierStart: number;
};

type Price3 = {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number;
};

type UsageUnit = {
    accumulative: boolean;
    id: string;
    name: string;
    namePlural: string;
};

type Images = {
    format: string;
    imageType: string;
    url: string;
};

type TotalPrice = {
    currencyIso: string;
    value: number;
};

const CartItem: FC<any> = ({
    setHasEnrollmentAlready,
    isAlert,
    item,
    fromThankYou,
}: CartItemsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hookDispatch = useDispatchPromise();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const { updateCartItemSuccess, removedCartItemSuccess } = useSelector(
        (state: RootState) => state.cartDetails,
    );

    const isDevelopEnvironment = process.env.REACT_APP_ENVIRONMENT !== 'production';

    const updateQuantity = async (type) => {
        let updateQtyValue = type === 'add' ? item?.quantity + 1 : item?.quantity - 1;

        if (updateQtyValue < 1) {
            hookDispatch(
                await removeCartItem({ entryNumber: item.entryNumber }),
            ).then(async (response) => {
                await dispatch(getCardDetails());
                dispatch(updateCartValue());
                setTimeout(() => {
                    setIsLoading(false);
                }, 500);
            }).catch((error) => {
                console.log('Error', error);
                setIsLoading(false);
            });
        } else {
            if (updateQtyValue) {
                let dataToSend = {
                    orderEntries: [
                        {
                            entryNumber: item.entryNumber,
                            product: {
                                code: item.product.code,
                            },
                            quantity: updateQtyValue,
                        },
                    ],
                };
                await dispatch(updateCartQuantity({ body: dataToSend }));
            }
        }
    };

    const removeItem = async (entryNumber) => {
        hookDispatch(
            await removeCartItem({ entryNumber: entryNumber }),
        ).then(async (response) => {
            await dispatch(getCardDetails());
            dispatch(updateCartValue());
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }).catch((error) => {
            console.log('Error', error);
            setIsLoading(false);
        });
    };

    // useEffect(() => {
    //     if (removedCartItemSuccess) {
    //         dispatch(getCardDetails()); 
    //         dispatch(updateCartValue());          
    //     }
    // }, [dispatch, removedCartItemSuccess]);

    useEffect(() => {
        if (updateCartItemSuccess) {
            dispatch(getCardDetails());
            dispatch(updateCartValue());
        }
    }, [dispatch, updateCartItemSuccess]);

    return (
        <Container>
            {isLoading && <PageLoading />}
            <FlexProductImage
                width={['10rem', '12.5rem', '12.5rem', '12.5rem']}
                minHeight='115px'
                paddingY={['15px', '20px', '20px', '20px']}
                paddingX={['10px', '20px', '20px', '20px']}
                justifyContent='center'
                style={{ border: '2px solid #ededed' }}>
                {/* TODO : Need to enable when dynamic images available */}
                {/* {item && item?.product?.images ? (
                  <Image
                    src={item?.product?.images[0].url}
                    alt='box image'
                    maxWidth='115px'
                    height='115px'
                  />
                ) : (
                  <ServiceIconLarge />
                )} */}
                <ServiceIconLarge />
            </FlexProductImage>
            <Flex
                flexWrap='nowrap'
                alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
                flexDirection={['column', 'column', 'column', 'row']}
                width='84%'>
                <Image
                    src={resideoBlackLogo}
                    alt='logo'
                    display={['none', 'none', 'none', 'block']}
                    paddingX={[0, 0, 0, 'large']}
                    style={{ transform: 'scale(0.6)' }}
                />

                <BoxItemDesc display='block' padding='large' flex='2'>
                    <Heading
                        data-test-cart-item-name
                        letterSpacing='0'
                        fontSize='medium'
                        color='cartTextHeading'>
                        {item?.product?.name
                            ?.split(/\s+/)
                            ?.map((w) => w[0]?.toUpperCase() + w.substr(1)?.toLowerCase())
                            ?.join(' ')}
                    </Heading>
                    <Text
                        data-test-cart-item-sku
                        color='gray'
                        fontSize='small'
                        fontWeight='500'
                        paddingTop='xSmall'>
                        {item.product.code}
                    </Text>
                    {item && item?.product?.itemType?.toLowerCase() === 'subscriptionproduct' && (
                        <Text
                            data-test-cart-item-sku
                            color='black'
                            fontSize='small'
                            paddingTop='xSmall'>
                            {t('cart.activationChargesLabel')}
                        </Text>
                    )}
                    {isAlert && (
                        <Flex alignItems='center' paddingY='0.625rem'>
                            <Image src={redAlertIcon} alt='error' />
                            <Text
                                paddingLeft='0.313rem'
                                data-test-cart-item-sku
                                color='redAlertError'
                                fontWeight='500'
                                fontSize='0.875rem'>
                                {t('cart.existingService')}
                            </Text>
                        </Flex>
                    )}
                </BoxItemDesc>
                {fromThankYou && item?.product?.itemType?.toLowerCase() === 'subscriptionproduct' && (
                    <StyledLink
                        data-test-go-back-btn
                        target='blank'
                        //href={`${window.location.origin}/subscription`}
                        onClick={() => history.push('/subscription')}
                        className='back-arrow-animation'>
                        {t('cart.completeOrder.activate')}
                    </StyledLink>
                )}
                {fromThankYou && (
                    <QtyAndTotal
                        data-test-cart-item-qty
                        fontSize='medium'
                        padding='medium'
                        paddingLeft={['large', 'large', 'large', '']}
                        fontWeight='bold'>
                        x{item.quantity || 1}
                    </QtyAndTotal>
                )}
                <Flex paddingLeft={['large', 'large', 'large', '']}>
                    <Flex display={['block', 'block', 'block', 'flex']} paddingRight='xLarge'>
                        {!fromThankYou && (
                            <>
                                {item && item?.product?.itemType?.toLowerCase() === 'product' ? (
                                    <Box display='flex' alignItems='center' padding={'10px'}>
                                        <StyledCursorImage
                                            onClick={() => updateQuantity('minus')}
                                            src={btnMinusBlack}
                                            alt='minus'
                                        />
                                        <Heading
                                            data-test-cart-item-qty
                                            fontSize='1.3125rem'
                                            padding='medium'
                                            color='cartTextHeading'
                                            fontWeight='bold'>
                                            {item?.quantity}
                                        </Heading>
                                        <StyledCursorImage
                                            onClick={() => updateQuantity('add')}
                                            src={btnPlusBlack}
                                            alt='minus'
                                        />
                                    </Box>
                                ) : (
                                    <Box display='flex' alignItems='center'>
                                        <Heading
                                            data-test-cart-item-qty
                                            fontSize='1.3125rem'
                                            color='cartTextHeading'
                                            padding='medium'
                                            paddingRight='xLarge'
                                            fontWeight='bold'>
                                            x{item?.quantity}
                                        </Heading>
                                    </Box>
                                )}
                            </>
                        )}

                        <QtyAndTotal
                            data-test-cart-item-total
                            padding={['0rem', '0rem', '1rem', '1rem']}
                            textAlign={['left', 'left', 'left', 'center']}>
                            {convertMoneyAmount(
                                {
                                    amount: item?.totalPrice?.value,
                                    currencyCode: item?.totalPrice?.currencyIso ?? 'USD',
                                },
                                'en-us',
                            )}
                        </QtyAndTotal>
                    </Flex>
                    {!fromThankYou && (
                        <StyledCursorImageClose
                            src={blackCrossImage}
                            alt='close'
                            onClick={() => {
                                setIsLoading(true);
                                setHasEnrollmentAlready(false);
                                removeItem(item?.entryNumber);
                            }}
                        />
                    )}
                </Flex>
            </Flex>
            {!fromThankYou && (
                <StyledCursorImageCloseMobile
                    src={blackCrossImage}
                    alt='close'
                    onClick={() => {
                        setIsLoading(true);
                        removeItem(item?.entryNumber);
                    }}
                />
            )}
        </Container>
    );
};

export default CartItem;
