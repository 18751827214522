import auth0 from 'auth0-js';

const auth0Client = new auth0.WebAuth({
    // the following three lines MUST be updated
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
    responseType: 'token',
    // scope: 'openid profile email'
});

export function handleAuthentication() {
    return new Promise((resolve, reject) => {
        auth0Client.parseHash((err, authResult) => {
            if (err) return reject(err);
            if (!authResult || !authResult.accessToken) {
                return reject(err);
            }
            const idToken = authResult.accessToken;
            const profile = authResult.idTokenPayload;
            // set the time that the id token will expire at
            const expiresAt = authResult.expiresIn * 1000;
            resolve({
                authenticated: true,
                idToken,
                profile,
                expiresAt,
            });
        });
    });
}

export function signIn() {
    auth0Client.authorize();
}

export function signOut() {
    auth0Client.logout({
        returnTo: process.env.REACT_APP_AUTH0_SIGNOUT_CALLBACK_URL,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
}
