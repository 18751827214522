import { Dispatch, FC, SetStateAction } from "react";
import {
    Title
} from "../../routes/Products/PLP/styles";

interface IProps {
    id?: string;
    title?: string;
    isOpen?: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onCategoryClick?: (args: any) => void;
    isSelectedCategoryID: any;
}

export const ItemHeader = ({ id, title, isOpen, setIsOpen, onCategoryClick, isSelectedCategoryID }: IProps) => {

    return (
        <div>
            <Title className={`${isSelectedCategoryID === id && 'active'}`}
                onClick={() => {
                    setIsOpen(!isOpen);
                    if (onCategoryClick) onCategoryClick(id);
                }}>
                {title}
            </Title>
        </div>
    )
};