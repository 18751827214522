import produce from 'immer';
import { ReduxAction } from '../types';
import { types } from './types';
import { parseCreditCardDetails } from 'utils/utility';
import { parseReviewOrder } from 'utils/ReviewOrderUtils';

const initialState = {
    fetchingAddressSuccess: false,
    addressDetails: [],
    fetchingAddressError: null,
    addDeliveryAddressSuccess: false,
    addDeliveryAddressDetails: [],
    addDeliveryAddressError: null,
    updateDeliveryAddressSuccess: false,
    updateDeliveryAddressDetails: [],
    updateDeliveryAddressError: null,
    fetchingDeliveryModeSuccess: false,
    deliveryModeDetails: [],
    fetchingDeliveryModeError: null,
    updateDeliveryModeSuccess: false,
    updateDeliveryMode: [],
    updateDeliveryModeError: null,
    isLoading: false,
    selectDeliveryAddressSuccess: false,
    selectDeliveryAddressDetails: [],
    selectDeliveryAddressError: null,
    fetchingCreditCardSuccess: false,
    creditCardDetailsList: [],
    fetchingCreditCardError: null,
    createCreditCardSuccess: false,
    createCreditCardDetails: [],
    createCreditCardError: null,
    updateCreditCardSuccess: false,
    updateCreditCardDetails: [],
    updateCreditCardError: null,
    placeOrderDetails: [],
    placeOrderSuccess: false,
    placeOrderError: null,
    cartItems: [],
    updateShippingInfoSuccess: false,
    updateShippingInfoFail: null,
    updateShippingInfo: [],
    reviewOrderDetails: [],
    reviewOrderSuccess: false,
    reviewOrderError: null,
    fetchingConsentList: [],
    fetchingConsentListSuccess: false,
    fetchingConsentListFail: null,
    createConsent: [],
    createConsentSuccess: false,
    createConsentFail: null,
    deleteConsent: [],
    deleteConsentSuccess: false,
    deleteConsentFail: null,
    contactDetails: [],
    contactDetailsSuccess: false,
    contactDetailsFail: null,
};

const checkOutDetails = (state = initialState, action: ReduxAction) => {
    const { payload, type } = action;

    switch (type) {
        case types.FETCH_ADDRESS_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingAddressSuccess = true;
            });
        case types.FETCH_ADDRESS_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingAddressSuccess = false;
                draftState.addressDetails = payload;
            });
        case types.FETCH_ADDRESS_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingAddressSuccess = false;
                draftState.fetchingAddressError = payload;
            });
        case types.ADD_DELIVERY_ADDRESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.addDeliveryAddressSuccess = true;
            });
        case types.ADD_DELIVERY_ADDRESS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.addDeliveryAddressSuccess = false;
                draftState.addDeliveryAddressDetails = payload;
                const oldAddress: any = [...draftState.addressDetails, payload];
                draftState.addressDetails = oldAddress;
            });
        case types.ADD_DELIVERY_ADDRESS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.addDeliveryAddressSuccess = false;
                draftState.addDeliveryAddressError = payload;
            });
        case types.UPDATE_DELIVERY_ADDRESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDeliveryAddressSuccess = true;
            });
        case types.UPDATE_DELIVERY_ADDRESS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDeliveryAddressSuccess = false;
                draftState.updateDeliveryAddressDetails = payload;
            });
        case types.UPDATE_DELIVERY_ADDRESS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDeliveryAddressSuccess = false;
                draftState.updateDeliveryAddressError = payload;
            });
        case types.FETCH_DELIVERY_MODES:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingDeliveryModeSuccess = true;
            });
        case types.FETCH_DELIVERY_MODES_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingDeliveryModeSuccess = false;
                draftState.deliveryModeDetails = payload;
            });
        case types.FETCH_DELIVERY_MODES_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingDeliveryModeSuccess = false;
                draftState.fetchingDeliveryModeError = payload;
            });
        case types.UPDATE_DELIVERY_MODES:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDeliveryModeSuccess = true;
            });
        case types.UPDATE_DELIVERY_MODES_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDeliveryModeSuccess = false;
                draftState.updateDeliveryMode = payload;
            });
        case types.UPDATE_DELIVERY_MODES_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateDeliveryModeSuccess = false;
                draftState.updateDeliveryModeError = payload;
            });
        case types.IS_LOADING:
            return produce(state, (draftState: typeof initialState) => {
                draftState.isLoading = payload;
            });
        case types.SELECT_DELIVERY_ADDRESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.selectDeliveryAddressSuccess = true;
            });
        case types.SELECT_DELIVERY_ADDRESS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.selectDeliveryAddressSuccess = false;
                draftState.selectDeliveryAddressDetails = payload;
            });
        case types.SELECT_DELIVERY_ADDRESS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.selectDeliveryAddressSuccess = false;
                draftState.selectDeliveryAddressError = payload;
            });
        case types.FETCH_CREDIT_CARD_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCreditCardSuccess = true;
            });
        case types.FETCH_CREDIT_CARD_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCreditCardSuccess = false;
                const ccList: any = parseCreditCardDetails(payload);
                draftState.creditCardDetailsList = ccList;
            });
        case types.FETCH_CREDIT_CARD_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingCreditCardSuccess = false;
                draftState.fetchingCreditCardError = payload;
            });
        case types.CREATE_CREDIT_CARD_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createCreditCardSuccess = true;
            });
        case types.CREATE_CREDIT_CARD_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createCreditCardSuccess = false;
                draftState.createCreditCardDetails = payload;
            });
        case types.CREATE_CREDIT_CARD_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createCreditCardSuccess = false;
                draftState.createCreditCardError = payload;
            });
        case types.UPDATE_CREDIT_CARD_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateCreditCardSuccess = true;
            });
        case types.UPDATE_CREDIT_CARD_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateCreditCardSuccess = false;
                draftState.updateCreditCardDetails = payload;
            });
        case types.UPDATE_CREDIT_CARD_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateCreditCardSuccess = false;
                draftState.updateCreditCardError = payload;
            });
        case types.PLACE_ORDER:
            return produce(state, (draftState: typeof initialState) => {
                draftState.placeOrderSuccess = true;
            });
        case types.PLACE_ORDER_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.placeOrderSuccess = false;
                draftState.placeOrderDetails = payload;
            });
        case types.PLACE_ORDER_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.placeOrderSuccess = false;
                draftState.placeOrderError = payload;
            });
        case types.SET_CART_ITEMS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.cartItems = payload;
            });
        case types.UPDATE_SHIPPING_INFO:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateShippingInfoSuccess = true;
            });
        case types.UPDATE_SHIPPING_INFO_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateShippingInfoSuccess = false;
                draftState.updateShippingInfo = payload;
            });
        case types.UPDATE_SHIPPING_INFO_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.updateShippingInfoSuccess = false;
                draftState.updateShippingInfoFail = payload;
            });

        case types.REVIEW_ORDER_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.reviewOrderSuccess = true;
            });
        case types.REVIEW_ORDER_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.reviewOrderSuccess = false;
                const reviewOrder: any = parseReviewOrder(payload);
                draftState.reviewOrderDetails = reviewOrder;
            });
        case types.REVIEW_ORDER_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.reviewOrderSuccess = false;
                draftState.reviewOrderError = payload;
            });
        case types.FETCH_CONSENTS_LIST:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingConsentListSuccess = true;
            });
        case types.FETCH_CONSENTS_LIST_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingConsentListSuccess = false;
                draftState.fetchingConsentList = payload;
            });
        case types.FETCH_CONSENTS_LIST_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.fetchingConsentListSuccess = false;
                draftState.fetchingConsentListFail = payload;
            });
        case types.CREATE_CONSENTS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createConsentSuccess = true;
            });
        case types.CREATE_CONSENTS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createConsentSuccess = false;
                draftState.createConsent = payload;
            });
        case types.CREATE_CONSENTS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.createConsentSuccess = false;
                draftState.createConsentFail = payload;
            });
        case types.DELETE_CONSENTS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deleteConsentSuccess = true;
            });
        case types.DELETE_CONSENTS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deleteConsentSuccess = false;
                draftState.deleteConsent = payload;
            });
        case types.DELETE_CONSENTS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.deleteConsentSuccess = false;
                draftState.deleteConsentFail = payload;
            });
        case types.CREATE_CONTACT_DETAILS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.contactDetailsSuccess = true;
            });
        case types.CREATE_CONTACT_DETAILS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.contactDetailsSuccess = false;
                draftState.contactDetails = payload;
            });
        case types.CREATE_CONTACT_DETAILS_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.contactDetailsSuccess = false;
                draftState.contactDetailsFail = payload;
            });

        default:
            return state;
    }
};

export { checkOutDetails };
