import React from 'react';
import { useField } from 'formik';
import { Input, Text, Select } from '@resideo/blueprint-react';
import styled from 'styled-components';
import ICON_CALENDAR from '../icons/calendar.svg';

const ErrorInput = styled(Input)<{ isError?: boolean; isDatePicker?: boolean }>`
  background: ${({ isDatePicker }) =>
    isDatePicker
      ? `calc(100% - 10px) 50% no-repeat url(${ICON_CALENDAR})`
      : ''};
  border: ${({ isError }) =>
    isError ? '1px solid red !important' : '1px solid #166a96'};
  box-shadow: ${({ isError }) =>
    isError ? '0px 0px 0px 1px red inset' : 'none'};
  :focus {
    box-shadow: ${({ isError }) =>
      isError ? '0px 0px 0px 1px red inset' : 'none'};
  }
  font-family: 'Roboto';
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
  ::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export const InputField = ({ label, name, isDatePicker = false, ...props }) => {
  const [field, meta] = useField(name);
  return (
    <>
      <label>
        <Text
          marginLeft='medium'
          paddingBottom='xSmall'
          color='black'
          fontSize='small'>
          {label}
        </Text>
        <ErrorInput
          {...field}
          {...props}
          isError={typeof (meta.touched && meta.error) === 'string'}
          isDatePicker={isDatePicker}
        />
      </label>
      {meta.touched && meta.error ? (
        <Text
          fontSize='small'
          className='error'
          paddingLeft='small'
          color={props.errorColor ?? 'red'}>
          {meta.error}
        </Text>
      ) : null}
    </>
  );
};
const ErrorSelect = styled(Select)<{ isError?: boolean }>`
  border: ${({ isError }) =>
    isError ? '1px solid red !important' : '1px solid #166a96'};
  box-shadow: ${({ isError }) =>
    isError ? '0px 0px 0px 1px red inset' : 'none'};
  font-family: 'Roboto';
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.header.secondaryLinkActive};
  :focus {
    box-shadow: ${({ isError }) =>
      isError ? '0px 0px 0px 1px red inset' : 'none'};
  }
`;

export const SelectField = ({ label, name, ...props }) => {
  const [field, meta] = useField(name);

  return (
    <>
      <label>
        <Text
          marginLeft='medium'
          paddingBottom={'xSmall'}
          color='black'
          fontSize='small'>
          {label}
        </Text>
        <ErrorSelect {...field} {...props} isError={props.isError} />
      </label>
      {meta.touched && meta.error ? (
        <Text
          fontSize='small'
          className='error'
          paddingLeft='small'
          color='red'>
          {meta.error}
        </Text>
      ) : null}
    </>
  );
};
