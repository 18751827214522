import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Box, Text } from '@resideo/blueprint-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledFixedTabs = styled(Box)`
  &.fixed-tab {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 5;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledTabs = styled.ul`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  list-style-type: none;
  margin: 0 0 10px 5px;
  padding: 8px 0 10px 0;
  display: flex;
  @media (max-width: 768px) {
    box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
  }
  li {
    font-size: 0.875rem;
    padding-bottom: 6px;
    display: inline;
    cursor: pointer;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    span {
      margin: 20px;
      margin-bottom: 10px;
      text-decoration: none;
    }
  }
  li:nth-child(even) {
    span {
      margin: 20px 0 !important;
    }
  }
  .active-tab {
    border-bottom: 5px solid ${({ theme }) => theme.colors.header.activeTabLink};
  }
`;

const HashRouter = ({
  setTab,
  tab,
  overviewRef,
  relatedProductRef,
  resourcesRef,
  getStartedRef,
  specificationsRef,
  data,
}) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const [scrollTop, setScrollTop] = useState(0);

  const handleScrollToView = ref => {
    if (ref.current) {
      const headerOffset = 100;
      const elementPosition =
        ref.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const onScroll = e => {
    setScrollTop(e.target.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const maxPageOffset = 400;

    const elementPositionOverview =
      overviewRef?.current?.getBoundingClientRect().top + window.scrollY;

    const elementPositionSpecification =
      specificationsRef?.current?.getBoundingClientRect().top + window.scrollY;

    const elementPositionResources =
      resourcesRef?.current?.getBoundingClientRect().top + window.scrollY;

    const elementPositionRelatedProjectRef =
      relatedProductRef?.current?.getBoundingClientRect().top + window.scrollY;

    const elementPositionGetStartedRef =
      getStartedRef?.current?.getBoundingClientRect().top + window.scrollY;

    if (elementPositionRelatedProjectRef - scrollTop < maxPageOffset) {
      setTab(relatedProductRef.current.id);
    } else if (elementPositionSpecification - scrollTop < maxPageOffset) {
      setTab(specificationsRef.current.id);
    } else if (elementPositionResources - scrollTop < maxPageOffset) {
      setTab(resourcesRef.current.id);
    } else if (elementPositionOverview - scrollTop < maxPageOffset) {
      setTab(overviewRef.current.id);
    } else if (elementPositionGetStartedRef - scrollTop < maxPageOffset) {
      setTab(getStartedRef.current.id);
    }
  }, [scrollTop]);

  return (
    <StyledFixedTabs
      className={'fixed-tab'}
      display='block'
      paddingTop={['medium', 'large', 'xLarge', 'xLarge']}>
      <StyledTabs>
        <Box
          paddingLeft={['0', 'small', 'medium', 'xLarge']}
          width={['auto', 'auto', 'auto', '83rem']}>
          {data && data?.webOverview && (
            <>
              <li
                onClick={() => {
                  handleScrollToView(overviewRef);
                }}
                className={tab === 'Overview' ? 'active-tab' : ''}>
                <Text as='span' fontWeight='500' color='textLight3'>
                  {t('productDetailPage.overviewTitle')}
                </Text>
              </li>
              <li>
                <Text as='span' fontWeight='500' color='textLight3'>
                  |
                </Text>
              </li>
            </>
          )}
          {data && data?.specs && data?.specs.length > 0 && (
            <>
              <li
                onClick={() => {
                  handleScrollToView(specificationsRef);
                }}
                className={tab === 'Specifications' ? 'active-tab' : ''}>
                <Text as='span' fontWeight='500' color='textLight3'>
                  {t('productDetailPage.specificationTitle')}
                </Text>
              </li>
              <li>
                <Text as='span' fontWeight='500' color='textLight3'>
                  |
                </Text>
              </li>
            </>
          )}
          {data && data?.resources && data?.resources.length > 0 && (
            <>
              <li
                onClick={() => {
                  handleScrollToView(resourcesRef);
                }}
                className={tab === 'Resources' ? 'active-tab' : ''}>
                <Text as='span' fontWeight='500' color='textLight3'>
                  {t('productDetailPage.resourcesTitle')}
                </Text>
              </li>
              <li>
                <Text as='span' fontWeight='500' color='textLight3'>
                  |
                </Text>
              </li>
            </>
          )}
          {!path.includes('home-owner') && (
            <>
              <li>
                <Text as='span' fontWeight='500' color='textLight3'>
                  |
                </Text>
              </li>
              <li
                onClick={() => {
                  handleScrollToView(relatedProductRef);
                }}
                className={tab === 'Related Products' ? 'active-tab' : ''}>
                <Text as='span' fontWeight='500' color='textLight3'>
                  {t('productDetailPage.relatedProductsTitle')}
                </Text>
              </li>
            </>
          )}
          {path.includes('home-owner') && (
            <>
              {/* <li>
                <Text as='span' fontWeight='500' color='textLight3'>
                  |
                </Text>
              </li> */}
              <li
                onClick={() => {
                  handleScrollToView(getStartedRef);
                }}
                className={tab === 'Get Started' ? 'active-tab' : ''}>
                <Text as='span' fontWeight='500' color='textLight3'>
                  {t('productDetailPage.getStarted')}
                </Text>
              </li>
            </>
          )}
        </Box>
      </StyledTabs>
    </StyledFixedTabs>
  );
};

export default HashRouter;
