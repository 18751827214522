import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { Button, Flex, Text } from '@resideo/blueprint-react';
// import { SuccessAlert, ErrorAlert } from 'components/common/Alert';
import styled from 'styled-components';
import { useToast } from 'components/common/Toast';
import { useIsMounted } from 'hooks/useIsMounted';
import { ReactComponent as SearchIcon } from 'components/icons/search-icon.svg';
import { signIn, signOut } from '../../config/Auth';

const StyledMenuButton = styled(MenuButton)`
    cursor: pointer;
    color: white;
    height: 28px;
    width: 119px;
    background-color: #166a96;
    font-weight: bold;
    font-family: Roboto;
`;

const NotificationRowComponent = ({ node, fetch }) => {
    const { t } = useTranslation();
    const toast = useToast();
    const isMounted = useIsMounted();

    return (
        <MenuItem
            onSelect={() => {
                // acceptInvite();
            }}>
            <Flex
                justifyContent='space-between'
                flexDirection={['column', 'row']}
                data-test-invite-notification>
                <Flex
                    flexDirection='column'
                    marginRight='large'
                    alignItems='self-start'
                    paddingBottom={['medium', 0]}>
                    <Text color='white'>
                        {t('header.notifications.invitedTo')}
                        {` ${node.partnerAccount.name}`}
                    </Text>
                    <Text color='faded' fontSize='small'>
                        {t('header.notifications.invitedBy')}
                    </Text>
                </Flex>
                <Button>{t('header.notifications.acceptInvite')}</Button>
            </Flex>
        </MenuItem>
    );
};

const CustomCartMenuList = styled(MenuList)`
    position: fixed;
    right: 0;
    top: 50px;
    width: 520px;
    @media (min-width: ${({ theme }) => theme.breakpoints.large}) {
        right: 70px;
        top: 50px;
        width: 500px;
    }
`;

const useMountEffect = (cb: () => void) => useEffect(cb, []);

const SignInButton: FC = () => {
    const isMounted = useIsMounted();
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState();

    const notExpired = (edge) => {
        const now = Date.now();
        const currentExpiration = new Date(edge.node?.expirationTime || 0);
        return currentExpiration.getTime() > now;
    };

    return (
        <Flex data-test-notifications-menu>
            <Menu>
                <StyledMenuButton onClick={() => signIn()}>JOIN / SIGN IN</StyledMenuButton>
            </Menu>
        </Flex>
    );
};

export default SignInButton;
