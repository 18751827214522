import { combineReducers } from 'redux';
import { reducer as languageReducer} from './language/reducer';
import { reducer as orderSummaryReducer} from './orderSummary/reducer';
import { authentication as authenticationReducer} from './authentication/reducers';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/graphql';
import { category as categoryReducer} from './category/reducer';
import { productListData as productListDataReducer } from './productlist/reducer';
import { productDetails as  productDetailsReducer} from './productdetails/reducer';
import { reducer as RefreshTokenReducer } from './refreshToken/reducer';
import {cartDetails as CartDetailsReducer} from './cart/reducer';
import { checkOutDetails as CheckoutDetailsReducer } from './checkout/reducer';
import { reducer as UserReducer} from './user/reducer';
import { subscriptionListData as SubscriptionListDataReducer} from './subscriptions/reducer';
import { digitalRiver as DigitalRiverReducer} from './digitalriver/reducer';
import { promoCodeDetails as promoCodeDetailsReducer } from './promocode/reducer';
import { b2bUserDetails as  b2bUserDetailsReducer} from './prouser/reducer';
import node from './graphql/__generated__/ProfileQuery.graphql';

const url = process.env.REACT_APP_GATEWAY_URL || "/";
export const encodeId = ({ appName, appVersion, md5Hash }) => btoa(`${appName}:${appVersion}:${md5Hash}`);

const onRequest = (request, requestAction, store) => {
    const token = localStorage.getItem('jwt');
    if (token) {
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }  
    return request;
  };


  /** The hash below was added to solve the issue with relay. it is thee profileQuery ID. 
   * Within our app, we only make one query call, hence why I chose to use it so, however, we can refactor
   * it to come from an env variable.
   * 
   * I'm also doubtful if that value will change in the future, and if it does, we need to account for that.
   */

const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver({ url: process.env.REACT_APP_USE_PERSISTED_QUERIES === 'true' ?  `${url}/${encodeId({appName: process.env.REACT_APP_NAME, appVersion: process.env.REACT_APP_VERSION, md5Hash: node.params.id})}` : url}),
    onRequest:onRequest
  });

const appReducer = combineReducers({
   language: languageReducer,
   authentication: authenticationReducer,
   userDetails: requestsReducer,
   category: categoryReducer,
   productListData: productListDataReducer,
   productDetails: productDetailsReducer,
   orderSummary: orderSummaryReducer,
   tokenRefresh: RefreshTokenReducer,
   cartDetails: CartDetailsReducer,
   checkOutDetails: CheckoutDetailsReducer,
   user: UserReducer,
   subscriptionListData: SubscriptionListDataReducer,
   digitalRiver: DigitalRiverReducer,
   promoCodeDetails: promoCodeDetailsReducer,
   b2bUserDetails: b2bUserDetailsReducer,
})

export type RootState = ReturnType<typeof appReducer>

const reducer = (state: any, action: any) => {
    if (action.type === 'PURGE') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action);
}

export { reducer, requestsMiddleware }
