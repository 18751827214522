import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { Text, Heading, Flex } from '@resideo/blueprint-react';

export const Container = styled.div`
    // display: flex;
    // padding-top: 32px;
    // margin-right: auto;
    // margin-left: auto;
    // padding-left: 15px;
    // padding-right: 15px;
    // //max-width: 1200px;
    // //width: 100%;
    // @media (min-width: 576px) {
    //     max-width: 540px;
    // }
    margin-right: auto;
	margin-left: auto;
	padding-left: 15px;
	padding-right: 15px;
    max-width: none;
	padding-top: 32px
    &:before {
        content: " ";
        display: table
    }
    &:after {
        content: " ";
        display: table;
        clear: both;
    }
    @media (min-width: 768px) {
        max-width: 750px;
    }
    @media (min-width: 992px) {
        max-width: 970px;
     }
    @media (min-width: 1200px) {
        max-width: 1170px;
    }
    @media (min-width: 1400px) {
        max-width: 1375px;
    }

    @media(max-width: 767px) {
        padding-left: 32px;
		padding-right: 32px;
        padding: 0;
    }
    @media(min-width: 1400px) {
        width: 1375px;
    }
    @media(min-width: 768px)and (max-width: 991px) {
        padding: 0;
    }
`;

export const LeftContainer = styled.div`
    width: calc(25% - 64px);
    padding: 0;
    margin: 0 56px 70px 0;
    display: block;
    float: left;
    @media (min-width: 1200px) and (max-width: 1400px) {
        width: 21%;
        margin: 0 45px 70px 0;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        width: 25%;
    }
    @media (max-width: 767px) {
        position: relative !important;
        top: 0 !important;
        float: none;
        width: calc(100% - 64px) !important;
        padding: 32px;
        margin: 0;
        background: #fff;
        transform: none !important;
        margin-bottom: 40px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        position: relative !important;
        top: 0 !important;
        float: none;
        width: calc(100% - 64px) !important;
        padding: 32px;
        margin: 0;
        background: #fff;
        transform: none !important;
        margin-bottom: 40px;
    }
`;

export const RightContainer = styled.div`
    // margin: 0 -16px 32px -16px;
    // display: inline-block;
    // float: left;
    // padding: 0 8px;
    // width: 100%;
    // @media (max-width: 991px) {
    //     padding: 0;
    //     margin: 0 0 32px 0;
    //     margin-top: 2rem;
    // }

    width: auto;
    margin-right: 16px;
    padding: 0 8px;
    @media (max-width: 767px) {
        width: calc(100% - 8px);
        margin: 0 4px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        width: calc(100% - 8px);
        margin: 0 4px;
    }
`;

export const FilterByText = styled.h2`
    font-family: 'URW_Din_CondBold';
    font-size: 40px;
    letter-spacing: 1px;
    line-height: 42px;
    text-transform: uppercase;
    margin: 0 0 32px 0;
    @media (min-width: 992px) {
        line-height: 35px;
    }
`;

export const CategoryText = styled.div`
    font-weight: bold;
    font-family: 'roboto', sans-serif;
    font-size: 16px;
    color: #000;
    line-height: 21px;
    text-transform: uppercase;
    margin: 0 0 4px 0;
    padding: 0 0 4px 0;
    border-bottom: 1px solid rgba(52, 48, 44, 0.5);
    @media (max-width: 768px) {
        margin-top: -30px;
    }
`;

export const CategorySubHeaderText = styled(Text)`
    font-size: 16px;
    font-family: 'roboto', sans-serif;
    line-height: 24px;
    margin: 0 0 4px 0;
    padding: 0 0 4px 0;
    color: #000;
    @media (max-width: 768px) {
        margin-top: -30px;
    }
`;

export const ProductContainer = styled.div`
    margin: 0 -16px 32px -16px;
    @media (max-width: 767px) {
        margin: 0 -4px 16px -4px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
        margin: 0 -4px 16px -4px;
    }

    // position: relative;
    // display: flex;
    // flex-wrap: wrap;
    // margin: 0 -4px;
    // @media (min-width: 992px) {
    //     margin-top: 16px;
    // }

    // display: grid;
    // grid-template-columns: repeat(3, 18em);
    // gap: 1rem 1rem;
    // @media (max-width: 992px) {
    //     grid-template-columns: repeat(2, 18rem);
    //     gap: 0.5rem 0.5rem;
    //     justify-content: center;
    // }
    // @media (max-width: 768px) {
    //     margin-top: 3em;
    //     grid-template-columns: repeat(1, 18rem);
    //     gap: 0.5rem 0.5rem;
    //     justify-content: center;
    // }
`;

export const ProductLayoutContainer = styled.div`
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 16px;
    @media (min-width: 768px) {
        margin: 0 -8px 32px;
    }
`;

export const Wrapper = styled(Flex)`
    background: #fff;
    font-size: 16px;
    border: none;
    border-radius: 0;
    transition: all 0.3s ease;
    flex-grow: inherit;
    width: calc(33.33333% - 32px);
    margin: 0 16px 32px 16px;
    overflow: unset;

    // display: flex;
    // flex-direction: column;
    // position : relative;
    // width: 280px;
    // background: #fff;
    // flex-grow: inherit;
    // overflow: unset;
    // font-size: 16px;
    // border: none
    // flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
    // align-items: ${(props) => (props.alignItems ? props.alignItems : 'left')};
    // position: relative;
    // box-sizing: border-box;

    &:hover {
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
        background: #ededed;
    }
    @media (min-width: 1024px) and (max-width: 1200px) {
        width: calc(33.33333% - 16px);
        margin: 0 8px 16px 8px;
    }
    @media (max-width: 767px) {
        width: calc(50% - 8px);
        margin: 0 4px 8px 4px;
    }
    @media (min-width: 767px) and (max-width: 991px) {
        width: calc(50% - 8px);
        margin: 0 4px 8px 4px;
    }
`;

export const BestSeller = styled.div`
    width: 50%;
    height: 30px;
    line-height: 30px;
    background-color: #258039;
    border-radius: 0px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-decoration: none !important;
    .img {
        margin: 0 auto 32px auto;
        object-fit: cover;
        min-height: 278px;
        max-height: 279px;
        @media (max-width: 768px) {
            min-height: auto;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
            min-height: 174px;
        }
        @media (min-width: 1200px) and (max-width: 1375px) {
            min-height: 224px;
        }
        @media (min-width: 992px) {
            min-height: 214px;
        }
        @media (min-width: 768px) {
            min-height: 312px;
        }
        @media (min-width: 992px) {
            min-height: 174px;
        }
        @media (min-width: 1200px) {
            min-height: 278px;
        }
    }
`;

export const List = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px 0px 30px 0px;
`;

export const ListItem = styled.li`
    list-style: none;
    margin: 0px 0px 8px 0px;
`;

export const Title = styled.span`
    color: #000;
    font-family: 'roboto', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    &.active {
        font-weight: 700;
    }
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const MyPaginate = styled(ReactPaginate).attrs({
    activeClassName: 'active',
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  //padding: 0 12rem;
  //width: 100%;
  text-align: center;
  color: #166a96;
  align-self: center;
  font-size: 13px;
    font-weight: bold;
    border: none;
  li a {
    padding: 0.3rem 1rem;
    cursor: pointer;
    position: relative;
    margin: 0;
  }
  li:hover a {
    color: #166a96;
    top: 0;
    background: #fff;
    box-shadow: 0 4px 4px rgb(0 0 0 / 20%);
  }
  li.previous a{
    background: transparent !important;
    box-shadow: none;
  }
  li.next a{
    background: transparent !important;
    box-shadow: none;
  }
  li.break a {
    border-color: transparent;
  }
  li.active a {
    border-color: transparent;
     color: black;
     min-width: 32px;
    top: 0;
    background: #fff;
    box-shadow: 0 4px 4px rgb(0 0 0 / 20%);
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
  @media(max-width:768px){
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

export const TextContainer = styled.div`
    font-size: 16px;
    line-height: 1.5;
    @media (max-width: 768px) {
        width: 100%
        display: flex;
        flex-direction: column;
    }
`;

export const ProductTitle = styled.span`
    float: left;
    width: 70%;
    @media (max-width: 992px) {
        height: 62%;
    }
    @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
    }
`;

export const PriceList = styled.span`
    float: left;
    font-size: 16px;
    text-align: right;
    color: #767676;
    text-decoration: line-through;
    @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
    }
`;

export const PromoPrice = styled.span`
    float: left;
    font-size: 16px;
    text-align: right;
    @media (max-width: 768px) {
        width: 100%;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
    }
`;

// export const AddToCartButton = styled.button`
//     display: inline-flex;
//     justify-content: center;
//     font-weight: bold;
//     color: #fff;
//     font-size: 14px;
//     line-height: 21px;
//     letter-spacing: 1px;
//     text-transform: uppercase;
//     padding: 12px 56px;
//     background: #166a96;
//     overflow: hidden;
//     cursor: pointer;
//     border: none;
//     /* "to left" / "to right" - affects initial color */
//     background: linear-gradient(to left, #166a96 50%, #004266 50%) right;
//     background-size: 200%;
//     transition: 0.5s ease-out;
//     &:hover {
//         background-position: left bottom;
//         background-color: #004266;
//         box-sizing: border-box;
//     }
//     @media (max-width: 768px) {
//         width: auto;
//         padding: 12px 36px;
//     }
// `;

// export const ButtonContainer = styled.div`
//     display: flex;
//     position: relative;
//     margin-bottom: 16px;
//     font-size: 16px;
//     line-height: 1.5;
//     align-self: center;
//     bottom: 0;
// `;

export const ButtonContainer = styled.button`
    position: relative;
    display: inline-block;
    text-decoration: none !important;
    text-align: center;
    border-radius: 0;
    transition: all 0.3s ease;
    &.rdePrimary {
        font-weight: bold;
        display: inline-flex;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 14px 46px;
        background: #166a96;
        z-index: 1;
        overflow: hidden;
        cursor: pointer;
        border: none;
        &:before {
            transition: all 0.3s ease;
            position: absolute;
            content: '';
            top: 0;
            left: -100%;
            width: 100%;
            height: 100%;
            background: #004266;
            z-index: -1;
        }
        &:hover {
            color: #fff;
            border-color: #004266;
        }
        &:hover:before {
            left: 0;
        }

        @media (max-width: 767px) {
            width: 100%;
        }
        @media (max-width: 1199px) {
            padding: 12px 32px;
        }
    }
`;

export const MoreTextContainer = styled.div`
    clear: both;
    display: block;
    min-height: 29px;
    font-size: 14px;
    color: #767676;
    font-style: italic;
    line-height: 21px;
    margin-top: auto;
    padding: 8px 0 0 0;
`;

export const StyledHeading = styled(Heading)`
    text-transform: uppercase;
    font-family: 'URW_Din_CondBold';
`;

export const RelatedProductBox = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    color: #000;
    text-decoration: none !important;
    cursor: pointer;
    img {
        object-fit: cover;
    }
`;

export const ProductTile = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: #000;
    text-decoration: none !important;
    padding: 16px;
`;

export const BreadCrumbContainer = styled.div`
    padding: 10px 0;
    position: relative;
    overflow: hidden;
    border-bottom: thin solid #bcc3ca;
    outline: none;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    box-align: start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    -o-align-items: flex-start;
    align-items: flex-start;
    -ms-flex-align: start;
    border: none;

    @media (max-width: 991px) {
        padding: 0 32px 32px;
    }
    @media (max-width: 991px) {
        padding: 0 32px 32px;
        flex-direction: column;
    }
    @media (max-width: 480px) {
        padding: 10px;
    }
`;

export const BreadCrumbItems = styled.div`
    padding-bottom: 10px;
    display: inline-block;
    -moz-flex-grow: 1;
    flex-grow: 1;
    -ms-flex-positive: 1;
    @media (max-width: 480px) {
        padding-bottom: 0;
    }
`;

export const BreadCrumbClearAllButton = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    text-decoration: none !important;
    padding: 0 8px;
    border: none;
    margin-right: 2px;
    &:focus {
        outline: 2px solid #2e88f5;
    }
    &:after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 20px;
        height: 20px;
        background: url('https://www.resideo.com/areas/resideo/img/pill-close.svg') center center
            no-repeat;
        background-size: 20px;
        transform: translateY(-50%);
        content: '';
    }
    & > div {
        font-weight: bold;
        color: #000 !important;
        font-size: 17px;
        line-height: 30px;
        padding-right: 32px;
    }
`;

export const BreadCrumbUnOrderList = styled.ul`
    margin: 0;
    display: block;
    list-style: none;
    padding: 0;
`;

export const BreadCrumbListItem = styled.li`
    display: inline-block;
`;

export const BreadCrumbText = styled.h3`
    font-family: "roboto", sans-serif;
	font-size: 16px;
	color: #000;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 24px
	margin: 0;
	display: inline;
	margin-right: 8px;
`;

export const BreadCrumbButton = styled.button`
    display: inline-flex;
    align-items: center;
    text-decoration: none !important;
    background: #dadada !important;
    margin: 4px 20px 4px 0;
    padding: 0 8px;
    font-size: 17px;
    font-weight: bold;
    color: #000 !important;
    line-height: 30px;
    border: none;
`;

export const BreadCrumbButtonIcon = styled.span`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: #000;
    margin-left: 16px;
    border-radius: 10px;
    :before {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: url('https://www.resideo.com/areas/resideo/img/pill-close.svg') center center
            no-repeat;
        background-size: cover;
        content: '';
    }
`;
